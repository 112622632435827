import React, { useEffect, useState } from 'react'
import SearchBar from 'helper/SearchBar'
import {
  useGetEventDefaultActivities,
  useEventStepTwo,
} from 'api/EventCreation/CreationStep2'
import { creationEventStore } from 'state/eventCreationStore'
import {
  GetActivitiesTypes,
  defaultActivitiesResp,
  EventStepTwoPayload,
} from 'types/events'
import Spinner from 'components/Spinner'

interface DefalutActivityTabProps {
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  params: GetActivitiesTypes
}

const DefalutActivityTab: React.FC<DefalutActivityTabProps> = ({
  closeModal,
  params,
}) => {
  const { _id } = creationEventStore(store => store.eventData)
  const activity = creationEventStore(store => store.activity)
  const setEventData = creationEventStore(store => store.setEventData)
  const { mutate: eventStep2Mutation, isLoading: eventStep2Loading } =
    useEventStepTwo()

  const { data, isLoading, isFetching, refetch } =
    useGetEventDefaultActivities(params)

  const handleSelectActivity = (data: defaultActivitiesResp) => {
    const payload: EventStepTwoPayload = {
      isDefault: true,
      activitiesScheduleV2: {
        duration: activity.difference,
        days: [activity.date],
        eventActivityId: data._id,
      },
    }

    eventStep2Mutation(
      { payload, eventId: _id },
      {
        onSuccess: data => {
          setEventData(data)
          closeModal(false)
        },
      },
    )
  }

  useEffect(() => {
    refetch()
  }, [params])

  return (
    <>
      {isLoading || isFetching ? (
        <div className="mt-30px mx-auto flex justify-center">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="mt-15px gay-searchbar">
            <SearchBar />
          </div>
          <div className="default-activity h-17rem overflow-auto-y scroller">
            {data.map((activity: defaultActivitiesResp) => (
              <div
                className="flex align-center justify-between mt-20px"
                key={activity._id}
              >
                <h3 className="w-500 text-16">{activity.name}</h3>
                <button
                  className="btn"
                  onClick={() => handleSelectActivity(activity)}
                >
                  Select
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default DefalutActivityTab
