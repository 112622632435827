import React, { useState, useEffect } from 'react'
import { Carousel } from 'antd'
import '../schedule-activities.scss'
import { MapPin } from '@phosphor-icons/react'
import partlyCloudy from 'assets/Images/PartlyCloudy.svg'
import rainy from 'assets/Images/Rainy.svg'
import foggy from 'assets/Images/Fog.svg'
import rainThunder from 'assets/Images/RainThunder.svg'
import sunny from 'assets/Images/Sunny.svg'
import { creationEventStore } from 'state/eventCreationStore'
import { useGetEventWeather } from 'api/EventCreation/CreationStep2'
import { WeatherRespType, WeatherDescription } from 'types/events'
import ButtonSpinner from 'components/global/ButtonSpinner'
import moment from 'moment'

const WeatherCard: React.FC = () => {
  // todo change this later
  const eventData = creationEventStore(store => store.eventData) as any

  const {
    data: weatherData,
    isLoading: weatherLoading,
    isFetching: weatherFetching,
    refetch: weatherRefetch,
  } = useGetEventWeather(eventData?._id)
  const [selectedTemp, setSelectedTemp] = useState<WeatherRespType>()
  const getWeatherConditionImage = (condition: WeatherDescription) => {
    switch (condition) {
      case 'Partly cloudy':
      case 'Overcast':
        return partlyCloudy
        break

      case 'Clear sky':
      case 'Mainly clear':
        return sunny
        break

      case 'Fog':
        return foggy
        break

      case 'Drizzle':
      case 'Freezing Drizzle':
      case 'Rain':
      case 'Freezing Rain':
      case 'Rain showers':
      case 'Snow showers':
      case 'Snow grains':
      case 'Snow fall':
        return rainy
        break

      case 'Thunderstorm with slight and heavy hail':
      case 'Thunderstorm: Slight or moderate':
        return rainThunder
        break

      default:
        return null
    }
  }

  useEffect(() => {
    if (weatherData) {
      setSelectedTemp(weatherData[0])
    }
  }, [weatherData])

  useEffect(() => {
    weatherRefetch()
  }, [eventData._id])

  return (
    <>
      {weatherLoading || weatherFetching ? (
        <div className="w-full flex align-center justify-center">
          <ButtonSpinner size={50} color="#2ba7ca" />
        </div>
      ) : (
        <div className="weather-card-container">
          <div className="flex gap-20px align-center">
            <div>
              <div className="flex align-center gap-5px">
                <MapPin size={16} color="#FEFEFF" />
                <p className="text-14 w-500 color-white-light">
                  {eventData.location.city}
                </p>
              </div>
              <h3 className="flex text-40 w-700 text-white">
                {selectedTemp?.apparentTemperatureMax} <span>&deg;</span>
              </h3>
              <h4 className="text-14 w-700 color-white-light">
                {selectedTemp?.weatherDescription}
              </h4>
            </div>
            <div className="current-weather-img">
              <img
                src={getWeatherConditionImage(selectedTemp?.weatherDescription)}
                alt={selectedTemp?.weatherDescription}
                className="w-full h-full"
              />
            </div>
          </div>

          <div className="carousel-cont">
            <Carousel
              slidesToShow={5}
              slidesToScroll={1}
              arrows={true}
              infinite={false}
              dots={false}
            >
              {weatherData.map((weather: WeatherRespType, index: number) => {
                return (
                  <div
                    key={index}
                    className={`week-weather-wrapper pointer ${
                      weather.time === selectedTemp?.time && 'current'
                    }`}
                    onClick={() => setSelectedTemp(weather)}
                  >
                    <p className="text-10 w-500 text-white">
                      {moment(weather?.time).format('DD/MM')}
                    </p>
                    <h4 className="flex text-14 w-700 text-white mt-10px">
                      {weather?.apparentTemperatureMax} <span>&deg;</span>
                    </h4>
                    <div className="weekly-weather-img mt-20px">
                      <img
                        className="w-full h-full"
                        src={getWeatherConditionImage(
                          weather?.weatherDescription,
                        )}
                        alt={weather?.weatherDescription}
                      />
                    </div>
                  </div>
                )
              })}
            </Carousel>
          </div>

          {/* <div className="flex overlfow-cont">
            {weatherData.map((weather: WeatherRespType, index: number) => {
              return (
                <div
                  key={index}
                  className={`week-weather-wrapper pointer ${
                    weather.time === selectedTemp?.time && 'current'
                  }`}
                  onClick={() => setSelectedTemp(weather)}
                >
                  <p className="text-10 w-500 text-white">
                    {moment(weather?.time).format('DD/MM')}
                  </p>
                  <h4 className="flex text-14 w-700 text-white mt-10px">
                    {weather?.apparentTemperatureMax} <span>&deg;</span>
                  </h4>
                  <div className="weekly-weather-img mt-20px">
                    <img
                      className="w-full h-full"
                      src={getWeatherConditionImage(
                        weather?.weatherDescription,
                      )}
                      alt={weather?.weatherDescription}
                    />
                  </div>
                </div>
              )
            })}
          </div> */}
        </div>
      )}
    </>
  )
}

export default WeatherCard
