import React, { useState } from 'react'
import { Layout, Steps, Button } from 'antd'
import Exitwithoutfinishing from '../../pages/Events/Views/Exitwithoutfinishing'
import './styles/sidebar.scss'
import { creationEventStore } from 'state/eventCreationStore'

const { Sider } = Layout
const EventSidebar: React.FC = () => {
  const stepCounter = creationEventStore(store => store.stepCounter)
  const { Step } = Steps
  const [isModalVisibleEvent, setModalVisibleEvent] = useState(false)
  const steps = [
    {
      title: 'Step 1',
      description: <b className="text-18 w-600">General Details</b>,
    },
    {
      title: 'Step 2',
      description: <b className="text-18 w-600">Schedule and activities</b>,
    },
    {
      title: 'Step 3',
      description: <b className="text-18 w-600">Supplies & Cost</b>,
    },
    {
      title: 'Step 4',
      description: <b className="text-18 w-600">Manage tickets</b>,
    },
    {
      title: 'Step 5',
      description: <b className="text-18 w-600">Additional event settings</b>,
    },
  ]
  return (
    <Sider className="side-bar create-event-side-bar " width={'506px'}>
      <div className="d-flex flex-column justify-between stepper-section-header pb-10">
        <div>
          <h2 className="mb-16 create-event-sidebar-heading">Create event</h2>
          <span className="mb-20 complete-all-steps">
            Complete all steps to create the event, you can also save it as a
            draft at any step.
          </span>
          <Steps direction="vertical" current={stepCounter}>
            {steps.map(item => (
              <Step
                key={item.title}
                className="stepper event-stepper event-step-bar"
                title={item.title}
                description={item.description}
              />
            ))}
          </Steps>
        </div>
        <div className="mt-30 save-progress-and-exit">
          <Button
            className="primary-transparent mb-30"
            style={{ width: '100%', height: '50px' }}
            onClick={() => {
              setModalVisibleEvent(true)
            }}
          >
            Save progress & exit
          </Button>
        </div>
      </div>
      {isModalVisibleEvent ? (
        <Exitwithoutfinishing
          isModalVisibleEvent={isModalVisibleEvent}
          setModalVisibleEvent={setModalVisibleEvent}
        />
      ) : (
        <></>
      )}
    </Sider>
  )
}

export default EventSidebar
