/* eslint-disable import/no-anonymous-default-export */
import actions from './action'

const initState = {
  ismodelVisible: false,
  eventsData: JSON.parse(localStorage.getItem('events')) || [],
  events: [],
  eventsDetails: {},
  eventOrganizationData: {},
  currentEventsData: [],
  eventsDataValue: {},
  eventCategoryData: [],
  eventActivityCategoryData: [],
  eventActivityGradesData: [],
  currentEventStepperStep: 0,
  loading: false,
  eventDefaultActivities: [],
  eventsScheduleActivitiesValue: {},
  eventsSuppliesDataValue: {},
  eventsAdmissionTicketDataValue: {},
  eventsAdditionalTicketDataValue: {},
  eventsDropInTicketDataValue: {},
  eventsAdditionalData: [],
  itemBringData: [],
  faqData: [],
  reqDocumentData: [],
  eventsPaymentFrequencyData: {},
  totalCount: 0,
  mentorEvents: [],
  mentorEventsTotalCount: 0,
  eventActivities: [],
  currentEvent: null,
  organizationLocations: [],
  approvedLocations: [],
  organizationFaqs: [],
  mentorRequest: null,
  shifts: null,
  approveSuccess: false,
  recentCheckIn: {},
}

export default (state = initState, action) => {
  switch (action.type) {
    case actions.GET_EVENT_REQUEST: {
      return {
        ...state,
        events: [],
        loading: true,
      }
    }

    case actions.GET_EVENT_REQUEST_SUCCESS: {
      return {
        ...state,
        totalCount: action.payload.count,
        events: action.payload.records,
        eventOrganizationData: {},
        loading: false,
      }
    }

    case actions.GET_EVENT_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.GET_MENTOR_EVENTS: {
      return {
        ...state,
        mentorEvents: [],
        loading: true,
      }
    }

    case actions.GET_MENTOR_EVENTS_SUCCESS: {
      return {
        ...state,
        mentorEventsTotalCount: action.payload.count,
        mentorEvents: action.payload.records,
        loading: false,
      }
    }

    case actions.GET_MENTOR_EVENTS_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.GET_ORGANIZATION_LOCATIONS: {
      return {
        ...state,
        organizationLocations: [],
        loading: true,
      }
    }

    case actions.GET_ORGANIZATION_LOCATIONS_SUCCESS: {
      return {
        ...state,
        organizationLocations: action.payload,
        loading: false,
      }
    }

    case actions.GET_ORGANIZATION_LOCATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.GET_APPROVED_LOCATIONS: {
      return {
        ...state,
        approvedLocations: [],
        loading: true,
      }
    }

    case actions.GET_APPROVED_LOCATIONS_SUCCESS: {
      return {
        ...state,
        approvedLocations: action.payload.records,
        loading: false,
      }
    }

    case actions.GET_APPROVED_LOCATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.GET_EVENT_DETAILS_REQUEST: {
      return {
        ...state,
        eventsDetails: {},
        loading: true,
      }
    }

    case actions.GET_EVENT_DETAILS_REQUEST_SUCCESS: {
      return {
        ...state,
        eventsDetails: action.payload,
        loading: false,
      }
    }

    case actions.GET_EVENT_DETAILS_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.GET_EVENT_ORGANIZATION_REQUEST: {
      return {
        ...state,
        eventOrganizationData: {},
        currentEvent: null,
        loading: true,
      }
    }

    case actions.GET_EVENT_ORGANIZATION_REQUEST_SUCCESS: {
      return {
        ...state,
        eventOrganizationData: action.payload,
        currentEvent: action.payload,
        loading: false,
      }
    }

    case actions.GET_EVENT_ORGANIZATION_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA: {
      return {
        ...state,
        eventsDataValue: {},
        loading: true,
      }
    }

    case actions.ADD_EVENTS_DATA_SUCCESS: {
      return {
        ...state,
        eventsDataValue: action.payload,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.ADD_MENTOR_REQUEST_DATA_IN_EVENT: {
      return {
        ...state,
        mentorRequest: null,
        loading: true,
      }
    }

    case actions.ADD_MENTOR_REQUEST_DATA_IN_EVENT_SUCCESS: {
      return {
        ...state,
        mentorRequest: action.payload,
        loading: false,
      }
    }

    case actions.ADD_MENTOR_REQUEST_DATA_IN_EVENT_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.ADD_MENTOR_SHIFT_DATA_IN_EVENT: {
      return {
        ...state,
        shifts: null,
        loading: true,
      }
    }

    case actions.ADD_MENTOR_SHIFT_DATA_IN_EVENT_SUCCESS: {
      return {
        ...state,
        shifts: action.payload,
        loading: false,
      }
    }

    case actions.ADD_MENTOR_SHIFT_DATA_IN_EVENT_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.CREATE_EVENT_SPECIFIC_ACTIVITY: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.CREATE_EVENT_SPECIFIC_ACTIVITY_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.CREATE_EVENT_SPECIFIC_ACTIVITY_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.EDIT_EVENTS_DATA: {
      return {
        ...state,
        eventsDataValue: {},
        loading: true,
      }
    }

    case actions.EDIT_EVENTS_DATA_SUCCESS: {
      return {
        ...state,
        eventsDataValue: action.payload,
        loading: false,
      }
    }

    case actions.EDIT_EVENTS_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.EDIT_MENTOR_REQUEST_DATA_IN_EVENT: {
      return {
        ...state,
        mentorRequest: {},
        loading: true,
      }
    }

    case actions.EDIT_MENTOR_REQUEST_DATA_IN_EVENT_SUCCESS: {
      return {
        ...state,
        mentorRequest: action.payload,
        loading: false,
      }
    }

    case actions.EDIT_MENTOR_REQUEST_DATA_IN_EVENT_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.UPDATE_MENTOR_REQUEST_STATUS_IN_EVENT_FAILURE: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.UPDATE_MENTOR_REQUEST_STATUS_IN_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.UPDATE_MENTOR_REQUEST_STATUS_IN_EVENT_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.UPDATE_EVENT_POST_STATUS: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.UPDATE_EVENT_POST_STATUS_SUCCESS: {
      return {
        ...state,
        ismodelVisible: false,
        eventsData: JSON.parse(localStorage.getItem('events')) || [],
        events: [],
        eventsDetails: {},
        eventOrganizationData: {},
        currentEventsData: [],
        eventsDataValue: {},
        eventCategoryData: [],
        currentEventStepperStep: 0,
        eventDefaultActivities: [],
        eventsScheduleActivitiesValue: {},
        eventsSuppliesDataValue: {},
        eventsAdmissionTicketDataValue: {},
        eventsAdditionalTicketDataValue: {},
        eventsDropInTicketDataValue: {},
        eventsAdditionalData: [],
        itemBringData: [],
        faqData: [],
        reqDocumentData: [],
        eventsPaymentFrequencyData: {},
        totalCount: 0,
        mentorEvents: [],
        mentorEventsTotalCount: 0,
        eventActivities: [],
        currentEvent: null,
        organizationLocations: [],
        approvedLocations: [],
        organizationFaqs: [],
        mentorRequest: null,
        shifts: null,
        loading: false,
      }
    }

    case actions.UPDATE_EVENT_POST_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.EVENTS_MODAL_VISIBLE: {
      return {
        ...state,
        ismodelVisible: action.payload,
      }
    }

    case actions.GET_EVENT_CATEGORIES: {
      return {
        ...state,
        eventCategoryData: [],
        loading: true,
      }
    }

    case actions.GET_EVENT_CATEGORIES_SUCCESS: {
      return {
        ...state,
        eventCategoryData: action.payload,
        loading: false,
      }
    }

    case actions.GET_EVENT_CATEGORIES_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.GET_EVENT_ACTIVITY_GRADES: {
      return {
        ...state,
        eventActivityGradesData: [],
        loading: true,
      }
    }

    case actions.GET_EVENT_ACTIVITY_GRADES_SUCCESS: {
      return {
        ...state,
        eventActivityGradesData: action.payload,
        loading: false,
      }
    }

    case actions.GET_EVENT_ACTIVITY_GRADES_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.GET_EVENT_ACTIVITY_CATEGORIES: {
      return {
        ...state,
        eventActivityCategoryData: [],
        loading: true,
      }
    }

    case actions.GET_EVENT_ACTIVITY_CATEGORIES_SUCCESS: {
      return {
        ...state,
        eventActivityCategoryData: action.payload,
        loading: false,
      }
    }

    case actions.GET_EVENT_ACTIVITY_CATEGORIES_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.CURRENT_EVENT_STEPPER_STEP: {
      return {
        ...state,
        currentEventStepperStep: action.payload,
        // loading: true,
      }
    }

    case actions.ADD_EVENTS_DATA_STEP_TWO: {
      return {
        ...state,
        eventsScheduleActivitiesValue: action.payload,
        loading: true,
      }
    }

    case actions.ADD_EVENTS_DATA__STEP_TWO_SUCCESS: {
      return {
        ...state,
        eventsScheduleActivitiesValue: action.payload,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA__STEP_TWO_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA_STEP_THREE: {
      return {
        ...state,
        eventsSuppliesDataValue: action.payload,
        loading: true,
      }
    }

    case actions.ADD_EVENTS_DATA__STEP_THREE_SUCCESS: {
      return {
        ...state,
        eventsSuppliesDataValue: action.payload,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA__STEP_THREE_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA_STEP_FOUR_ADMISSION: {
      return {
        ...state,
        eventsAdmissionTicketDataValue: action.payload,
        loading: true,
      }
    }

    case actions.ADD_EVENTS_DATA_STEP_FOUR_ADMISSION_SUCCESS: {
      return {
        ...state,
        eventsAdmissionTicketDataValue: action.payload,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA_STEP_FOUR_ADMISSION_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA_STEP_FOUR_ADDITIONAL: {
      return {
        ...state,
        eventsAdditionalTicketDataValue: action.payload,
        loading: true,
      }
    }

    case actions.ADD_EVENTS_DATA_STEP_FOUR_ADDITIONAL_SUCCESS: {
      return {
        ...state,
        eventsAdditionalTicketDataValue: action.payload,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA_STEP_FOUR_ADDITIONAL_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA_STEP_FOUR_DROPIN: {
      return {
        ...state,
        eventsDropInTicketDataValue: action.payload,
        loading: true,
      }
    }

    case actions.ADD_EVENTS_DATA_STEP_FOUR_DROPIN_SUCCESS: {
      return {
        ...state,
        eventsDropInTicketDataValue: action.payload,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA_STEP_FOUR_DROPIN_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA_STEP_FIVE: {
      return {
        ...state,
        eventsAdditionalData: {},
        loading: true,
      }
    }

    case actions.ADD_EVENTS_DATA_STEP_FIVE_SUCCESS: {
      return {
        ...state,
        eventsAdditionalData: action.payload,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA_STEP_FIVE_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.SET_ADD_ITEM_BRING_DATA: {
      return {
        ...state,
        itemBringData: [...state.itemBringData, action.payload],
      }
    }

    case actions.SET_ADD_FAQ_DATA: {
      return {
        ...state,
        faqData: [...state.faqData, action.payload],
      }
    }

    case actions.SET_ADD_REQ_DOCUMENT_DATA: {
      return {
        ...state,
        reqDocumentData: [...state.reqDocumentData, action.payload],
      }
    }

    case actions.ADD_EVENTS_PAYMENT_FREQUENCY_DATA: {
      return {
        ...state,
        eventsPaymentFrequencyData: {},
        loading: true,
      }
    }

    case actions.ADD_EVENTS_PAYMENT_FREQUENCY_DATA_SUCCESS: {
      return {
        ...state,
        eventsPaymentFrequencyData: action.payload,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_PAYMENT_FREQUENCY_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.GET_EVENT_DEFAULT_ACTIVITIES: {
      return {
        ...state,
        eventDefaultActivities: [],
        loading: true,
      }
    }

    case actions.GET_EVENT_DEFAULT_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        eventDefaultActivities: action.payload,
        loading: false,
      }
    }

    case actions.GET_EVENT_DEFAULT_ACTIVITIES_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.GET_EVENT_ORGANIZATION_FAQS: {
      return {
        ...state,
        organizationFaqs: [],
        loading: true,
      }
    }

    case actions.GET_EVENT_ORGANIZATION_FAQS_SUCCESS: {
      return {
        ...state,
        organizationFaqs: action.payload,
        loading: false,
      }
    }

    case actions.GET_EVENT_ORGANIZATION_FAQS_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.DELETE_SCHEDULED_EVENT_ACTIVITY: {
      return {
        ...state,
        //eventDefaultActivities: [],
        loading: true,
      }
    }

    case actions.DELETE_SCHEDULED_EVENT_ACTIVITY_SUCCESS: {
      return {
        ...state,
        //eventDefaultActivities: action.payload,
        loading: false,
      }
    }

    case actions.DELETE_SCHEDULED_EVENT_ACTIVITY_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.DELETE_ADMISSION_TICKET: {
      return {
        ...state,
        //eventDefaultActivities: [],
        loading: true,
      }
    }

    case actions.DELETE_ADMISSION_TICKET_SUCCESS: {
      return {
        ...state,
        //eventDefaultActivities: action.payload,
        loading: false,
      }
    }

    case actions.DELETE_ADMISSION_TICKET_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.DUPLICATE_ADMISSION_TICKET: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.DUPLICATE_ADMISSION_TICKET_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.DUPLICATE_ADMISSION_TICKET_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.DUPLICATE_ADDITIONAL_OPTION_TICKET: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.DUPLICATE_ADDITIONAL_OPTION_TICKET_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.DUPLICATE_ADDITIONAL_OPTION_TICKET_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.DELETE_DROP_IN_TICKET: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.DELETE_DROP_IN_TICKET_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.DELETE_DROP_IN_TICKET_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.DELETE_ADDITIONAL_OPTION_TICKET: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.DELETE_ADDITIONAL_OPTION_TICKET_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.DELETE_ADDITIONAL_OPTION_TICKET_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.EDIT_EVENT_SPECIFIC_ACTIVITY: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.EDIT_EVENT_SPECIFIC_ACTIVITY_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.EDIT_EVENT_SPECIFIC_ACTIVITY_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }
    case action.APPROVE_PARTICIPANTS: {
      return {
        ...state,
        approveSuccess: true,
      }
    }
    case actions.GET_RECENT_CHECK_IN: {
      return {
        ...state,
        recentCheckIn: action.payload,
      }
    }

    default:
      return state
  }
}
