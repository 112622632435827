import React, { useState, useEffect } from 'react'
import {
  useEventStepTwo,
  useGetEventDefaultActivities,
} from 'api/EventCreation/CreationStep2'
import {
  EventStepTwoPayload,
  GetActivitiesTypes,
  defaultActivitiesResp,
} from 'types/events'
import { creationEventStore } from 'state/eventCreationStore'
import Spinner from 'components/Spinner'
import { FileMagnifyingGlass } from '@phosphor-icons/react'

interface SpecificActivtyTabProps {
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  params: GetActivitiesTypes
}

const SpecificActivtyTab: React.FC<SpecificActivtyTabProps> = ({
  closeModal,
  params,
}) => {
  const { _id } = creationEventStore(store => store.eventData)
  const activity = creationEventStore(store => store.activity)
  const setEventData = creationEventStore(store => store.setEventData)
  const {
    data: eventActivitiesData,
    isLoading: eventActivitiesLoading,
    isFetching: eventActivitiesFetching,
    refetch: eventActivitiesRefetch,
  } = useGetEventDefaultActivities(params)
  const { mutate: eventStep2Mutation, isLoading: eventStep2Loading } =
    useEventStepTwo()

  const handleSelectActivity = (data: defaultActivitiesResp) => {
    const payload: EventStepTwoPayload = {
      isDefault: true,
      activitiesScheduleV2: {
        duration: activity.difference,
        days: [activity.date],
        eventActivityId: data._id,
      },
    }

    eventStep2Mutation(
      { payload, eventId: _id },
      {
        onSuccess: data => {
          setEventData(data)
          closeModal(false)
        },
      },
    )
  }

  useEffect(() => {
    eventActivitiesRefetch()
  }, [params])

  return (
    <>
      {eventActivitiesLoading || eventActivitiesFetching ? (
        <div className="mt-30px mx-auto flex justify-center">
          <Spinner />
        </div>
      ) : (
        <div>
          {eventActivitiesData.length === 0 ? (
            <div className="mt-30px">
              <FileMagnifyingGlass size={100} color="#D5D5D5" />
              <p className="mt-15px">No activites available</p>
            </div>
          ) : (
            <div className="default-activity h-20 overflow-auto-y scroller">
              {eventActivitiesData.map((activity: defaultActivitiesResp) => (
                <div
                  className="flex align-center justify-between mt-20px"
                  key={activity._id}
                >
                  <h3 className="w-500 text-16">{activity.name}</h3>
                  <button
                    className="btn"
                    onClick={() => handleSelectActivity(activity)}
                  >
                    Select
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default SpecificActivtyTab
