import React, {useEffect, useState} from "react";
import { Button, Row, Col, Form, Input } from "antd";
import Modal from "antd/lib/modal/Modal";
import amenitiesActions from "../../../../../../../Redux/Amenities/action";

const AmenitiesModal = ({
                          setAmenityModalVisible,
                          setMapModalData,
                            amenitiesData,
                          setFieldsValue,
                            setAdditionalAmenities ,
                            amenityIndexToEdit,
                            setAmenityIndexToEdit,
                            currentAmenity,
                            setCurrentAmenity
                      }: any) => {
    const [form] = Form.useForm();
    const [current, setCurrent] = React.useState(0);
    const [data, setData] = useState(amenitiesData || []);

    const formSchema = {
        name: currentAmenity?.name ? currentAmenity?.name : "",
    };

    const validateMessages = {
        required: "${label} is required!",
    };
    useEffect(() => {
       console.log("amenity index", amenityIndexToEdit)
    }, []);
    const onFinish = (values: any) => {
        if(currentAmenity){
            let temp = data ;
            temp = temp[amenityIndexToEdit] = values
            console.log(temp);
            // setAdditionalAmenities(temp)
            // setAdditionalAmenities([amenitiesData[amenityIndexToEdit] = values]);
        }else{
            setAdditionalAmenities([...data,values]);
        }
        setAmenityModalVisible(false);
        setAmenityIndexToEdit(null)
        setCurrentAmenity(null)

    };

    return (
        <div>
            <Modal
                visible={true}
                className="map-modal"
                footer={null}
                centered={true}
                width={600}
                onCancel={() => {
                    setAmenityModalVisible(false);
                }}
            >
                <h2 className="mb-20 b1" style={{paddingTop : 20}}>{amenityIndexToEdit !== null ? "Edit amenity" : "Add amenity" }</h2>
                <Form
                    form={form}
                    layout="horizontal"
                    initialValues={formSchema}
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                >
                    <Row gutter={10}>
                        <Col>
                            <label className="input-label required-input">Amenity name</label>
                            <Form.Item
                                className="form-control"
                                name="name"
                                rules={[
                                    { required: true, message: "Amenity name is required" },
                                ]}
                            >
                                <Input
                                    placeholder="Amenity name"
                                    className="form-control-field"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-center gap-20 mt-10">
                            <Button
                                className="gry-btn btn-h-50 w-155 hl-50 br-8 py-0"
                                onClick={() => {
                                    setAmenityModalVisible(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button htmlType="submit" className="primary-btn btn-h-50 w-155 br-8 hl-50 py-0">
                                {currentAmenity ? "Update" : "Save"}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default AmenitiesModal;
