import { Tabs } from 'antd'
import { useState ,useEffect} from 'react'
import Schedule from './Schedule'
import AppLayout from '../../../components/layout/layout'
import SessionActivity from '../../Sessions/Profiles/SessionActivities'
import EventAnalytics from './Analytics'
import ProfileHeader from './ProfileHeader'
import EventDetails from './Details'
import { useParams } from 'react-router-dom'
import Attendance from './Attendance'
import { getEventById } from '../../../api/Events'
import Payments from './Payments'
import { useQuery } from '@tanstack/react-query'
import Loader from 'helper/Loader'
const { TabPane } = Tabs
const EventsDetails = () => {
  const { id }: any = useParams()
  const eventId = id 
  // console.log(id)
  const { data:event, isLoading, refetch } = useQuery(
    ['getEventById', id],
    () => {
      return getEventById(id)
    },
    { enabled: false },
  )

  useEffect(() => {
    if (eventId) {
      refetch()
    }
  }, [eventId, refetch])
  if (isLoading) {
    return <Loader />
  }

  return (
    <AppLayout>
      <div className="container bg-grey">
        <div className="org-profile-header pt-30">
          <ProfileHeader event={event} />
        </div>
        <Tabs defaultActiveKey="1" className="bg-white profile-tabs">
          <TabPane tab="Details" key="1">
            <EventDetails event={event} />
          </TabPane>
          <TabPane tab="Schedule" key="2">
            <Schedule />
          </TabPane>
          <TabPane tab="Updates" key="3" className={'event-tab-items'}>
            <SessionActivity contractRoomId={id} />
          </TabPane>
          <TabPane tab="Analytics" key="4">
            <EventAnalytics event={event} />
          </TabPane>
          <TabPane tab="Attendance" key="5">
            <Attendance  />
          </TabPane>
          <TabPane tab="Payment" key="6">
            <Payments />
          </TabPane>

          <TabPane tab="Staff" key="7"></TabPane>
          <TabPane tab="Notes" key="8"></TabPane>
        </Tabs>
      </div>
    </AppLayout>
  )
}

export default EventsDetails
