import { Checkbox, Radio, RadioChangeEvent, Dropdown, Menu, Space } from 'antd'
import { CloseDropeDown } from 'helper/CloseDropdown'
import Down from 'helper/Icons/DownIcon'
import { useEffect, useRef, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import NoStatus from 'assets/Images/noStatus.svg'
import PendingCheckOut from 'assets/Images/pendignCheckout.svg'
import DropInIco from 'assets/Images/dropIn.svg'
import CheckedOut from 'assets/Images/checkedOut.svg'
import CheckedIn from 'assets/Images/checkedIn.svg'
import { failureMessage } from 'api/global'
import moment from 'moment'
import { DownOutlined } from '@ant-design/icons'
import {
  AtendeeStatusParams,
  checkInOutStatusType,
  Attendees,
} from 'pages/Events/types'
import {
  capitalizeFirstLetter,
  clearFilters,
  convertToLocal,
} from 'helper/utility'
import RoolCall from './RollCall'
import DropIn from './Drop-in'
import downloadImg from 'assets/Images/Download.svg'
import ExportCheckInOut from './ExportCheckInOut'
interface StatusL {
  key: number
  value: typeof checkInOutStatusType
  title: string
  img: string
  color: string
}
export const StatusList: StatusL[] = [
  {
    key: 1,
    value: 'no-status',
    title: 'No status',
    img: NoStatus,
    color: '#F6F7F8',
  },
  {
    key: 2,
    value: 'droped-in',
    title: 'Dropped in',
    img: DropInIco,
    color: '#E8E9F7',
  },
  {
    key: 3,
    value: 'dropped-out',
    title: 'Dropped out',
    img: DropInIco,
    color: '#E8E9F7',
  },
  {
    key: 3,
    value: 'checked-in',
    title: 'Checked in',
    img: CheckedIn,
    color: '#F1F8E7',
  },
  {
    key: 4,
    value: 'checked-out',
    title: 'Checked out',
    img: CheckedOut,
    color: '#FFE2E0',
  },
  {
    key: 5,
    value: 'request-check-out',
    title: 'Request Check-out',
    img: PendingCheckOut,
    color: '#FEF4E8',
  },
  {
    key: 6,
    value: 'pending',
    title: 'Pending',
    img: PendingCheckOut,
    color: '#FEF4E8',
  },
  {
    key: 7,
    value: 'request-check-in',
    title: 'Request Check-in',
    img: PendingCheckOut,
    color: '#FEF4E8',
  },
]
interface Header {
  participantData: Attendees[]
  params: AtendeeStatusParams
  setParms: React.Dispatch<React.SetStateAction<AtendeeStatusParams>>
  setView: React.Dispatch<React.SetStateAction<'particpants' | 'roll-calls'>>
  currentView: 'particpants' | 'roll-calls'
  date: any
}
const CheckInHeader: React.FC<Header> = ({
  participantData,
  params,
  setParms,
  setView,
  currentView,
  date,
}) => {
  const currentDate = moment().startOf('day').format('DD MMMM YYYY')
  const selectedDate = convertToLocal(date, 'DD MMMM YYYY')
  const [open, setOpen] = useState(0)
  const [modal, setModal] = useState<number>(0)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const handleDropdownClick = CloseDropeDown({ dropdownRef, setOpen })
  const handleSearchChange = (e: any) => {
    setParms((prevParms: any) => ({
      ...prevParms,
      searchQuery: e,
    }))
  }
  const [selectedStatus, setSelectedStatus] = useState<
    typeof checkInOutStatusType | ''
  >()
  const handleCheckboxChange = (key: typeof checkInOutStatusType) => {
    setSelectedStatus(key)
    setOpen(0)
  }
  useEffect(() => {
    if (selectedStatus !== '') {
      setParms(prevParams => ({
        ...prevParams,
        status: selectedStatus,
      }))
    }
  }, [selectedStatus])
  const debounced = debounce(handleSearchChange, 300)
  const filteredStatusList = StatusList.filter(
    item => item.value !== 'no-status',
  )
  const Status = [
    {
      key: '1',
      label: (
        <div>
          {filteredStatusList.map(item => {
            return (
              <div key={item.key} className="flex mt-15px align-center">
                <Checkbox
                  checked={selectedStatus === item.value}
                  onChange={() => handleCheckboxChange(item.value)}
                />
                <div
                  style={{ backgroundColor: `${item.color}` }}
                  className="flex px-15px py-10px radius-12 ml-10px"
                >
                  <img src={item.img} alt={item.title} />
                  <h4 className="ml-10px text-12 w-600">{item.title}</h4>
                </div>
              </div>
            )
          })}
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <h4
          className="border-top pt-15px pb-10px prim-txt text-16 w-500"
          onClick={() => {
            clearFilters('status', params, setParms, setSelectedStatus)
          }}
        >
          Clear filter
        </h4>
      ),
    },
  ]

  const Filter = [
    {
      key: '1',
      label: (
        <Radio.Group
          options={[
            { label: 'Particpants', value: 'particpants' },
            { label: 'Roll-calls', value: 'roll-calls' },
          ]}
          className="dropdwn checkbox"
          value={currentView}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          onChange={(val: RadioChangeEvent) => setView(val.target.value)}
        />
      ),
    },
    {
      key: 'btns',
      label: (
        <div className="flex justify-between mx-15px">
          <button className="clr-btn">Clear</button>
          <button
            onClick={() => {
              setOpen(0)
            }}
            className="aply-btn"
          >
            Apply
          </button>
        </div>
      ),
    },
  ]
  const handleClick = () => {
    if (selectedDate !== currentDate) {
      failureMessage('Please select the current date to perform this action')
    } else {
      setModal(2)
    }
  }
  const [isExportModalVisible, setExportModalVisible] = useState(false)
  const handleModalClose = () => {
    setExportModalVisible(false)
  }
  const handleExportClick = () => {
    setExportModalVisible(true)
  }
  const menu = (
    <Menu>
      <Menu.Item onClick={handleExportClick}>
        <Space>
          <img alt="img" src={downloadImg} /> Export
        </Space>
      </Menu.Item>
    </Menu>
  )
  return (
    <div className="flex justify-between my-20px">
      <div className="flex align-center ">
        <div className="search-input-box w-fit">
          <input
            type="search"
            className="search-input-group "
            placeholder="Search"
            style={{ width: '350px' }}
            onChange={e => {
              e.preventDefault()
              debounced(e.target.value)
            }}
          />
          <SearchOutlined />
        </div>
        <div
          ref={dropdownRef}
          onClick={handleDropdownClick}
          className="flex align-center"
        >
          <Dropdown
            menu={{
              items: Status,
            }}
            placement="bottomLeft"
            arrow={false}
            className=" mr-10px"
            open={open === 1}
            overlayStyle={{ width: '300px' }}
          >
            <button
              className={` ${
                open === 1 ? 'filter-btn-click ' : 'filter-btn'
              } w-500`}
              onClick={() => setOpen(1)}
            >
              Status <Down />
            </button>
          </Dropdown>
          <Dropdown
            menu={{
              items: Filter,
            }}
            placement="bottomLeft"
            arrow={false}
            className="ml-20px mr-10px"
            open={open === 2}
            overlayStyle={{ width: '250px' }}
          >
            <button
              className={` ${
                open === 2 ? 'filter-btn-click ' : 'filter-btn'
              } w-500`}
              onClick={() => setOpen(2)}
            >
              {capitalizeFirstLetter(currentView)} <Down />
            </button>
          </Dropdown>
        </div>
      </div>
      <div className="flex">
        <Dropdown overlay={menu} placement="bottom" className="bg-grey mx-20px">
          <button className=" prim-sm-btn" style={{ color: 'black' }}>
            More <DownOutlined />
          </button>
        </Dropdown>
        <button
          className="borderd-btn ml-20px"
          onClick={() => {
            setModal(1)
          }}
        >
          Drop-in
        </button>
        <button
          className="borderd-btn ml-20px"
          onClick={() => {
            handleClick()
          }}
        >
          Roll Call
        </button>
      </div>
      {modal === 1 && <DropIn setOpen={setModal} />}
      {modal === 2 && selectedDate === currentDate && (
        <RoolCall setOpen={setModal} participants={participantData} />
      )}
      <ExportCheckInOut
        visible={isExportModalVisible}
        onClose={handleModalClose}
        data={participantData}
      />
      {/* {selectedDate !== currentDate && (
        <>
          {failureMessage(
            'Please select the current date to perform this action',
          )}
        </>
      )}

      {modal === 2 && selectedDate === currentDate && (
        <RoolCall setOpen={setModal} participants={participantData} />
      )} */}
    </div>
  )
}
export default CheckInHeader
