import { Modal, Select } from 'antd'
import Decrease from '../../../../../assets/Images/decrease.svg'
import Increase from '../../../../../assets/Images/increase.svg'
import { useState } from 'react'
import Cancel from '../../../../../assets/Images/close.svg'
import { useMutation } from '@tanstack/react-query'
import {
  AddFamilyCredits,
  DeductFamilyCredits,
} from '../../../../../api/family'

const EditCredits = ({ fId, fetchAgain, closeModel, org }: any) => {
  const [open, setOpen] = useState<boolean>(true)
  const [formData, setFormData] = useState<any>({
    credits: org.current,
    newBalance: org.current,
    orgData: {},
    notes: '',
  })
  const handleDataChange = (event: any) => {
    const { name, value } = event.target
    if (value.trim() === '') {
      setFormData({
        ...formData,
        credits: 0,
        newBalance: org.current,
      })
      return
    }
    setFormData({
      ...formData,
      [name]: value,
      // newBalance:
      //   value > org?.current ? value - org.current : value - org.current,
      newBalance:
        parseFloat(value) > 0
          ? parseInt(value) + org.current
          : parseInt(value) + org.current,
    })
  }

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (parseFloat(value) === 0) {
      event.target.value = ''
    }
  }

  const Intparser = (pdata: any) => {
    return parseInt(pdata?.toString(), 10)
  }
  const addCredits = useMutation({
    mutationFn: (mutData: any) => {
      return AddFamilyCredits(fId, mutData)
    },
    onSuccess: () => {
      fetchAgain()
    },
  })
  const deductCredits = useMutation({
    mutationFn: (mutData: any) => {
      return DeductFamilyCredits(fId, mutData)
    },
    onSuccess: () => {
      fetchAgain()
    },
  })

  const onFinish = (event: any) => {
    event.preventDefault()
    if (formData.credits + org.current > org.current) {
      addCredits.mutate({
        credit: formData?.credits,
        organizationId: org.id,
        adminId: localStorage.getItem('adminId'),
        notes: formData.notes,
      })
      fetchAgain()

      setOpen(false)
      closeModel(false)
    }
    if (formData.credits - org.current < org.current) {
      console.log(formData?.newBalance)
      formData?.credits > 0 &&
        deductCredits.mutate({
          credit: org.current - formData?.credits,
          organizationId: org.id,
          adminId: localStorage.getItem('adminId'),
          notes: formData.notes,
        })
      fetchAgain()

      setOpen(false)
      closeModel(false)
    }
  }
  const incre = () => {
    const updatedValue = parseFloat(formData.credits) + 10
    setFormData({
      ...formData,
      credits: updatedValue,
      newBalance: Intparser(org.current) + updatedValue,
    })
  }
  const decre = () => {
    const updatedValue = parseFloat(formData.credits) - 10
    setFormData({
      ...formData,
      credits: updatedValue,
      newBalance: Intparser(org.current) + updatedValue,
    })
  }
  const handleCancel = () => {
    setOpen(false)
    closeModel(false)
  }
  return (
    <div className="changeModal">
      <Modal open={open} onCancel={handleCancel} className="popup-modal">
        <div>
          <div className="popup-header">
            <h2 className="w-600">Edit Credits</h2>
            <img
              src={Cancel}
              alt="close button pointer"
              onClick={handleCancel}
            />
          </div>
          <form onSubmit={onFinish}>
            <div className="inputGroup">
              <label className="text-12 font-600">Select Organization</label>

              <div className="mt-10 ">
                <Select
                  className="custom-select-field org-select-field h-3 position w-full"
                  value={org.name}
                  disabled={true}
                />
              </div>
            </div>
            <div className="inputGroup">
              <label className="text-12 font-600">Deduct or add credits</label>
              <div className="countergroup   mt-10px">
                <div className="counter flex">
                  <div className="dec pointer" onClick={decre}>
                    <img src={Decrease} alt="decrease" />
                  </div>
                  <input
                    type="number"
                    id="credits"
                    name="credits"
                    className="inp-counter"
                    value={formData.credits}
                    onChange={handleDataChange}
                    onFocus={handleFocus}
                  />
                  <div className="inc pointer" onClick={incre}>
                    <img src={Increase} alt="decrease" />
                  </div>
                </div>
                <div></div>
                <div className="">
                  <div className="flex justify-between">
                    <h4 className="text-14 w-600">Current balance: </h4>
                    <h4 className="text-14 w-400">${org.current}</h4>
                  </div>
                  <div className="flex justify-between">
                    <h4 className="text-14 w-600">New balance: </h4>
                    <h4 className="text-14 w-400">${formData.newBalance}</h4>
                  </div>
                </div>
              </div>
              <textarea
                placeholder="Leave a notes (optional)"
                className="mt-30px p-15px radius-12"
                style={{ height: '120px' }}
                id="notes"
                name="notes"
              />
            </div>
            <div className="flex justify-center py-40px">
              <button className="btn-sec pointer" onClick={handleCancel}>
                Cancel
              </button>
              <button className="btn-prim" type="submit">
                Update
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  )
}
export default EditCredits
