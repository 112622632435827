import React from 'react'
import IconsTextNavigation from 'components/global/IconsTextNavigation'

const ConfirmationEmail: React.FC = () => {
  return (
    <div>
      <IconsTextNavigation
        iconName="EnvelopeSimple"
        heading="Confirmation email"
        details="No email"
      />
    </div>
  )
}

export default ConfirmationEmail
