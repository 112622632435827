import { Modal } from 'antd'
import Cancel from '../../../assets/Images/close.svg'
import { useState } from 'react'
import { failureMessage } from '../../../api/global'

interface changeEmailProps {
  setModal: (arg: Number | null | false) => void
  prevEmail: string
  changeEmail: (arg: { [key: string]: string }) => void
}
const ChangeEmail: React.FC<changeEmailProps> = ({
  setModal,
  prevEmail,
  changeEmail,
}) => {
  const [formData, setFormData] = useState({
    email: '',
    confirm: '',
  })
  const [open, setOpen] = useState<boolean>(true)

  const handleCancel = () => {
    setModal(false)
    setOpen(false)
  }
  const onFinish = (event: any) => {
    event.preventDefault()
    if (formData.email === formData.confirm && formData.email !== '') {
      changeEmail({ email: formData.email })
      setOpen(false)
      setModal(false)
    } else failureMessage("Email don't match")
  }
  const handleInputChange = (event: any) => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })
  }
  return (
    <Modal open={open} onCancel={handleCancel} className="popup-modal">
      <div>
        <div className="popup-header">
          <div>
            <h2>Change your email</h2>
            <p className="subtitlePara">Your current email is {prevEmail}</p>
          </div>
          <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
        </div>
        <form onSubmit={onFinish}>
          <div className="inputGroup">
            <label className="inp-label">New email</label>
            <input
              type="email"
              name="email"
              className="my-input"
              placeholder="Enter your new email"
              onChange={handleInputChange}
              value={formData.email}
            />
          </div>
          <div className="inputGroup">
            <label className="inp-label">Confirm email</label>
            <input
              type="email"
              name="confirm"
              className="my-input"
              placeholder="Confirm your email"
              onChange={handleInputChange}
              value={formData.confirm}
            />
          </div>
          <div className="popup-footer">
            <button className="btn-sec" onClick={handleCancel}>
              Cancel
            </button>
            <button className="btn-prim" type="submit">
              Change email
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}
export default ChangeEmail
