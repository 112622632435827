import React from 'react'
import { Modal, Form, Row, Col, Input } from 'antd'
import closeIcon from 'assets/Images/close.svg'
import { AddFaqPayload } from 'types/eventStep5'
import { creationEventStore } from 'state/eventCreationStore'
import { useAddEventFaq } from 'api/EventCreation/creationStep5'
import ButtonSpinner from 'components/global/ButtonSpinner'

interface AddQuestionProps {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
}

const AddQuestion: React.FC<AddQuestionProps> = ({ openModal, closeModal }) => {
  const [form] = Form.useForm()
  const { _id } = creationEventStore(store => store.eventData)
  const { mutate: createEventFaq, isLoading: addFaqLoading } = useAddEventFaq()

  const formSchema = {}
  const handleCancel = () => {
    closeModal(false)
  }

  const onFinish = (values: AddFaqPayload) => {
    const payload = {
      ...values,
      eventId: _id,
    }
    createEventFaq(payload, {
      onSuccess: data => {
        handleCancel()
      },
    })
  }
  return (
    <div>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        className="items-add-question"
        footer={false}
        width={600}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">New question</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="mt-20px">
          <Form
            form={form}
            layout="vertical"
            initialValues={formSchema}
            onFinish={onFinish}
          >
            <Row gutter={10}>
              <Col>
                <label className="input-label required-input">Question</label>
                <Form.Item
                  className="form-control"
                  name="question"
                  rules={[{ required: true, message: 'Question is required' }]}
                >
                  <Input.TextArea
                    className="question-field"
                    placeholder="Enter a note here"
                    autoSize={{ minRows: 5, maxRows: 8 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col>
                <label className="input-label required-input">Answer</label>
                <Form.Item
                  className="form-control"
                  name="answer"
                  rules={[{ required: true, message: 'Answer is required' }]}
                >
                  <Input.TextArea
                    className="answer-field"
                    placeholder="Enter a note here"
                    autoSize={{ minRows: 5, maxRows: 8 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mt-10px justify-end">
              <button className="confirm-btn" type="submit">
                {addFaqLoading ? (
                  <ButtonSpinner size={20} color="#000" />
                ) : (
                  'Save'
                )}
              </button>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default AddQuestion
