import axios from 'axios'
import { axiosGet, axiosPost } from 'Redux/axiosHelper'
import { RequestParms } from 'types/globalTypes'
import { locationBody } from 'types/location'
import { BASE_URL } from './global'

export const addNewLocation = async (locationData: locationBody) => {
  const response = await axiosPost(locationData, 'v1/location/add')
  return response.data
}
export const getApprovedLocations = async (params: RequestParms) => {
  const response = await axiosGet('v1/location/approved', params)
  return response.data
}
export const getPendingLocations = async (params: RequestParms) => {
  const response = await axiosGet('v1/location/pending', params)
  return response.data
}
export const approveLocation = async (locationId: any, location: any) => {
  const response = await axios.patch(
    `${BASE_URL}v1/location/approve-location/${locationId}`,
    location,
  )
  return response.data
}
export const rejectLocation = async (locationId: string) => {
  const response = await axios.patch(
    `${BASE_URL}v1/location/reject-location/${locationId}`,
  )
  return response.data
}
export const getLocationById = async (locationId: string) => {
  const response = await axiosGet(`/api/v1/location/${locationId}`)
  return response.data
}
