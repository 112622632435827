import { useEffect } from 'react'
import AppLayout from '../../../components/layout/layout'
import { useLocation, useParams } from 'react-router-dom'
import {
  Row,
  Col,
  Avatar,
  Button,
  Space,
  Dropdown,
  Menu,
  Tabs,
  Tooltip,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import deleteSvg from '../../../assets/Images/delete_white.svg'
import pencilLine from '../../../assets/Images/PencilLine.svg'
import LockKey from '../../../assets/Images/LockKey.svg'
import Basic from './Basic'
import Events from './Events'
import Organizations from './Organizations'
import actions from '../../../Redux/Organizer/action'
import CreateOrganizerModal from '../../../components/modals/organizerModal'
import StatusDropdown from '../../../helper/StatusDropdown'
import OrganizerDataType from '../../../types/organizer'
import OrganizerBasicInfo from './BasicInformation'
import { getNameSignature } from 'helper/utility'
import { getAllergyList } from '../../../api/Lists'
import { useQuery } from '@tanstack/react-query'
import icon from 'assets/Images/otherCategory.svg'
import WarningCircle from 'assets/Images/WarningCircle.svg'
import { useState } from 'react'
import warning from 'assets/Images/Warning.png'
import envelopSimple from 'assets/Images/EnvelopeSimple.svg'
const { TabPane } = Tabs

interface propsType {
  organizer: OrganizerDataType
  modalVisible: Boolean
  isChangingPassword: Boolean
}

const OrganizerProfile = ({
  organizer,
  modalVisible,
  isChangingPassword,
}: propsType) => {
  const [modal, setModal] = useState<Number | null | false>(null)
  const menu = (
    <Menu>
      <Menu.Item
        key="email"
        onClick={() => {
          setModal(1)
        }}
      >
        <Space>
          <img alt="img" src={envelopSimple}></img> Change email
        </Space>
      </Menu.Item>

      <Menu.Item key="block">
        <Space className="text-color-red">
          <img alt="img" src={warning}></img> Block email
        </Space>
      </Menu.Item>
    </Menu>
  )
  const { data: AllergyData, isLoading } = useQuery(['getAllergyList'], () =>
    getAllergyList(),
  )
  const getAllergywithIcon = (data: any) => {
    const allergy = AllergyData?.find(
      (allergy: any) => allergy._id === data?._id,
    )

    if (allergy) {
      return (
        <Tooltip placement="bottom" title={allergy?.name || data?.name}>
          <Avatar
            src={allergy?.icon || icon}
            size={20}
            className="allergy-icon"
          />
        </Tooltip>
      )
    } else {
      return (
        <Tooltip placement="bottom" title={data?.name}>
          <Avatar src={icon} size={20} className="allergy-icon" />
        </Tooltip>
      )
    }
  }
  const dispatch = useDispatch()
  const location: any = useLocation()
  let data = location.state
  let { id }: any = useParams()

  useEffect(() => {
    dispatch({
      type: actions.GET_ORGANIZER,
      payload: null,
      id: id,
    })
  }, [id])

  const actionMenu = (
    <Menu>
      <Menu.Item
        key="changePassword"
        onClick={() => {
          dispatch({
            type: actions.OPEN_ORGANIZER_CHANGE_PASSWORD,
          })
        }}
      >
        <Space>
          <img alt="img" src={LockKey}></img> Change Password
        </Space>
      </Menu.Item>
      <Menu.Item
        key="Editprofile"
        onClick={() => {
          dispatch({
            type: actions.ORGANIZER_MODAL_VISIBLE,
            payload: true,
          })
        }}
      >
        <Space>
          <img alt="img" src={pencilLine}></img> Edit profile
        </Space>
      </Menu.Item>
      <Menu.Item
        key="Delete"
        onClick={() => {
          dispatch({
            type: actions.DELETE_ORGANIZER_REQUEST,
            payload: { _id: organizer.id },
          })
        }}
      >
        <Space>
          <img alt="img" src={deleteSvg}></img> Delete
        </Space>
      </Menu.Item>
    </Menu>
  )

  return (
    <AppLayout id={id}>
      <div className="bg-grey">
        <div className="org-profile-header">
          {/* cancel that view according to the QA requirment
          <div
            // style={{ background: '#EAF6FA' }}
            className="verification-email-sent verification-email-color"
          >
            <div className="flex">
              <img
                className="ml-5px mr-8px"
                src={WarningCircle}
                alt="WarningCircle"
              />
              <p>Verification email sent</p>
            </div>
            <Dropdown overlay={menu} className="status_dropdown">
              <Button type="primary" className="mr-8px w-500 ">
                Resend verification email
              </Button>
            </Dropdown>
          </div> */}
          <Row gutter={10}>
            <Col xl={{ span: 10 }}>
              <Row align="middle">
                {/* <Col xl={{ span: 6 }} xs={{ span: 4 }}>
                </Col> */}
                <Col span={24} lg={{ span: 24 }}>
                  <div className="d-flex align-center">
                    <Avatar size={80} src={organizer?.photo?.post}>
                      {getNameSignature(
                        organizer?.firstName + ' ' + organizer?.lastName,
                      )}
                    </Avatar>
                    <div className="avatar-name align-self-center">
                      <h1 style={{ marginBottom: '0px' }}>
                        <b className="b1">{organizer?.firstName}</b>
                      </h1>
                      {isLoading ||
                        (organizer?.allergies?.length > 0 && (
                          //  className="mt-15px"
                          <div>
                            <div className="flex  flex-wrap w-full align-items-center">
                              {organizer?.allergies?.map((data: any) => {
                                return (
                                  <div
                                    key={data?._id}
                                    className="mt-10px allergyChips "
                                    style={{ marginRight: '10px' }}
                                  >
                                    {getAllergywithIcon(data)}
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xl={{ span: 14 }}>
              <Row>
                <Col span={24} className="Organization-header">
                  <Space>
                    <h5 className="status-title">
                      <b className="b1">Status</b>
                    </h5>
                    <div className="profile-head-action-dropdown">
                      <StatusDropdown
                        id={organizer?.id}
                        name="organizer"
                        status={organizer.status}
                      />
                    </div>
                  </Space>
                  <Dropdown overlay={actionMenu} className="status_dropdown">
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      className="actionButton actionButton-org-profile"
                    >
                      Manage
                    </Button>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Row>
          <Tabs
            defaultActiveKey="1"
            style={{ width: '100%', height: '100%' }}
            className="bg-white profile-tabs"
          >
            <TabPane tab="Basic Info" key="1">
              {/* <Basic profile={organizer} /> */}
              <OrganizerBasicInfo parent={organizer}></OrganizerBasicInfo>
            </TabPane>
            <TabPane tab="Organizations" key="2">
              <Organizations organizations={organizer.organization} />
            </TabPane>
            <TabPane tab="Events" key="3">
              <Events />
            </TabPane>
          </Tabs>
        </Row>
      </div>
      {modalVisible ? (
        <CreateOrganizerModal
          modalVisible={modalVisible}
          currentOrganizer={organizer}
          isChangingPassword={isChangingPassword}
        />
      ) : (
        <></>
      )}
    </AppLayout>
  )
}
const mapStateToProps = (state: any) => ({
  organizers: state.organizer.organizerData,
  organizer: state.organizer.organizer,
  modalVisible: state.organizer.isModalVisible,
  isChangingPassword: state.organizer.isChangingPassword,
})

export default connect(mapStateToProps)(OrganizerProfile)
