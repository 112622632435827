import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Upload, Image, Spin } from 'antd'
import '../../styles/style.scss'
import type { UploadProps } from 'antd/es/upload/interface'
import BigPlusIcon from '../../../../../../assets/Images/BigPlusIcon.svg'
import Coverimage from '../../../../../../assets/Images/Coverimage.svg'
import Icon from '../../../../../../assets/Images/icon.svg'
import { useDispatch, connect } from 'react-redux'
import fileActions from '../../../../../../Redux/FileUpload/action'
import { File } from 'types/globalTypes'
interface PropsTypes {
  documentIds: string[]
  setDocumentIds: (value: string[]) => void
  fileData: File
  loading: boolean
}

const DocUpload: React.FC<PropsTypes> = ({
  documentIds,
  setDocumentIds,
  fileData,
  loading,
}) => {
  const dispatch = useDispatch()
  const [galleryUrl, setGalleryUrl] = useState<any>([])

  const propsForPhoto: UploadProps = {
    showUploadList: false,
    className: 'mt-100',
    onChange: (info: any) => {
      if (info.file.status === 'uploading') {
        info.file.status = 'done'
      }
      if (info.file.status === 'done') {
        const fileObj = new FormData()
        fileObj.append('file', info.file.originFileObj)
        let newFile = {
          title: 'session',
          description: 'image_id',
          altText: 'img',
          sourceType: 'user_profile',
        }

        dispatch({
          type: fileActions.REQUEST_FILE_UPLOAD,
          payload: fileObj,
          newFile: newFile,
        })
      }
    },
    beforeUpload: file => {
      var url = URL.createObjectURL(file)
      let type = file.type.split(/[/]/)
      if (type[0] === 'image')
        setGalleryUrl((prevValue: any) => [
          ...prevValue,
          { url: url, type: type[0] },
        ])
      return true
    },
  }

  const handleDeleteImage = (idx: number) => {
    console.log('idx', idx)
    const newImageData = galleryUrl.filter(
      (_: any, index: number) => index !== idx,
    )
    const newPhotoIds = documentIds.filter(
      (_: any, index: number) => index !== idx,
    )
    setGalleryUrl(newImageData)
    setDocumentIds(newPhotoIds)
  }

  useEffect(() => {
    if (fileData?._id) {
      setDocumentIds([...documentIds, fileData._id])
      dispatch({
        type: fileActions.CLEAR_FILE_DATA,
      })
    }
  }, [fileData])

  const uploadButton = (
    <div>
      <>
        <div className="upload-acover-pic">
          <img src={Coverimage} alt="avatar" />
          Upload image
        </div>
      </>
    </div>
  )

  return (
    <Row gutter={10}>
      {galleryUrl.length === 0 && (
        <Col span={15}>
          <Form.Item name="galleryIds">
            <Row>
              <Col span={24} className="d-flex">
                <Upload
                  {...propsForPhoto}
                  listType="picture-card"
                  accept={'image/*,.pdf,.doc,.docx,.txt'}
                  multiple={true}
                  className="cover-img-step-5"
                >
                  {galleryUrl.length > 0 ? (
                    <img
                      alt="#img"
                      src={BigPlusIcon}
                      style={{
                        width: '50px',
                        height: '50px',
                      }}
                      className="step-cover-photo"
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      )}
      <Col span={17}>
        {galleryUrl.length > 0 && (
          <Col className="antd-img-upload d-flex gap-20">
            {galleryUrl.map((item: any, index: number) => {
              return (
                <div className="img-upload-notes" key={index}>
                  {loading && <Spin />}
                  <img
                    alt="#img"
                    className="closing-icon"
                    src={Icon}
                    onClick={() => handleDeleteImage(index)}
                  />
                  <Image
                    key={index}
                    className="br-12"
                    height={70}
                    width={70}
                    src={item.url}
                    rootClassName={'mb-10'}
                  />
                </div>
              )
            })}
          </Col>
        )}
      </Col>
    </Row>
  )
}

const mapStateToProps = (state: any) => ({
  eventsDataValue: state.events.eventsDataValue,
  eventOrganizationData: state.events.eventOrganizationData,
  fileData: state.fileUpload.fileData,
  loading: state.fileUpload.loading,
})
export default connect(mapStateToProps)(DocUpload)
