import React, { useEffect } from 'react'
import AppLayout from '../../components/layout/layout'
import { Card, Tooltip } from 'antd'
import { Menu, Dropdown, Button } from 'antd'
import { Tabs } from 'antd'
import userIcon from '../../assets/Images/userIcon.svg'
import Card2 from '../../assets/Images/Card2.svg'
import Card3 from '../../assets/Images/Card3.svg'
import Card4 from '../../assets/Images/Card4.svg'
import { Row, Col } from 'antd'
import ColumnPlot from '../../helper/Charts/ColumnGraph/ColumnPlot'
import { DownOutlined } from '@ant-design/icons'
import Barchart from '../../helper/Charts/Barchart/Barchart'
import Progressbar from '../../helper/Charts/ProgressBar/Progressbar'
import Capacity from '../../helper/Charts/Capacity/Capacity'
import Skills from '../../helper/Charts/Capacity-Skills/Skills'
import Managewidgets from './Manage-widgets/Manage-widgets'
import Pendings from './Pending/Pendings'
import { connect } from 'react-redux'
import actions from '../../Redux/Organization/action'
import { useDispatch } from 'react-redux'
import { history } from '../../Redux/app-store'
import OraganazationModel from '../Modals/OrganizationModal'
import { useQuery } from '@tanstack/react-query'
import { getOverviewCounts } from '../../api/Overview'
import { myStore } from 'state/roleStore'
import { useState } from 'react'
const { TabPane } = Tabs

const colors = ['blue']
const menu = (
  <Menu>
    <Menu.Item>
      <p>Active</p>
    </Menu.Item>
    <Menu.Item>
      <p>Pending</p>
    </Menu.Item>
    <Menu.Item>
      <p>Partner</p>
    </Menu.Item>
  </Menu>
)
function callback(key: any) {}

const Dashboard = ({
  currentData,
  pendingOrganizations,
  modalVisible,
}: any) => {
  const dispatch = useDispatch()

  // useEffect(() => {
  //   dispatch({
  //     type: actions.GET_ORGANIZATIONS,
  //   })
  // }, [])
  const handleMenuClick = (menuItem: any) => {
    console.log(menuItem, currentData.id)

    // dispatch({
    //   type: actions.UPDATE_ORGANIZATIONS_STATUS,
    //   payload: {
    //     status: menuItem.key,
    //     reason: 'demo',
    //   },
    //   params: currentData.id,
    // })
  }
  const { role, setRole } = myStore()
  // const { data, refetch } = useQuery(['getOverview'], getOverviewCounts, {
  //   enabled: !!role,
  // })
  const [overviewData, setOverviewData] = useState(null) // State to store data

  let { data, refetch } = useQuery(['getOverview'], getOverviewCounts, {
    enabled: !!role,
  })

  useEffect(() => {
    if (data) {
      setOverviewData(data)
    }
  }, [data])
  useEffect(() => {
    if (role) {
      refetch()
    } else {
      setOverviewData(null)
    }
  }, [role])

  return (
    <AppLayout>
      <Managewidgets />
      <div className="site-card-wrapper page-header pr">
        <Row justify="center" gutter={[26, 26]}>
          <Col xl={{ span: 6 }} xs={{ span: 12 }}>
            <Card
              title="Active organizations"
              className="card-box manage-status-items"
            >
              <div className="cricle Aesthetic-light">
                <span>
                  <img alt="img" src={userIcon} />
                </span>
              </div>
              <div className="card-number">
                {/* <span>{data?.organization}</span> */}
                <span>{overviewData ? overviewData.organization : 0}</span>
              </div>
              {colors.map(color => (
                <Tooltip
                  placement="bottom"
                  title="Manage Organizations"
                  color={color}
                  key={color}
                >
                  <span
                    className="card-label-text cursor_pointer"
                    onClick={() => {
                      history.push('/organization')
                    }}
                  >
                    Manage organizations
                  </span>
                </Tooltip>
              ))}
            </Card>
          </Col>
          <Col xl={{ span: 6 }} xs={{ span: 12 }}>
            <Card
              title="Total Events"
              className="card-box blue-light-card manage-status-items"
            >
              <div className="cricle Aesthetic-dark">
                <span>
                  <img alt="img" src={Card2} />
                </span>
              </div>

              <div className="card-number">
                {/* <span>{data?.events}</span> */}
                <span>{overviewData ? overviewData.events : 0}</span>
              </div>
              {colors.map(color => (
                <Tooltip
                  placement="bottom"
                  title="Manage events"
                  color={color}
                  key={color}
                >
                  <span
                    className="card-label-text cursor_pointer"
                    onClick={() => {
                      history.push('/events')
                    }}
                  >
                    Manage events
                  </span>
                </Tooltip>
              ))}
            </Card>
          </Col>
          <Col xl={{ span: 6 }} xs={{ span: 12 }}>
            <Card
              title="Total Families"
              className="card-box lavender-light-card manage-status-items"
            >
              <div className="cricle Lavender-light">
                <span>
                  <img alt="img" src={Card3} />
                </span>
              </div>
              <div className="card-number">
                {/* <span>{data?.families}</span> */}
                <span>{overviewData ? overviewData.families : 0}</span>
              </div>
              {colors.map(color => (
                <Tooltip
                  placement="bottom"
                  title="View mentors"
                  color={color}
                  key={color}
                >
                  <span
                    className="card-label-text cursor_pointer"
                    onClick={() => {
                      history.push('/family')
                    }}
                  >
                    Manage families
                  </span>
                </Tooltip>
              ))}
            </Card>
          </Col>
          <Col xl={{ span: 6 }} xs={{ span: 12 }}>
            <Card
              title="Total Mentors"
              className="card-box aesthetic-dark-card manage-status-items"
            >
              <div className="cricle Aesthetic-dark">
                <span>
                  <img alt="img" src={Card4} />
                </span>
              </div>
              <div className="card-number">
                {/* <span>{data?.mentors}</span> */}
                <span>{overviewData ? overviewData.mentors : 0}</span>
              </div>
              {colors.map(color => (
                <Tooltip
                  placement="bottom"
                  title="Total mentors"
                  color={color}
                  key={color}
                >
                  <span
                    className="card-label-text cursor_pointer"
                    onClick={() => {
                      history.push('/mentors')
                    }}
                  >
                    Manage Mentors
                  </span>
                </Tooltip>
              ))}
            </Card>
          </Col>
        </Row>
      </div>
      <div className="page-content">
        {/* <div>
          <Card className="card-table pt-30">
            <Row>
              {colors.map(color => (
                <h2 className="mb-20">Organizations to validate</h2>
              ))}
            </Row>
            <Row>
              <Pendings
                pendingOrganizations={pendingOrganizations}
                handleMenu={handleMenuClick}
              />
            </Row>
          </Card>
        </div> */}
        {/* <div style={{ marginTop: 30 }} className="satisfaction-score">
          <Row gutter={26} justify="center">
            <Col lg={{ span: 12 }} xs={{ span: 22 }} md={{ span: 24 }}>
              <Card style={{ height: '100%' }} className="px-30 pt-30">
                <Row className="mb-20">
                  <Col span={12}>
                    <h2>Satisfaction score</h2>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Dropdown overlay={menu} className={'mentor-dropdown'}>
                      <Button>
                        All mentors <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ alignItems: 'center', padding: '20px 0px' }}>
                    <Barchart />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={{ span: 12 }} xs={{ span: 22 }} md={{ span: 24 }}>
              <Card className="px-30 pt-30 h-100">
                <Row className="mb-10">
                  <div className="d-flex justify-between w-100">
                    <h2>Kids</h2>
                    <h2>30</h2>
                  </div>
                </Row>
                <Row>
                  <Col>
                    <Tabs
                      defaultActiveKey="1"
                      onChange={callback}
                      className="tab-ml-0"
                    >
                      <TabPane tab="Gender" key="1" style={{}}>
                        <Row>
                          <Col style={{ alignItems: 'center' }}>
                            <ColumnPlot />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tab="Age" key="2">
                        <Row>
                          <Col style={{ alignItems: 'center' }}>
                            <Progressbar />
                          </Col>
                        </Row>
                      </TabPane>
                    </Tabs>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <div style={{ marginTop: 30 }}>
            <Card className="px-30 pt-30">
              <Row>
                <Col span={12}>
                  <h2>Capacity</h2>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Dropdown overlay={menu} className="mentor-dropdown">
                    <Button>
                      Select mentors <DownOutlined />
                    </Button>
                  </Dropdown>
                </Col>
              </Row>
              <Tabs
                defaultActiveKey="1"
                onChange={callback}
                className="tab-ml-0"
              >
                <TabPane tab="Mentors" key="1" className="mt-20">
                  <Row>
                    <Col className="w-100" style={{ height: 400 }}>
                      <Capacity />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Skills" key="2" className="mt-20">
                  <Card>
                    <Row>
                      <Col className="w-100" style={{ height: 400 }}>
                        <Skills />
                      </Col>
                    </Row>
                  </Card>
                </TabPane>
              </Tabs>
            </Card>
          </div>
        </div> */}
      </div>
      {modalVisible ? (
        <OraganazationModel
          modalVisible={modalVisible}
          currentData={currentData}
        />
      ) : (
        <></>
      )}
    </AppLayout>
  )
}
const mapStateToProps = (state: any) => ({
  organizationData: state.organization.organizationData,
  currentData: state.organization.currentOrganizationData,
  pendingOrganizations: state.organization.pendingOrganizations,
  modalVisible: state.organization.isModalVisible,
})
export default connect(mapStateToProps)(Dashboard)
