import { Modal } from 'antd'
import React, { useState } from 'react'
import closeIcon from 'assets/Images/close.svg'
import plusBlue from 'assets/Images/plusBlue.svg'
import EmptyData from 'components/global/EmptyData'
import AddItemsModal from './AddItemsModal'
import { List, PencilLine, Trash } from '@phosphor-icons/react'
import { creationEventStore } from 'state/eventCreationStore'

interface ItemsBringModalProps {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  prevItems?: string[]
  setPrevItems?: React.Dispatch<React.SetStateAction<string[]>>
}

interface SelectedItem {
  index: number
  item: string
}

const ItemsBringModal: React.FC<ItemsBringModalProps> = ({
  openModal,
  closeModal,
  prevItems,
  setPrevItems,
}) => {
  const payload = creationEventStore(store => store.step5Payload)
  const setStep5Payload = creationEventStore(store => store.setStep5Payload)
  const [openAddItemModal, setOpenAddItemModal] = useState<boolean>(false)
  const [selectedIndex, setSelectedIndex] = useState<undefined | SelectedItem>(
    undefined,
  )
  const handleCancel = () => {
    closeModal(false)
  }
  const handleRemoveItem = (index: number) => {
    setPrevItems(prevItems.filter((_, i) => index !== i))
  }
  const handleEdit = (index: number, item: string) => {
    setSelectedIndex({ index, item })
    setOpenAddItemModal(true)
  }

  const handleSave = () => {
    setStep5Payload({ ...payload, itemsToBring: prevItems })
    handleCancel()
  }

  return (
    <>
      <div>
        <Modal
          open={openModal}
          onCancel={handleCancel}
          className="items-t0-bring"
          footer={false}
          centered
        >
          <div className="flex-center pt-20px justify-between ">
            <h2 className="w-600 text-18">Items to bring</h2>
            <img
              src={closeIcon}
              alt="close-button"
              onClick={handleCancel}
              className="close button pointer"
            />
          </div>
          <div className="mt-20px">
            <p>
              Listed below what is mandatory things should kid bring with
              themselves for the event
            </p>

            {prevItems.length > 0 ? (
              <div className="mt-20px">
                {prevItems.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex align-center justify-between my-20px"
                    >
                      <div className="flex align-center gap-10px">
                        <List size={20} color="#B3B3BF" />
                        <p>{item}</p>
                      </div>
                      <div className="flex align-center gap-16px">
                        <PencilLine
                          size={20}
                          color="#000"
                          className="pointer"
                          onClick={() => handleEdit(index, item)}
                        />
                        <Trash
                          size={20}
                          color={'#FF6961'}
                          className="pointer"
                          onClick={() => handleRemoveItem(index)}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className="py-20px w-full flex justify-center">
                <EmptyData message="No item is added" />
              </div>
            )}
          </div>
          <div className="mt-30px flex gap-20px justify-between align-center">
            <button
              className="pointer flex align-center gap-16px simple-btn"
              onClick={() => setOpenAddItemModal(true)}
            >
              <img src={plusBlue}></img> <p>Add new Item</p>
            </button>
            <button className="confirm-btn pointer" onClick={handleSave}>
              Save
            </button>
          </div>
        </Modal>
      </div>

      {openAddItemModal && (
        <AddItemsModal
          openModal={openAddItemModal}
          closeModal={setOpenAddItemModal}
          items={prevItems}
          setItems={setPrevItems}
          selectedIndex={selectedIndex}
        />
      )}
    </>
  )
}

export default ItemsBringModal
