import React, { useState } from 'react'
import { Modal, Tabs } from 'antd'
import closeIcon from 'assets/Images/close.svg'
import './styles/styles.scss'
import DefalutActivityTab from './DefalutActivityTab'
import SpecificActivtyTab from './SpecificActivtyTab'
import AddActivity from './addActivity/AddActivity'
import { GetActivitiesTypes } from 'types/events'
import { creationEventStore } from 'state/eventCreationStore'

interface SelectActivityProps {
  modalOpen: boolean
  setmodalopen: React.Dispatch<React.SetStateAction<boolean>>
}

const SelectActivityModal: React.FC<SelectActivityProps> = ({
  modalOpen,
  setmodalopen,
}) => {
  const { organizationId } = creationEventStore(store => store.eventData)
  const [addActivityModal, setAddActivityModal] = useState<boolean>(false)
  const [params, setParams] = useState<GetActivitiesTypes>({
    organizationId,
    defaultActivities: true,
  })
  const handleCancel = () => {
    setmodalopen(false)
  }

  const handleSubmit = () => {}

  const onChange = (key: string) => {
    if (key === '1') {
      setParams({ ...params, defaultActivities: true })
    } else {
      setParams({ ...params, defaultActivities: false })
    }
  }
  const items = [
    {
      key: '1',
      label: 'Default',
      children: (
        <DefalutActivityTab closeModal={setmodalopen} params={params} />
      ),
    },
    {
      key: '2',
      label: 'Specific',
      children: (
        <SpecificActivtyTab closeModal={setmodalopen} params={params} />
      ),
    },
  ]

  return (
    <>
      <div>
        <Modal
          open={modalOpen}
          onCancel={handleCancel}
          className="select-activityModal"
          footer={false}
        >
          <div className="flex-center pt-20px justify-between ">
            <h2 className="w-600 text-18">Select activity</h2>
            <img
              src={closeIcon}
              alt="close-button"
              onClick={handleCancel}
              className="close button pointer"
            />
          </div>
          <div className="mt-10px text-center activity-select-container">
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          </div>

          <div className="mt-30px flex align-center justify-between">
            <button
              className="create-activity-btn"
              onClick={() => setAddActivityModal(true)}
            >
              Create new activity
            </button>
            <button className="confirm-btn" onClick={handleSubmit}>
              Confirm
            </button>
          </div>
        </Modal>
      </div>
      <AddActivity
        modalOpen={addActivityModal}
        setModalOpen={setAddActivityModal}
      />
    </>
  )
}

export default SelectActivityModal
