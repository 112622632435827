import { Button, Card, Col, Dropdown, Image, Menu, Row, Space } from 'antd'
import moment from 'moment'
import {
  DeleteOutlined,
  PushpinOutlined,
  EditOutlined,
} from '@ant-design/icons'
import timeSvg from '../../../../assets/Images/time.svg'
import Actionicon from '../../../../assets/Images/Action.svg'
import { history } from '../../../../Redux/app-store'
import { connect } from 'react-redux'

const Events = ({ eventsData }: any) => {
  const Actionmenu = (
    <Menu>
      <Menu.Item key="edit">
        <p>
          <EditOutlined /> Edit
        </p>
      </Menu.Item>
      <Menu.Item key="pin">
        <p>
          <PushpinOutlined /> Pin to the top
        </p>
      </Menu.Item>
      <Menu.Item key="delete">
        <p>
          <DeleteOutlined /> Delete
        </p>
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="container" style={{ paddingTop: '40px' }}>
      {eventsData?.map((events: any) => {
        return (
          <Card
            title="Todays Event"
            className="events_card events-details-card"
            // style={{ width: "100%", marginBottom: "20px" }}
            key={events.id}
          >
            <Row justify="space-between">
              <Col
                xl={{ span: 8 }}
                onClick={() => {
                  history.push({
                    pathname: `/events-details/${events.id}`,
                    state: events,
                  })
                }}
              >
                <Image src={events?.coverPicture}></Image>
              </Col>
              <Col xl={{ span: 12 }}>
                <Space direction="vertical" size={4}>
                  <h4>
                    <b className="b2"></b>
                    {events.eventName}
                  </h4>
                  <h5>
                    <b className="b3 text-secondary">Ages {events.ageRange}</b>
                  </h5>
                  <Row align="middle" gutter={24}>
                    <Col span={2}>
                      <img alt="img" className="mb-0" src={timeSvg}></img>{' '}
                    </Col>
                    <Col span={2}>
                      <h5 className="font12 mb-0">
                        <b className="b2">
                          {moment(events.date).format('DD/MM/YYYY')}
                        </b>
                      </h5>
                    </Col>
                  </Row>
                </Space>
              </Col>
              <Col xs={{ span: 24 }} xl={{ span: 4 }}>
                <Dropdown
                  overlay={Actionmenu}
                  placement="bottom"
                  className="event-btns-card"
                >
                  <Button>
                    <img alt="img" src={Actionicon} />
                  </Button>
                </Dropdown>
              </Col>
            </Row>
          </Card>
        )
      })}
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  eventsData: state.events.eventsData,
})
export default connect(mapStateToProps)(Events)
