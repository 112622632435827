// import React, { useState, useEffect } from 'react'
// import {
//   ScheduleComponent,
//   Day,
//   Inject,
//   CellClickEventArgs,
//   PopupOpenEventArgs,
// } from '@syncfusion/ej2-react-schedule'
// import { creationEventStore } from 'state/eventCreationStore'
// import { Activity, CaretLeft, CaretRight } from '@phosphor-icons/react'
// import moment from 'moment'
// import { infoMessage } from 'api/global'
// import { convertMinutesToLocalTime } from 'helper/utility'
// import ActivityTime from '../Modals/ActivityTime'
// import CustomCalender from 'helper/Calendar/Eventscalendar/CustomCalender'
// import { Dropdown, Menu } from 'antd'
// import './styles/schedular.scss'

// const ActivityCalendar: React.FC = () => {
//   const { datesOfEvent, timings, activitiesScheduleV2 } = creationEventStore(
//     store => store.eventData,
//   )
//   const selectedDate = creationEventStore(store => store.selectedDate)
//   const setSelectedDate = creationEventStore(store => store.setSelectedDate)
//   const [openModal, setOpenModal] = useState<boolean>(false)
//   const [cellData, setCellData] = useState<CellClickEventArgs>()
//   const [specialSchedule, setSpecialSchedule] = useState<any>()
//   const [data, setData] = useState<any>()
//   const timeScale = { enable: true, interval: 60, slotCount: 4 }

//   // todo
//   const menu = (
//     <Menu>
//       <Menu.Item key="1">Add Overlapping Activity</Menu.Item>
//       <Menu.Item key="2">Add New</Menu.Item>
//       <Menu.Item key="3">Copy Activity</Menu.Item>
//     </Menu>
//   )
//   // todo

//   useEffect(() => {
//     const activityShower = activitiesScheduleV2.map((activity: any) => {
//       const startTime = new Date(activity.days[0])
//       const endTime = new Date(activity.days[0])
//       endTime.setMinutes(endTime.getMinutes() + activity.duration)
//       return {
//         Id: activity._id,
//         Subject: activity.name,
//         StartTime: startTime,
//         EndTime: endTime,
//         IsAllDay: false,
//       }
//     })
//     setData(activityShower)
//   }, [activitiesScheduleV2])

//   const eventSettings = { dataSource: data }

//   const onCellClick = (args: CellClickEventArgs) => {
//     args.cancel = true
//     openCustomPopup(args)
//   }

//   const onCellDoubleClick = (args: CellClickEventArgs) => {
//     args.cancel = true
//     openCustomPopup(args)
//   }

//   const openCustomPopup = (args: CellClickEventArgs) => {
//     setCellData(args)
//     setOpenModal(true)
//   }

//   const handlePopupOpen = (args: PopupOpenEventArgs) => {
//     if (args.type === 'QuickInfo' || args.type === 'Editor') {
//       args.cancel = true
//     }
//   }

//   const onEventRendered = args => {
//     const eventElement = args.element
//     const event = args.data
//     eventElement.style.backgroundColor =
//       event.Subject === 'Check-in'
//         ? '#FDEAD2'
//         : event.Subject === 'Breakfast'
//         ? '#D5E1F4'
//         : event.Subject === 'Lunch'
//         ? '#8CC73F33'
//         : event.Subject === 'Dinner'
//         ? '#D5EDF4'
//         : event.Subject === 'Check-out'
//         ? '#E8769F33'
//         : '#47BFDF33'
//     eventElement.style.color = '#ffffff'
//     eventElement.style.borderRadius = '0px'
//     eventElement.style.border = 'none'
//     eventElement.classList.add('custom-event')
//     eventElement.innerHTML = `
//       <div class="custom-event-template">
//           <div class="custom-event-header">${event.Subject}</div>
//             <div class="custom-event-body">
//               <div class="custom-event-time">
//                 ${new Date(event.StartTime).toLocaleTimeString()} - ${new Date(
//                   event.EndTime,
//                 ).toLocaleTimeString()}
//               </div>
//             </div>
//         <div class="custom-menu-opener">
//         </div>
//       </div>
//     `

//     eventElement.addEventListener('click', e => {
//       e.preventDefault()
//       e.stopPropagation()
//       handleEventClick(event, e)
//     })

//     const eventOpener = eventElement.querySelector('.custom-event-template')
//     const menuOpener = eventElement.querySelector('.custom-menu-opener')
//     if (menuOpener) {
//       menuOpener.addEventListener('click', e => {
//         e.stopPropagation()
//         handleMenuOpenerClick(event)
//       })
//     }

//     if (eventOpener) {
//       menuOpener.addEventListener('click', e => {
//         e.stopPropagation()
//       })
//     }
//   }

//   const handleEventClick = (event, e) => {
//     console.log('Event clicked:', event)
//   }

//   const handleMenuOpenerClick = event => {
//     // Your logic here, for example:
//     console.log('Menu opener clicked for event:', event)
//   }

//   // utility functions
//   const findCurrentIndex = () => {
//     for (let i = 0; i < datesOfEvent.length; i++) {
//       if (
//         moment(datesOfEvent[i]).format('DD-MM-YYYY') ===
//         moment(selectedDate).format('DD-MM-YYYY')
//       ) {
//         return i
//       }
//     }
//   }

//   const handleNextDate = () => {
//     const currentIndex = findCurrentIndex()
//     if (currentIndex < datesOfEvent.length - 1) {
//       setSelectedDate(new Date(datesOfEvent[currentIndex + 1]))
//     } else {
//       infoMessage('Date does not exist in event')
//     }
//   }

//   const handlePrevDate = () => {
//     const currentIndex = findCurrentIndex()
//     if (currentIndex > 0) {
//       setSelectedDate(new Date(datesOfEvent[currentIndex - 1]))
//     } else {
//       infoMessage('Date does not exists in event')
//     }
//   }
//   useEffect(() => {
//     const hasSpecialSchedule = timings?.specialSchedules?.find(schedule => {
//       return (
//         moment(selectedDate).format('MMMM-ddd-yyyy') ===
//         moment(schedule.day).format('MMMM-ddd-yyyy')
//       )
//     })
//     if (hasSpecialSchedule) {
//       setSpecialSchedule(hasSpecialSchedule)
//     } else {
//       setSpecialSchedule(undefined)
//     }
//   }, [selectedDate])

//   return (
//     <>
//       <div className="activity-header">
//         <div className="carret-cont" onClick={handlePrevDate}>
//           <CaretLeft size={20} color="#000" />
//         </div>
//         <h3>{moment(selectedDate).format('DD MMMM')}</h3>
//         <div className="carret-cont" onClick={handleNextDate}>
//           <CaretRight size={20} color="#000" />
//         </div>
//       </div>

//       <ScheduleComponent
//         key={specialSchedule ? specialSchedule.day : 'default'}
//         width="100%"
//         height="550px"
//         currentView="Day"
//         showHeaderBar={false}
//         cssClass="activity-scheduel-calendar"
//         timeScale={timeScale}
//         selectedDate={selectedDate}
//         startHour={
//           specialSchedule
//             ? convertMinutesToLocalTime(specialSchedule.startTime, 'HH:mm')
//             : convertMinutesToLocalTime(timings.startTime, 'HH:mm')
//         }
//         endHour={
//           specialSchedule
//             ? convertMinutesToLocalTime(specialSchedule.endTime, 'HH:mm')
//             : convertMinutesToLocalTime(timings.endTime, 'HH:mm')
//         }
//         eventSettings={eventSettings}
//         cellClick={onCellClick}
//         cellDoubleClick={onCellDoubleClick}
//         eventRendered={onEventRendered}
//         popupOpen={handlePopupOpen}
//       >
//         <Inject services={[Day]} />
//       </ScheduleComponent>

//       <ActivityTime
//         openModal={openModal}
//         setOpenModal={setOpenModal}
//         defaultData={cellData}
//         specialSchedule={specialSchedule}
//       />
//     </>
//   )
// }

// export default ActivityCalendar

import React, { useState, useEffect } from 'react'
import {
  ScheduleComponent,
  Day,
  Inject,
  CellClickEventArgs,
  PopupOpenEventArgs,
} from '@syncfusion/ej2-react-schedule'
import { creationEventStore } from 'state/eventCreationStore'
import { Activity, CaretLeft, CaretRight } from '@phosphor-icons/react'
import moment from 'moment'
import { infoMessage } from 'api/global'
import { convertMinutesToLocalTime } from 'helper/utility'
import ActivityTime from '../Modals/ActivityTime'
import ActivityMenu from './CustomMeuuOptions'
import { Dropdown, Button } from 'antd'
import './styles/schedular.scss'

const ActivityCalendar: React.FC = () => {
  const { datesOfEvent, timings, activitiesScheduleV2 } = creationEventStore(
    store => store.eventData,
  )
  const selectedDate = creationEventStore(store => store.selectedDate)
  const setSelectedDate = creationEventStore(store => store.setSelectedDate)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [cellData, setCellData] = useState<CellClickEventArgs>()
  const [specialSchedule, setSpecialSchedule] = useState<any>()
  const [data, setData] = useState<any>()
  const [menuVisible, setMenuVisible] = useState(false)
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 })
  const [clickedEvent, setClickedEvent] = useState<any>(null)
  const timeScale = { enable: true, interval: 60, slotCount: 4 }

  useEffect(() => {
    const activityShower = activitiesScheduleV2.map((activity: any) => {
      const startTime = new Date(activity.days[0])
      const endTime = new Date(activity.days[0])
      endTime.setMinutes(endTime.getMinutes() + activity.duration)
      return {
        Id: activity._id,
        Subject: activity.name,
        StartTime: startTime,
        EndTime: endTime,
        IsAllDay: false,
      }
    })
    setData(activityShower)
  }, [activitiesScheduleV2])

  const eventSettings = { dataSource: data }

  const onCellClick = (args: CellClickEventArgs) => {
    args.cancel = true
    openCustomPopup(args)
  }

  const onCellDoubleClick = (args: CellClickEventArgs) => {
    args.cancel = true
    openCustomPopup(args)
  }

  const openCustomPopup = (args: CellClickEventArgs) => {
    setCellData(args)
    setOpenModal(true)
  }

  const handlePopupOpen = (args: PopupOpenEventArgs) => {
    if (args.type === 'QuickInfo' || args.type === 'Editor') {
      args.cancel = true
    }
  }

  const onEventRendered = args => {
    const eventElement = args.element
    const event = args.data
    eventElement.style.backgroundColor =
      event.Subject === 'Check-in'
        ? '#FDEAD2'
        : event.Subject === 'Breakfast'
        ? '#D5E1F4'
        : event.Subject === 'Lunch'
        ? '#8CC73F33'
        : event.Subject === 'Dinner'
        ? '#D5EDF4'
        : event.Subject === 'Check-out'
        ? '#E8769F33'
        : '#47BFDF33'
    eventElement.style.color = '#ffffff'
    eventElement.style.borderRadius = '0px'
    eventElement.style.border = 'none'
    eventElement.classList.add('custom-event')
    eventElement.innerHTML = `
      <div class="custom-event-template">
        <div class="custom-event-header">${event.Subject}</div>
        <div class="custom-event-body">
          <div class="custom-event-time">
            ${new Date(event.StartTime).toLocaleTimeString()} - ${new Date(
              event.EndTime,
            ).toLocaleTimeString()}
          </div>
        </div>
        <div class="custom-menu-opener"></div>
      </div>
    `

    eventElement.addEventListener('click', e => {
      e.preventDefault()
      e.stopPropagation()
      handleEventClick(event, e)
    })

    const menuOpener = eventElement.querySelector('.custom-menu-opener')
    if (menuOpener) {
      menuOpener.addEventListener('click', e => {
        e.stopPropagation()
        handleMenuOpenerClick(event, e)
      })
    }
  }

  const handleEventClick = (event, e) => {
    console.log('Event clicked:', event)
  }

  const handleMenuOpenerClick = (event, e) => {
    e.preventDefault()
    const { clientX: x, clientY: y } = e
    setMenuPosition({ x, y })
    setClickedEvent(event)
    setMenuVisible(prev => !prev)
  }

  const handleMenuClick = key => {
    setMenuVisible(false)

    switch (key) {
      case 'view':
        console.log('key', key, clickedEvent)
        break
      case 'edit-activity':
        console.log('key', key)
        break
      case 'overlapping':
        setCellData(clickedEvent)
        setOpenModal(true)
        break
      case 'edit-time':
        console.log('key', key, clickedEvent)
        break
      case 'delete-activity':
        console.log('key', key, clickedEvent)
        break
      default:
        break
    }
  }

  // utility functions
  const findCurrentIndex = () => {
    for (let i = 0; i < datesOfEvent.length; i++) {
      if (
        moment(datesOfEvent[i]).format('DD-MM-YYYY') ===
        moment(selectedDate).format('DD-MM-YYYY')
      ) {
        return i
      }
    }
  }

  const handleNextDate = () => {
    const currentIndex = findCurrentIndex()
    if (currentIndex < datesOfEvent.length - 1) {
      setSelectedDate(new Date(datesOfEvent[currentIndex + 1]))
    } else {
      infoMessage('Date does not exist in event')
    }
  }

  const handlePrevDate = () => {
    const currentIndex = findCurrentIndex()
    if (currentIndex > 0) {
      setSelectedDate(new Date(datesOfEvent[currentIndex - 1]))
    } else {
      infoMessage('Date does not exists in event')
    }
  }

  useEffect(() => {
    const hasSpecialSchedule = timings?.specialSchedules?.find(schedule => {
      return (
        moment(selectedDate).format('MMMM-ddd-yyyy') ===
        moment(schedule.day).format('MMMM-ddd-yyyy')
      )
    })
    if (hasSpecialSchedule) {
      setSpecialSchedule(hasSpecialSchedule)
    } else {
      setSpecialSchedule(undefined)
    }
  }, [selectedDate])

  return (
    <>
      <div className="activity-header">
        <div className="carret-cont" onClick={handlePrevDate}>
          <CaretLeft size={20} color="#000" />
        </div>
        <h3>{moment(selectedDate).format('DD MMMM')}</h3>
        <div className="carret-cont" onClick={handleNextDate}>
          <CaretRight size={20} color="#000" />
        </div>
      </div>

      <ScheduleComponent
        key={specialSchedule ? specialSchedule.day : 'default'}
        width="100%"
        height="550px"
        currentView="Day"
        showHeaderBar={false}
        cssClass="activity-scheduel-calendar"
        timeScale={timeScale}
        selectedDate={selectedDate}
        startHour={
          specialSchedule
            ? convertMinutesToLocalTime(specialSchedule.startTime, 'HH:mm')
            : convertMinutesToLocalTime(timings.startTime, 'HH:mm')
        }
        endHour={
          specialSchedule
            ? convertMinutesToLocalTime(specialSchedule.endTime, 'HH:mm')
            : convertMinutesToLocalTime(timings.endTime, 'HH:mm')
        }
        eventSettings={eventSettings}
        cellClick={onCellClick}
        cellDoubleClick={onCellDoubleClick}
        eventRendered={onEventRendered}
        popupOpen={handlePopupOpen}
      >
        <Inject services={[Day]} />
      </ScheduleComponent>

      <ActivityTime
        openModal={openModal}
        setOpenModal={setOpenModal}
        defaultData={cellData}
        specialSchedule={specialSchedule}
      />

      {menuVisible && (
        <Dropdown
          overlay={<ActivityMenu handleMenuClick={handleMenuClick} />}
          open={menuVisible}
          onOpenChange={setMenuVisible}
        >
          <div
            style={{
              position: 'absolute',
              left: menuPosition.x - 150,
              top: menuPosition.y - 20,
            }}
          >
            <Button type="text" />
          </div>
        </Dropdown>
      )}
    </>
  )
}

export default ActivityCalendar
