import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Form,
  DatePicker,
  TimePicker,
  Input,
  Select,
  Dropdown,
} from 'antd'
import EventModal from './EventModal'
import { MapPinLine } from '@phosphor-icons/react'
import MapModal from 'helper/Map/MapModal'
import DocUpload from './DocUpload'
import { Locations } from "types/location"
interface IncidentFieldsProps {
  event: any
  setEvent: any
  handleChange: (value: any) => void
  mapData: Locations
  setMapData: (value: Locations) => void
  documentIds: string[];
  setDocumentIds: (ids: string[]) => void;
}

const IncidentFields: React.FC<IncidentFieldsProps> = ({
  event,
  setEvent,
  handleChange,
  mapData,
  setMapData,
  documentIds,
  setDocumentIds
}) => {
  const [form] = Form.useForm()
  const [eventModalVisible, setEventModalVisible] = useState<boolean>(false)
  const [mapModalVisible, setMapModalVisible] = useState<boolean>(false)
  const [mapModalData, setMapModalData] = useState<any>(event?.location)

  useEffect(() => {
    if (event?.location) {
      setMapModalData({
        street: event?.location?.street,
        city: event?.location?.city,
        state: event?.location?.state,
        zip: event?.location?.zip,
        country: event?.location?.country,
        coordinates: event?.location?.coordinates,
      })
    }
  }, [])

  const setFieldsValue = (data: any) => {
    if (data?.address) setMapData(data)
  }

  const items: any = [
    {
      key: 'custom-location',
      label: (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '16px',
            width: '100%',
          }}
          onClick={() => setMapModalVisible(true)}
        >
          <MapPinLine color="#2BA7CA" size={24} />
          <div>
            <h5
              className="cursor_pointer"
              style={{
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '22px',
                color: '#000',
                marginBottom: '8px !important',
              }}
            >
              Custom location
            </h5>
          </div>
        </div>
      ),
    },
    {
      key: 'pre-location',
      label: (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '16px',
            width: '100%',
          }}
        >
          <MapPinLine color="#2BA7CA" size={24} />
          <div>
            <h5
              className="cursor_pointer"
              style={{
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '22px',
                color: '#000',
                marginBottom: '8px !important',
              }}
            >
              {mapData
                ? `${mapData.title}: ${mapData.city}, ${mapData.state}`
                : 'This event has no location'}
            </h5>
          </div>
        </div>
      ),
    },
  ]

  return (
    <>
      <div>
        <Row>
          <Col span={24}>
            <label htmlFor="" className="custom-field-label required-input">
              Name of Incident
            </label>
            <Form.Item
              name="incidentName"
              rules={[{ required: true, message: 'Please enter Incident' }]}
            >
              <Input
                className="custom-input-field"
                placeholder="Ex.: Broken arm"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label htmlFor="" className="custom-field-label required-input">
              Date and Time of Incident
            </label>
            <Form.Item
              name="incidentDate"
              rules={[{ required: true, message: 'Date is required' }]}
            >
              <DatePicker
                className="w-100 form-control-field"
                placeholder="Date of Incident"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="incidentTime"
              rules={[{ required: true, message: 'Incident time is required' }]}
            >
              <TimePicker
                className="w-100 form-control-field"
                placeholder="Time of Incident"
                format="h:mm a"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label htmlFor="" className="custom-field-label required-input">
              Which event did this occur at?
            </label>
            {/* <Form.Item rules={[{required: true, message: "No event is selected"}]} colon={false} name="organizationId"> */}
            <Input
              onClick={() => {
                setEventModalVisible(true)
              }}
              placeholder="Select event"
              value={event ? event?.name : 'Select event'}
              className="form-control-field mb-20px"
            ></Input>
            {/* </Form.Item> */}
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label htmlFor="" className="custom-field-label required-input">
              Incident Type
            </label>
            <Form.Item
              name="incidentType"
              rules={[{ required: true, message: 'Select incident type' }]}
              className="pointer selector-input"
            >
              <Select
                className="select-input-field notes-select-field"
                onChange={handleChange}
                optionLabelProp="value"
                options={[
                  {
                    value: 'behaviour',
                    label: (
                      <>
                        <h1 className="text-12 w-500">Disruptive behaviour</h1>{' '}
                        <p className="grey-txt">
                          (Yelling uncontrollably, lying, stealing, causing
                          damage to objects, etc)
                        </p>
                      </>
                    ),
                  },
                  {
                    value: 'bullying',
                    label: (
                      <>
                        <h1 className="text-12 w-500">Bullying</h1>{' '}
                        <p className="grey-txt">
                          (physical, mental, cognitive, spiritual, social, or
                          emotional)
                        </p>
                      </>
                    ),
                  },
                  {
                    value: 'language',
                    label: (
                      <>
                        <h1 className="text-12 w-500">Foul Language</h1>{' '}
                        <p className="grey-txt">
                          (cussing, innuendos, inappropriate topics, hurtful
                          words)
                        </p>
                      </>
                    ),
                  },
                  {
                    value: 'violance',
                    label: (
                      <>
                        <h1 className="text-12 w-500">Physical Violence</h1>{' '}
                        <p className="grey-txt">
                          (hit, slap, push, shove, punch, trip, throw, etc)
                        </p>
                      </>
                    ),
                  },
                  {
                    value: 'first-aid',
                    label: (
                      <>
                        <h1 className="text-12 w-500">First Aid Injury</h1>{' '}
                        <p className="grey-txt">
                          (minor cuts, bruises, abrasions)
                        </p>
                      </>
                    ),
                  },
                  {
                    value: 'severity-injury',
                    label: (
                      <>
                        <h1 className="text-12 w-500">Low-severity injury</h1>{' '}
                        <p className="grey-txt">
                          (sprain, bruise from Impact, etc)
                        </p>
                      </>
                    ),
                  },
                  {
                    value: 'tooth-loss',
                    label: (
                      <>
                        <h1 className="text-12 w-500">Loss of Tooth </h1>{' '}
                        <p className="grey-txt">
                          (automatic, manual, resulting from external force)
                        </p>
                      </>
                    ),
                  },
                  {
                    value: 'severe-injury',
                    label: (
                      <>
                        <h1 className="text-12 w-500">Severe Injury </h1>{' '}
                        <p className="grey-txt">
                          (broken limb, fracture, loss of consciousness)
                        </p>
                      </>
                    ),
                  },
                  {
                    value: 'other',
                    label: 'Other',
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label htmlFor="" className="custom-field-label required-input">
              Parties Involved
            </label>
            <Form.Item
              name="partiesInvolved"
              rules={[
                {
                  required: true,
                  message: 'Please enter parties involved',
                },
              ]}
            >
              <Input
                className="custom-input-field"
                placeholder="Ex.: Broken arm"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label htmlFor="" className="custom-field-label required-input">
              Description of Incident
            </label>
            <Form.Item
              name="description"
              rules={[{ required: true, message: 'Please enter Description' }]}
            >
              <Input.TextArea
                className="note-field"
                placeholder="Enter a note here"
                autoSize={{ minRows: 3, maxRows: 7 }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label htmlFor="" className="custom-field-label required-input">
              Incident Location
            </label>
            <Form.Item name="incidentloc" className="pointer selector-input">
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottomLeft"
                trigger={['click']}
                className="dropdown-tagged-member"
                overlayStyle={{ pointerEvents: 'auto' }}
              >
                <Input
                  placeholder="Select location"
                  value={
                    mapData
                      ? mapData.title +
                        ': ' +
                        mapData.city +
                        ',' +
                        mapData.state
                      : ''
                  }
                  className="custom-input-field"
                />
              </Dropdown>
            </Form.Item>
          </Col>

          {mapModalVisible && (
            <MapModal
              setMapModalVisible={setMapModalVisible}
              setMapModalData={setMapModalData}
              mapData={mapModalData}
              setFieldsValue={setFieldsValue}
            />
          )}
        </Row>

        <Row>
          <Col span={24}>
            <label htmlFor="" className="custom-field-label required-input">
              Agency notified
            </label>
            <Form.Item
              name="agencyNotified"
              rules={[
                {
                  required: true,
                  message: 'Please enter agency to notify',
                },
              ]}
            >
              <Input
                className="custom-input-field"
                placeholder="Agency notify"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label htmlFor="" className="custom-field-label">
              Any other relevent info?
            </label>
            <Form.Item name="relevantInfo">
              <Input
                className="custom-input-field"
                placeholder="Any other info"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
        <label htmlFor="" className="custom-field-label">
             Upload image or document
            </label>
          <Col span={16}>
              <DocUpload documentIds={documentIds} setDocumentIds={setDocumentIds} />
          </Col>
        </Row>
      </div>
      {eventModalVisible && (
        <EventModal
          open={eventModalVisible}
          setOpen={setEventModalVisible}
          selectedEvent={event}
          setSelectedEvent={setEvent}
        />
      )}
    </>
  )
}

export default IncidentFields
