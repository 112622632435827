import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Radio, RadioChangeEvent, Input } from 'antd'
import { Address, OrganizationResp } from 'types/globalTypes'
import LocationListingModal from '../GeneralDetailModal/LocationListingModal'
import VenueModal from '../GeneralDetailModal/VenueModal'
import MapModal from 'helper/Map/MapModal'
import { OrganizationVenues } from 'types/organization'
import { useAddOrgLocation } from 'api/EventCreation/EventCreation'

interface VenueInputPropsTypes {
  isEventOnline: boolean
  setEventOnline: React.Dispatch<React.SetStateAction<boolean>>
  selectedOrganization: OrganizationResp
  mapData: Address
  setMapModalData: React.Dispatch<React.SetStateAction<Address>>
  venueDataItem: OrganizationVenues | null
  setVenueDataItem: React.Dispatch<React.SetStateAction<OrganizationVenues>>
}

const VenuType: React.FC<VenueInputPropsTypes> = ({
  isEventOnline,
  setEventOnline,
  selectedOrganization,
  mapData,
  setMapModalData,
  venueDataItem,
  setVenueDataItem,
}) => {
  const [form] = Form.useForm()
  const [activeKey, setActiveKey] = useState<string>('1')
  const [locationModalVisible, setLocationModalVisible] =
    useState<boolean>(false)
  const [venueModalVisible, setVenueModalVisible] = useState<boolean>(false)
  const [mapModalVisible, setMapModalVisible] = useState<boolean>(false)
  const [isFromPreviousLoc, setIsFromPreviousLoc] = useState<boolean>(false)
  const { mutate, isLoading } = useAddOrgLocation()

  const setFieldsValue = (data: any) => {
    if (data?.address || data?.country) {
      form.setFieldsValue({ location: data?.title })
    } else {
      form.setFieldsValue({ categories: data?.categories })
    }
  }

  useEffect(() => {
    if (!isFromPreviousLoc && mapData) {
      mutate(
        { payload: mapData, organizationId: selectedOrganization?._id },
        {
          onSuccess: result => {
            setLocationModalVisible(false)
          },
        },
      )
    }
  }, [mapData])

  return (
    <>
      <div>
        <Row gutter={10}>
          <Col className="mt-10">
            <label className="input-label required-input">
              Event Venue Type
            </label>
            <Form.Item className="form-control mb-0" name="venueType">
              <Radio.Group
                onChange={(val: RadioChangeEvent) => {
                  if (val.target.value !== 'physical') {
                    setEventOnline(true)
                  } else {
                    setEventOnline(false)
                  }
                }}
                defaultValue={'physical'}
              >
                <Radio value={'physical'} className="mr-40 radio">
                  In person
                </Radio>
                <Radio value={'virtual'} className="mr-40 radio">
                  Online
                </Radio>
                <Radio value={'to-be-determined'} className="mr-40 radio">
                  To be determined
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        {isEventOnline ? (
          <></>
        ) : (
          <>
            {selectedOrganization?.id && (
              <>
                <Row className="mt-10px">
                  <Col>
                    <label className="required-input  input-label">
                      Location
                    </label>
                  </Col>
                  <Col>
                    <div
                      className="w-full border-1px radius-10 flex align-center px-12px pointer h-50px"
                      onClick={() => setLocationModalVisible(true)}
                    >
                      {mapData ? (
                        <div className=" w-full flex justify-between align-center ">
                          <p className="text-gray">{`${mapData.title}: ${mapData.apartment}, ${mapData.city}, ${mapData.country}`}</p>
                        </div>
                      ) : (
                        <p className="text-gray">Select Location</p>
                      )}
                    </div>
                  </Col>
                </Row>
                {mapData && (
                  <Row gutter={10} className="mt-15px">
                    <Col>
                      <label className="required-input  input-label">
                        Venue
                      </label>
                    </Col>
                    <Col>
                      <div
                        className="w-full border-1px radius-10 flex align-center px-12px pointer h-50px"
                        onClick={() => setVenueModalVisible(true)}
                      >
                        {venueDataItem ? (
                          <div className=" w-full flex justify-between align-center">
                            <p className="text-gray">{`${venueDataItem.name}`}</p>
                          </div>
                        ) : (
                          <p className="text-gray">Select Venue</p>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </>
        )}
      </div>

      {locationModalVisible && (
        <LocationListingModal
          isModalVisible={locationModalVisible}
          setModalVisible={setLocationModalVisible}
          organizationId={selectedOrganization?.id}
          activeKeyTab={activeKey}
          setDefaultTabKey={setActiveKey}
          setMapModalVisible={setMapModalVisible}
          setMapModalData={setMapModalData}
          setFieldsValue={setFieldsValue}
          setIsFromPreviousLoc={setIsFromPreviousLoc}
        />
      )}

      {venueModalVisible && (
        <VenueModal
          mapData={mapData}
          setVenueModalVisible={setVenueModalVisible}
          setFieldsValue={setFieldsValue}
          organizationId={selectedOrganization?.id}
          setVenueDataItem={setVenueDataItem}
        />
      )}

      {mapModalVisible && (
        <MapModal
          mapData={mapData}
          setMapModalVisible={setMapModalVisible}
          setMapModalData={setMapModalData}
          setFieldsValue={setFieldsValue}
        />
      )}
    </>
  )
}

export default VenuType
