import React, { useState, useEffect } from 'react'
import { Modal, Tabs } from 'antd'
import closeIcon from 'assets/Images/close.svg'
import { useGetInventoryStorageLoc } from 'api/EventCreation/CreationStep2'
import { creationEventStore } from 'state/eventCreationStore'
import Loader from 'helper/Loader'
import EmptyData from 'components/global/EmptyData'
import '../style/styles.scss'
import StorageMapModal from './StorageMapModal'
import { inventoryStorageLocType } from 'types/events'

interface StorageLocationModalProps {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
}

const StorageLocationModal: React.FC<StorageLocationModalProps> = ({
  openModal,
  closeModal,
}) => {
  const { organizationId } = creationEventStore(store => store.eventData)
  const setStorageLocation = creationEventStore(
    store => store.setStorageLocation,
  )
  const [openMapModal, setOpenMapModel] = useState<boolean>(false)
  const {
    data: inventoryStorageLoc,
    isLoading: inventoryStorageLoading,
    isFetching: inventoryStorageFetching,
    refetch: inventoryStorageRefetch,
  } = useGetInventoryStorageLoc(organizationId)

  const handleCancel = () => {
    closeModal(false)
  }

  const handleSelectStorage = (storage: inventoryStorageLocType) => {
    setStorageLocation(storage)
    handleCancel()
  }

  useEffect(() => {
    inventoryStorageRefetch()
  }, [organizationId])

  return (
    <>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        className="add-supplyform-modal"
        footer={false}
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Storage location</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>

        <div className="h-33rem overflow-auto-y overflow-hidden-x scroller">
          {inventoryStorageLoading || inventoryStorageFetching ? (
            <Loader />
          ) : (
            <div
              className={`${
                inventoryStorageLoc.length === 0 &&
                'flex justify-center align-center flex-col'
              }`}
            >
              <div
                className={`${
                  inventoryStorageLoc.length === 0 &&
                  'h-30rem justify-center align-center'
                } flex `}
              >
                {inventoryStorageLoc.length > 0 ? (
                  <div className="mt-30px w-full">
                    {inventoryStorageLoc.map((loc: inventoryStorageLocType) => {
                      return (
                        <div
                          key={loc._id}
                          className="flex align-center justify-between mt-10px"
                        >
                          <p>{`${loc.title}: ${loc.country}, ${loc.city}`}</p>
                          <button
                            className="border-1px-blue px-15px py-12px radius-8 pointer"
                            onClick={() => handleSelectStorage(loc)}
                          >
                            Select
                          </button>
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <EmptyData message="No locations available" />
                )}
              </div>
              <div className="mt-20px">
                <button
                  className="big-btn mx-auto"
                  onClick={() => setOpenMapModel(true)}
                >
                  Add Custom location
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
      {openMapModal && (
        <StorageMapModal
          openModal={openMapModal}
          closeModal={setOpenMapModel}
        />
      )}
    </>
  )
}

export default StorageLocationModal
