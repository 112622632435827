import { Modal, Select, Avatar } from 'antd'
import { useQuery } from '@tanstack/react-query'
import { getOrganizations } from '../../api/Organizations'
import { useState } from 'react'
import Cancel from '../../assets/Images/close.svg'
import './index.scss'
import { Pagination } from 'antd'
import Spinner from '../Spinner'
import { paginationInfo } from '../../helper/utility'
import { GetRequestParams, RequestParms } from '../../types/globalTypes'
const OrganizationListing = ({ setData, prevData }: any) => {
  const [params, setParams] = useState<RequestParms & GetRequestParams>({
    page: 1,
    limit: 10,
    status: 'approved',
  })
  const [selectorDef, setSelectorDef] = useState(
    'Please select an Organization',
  )
  const { isLoading, data, isFetching, refetch } = useQuery({
    queryKey: ['listingOrganizations', params],
    queryFn: () => getOrganizations(params),
  })
  const [isOpen, setIsOpen] = useState(false)
  const [clickedButtons, setClickedButtons] = useState<any>()
  const PaginationChanger = (pageNumber: number, pageSize: number) => {
    setParams({
      ...paginationInfo,
      page: pageNumber,
      limit: pageSize,
      status: 'approved',
    })
    refetch()
  }
  const ClickedOrg = (data: any, index: number) => {
    setClickedButtons(index)
    setSelectorDef(data?.name)
    setData((prev: any) => ({ ...prev, orgData: data?._id }))
  }

  const submit = () => {
    setIsOpen(false)
  }
  const handleCancel = () => {
    setIsOpen(false)
  }
  return (
    <div>
      <Select
        className="custom-select-field h-3 position w-full org-select-field"
        value={selectorDef}
        placeholder="sort by"
        onClick={() => {
          setIsOpen(true)
        }}
      ></Select>
      <Modal
        visible={isOpen}
        onCancel={handleCancel}
        className="popup-modal org-modal"
        style={{ zIndex: '50000' }}
      >
        <div>
          <div className="popup-header">
            <h2 className="w-600">Select Organization</h2>
            <img
              src={Cancel}
              alt="close button pointer"
              onClick={handleCancel}
              className=""
            />
          </div>
          <div className="org-list pointer">
            {isLoading || isFetching ? (
              <div className="flex justify-center align-center h-full">
                <Spinner />
              </div>
            ) : (
              data?.records.map((orgData: any, index: number) => {
                return (
                  <div
                    className={
                      clickedButtons === index
                        ? 'org-active flex align-center mt-15px border-1 pb-10px pointer'
                        : 'flex align-center mt-15px border-1 pb-10px  pointer ml-20px'
                    }
                    key={index}
                    onClick={() => {
                      ClickedOrg(orgData, index)
                    }}
                  >
                    <Avatar
                      size={34}
                      src={
                        orgData?.organization?.photo
                          ? orgData?.organization?.photo?.post
                          : ''
                      }
                    >
                      M
                    </Avatar>
                    <h4 className="ml-10">{orgData.name}</h4>
                  </div>
                )
              })
            )}
          </div>
          <div className="mt-40px justify-center flex align-center">
            <Pagination
              defaultCurrent={params.page}
              total={data?.count}
              onChange={PaginationChanger}
            />
          </div>

          <div className="popup-footer">
            <button
              className="btn-sec pointer"
              onClick={() => {
                handleCancel()
              }}
            >
              Cancel
            </button>
            <button className="btn-prim" onClick={submit}>
              Select Organization
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default OrganizationListing
