import { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import AppLayout from '../../components/layout/layout'
import { Tabs } from 'antd'
import FamilyTable from './Table/FamilyTable'
import GuardiansModal from '../../components/modals/add-EditFamilyMembers/Guardian/GuardiansModal'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getFamilies, detletFamily, changeFamilyStatus } from '../../api/family'
import Spinner from '../../components/Spinner'
import ActionsHeader from './actionsHeader'
import PageNameHeader from '../../components/Header/pageHeaderComponent'
import { GetRequestParams, Populate, RequestParms } from 'types/globalTypes'
import { useDispatch, connect } from 'react-redux'
import actions from 'Redux/Family/action'
import { Data } from '@react-google-maps/api'

const { TabPane } = Tabs
const Family = ({ currentPage, defaultActivePage, status }) => {
  const prevRoute = localStorage.getItem('previousRoute')
  const dispatch = useDispatch()
  const [parms, setParms] = useState<
    RequestParms & GetRequestParams & Populate
  >({
    page: prevRoute.includes('profile') ? currentPage : 1,
    limit: 10,
    // status: 'active',
    status: prevRoute.includes('profile') ? status : 'active',
    populate: ['guardians', 'kids', 'adults'],
  })
  const { isLoading, isFetching, data, refetch } = useQuery(
    ['getFamiles', parms],
    () => getFamilies(parms),
    { enabled: false },
  )
  const [hiddenColumns, setHiddenColumns] = useState<any>({
    family: true,
    guardian: true,
    adults: true,
    kids: true,
    primarycontact: true,
    status: true,
    action: true,
  })
  const [selectedRows, setSelectedRows] = useState([])
  const changeMutation = useMutation({
    mutationFn: async ({ params, payload }: any) => {
      return await changeFamilyStatus(params, payload)
    },
    onSuccess() {
      refetch()
    },
  })

  const mutation = useMutation({
    mutationFn: async mutData => {
      return await detletFamily(mutData)
    },
  })
  const deletLoading = mutation.isLoading

  const OndeleteFamily = (activeItem: any) => {
    mutation.mutate(activeItem)
    refetch()
  }
  const [isModalVisible, setModalVisible] = useState(false)

  function callback(key: any) {
    if (key == 1) {
      setParms({ ...parms, status: 'active', page: 1 })
      dispatch({ type: actions.CURRENT_PAGE, payload: 1 })
      dispatch({
        type: actions.DEFAULT_ACTIVE_PAGE,
        payload: { key: '1', status: 'active' },
      })
      refetch()
    } else if (key == 2) {
      setParms({ ...parms, status: 'pending', page: 1 })
      dispatch({ type: actions.CURRENT_PAGE, payload: 1 })
      dispatch({
        type: actions.DEFAULT_ACTIVE_PAGE,
        payload: { key: '2', status: 'pending' },
      })
      refetch()
    } else if (key == 3) {
      setParms({ ...parms, status: 'blocked', page: 1 })
      dispatch({ type: actions.CURRENT_PAGE, payload: 1 })
      dispatch({
        type: actions.DEFAULT_ACTIVE_PAGE,
        payload: { key: '3', status: 'blocked' },
      })
      refetch()
    }
  }

  useEffect(() => {
    if (prevRoute.includes('profile')) {
      dispatch({ type: actions.CURRENT_PAGE, payload: currentPage })
      dispatch({
        type: actions.DEFAULT_ACTIVE_PAGE,
        payload: { key: defaultActivePage, status: status },
      })
    } else {
      dispatch({ type: actions.CURRENT_PAGE, payload: 1 })
      dispatch({
        type: actions.DEFAULT_ACTIVE_PAGE,
        payload: { key: '1', status: 'active' },
      })
    }
  }, [])
  useEffect(() => {
    refetch()
  }, [currentPage, defaultActivePage, status, parms])

  // console.log(selectedRows, 'selected')
  // console.log(data, 'fullData')
  // console.log(parms, 'params')
  return (
    <AppLayout>
      <PageNameHeader
        selectedRows={selectedRows}
        completedata={data?.records}
        fullData={data?.records}
      />
      <Row>
        <Col style={{ width: '100%' }}>
          <Tabs
            className="orgn-tabs"
            defaultActiveKey={
              prevRoute.includes('profile') ? defaultActivePage : '1'
            }
            onChange={callback}
          >
            <TabPane tab="Active" key="1" style={{}}>
              <Row>
                <Col style={{ alignItems: 'center', width: '100%' }}>
                  <ActionsHeader
                    setParms={setParms}
                    parmsData={parms}
                    hiddenColumns={hiddenColumns}
                    setHiddenColumns={setHiddenColumns}
                  />
                  {isLoading || isFetching || deletLoading ? (
                    <div
                      className="flex justify-center aligin-center items-center "
                      style={{ height: '80vh' }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <FamilyTable
                      activeData={data}
                      del={OndeleteFamily}
                      setSelectedRows={setSelectedRows}
                      status="active"
                      setParms={setParms}
                      parmsData={parms}
                      changeStatus={changeMutation}
                      refetchFamilies={refetch}
                      fullData={data}
                      hiddenColumns={hiddenColumns}
                    />
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={<span>Pending</span>} key="2">
              <Row style={{ width: '100%' }}>
                <Col style={{ alignItems: 'center', width: '100%' }}>
                  <ActionsHeader
                    setParms={setParms}
                    parmsData={parms}
                    hiddenColumns={hiddenColumns}
                    setHiddenColumns={setHiddenColumns}
                  />
                  {isLoading || isFetching || deletLoading ? (
                    <div
                      className="flex justify-center aligin-center items-center "
                      style={{ height: '80vh' }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <FamilyTable
                      activeData={data}
                      del={OndeleteFamily}
                      setSelectedRows={setSelectedRows}
                      status="pending"
                      setParms={setParms}
                      parmsData={parms}
                      changeStatus={changeMutation}
                      refetchFamilies={refetch}
                      fullData={data}
                      hiddenColumns={hiddenColumns}
                    />
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={<span>Blocked</span>} key="3">
              <Row style={{ width: '100%' }}>
                <Col style={{ alignItems: 'center', width: '100%' }}>
                  <ActionsHeader
                    setParms={setParms}
                    parmsData={parms}
                    hiddenColumns={hiddenColumns}
                    setHiddenColumns={setHiddenColumns}
                  />
                  {isLoading || isFetching || deletLoading ? (
                    <div
                      className="flex justify-center aligin-center items-center "
                      style={{ height: '80vh' }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <FamilyTable
                      activeData={data}
                      del={OndeleteFamily}
                      setSelectedRows={setSelectedRows}
                      status="blocked"
                      setParms={setParms}
                      parmsData={parms}
                      changeStatus={changeMutation}
                      refetchFamilies={refetch}
                      fullData={data}
                      hiddenColumns={hiddenColumns}
                    />
                  )}
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      {isModalVisible ? (
        <GuardiansModal
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          operation="create"
        />
      ) : (
        <></>
      )}
    </AppLayout>
  )
}
const mapStateToProps = (state: any) => ({
  currentPage: state.family.currentPage,
  defaultActivePage: state.family.defaultActivePage,
  status: state.family.status,
})

export default connect(mapStateToProps)(Family)
