import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Form, TimePicker } from 'antd'
import moment, { Moment } from 'moment'
import { connect, useDispatch } from 'react-redux'
import Modal from 'antd/lib/modal/Modal'
import { useAppSelector } from '../../../../../../Redux/hooks'
import CustomCalender from 'helper/Calendar/Eventscalendar/CustomCalender'
import Calendar from 'react-calendar'
import plusBlue from '../../../../../../assets/Images/plusBlue.svg'

const SelectScheduleModal = ({
  setIsSelectScheduleModalVisible,
  eventOrganizationData,
  selectedDates,
  setSelectedDates,
  step1,
  isAdditionalTicket,
  selectedTicketId,
  setAddSpecialScheduleModalVisible,
  specialScheduleData,
  setEventTime,
  eventTime,
}: any) => {
  const [form] = Form.useForm()
  const [days, setDays] = useState<moment.Moment[]>()
  const [defaultTime, setDefaultTime] = useState({
    startTime: eventTime.startTime
      ? eventTime.startTime
      : moment('9:00', 'HH:mm'),
    endTime: eventTime.endTime ? eventTime.endTime : moment('15:00', 'HH:mm'),
  })

  const handleStartTimeChange = (time: Moment | null, timeString: string) => {
    setDefaultTime({ ...defaultTime, startTime: moment(time) })
  }
  const handleEndTimeChange = (time: Moment | null, timeString: string) => {
    setDefaultTime({ ...defaultTime, endTime: moment(time) })
  }

  const timeFormat = 'HH:mm a'
  const [datesOfAllowedTicket, setDatesOfAllowedTicket] = useState([])
  // @ts-ignore
  const currentEvent: EventType = useAppSelector(
    state => state.events.currentEvent,
  )
  const handleTileClick = (date: any) => {
    const formattedDate = moment(date).format('YYYY-MM-DD')
    if (!selectedDates.includes(formattedDate)) {
      setSelectedDates([...selectedDates, formattedDate])
    } else {
      setSelectedDates(
        selectedDates.filter(
          (selectedDate: any) => selectedDate !== formattedDate,
        ),
      )
    }
  }

  useEffect(() => {
    if (isAdditionalTicket) {
      console.log(
        'eventOrganizationData ',
        eventOrganizationData?.admissionTickets,
      )
      const tempObj = eventOrganizationData?.admissionTickets.find(
        (obj: any) => obj._id === selectedTicketId,
      )
      setDatesOfAllowedTicket(tempObj.days)
      console.log('dates to be show : ', datesOfAllowedTicket)
    }
  }, [])

  useEffect(() => {
    setEventTime([moment('9:00', 'HH:mm'), moment('15:00', 'HH:mm')])
  }, [])

  useEffect(() => {
    if (selectedDates.length > 0) {
      const totalDays = selectedDates.length
      const temp = []
      for (let i = 0; i < totalDays; i++) {
        temp.push(moment(selectedDates[i], 'yyyy-MM-DD'))
      }
      setDays([...temp])
    } else {
      setDays([])
    }
  }, [selectedDates])
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }
  function formatDatesToDateOnly(datetimeArray) {
    return datetimeArray?.map(dateTimeString => {
      return moment(dateTimeString).format('YYYY-MM-DD')
    })
  }
  const tileDisabledForAdditionalOption = ({ date }: { date: Date }) =>
    eventOrganizationData
      ? !formatDatesToDateOnly(datesOfAllowedTicket).find(
          (d: string) => d === moment(date).format('YYYY-MM-DD'),
        )
      : false

  const tileDisabled = ({ date }: { date: Date }) =>
    eventOrganizationData
      ? !formatDatesToDateOnly(eventOrganizationData?.datesOfEvent).find(
          (d: string) => d === moment(date).format('YYYY-MM-DD'),
        )
      : false
  const onAddPress = (values: any) => {
    setEventTime(values.eventTime)
    setIsSelectScheduleModalVisible(false)
  }
  const formSchema = {
    eventTime: [moment('9:00', 'HH:mm'), moment('15:00', 'HH:mm')],
  }
  useEffect(() => {
    setEventTime(defaultTime)
  }, [defaultTime])
  return (
    <div>
      <Modal
        open={true}
        className="map-modal add-more-fees-modal"
        footer={null}
        centered={true}
        width={450}
        onCancel={() => {
          setIsSelectScheduleModalVisible(false)
        }}
      >
        <h1 className="f-semibold"> Select Event Time </h1>
        <Form
          form={form}
          layout="horizontal"
          initialValues={formSchema}
          onFinish={onAddPress}
          validateMessages={validateMessages}
        >
          <div>
            {/* <CustomCalender daysToHighlight={days} /> */}
            <div className="border-btm pb-10px">
              <Calendar
                tileClassName={({ date, view }) => {
                  if (
                    selectedDates?.find(
                      (x: any) => x === moment(date).format('YYYY-MM-DD'),
                    )
                  ) {
                    return 'selected'
                  }
                }}
              />
            </div>
            <div className="mt-20px">
              <h2 className="text-14 w-600">Weekly default schedule</h2>
              <div className="flex align-center gap-16px mt-15px w-70p">
                <TimePicker
                  className="w-100 form-control-field"
                  placeholder="Start Time"
                  format="h:mm a"
                  value={defaultTime.startTime}
                  showNow={false}
                  clearIcon={false}
                  onChange={handleStartTimeChange}
                />
                <div>-</div>
                <TimePicker
                  className="w-100 form-control-field"
                  placeholder="End Time"
                  format="h:mm a"
                  value={defaultTime.endTime}
                  showNow={false}
                  clearIcon={false}
                  onChange={handleEndTimeChange}
                />
              </div>
            </div>
            <Col
              className="d-flex align-items-center"
              style={{ marginTop: 30 }}
            >
              <h5
                className="text-primary cursor_pointer"
                onClick={() => {
                  if (selectedDates.length > 0) {
                    setAddSpecialScheduleModalVisible(true)
                  }
                }}
              >
                <b className="b2">
                  <img src={plusBlue}></img> Add Special schedule
                </b>
              </h5>
            </Col>
          </div>

          <Row>
            <Col className="d-flex justify-center gap-20 mt-10">
              <Button
                className="gry-btn btn-h-50 w-155 hl-50 br-8 py-0 mt-20"
                onClick={() => setIsSelectScheduleModalVisible(false)}
              >
                Cancel
              </Button>
              <Button className="stepper-button mt-20" htmlType="submit">
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  eventsDataValue: state.events.eventsDataValue,
  eventOrganizationData: state.events.eventOrganizationData,
})
export default connect(mapStateToProps)(SelectScheduleModal)
