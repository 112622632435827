import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Switch,
  Slider,
  InputNumber,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import FeedBack from "../../../../../../Modals/JobRequestModal/Screens/FeedBack";

const FeeModal = ({ setFeeModalVisible, FeeData, setFeeData }: any) => {
  const [form] = Form.useForm();
  const [isPriceRange, setIsPriceRange] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState(1);
  const [sliderRangeValue, setSliderRangeValue] = useState<any>([0, 20]);

  const formSchema = {
    feeName: "",
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onSliderChange = (value: number | [number, number]) => {
    console.log("onChange: ", value);
    setSliderRangeValue(value);
  };

  const onChangetoggle = (e: any, checked?: boolean) => {
    setIsPriceRange(e);
  };

  const onFinish = (values: any) => {
    console.log("val:", values);
    // console.log("isPriceRange:", isPriceRange);
    // console.log("sliderRangeValue:", sliderRangeValue);
    const dataValues = {
      feeName: values.feeName,
      price: !isPriceRange && values.price,
      priceRange: isPriceRange && sliderRangeValue,
    };
    setFeeData([...FeeData, dataValues]);
    setFeeModalVisible(false);
  };
  return (
    <div>
      <Modal
        visible={true}
        className="map-modal add-more-fees-modal"
        footer={null}
        centered={true}
        width={600}
        onCancel={() => {
          setFeeModalVisible(false);
        }}
      >
        <h4 className="f-semibold fees-modal-heading">Add more fees</h4>

        <div className="modal-content-body">
          <Form
            form={form}
            layout="horizontal"
            initialValues={formSchema}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Row gutter={10}>
              <Col className="mt-10">
                <label className="input-label required-input">Fee name</label>
                <Form.Item
                  className="form-control mb-0"
                  name="feeName"
                  rules={[{ required: true, message: "Fee name is required" }]}
                >
                  <Input
                    placeholder="Enter fee name"
                    className="custom-common-field mb-0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                className="mt-10 inner-toggle-swich"
                style={{
                  paddingBottom: "12px",
                  display: "flex",
                }}
              >
                <div>
                  <p>Price range</p>
                </div>
                <Form.Item valuePropName="checked" name="priceRange" noStyle>
                  <Switch
                    onChange={(e: any) => onChangetoggle(e)}
                    className="custom-switch-btn"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              {!isPriceRange ? (
                <Col>
                  <label className="input-label required-input">Price</label>
                </Col>
              ) : (
                <Col>
                  <label className="input-label required-input">
                    Price range
                  </label>
                </Col>
              )}
              {!isPriceRange ? (
                <Col className="d-flex justify-between">
                  <Form.Item name="price" noStyle>
                    <div>
                      <span className="d-inline-block mr-10 font16">$</span>
                      <InputNumber
                        type={"number"}
                        className="br-12 custom-input-num-field font16 text-end"
                      />
                    </div>
                  </Form.Item>
                </Col>
              ) : (
                <Col className="d-flex justify-between">
                  <InputNumber
                    type={"number"}
                    prefix="$"
                    value={sliderRangeValue && sliderRangeValue[0]}
                    disabled={isPriceRange}
                    className="br-12 custom-input-num-field font16 text-color-black"
                  />

                  <InputNumber
                    type={"number"}
                    prefix="$"
                    value={sliderRangeValue && sliderRangeValue[1]}
                    disabled
                    className="br-12 custom-input-num-field font16 text-color-black"
                  />
                </Col>
              )}
              {isPriceRange && (
                <Col className="mt-20">
                  <Slider
                    min={0}
                    max={50}
                    range
                    onChange={onSliderChange}
                    value={sliderRangeValue}
                    defaultValue={[0, 20]}
                    className="price-range-slider"
                  />
                </Col>
              )}
            </Row>
            <div className="justify-center">
              <Row className="justify-end">
                <Button className="stepper-button mt-30" htmlType="submit">
                  Add
                </Button>
              </Row>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default FeeModal;
