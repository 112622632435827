const actions = {
  ADD_ORGANIZER: "ADD_ORGANIZER",
  ADD_ORGANIZER_DATA_SUCCESS: "ADD_ORGANIZER_DATA_SUCCESS",
  ADD_ORGANIZER_DATA_FAILURE: "ADD_ORGANIZER_DATA_FAILURE",
  UPDATE_ORGANIZER: "UPDATE_ORGANIZER",
  UPDATE_ORGANIZER_SUCCESS: "UPDATE_ORGANIZER_SUCCESS",
  UPDATE_ORGANIZER_FAILURE: "UPDATE_ORGANIZER_FAILURE",
  DELETE_ORGANIZER: "DELETE_ORGANIZER",
  CURRENT_ORGANIZER: "CURRENT_ORGANIZER",
  GET_ORGANIZERS: "GET_ORGANIZERS",
  GET_ORGANIZERS_SUCCESS: "GET_ORGANIZERS_SUCCESS",
  GET_ORGANIZERS_FAILURE: "GET_ORGANIZERS_FAILURE",
  GET_ORGANIZER: "GET_ORGANIZER",
  GET_ORGANIZER_SUCCESS: "GET_ORGANIZER_SUCCESS",
  GET_ORGANIZER_FAILURE: "GET_ORGANIZER_FAILURE",
  GET_ALLERGIES: "GET_ALLERGIES",
  GET_ALLERGIES_SUCCESS: "GET_ALLERGIES_SUCCESS",
  GET_ALLERGIES_FAILURE: "GET_ALLERGIES_FAILURE",
  DELETE_ORGANIZER_DATA: "DELETE_ORGANIZER_DATA",
  CURRENT_ORGANIZER_DATA: "CURRENT_ORGANIZER_DATA",
  CURRENT_MENU: "CURRENT_MENU",
  ORGANIZER_MODAL_VISIBLE: "ORGANIZER_MODAL_VISIBLE",
  OPEN_ORGANIZER_CHANGE_PASSWORD:"OPEN_ORGANIZER_CHANGE_PASSWORD",
  CLOSE_ORGANIZER_CHANGE_PASSWORD:"CLOSE_ORGANIZER_CHANGE_PASSWORD",
  UPDATE_ORGANIZER_STATUS: "UPDATE_ORGANIZER_STATUS",
  UPDATE_ORGANIZER_STATUS_SUCCESS: "UPDATE_ORGANIZER_STATUS_SUCCESS",
  UPDATE_ORGANIZER_STATUS_FAILURE: "UPDATE_ORGANIZER_STATUS_FAILURE",
  ASSIGN_ORGANIZATION: "ASSIGN_ORGANIZATION",
  ASSIGN_ORGANIZATION_SUCCESS: "ASSIGN_ORGANIZATION_SUCCESS",
  ASSIGN_ORGANIZATION_FAILURE: "ASSIGN_ORGANIZATION_FAILURE",
  GET_ACCESS_LEVEL: "GET_ACCESS_LEVEL",
  GET_ACCESS_LEVEL_SUCCESS: "GET_ACCESS_LEVEL_SUCCESS",
  GET_ACCESS_LEVEL_FAILURE: "GET_ACCESS_LEVEL_FAILURE",

  // MY VARIABLES
  DELETE_ORGANIZER_REQUEST: "DELETE_ORGANIZER_REQUEST",
  DELETE_ORGANIZER_SUCCESS: "DELETE_ORGANIZER_SUCCESS",
  DELETE_ORGANIZER_FAILURE: "DELETE_ORGANIZER_FAILURE",
  UPDATE_UI_DELETION: "UPDATE_UI_DELETION",


  // Change Organizer password
  CHANGE_ORGANIZER_PASSWORD: "CHANGE_ORGANIZER_PASSWORD",
  CHANGE_ORGANIZER_PASSWORD_SUCCESS: "CHANGE_ORGANIZER_PASSWORD_SUCCESS",
  CHANGE_ORGANIZER_PASSWORD_FAILURE: "CHANGE_ORGANIZER_PASSWORD_FAILURE",


  //Block Email
  BLOCK_ORGANIZER_EMAIL : "BLOCK_ORGANIZER_EMAIL",
  BLOCK_ORGANIZER_EMAIL_SUCCESS: " BLOCK_ORGANIZER_EMAIL_SUCCESS",
  BLOCK_ORGANIZER_EMAIL_FAILURE: " BLOCK_ORGANIZER_EMAIL_FAILURE",

  modalVisible: (payload = false) => ({
    type: actions.MODAL_VISIBLE,
    payload,
  }),

  getOrganizersRequest: (params) => ({
    type: actions.GET_ORGANIZERS,
    params,
  }),

  getOrganizersSuccess: (payload) => ({
    type: actions.GET_ORGANIZERS_SUCCESS,
    payload,
  }),

  getOrganizersFailure: () => ({
    type: actions.GET_ORGANIZERS_FAILURE,
  }),

  getOrganizerRequest: (payload, id) => ({
    type: actions.GET_ORGANIZER,
    payload,
    id,
  }),

  getOrganizerSuccess: (payload) => ({
    type: actions.GET_ORGANIZER_SUCCESS,
    payload,
  }),

  getOrganizerFailure: () => ({
    type: actions.GET_ORGANIZER_FAILURE,
  }),

  updateOrganizerRequest: (payload, params) => ({
    type: actions.UPDATE_ORGANIZER,
    payload,
    params,
  }),

  updateOrganizerSuccess: (payload) => ({
    type: actions.UPDATE_ORGANIZER_SUCCESS,
    payload,
  }),

  updateOrganizerFailure: () => ({
    type: actions.UPDATE_ORGANIZER_FAILURE,
  }),

  addOrganizerRequest: (payload) => ({
    type: actions.ADD_ORGANIZER,
    payload,
  }),

  addOrganizerSuccess: (payload) => ({
    type: actions.ADD_ORGANIZER_DATA_SUCCESS,
    payload,
  }),

  addOrganizerFailure: () => ({
    type: actions.ADD_ORGANIZER_DATA_FAILURE,
  }),

  getAllergiesRequest: (payload) => ({
    type: actions.GET_ALLERGIES,
    payload,
  }),

  getAllergiesSuccess: (payload) => ({
    type: actions.GET_ALLERGIES_SUCCESS,
    payload,
  }),

  getAllergiesFailure: () => ({
    type: actions.GET_ALLERGIES_FAILURE,
  }),

  updateOrganizerStatusRequest: (payload, id) => ({
    type: actions.UPDATE_ORGANIZER_STATUS,
    payload,
    id,
  }),

  updateOrganizerStatusSuccess: (payload) => ({
    type: actions.UPDATE_ORGANIZER_STATUS_SUCCESS,
    payload,
  }),

  updateOrganizerStatusFailure: () => ({
    type: actions.UPDATE_ORGANIZER_STATUS_FAILURE,
  }),

  assignOrganization: (payload) => ({
    type: actions.ASSIGN_ORGANIZATION,
    payload,
  }),

  assignOrganizationSuccess: (payload) => ({
    type: actions.ASSIGN_ORGANIZATION_SUCCESS,
    payload,
  }),

  assignOrganizationFailure: () => ({
    type: actions.ASSIGN_ORGANIZATION_FAILURE,
  }),

  getAccessLevelRequest: (payload) => ({
    type: actions.GET_ACCESS_LEVEL,
    payload,
  }),

  getAccessLevelSuccess: (payload) => ({
    type: actions.GET_ACCESS_LEVEL_SUCCESS,
    payload,
  }),

  getAccessLevelFailure: () => ({
    type: actions.GET_ACCESS_LEVEL_FAILURE,
  }),


  //my code is being worked on

  deleteOrganizerSuccess: (payload) => ({
    type: actions.DELETE_ORGANIZER_SUCCESS,
    payload,
  }),

  deleteOrganizerRequest: (payload) => ({
    type: actions.DELETE_ORGANIZER_REQUEST,
  }),

  deleteOrganizerFailure: (payload) => ({
    type: actions.DELETE_ORGANIZER_FAILURE,
    payload,
  }),

  updateUiDeletion: (payload) => ({
    type: actions.UPDATE_UI_DELETION,
    payload,
  }),

  blockOrganizerEmailRequest: (payload) => ({
    type: actions.BLOCK_ORGANIZER_EMAIL,
    payload,
  }),

  blockOrganizerEmailSuccess: (payload) => ({
    type: actions.GET_ALLERGIES_SUCCESS,
    payload,
  }),

  blockOrganizerEmailFailure: () => ({
    type: actions.GET_ALLERGIES_FAILURE,
  }),

  changeOrganizerPasswordRequest: (payload) => ({
    type: actions.CHANGE_ORGANIZER_PASSWORD,
    payload
  }),

  changeOrganizerPasswordSuccess: (payload) => ({
    type: actions.CHANGE_ORGANIZER_PASSWORD_SUCCESS,
    payload
  }),

  changeOrganizerPasswordFailure: () => ({
    type: actions.CHANGE_ORGANIZER_PASSWORD_FAILURE,
  })

};

export default actions;
