import { useHistory } from 'react-router'
import { AppstoreOutlined, SettingOutlined } from '@ant-design/icons'
import { Layout, Menu, Space, Spin } from 'antd'
import 'antd/dist/antd.css'
import Calendar from '../../assets/Images/Calendar.svg'
import AnalyticsIcon from '../../assets/Images/AnalyticsIcon.svg'
import Newspaper from '../../assets/Images/Newspaper.svg'
import Location from '../../assets/Images/MapPinLine.svg'
import Inventory from '../../assets/Images/Inventory.svg'
import buildings_svg from '../../assets/Images/Buildings.svg'
import userPlus_svg from '../../assets/Images/UserPlus.svg'
import medal_svg from '../../assets/Images/Medal.svg'
import user_svg from '../../assets/Images/3 User.svg'
import sessionIcon_svg from '../../assets/Images/SessionIcon.svg'
import Jobs_svg from '../../assets/Images/jobs.svg'
import work_svg from '../../assets/Images/Work.svg'
import dashboardIcon from '../../assets/Images/dashboardIcon.svg'
import { useSelector } from 'react-redux'
import Loader from 'helper/Loader'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { getMe } from '../../api/Organizations'
import { string } from 'yup'
import activityIcon from 'assets/Images/activityIcon.svg'
const { Sider } = Layout

const DefaultSidebarV2 = () => {
  // const {
  //   isLoading,
  //   isFetching,
  //   data: data,
  //   refetch,
  // } = useQuery(['getMe'], () => getMe(), { enabled: false })
  // const history = useHistory()
  // useEffect(() => {
  //   refetch()
  // }, [refetch])
  let data: { role?: string } | undefined

  const [count, setCount] = useState(0)
  const {
    isLoading,

    data: getdata,
    refetch,
  } = useQuery(['getMe'], () => getMe())
  const history = useHistory()
  data = getdata
  // useEffect(() => {
  //   if (count === 0) {
  //     refetch()
  //     setCount(1)
  //   }
  // }, [count, refetch])
  // console.log(count, 'count')
  return (
    <Sider className="side-bar" width={'278px'}>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '20rem',
          }}
        >
          <Loader />
        </div>
      ) : (
        <Menu mode="inline" style={{ height: '100%' }}>
          <Menu.Item
            key="dashboard"
            id="overview"
            onClick={() => {
              history.push('/dashboard')
            }}
            className={
              history.location.pathname === '/dashboard' ? 'activeMenu' : ''
            }
          >
            <Space size={5}>
              <img src={dashboardIcon} /> <h5 className="b3">Overview</h5>
            </Space>
          </Menu.Item>
          <Menu.Item
            key="analytics"
            id="analytics"
            onClick={() => {
              history.push('/analytics')
            }}
            className={
              history.location.pathname === '/analytics' ? 'activeMenu' : ''
            }
          >
            <Space size={5}>
              <img alt="alt" src={AnalyticsIcon} />{' '}
              <h5 className="b3">Analytics</h5>
            </Space>
          </Menu.Item>
          {data?.role === 'admin' && (
            <Menu.Item
              key="settings"
              id="settings"
              onClick={() => {
                history.push('/settings')
              }}
              className={
                history.location.pathname === '/settings' ? 'activeMenu' : ''
              }
            >
              <Space size={15}>
                <SettingOutlined />
                <h5 className="b3"> Settings</h5>
              </Space>
            </Menu.Item>
          )}

          <Menu.ItemGroup key="g1" title="PEOPLE">
            <Menu.Item
              key="organization"
              id="organization"
              onClick={() => {
                history.push({
                  pathname: '/organization',
                })
              }}
              className={
                history.location.pathname === '/organization'
                  ? 'activeMenu'
                  : ''
              }
            >
              <Space size={15}>
                <img src={buildings_svg} alt=""></img>
                <h5 className="b3">Organizations</h5>
              </Space>
            </Menu.Item>

            <Menu.Item
              key="organizer"
              id="organizer"
              onClick={() => {
                history.push({
                  pathname: '/organizer',
                })
              }}
              className={
                history.location.pathname === '/organizer' ? 'activeMenu' : ''
              }
            >
              <Space size={18}>
                <img src={userPlus_svg} alt=""></img>
                <h5 className="b3"> Organizer</h5>
              </Space>
            </Menu.Item>
            <Menu.Item
              key="mentors"
              id="mentors"
              onClick={() => {
                history.push('/mentors')
              }}
              className={
                history.location.pathname === '/mentors' ? 'activeMenu' : ''
              }
            >
              <Space size={14}>
                <img src={medal_svg} alt=""></img>
                <h5 className="b3"> Mentors</h5>
              </Space>
            </Menu.Item>
            <Menu.Item
              key="family"
              id="family"
              onClick={() => {
                history.push('/family')
              }}
              className={
                history.location.pathname === '/family' ? 'activeMenu' : ''
              }
            >
              <Space size={8}>
                <img src={user_svg} alt=""></img>{' '}
                <h5 className="b3"> Families</h5>
              </Space>
            </Menu.Item>
          </Menu.ItemGroup>

          <Menu.ItemGroup
            key="g2"
            title="EVENTS"
            style={{ paddingBottom: '20px' }}
          >
            <Menu.Item
              key="events"
              id="events"
              onClick={() => {
                history.push('/events')
              }}
              className={
                history.location.pathname === '/events' ? 'activeMenu' : ''
              }
            >
              <Space size={18}>
                <img src={Calendar} alt=""></img>
                <h5 className="b3"> Events</h5>
              </Space>
            </Menu.Item>
            {/* <Menu.Item
            key="calendar"
            id="calendar"
            onClick={() => {
              history.push("/calendar");
            }}
            className={
              history.location.pathname === "/calendar" ? "activeMenu" : ""
            }
          >
            <Space size={15}>
              <img src={Calendar} alt=""></img>
              <h5 className="b3">Calendar</h5>
            </Space>
          </Menu.Item> */}
            {data?.role === 'admin' && (
              <Menu.Item
                key="jobrequest"
                id="jobrequest"
                onClick={() => {
                  history.push('/jobrequest')
                }}
                className={
                  history.location.pathname === '/jobrequest'
                    ? 'activeMenu'
                    : ''
                }
              >
                <Space>
                  <img src={work_svg} alt=""></img>{' '}
                  <h5 className="b3">Request</h5>
                </Space>
              </Menu.Item>
            )}
            {data?.role === 'admin' && (
              <Menu.Item
                key="sessions"
                id="sessions"
                onClick={() => {
                  history.push('/sessions')
                }}
                className={
                  history.location.pathname === '/sessions' ? 'activeMenu' : ''
                }
              >
                <Space>
                  <img src={sessionIcon_svg} alt=""></img>{' '}
                  <h5 className="b3">Sessions</h5>
                </Space>
              </Menu.Item>
            )}
            {data?.role === 'admin' && (
              <Menu.Item
                key="jobs"
                id="jobs"
                onClick={() => {
                  history.push('/jobs')
                }}
                className={
                  history.location.pathname === '/jobs' ? 'activeMenu' : ''
                }
              >
                <Space>
                  <img src={Jobs_svg} alt=""></img> <h5 className="b3">Jobs</h5>
                </Space>
              </Menu.Item>
            )}

            <Menu.Item
              key="newsfeed"
              id="newsfeed"
              onClick={() => {
                history.push('/newsfeed')
              }}
              className={
                history.location.pathname === '/newsfeed' ? 'activeMenu' : ''
              }
            >
              <Space>
                <img alt="alt" src={Newspaper} /> <h5 className="b3">News</h5>
              </Space>
            </Menu.Item>
            {data?.role === 'admin' && (
              <Menu.Item
                key="Locations"
                id="locations"
                onClick={() => {
                  history.push('/location')
                }}
                className={
                  history.location.pathname === '/location' ? 'activeMenu' : ''
                }
              >
                <Space>
                  <img alt="alt" src={Location} />{' '}
                  <h5 className="b3">Locations</h5>
                </Space>
              </Menu.Item>
            )}
            {
              <Menu.Item
                key="Inventory"
                id="inventory"
                onClick={() => {
                  history.push('/inventory')
                }}
                className={
                  history.location.pathname === '/inventory' ? 'activeMenu' : ''
                }
              >
                <Space>
                  <img alt="Inventory" src={Inventory} />
                  <h5 className="b3">Inventory</h5>
                </Space>
              </Menu.Item>
            }
            {data?.role === 'admin' && (
              <Menu.Item
                key="Activity"
                id="activity"
                onClick={() => {
                  history.push('/activity')
                }}
                className={
                  history.location.pathname === '/activity' ? 'activeMenu' : ''
                }
              >
                <Space>
                  <img alt="alt" src={activityIcon} />{' '}
                  <h5 className="b3">Activity</h5>
                </Space>
              </Menu.Item>
            )}
          </Menu.ItemGroup>
        </Menu>
      )}
    </Sider>
  )
}

export default DefaultSidebarV2

// import { useHistory } from 'react-router'
// import { SettingOutlined } from '@ant-design/icons'
// import { Layout, Menu, Space } from 'antd'
// import 'antd/dist/antd.css'
// import Calendar from '../../assets/Images/Calendar.svg'
// import AnalyticsIcon from '../../assets/Images/AnalyticsIcon.svg'
// import Newspaper from '../../assets/Images/Newspaper.svg'
// import Location from '../../assets/Images/MapPinLine.svg'
// import buildings_svg from '../../assets/Images/Buildings.svg'
// import userPlus_svg from '../../assets/Images/UserPlus.svg'
// import medal_svg from '../../assets/Images/Medal.svg'
// import user_svg from '../../assets/Images/3 User.svg'
// import sessionIcon_svg from '../../assets/Images/SessionIcon.svg'
// import Jobs_svg from '../../assets/Images/jobs.svg'
// import work_svg from '../../assets/Images/Work.svg'
// import dashboardIcon from '../../assets/Images/dashboardIcon.svg'
// import { useQuery } from '@tanstack/react-query'
// import React, { useEffect } from 'react'
// import { getMe } from '../../api/Organizations'
// import Loader from 'helper/Loader'

// const { Sider } = Layout

// const DefaultSidebarV2 = () => {
//   const history = useHistory()

//   const { isLoading, isFetching, data, refetch } = useQuery(['getMe'], getMe, {
//     enabled: false,
//     onSuccess: data => {
//       console.log('Fetched data:', data)
//     },
//     onError: error => {
//       console.error('Error fetching data:', error)
//     },
//   })

//   useEffect(() => {
//     refetch()
//   }, [refetch])

//   useEffect(() => {
//     if (isLoading || isFetching) {
//       console.log('Loading data...')
//     } else {
//       console.log('Data loaded:', data)
//     }
//   }, [isLoading, isFetching, data])

//   return (
//     <Sider className="side-bar" width={'278px'}>
//       {isLoading || isFetching ? (
//         <div
//           style={{
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             height: '20rem',
//           }}
//         >
//           <Loader />
//         </div>
//       ) : (
//         <Menu mode="inline" style={{ height: '100%' }}>
//           <Menu.Item
//             key="dashboard"
//             id="overview"
//             onClick={() => {
//               history.push('/dashboard')
//             }}
//             className={
//               history.location.pathname === '/dashboard' ? 'activeMenu' : ''
//             }
//           >
//             <Space size={5}>
//               <img src={dashboardIcon} alt="Dashboard Icon" />{' '}
//               <h5 className="b3">Overview</h5>
//             </Space>
//           </Menu.Item>
//           <Menu.Item
//             key="analytics"
//             id="analytics"
//             onClick={() => {
//               history.push('/analytics')
//             }}
//             className={
//               history.location.pathname === '/analytics' ? 'activeMenu' : ''
//             }
//           >
//             <Space size={5}>
//               <img alt="Analytics Icon" src={AnalyticsIcon} />{' '}
//               <h5 className="b3">Analytics</h5>
//             </Space>
//           </Menu.Item>
//           <Menu.Item
//             key="settings"
//             id="settings"
//             onClick={() => {
//               history.push('/settings')
//             }}
//             className={
//               history.location.pathname === '/settings' ? 'activeMenu' : ''
//             }
//           >
//             <Space size={15}>
//               <SettingOutlined />
//               <h5 className="b3"> Settings</h5>
//             </Space>
//           </Menu.Item>
//           <Menu.ItemGroup key="g1" title="PEOPLE">
//             {data?.role === 'admin' && (
//               <Menu.Item
//                 key="organization"
//                 id="organization"
//                 onClick={() => {
//                   history.push({
//                     pathname: '/organization',
//                   })
//                 }}
//                 className={
//                   history.location.pathname === '/organization'
//                     ? 'activeMenu'
//                     : ''
//                 }
//               >
//                 <Space size={15}>
//                   <img src={buildings_svg} alt="Organizations Icon"></img>
//                   <h5 className="b3">Organizations</h5>
//                 </Space>
//               </Menu.Item>
//             )}

//             <Menu.Item
//               key="organizer"
//               id="organizer"
//               onClick={() => {
//                 history.push({
//                   pathname: '/organizer',
//                 })
//               }}
//               className={
//                 history.location.pathname === '/organizer' ? 'activeMenu' : ''
//               }
//             >
//               <Space size={18}>
//                 <img src={userPlus_svg} alt="Organizer Icon"></img>
//                 <h5 className="b3"> Organizer</h5>
//               </Space>
//             </Menu.Item>
//             <Menu.Item
//               key="mentors"
//               id="mentors"
//               onClick={() => {
//                 history.push('/mentors')
//               }}
//               className={
//                 history.location.pathname === '/mentors' ? 'activeMenu' : ''
//               }
//             >
//               <Space size={14}>
//                 <img src={medal_svg} alt="Mentors Icon"></img>
//                 <h5 className="b3"> Mentors</h5>
//               </Space>
//             </Menu.Item>
//             <Menu.Item
//               key="family"
//               id="family"
//               onClick={() => {
//                 history.push('/family')
//               }}
//               className={
//                 history.location.pathname === '/family' ? 'activeMenu' : ''
//               }
//             >
//               <Space size={8}>
//                 <img src={user_svg} alt="Families Icon"></img>{' '}
//                 <h5 className="b3"> Families</h5>
//               </Space>
//             </Menu.Item>
//           </Menu.ItemGroup>

//           <Menu.ItemGroup
//             key="g2"
//             title="EVENTS"
//             style={{ paddingBottom: '20px' }}
//           >
//             <Menu.Item
//               key="events"
//               id="events"
//               onClick={() => {
//                 history.push('/events')
//               }}
//               className={
//                 history.location.pathname === '/events' ? 'activeMenu' : ''
//               }
//             >
//               <Space size={18}>
//                 <img src={Calendar} alt="Events Icon"></img>
//                 <h5 className="b3"> Events</h5>
//               </Space>
//             </Menu.Item>
//             <Menu.Item
//               key="jobrequest"
//               id="jobrequest"
//               onClick={() => {
//                 history.push('/jobrequest')
//               }}
//               className={
//                 history.location.pathname === '/jobrequest' ? 'activeMenu' : ''
//               }
//             >
//               <Space>
//                 <img src={work_svg} alt="Job Request Icon"></img>{' '}
//                 <h5 className="b3">Request</h5>
//               </Space>
//             </Menu.Item>
//             <Menu.Item
//               key="sessions"
//               id="sessions"
//               onClick={() => {
//                 history.push('/sessions')
//               }}
//               className={
//                 history.location.pathname === '/sessions' ? 'activeMenu' : ''
//               }
//             >
//               <Space>
//                 <img src={sessionIcon_svg} alt="Sessions Icon"></img>{' '}
//                 <h5 className="b3">Sessions</h5>
//               </Space>
//             </Menu.Item>
//             <Menu.Item
//               key="jobs"
//               id="jobs"
//               onClick={() => {
//                 history.push('/jobs')
//               }}
//               className={
//                 history.location.pathname === '/jobs' ? 'activeMenu' : ''
//               }
//             >
//               <Space>
//                 <img src={Jobs_svg} alt="Jobs Icon"></img>{' '}
//                 <h5 className="b3">Jobs</h5>
//               </Space>
//             </Menu.Item>
//             <Menu.Item
//               key="newsfeed"
//               id="newsfeed"
//               onClick={() => {
//                 history.push('/newsfeed')
//               }}
//               className={
//                 history.location.pathname === '/newsfeed' ? 'activeMenu' : ''
//               }
//             >
//               <Space>
//                 <img alt="Newsfeed Icon" src={Newspaper} />{' '}
//                 <h5 className="b3">News</h5>
//               </Space>
//             </Menu.Item>
//             <Menu.Item
//               key="Locations"
//               id="locations"
//               onClick={() => {
//                 history.push('/location')
//               }}
//               className={
//                 history.location.pathname === '/location' ? 'activeMenu' : ''
//               }
//             >
//               <Space>
//                 <img alt="Locations Icon" src={Location} />{' '}
//                 <h5 className="b3">Locations</h5>
//               </Space>
//             </Menu.Item>
//           </Menu.ItemGroup>
//         </Menu>
//       )}
//     </Sider>
//   )
// }

// export default DefaultSidebarV2
