import React from 'react'
import { OrgDocumentResp } from 'types/eventStep5'
import pdfIcon from 'assets/Images/pdf.svg'
import { PencilLine } from '@phosphor-icons/react'
import { Switch } from 'antd'

interface SelectDocContainerProps {
  data: OrgDocumentResp
  eventSelectedDocs: string[]
  setEventSelectedDocs: React.Dispatch<React.SetStateAction<string[]>>
}

const SelectDocContainer: React.FC<SelectDocContainerProps> = ({
  data,
  eventSelectedDocs,
  setEventSelectedDocs,
}) => {
  const hanldeSelect = (id: string) => {
    if (eventSelectedDocs.includes(id)) {
      const updatedArr = eventSelectedDocs.filter(item => item !== id)
      setEventSelectedDocs(updatedArr)
    } else {
      setEventSelectedDocs([...eventSelectedDocs, id])
    }
  }

  return (
    <div className="py-15px border-btm">
      <div className="flex justify-between align-center">
        <div className="flex gap-16px align-center">
          <img src={pdfIcon} alt="pdf-icon" width={40} height={40} />
          <h3>{data.name}</h3>
        </div>
        <div className="flex gap-16px align-center">
          {!data.isDefault && <PencilLine size={20} color="#000" />}
          <Switch
            checked={eventSelectedDocs.includes(data._id)}
            onChange={() => hanldeSelect(data._id)}
          />
        </div>
      </div>
      <p className="mt-10px">{data.description}</p>
    </div>
  )
}

export default SelectDocContainer
