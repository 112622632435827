import { useQuery } from '@tanstack/react-query'
import { getEventAttendee } from 'api/Events'
import Spinner from 'components/Spinner'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { RequestParms } from 'types/globalTypes'
import AttendanceHeader from './Header'
import AttendieTable from './Table'
const Attendance = () => {
  const { id }: any = useParams()

  const [params, setParams] = useState<RequestParms>({
    page: 1,
    limit: 10,
  })

  const { data, refetch, isLoading } = useQuery(
    ['getEventCategories', params],
    () => getEventAttendee(id, params),
    { enabled: false },
  )

  useEffect(() => {
    refetch()
  }, [params])
  return (
    <div className="bg-grey container padding-top-10">
      <div className="profile_container">
        <AttendanceHeader fullData={data?.records} />
        {isLoading ? (
          <div className="h-[50vh] flex-center justify-center">
            <Spinner />
          </div>
        ) : (
          <AttendieTable data={data} params={params} setParams={setParams} />
        )}
      </div>
    </div>
  )
}
export default Attendance
