import React, { useState, useEffect } from 'react'
import { MentorRequestResp } from '../types'
import GetAvatar from 'components/global/GetAvatar'
import {
  capitalizeFirstLetter,
  convertToLocal,
  getUserName,
} from 'helper/utility'
import { Link, useHistory } from 'react-router-dom'
import { CirclesThreePlus, DotsThree, Trash } from '@phosphor-icons/react'
import oneTime from 'assets/Images/one-time.svg'
import OneTimeRequest from './OneTimeRequest'
import ReccuringRequest from './RecurringRequest'
import iconFlexible from 'assets/Images/flxible-icon.svg'
import RejectOtherRequestModal from '../Modals/RejectOtherModal'
import { Dropdown } from 'antd'
import RejectRequestModal from '../Modals/MentorRejectModal'
import { useMutation } from '@tanstack/react-query'
import { rejectMentorRequest } from 'api/JobRequests'
import { useDispatch } from 'react-redux'
import actions from 'Redux/JobRequest/action'
import { result } from 'lodash'
interface CardProps {
  request: MentorRequestResp
}
const RequestCard: React.FC<CardProps> = ({ request }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [model, setModel] = useState<boolean>(false)
  const [requestId, setRequestId] = useState<any>()

  const handlePopup = (id: string) => {
    setModel(true)
    setRequestId(id)
  }
  const [isOtherReqeustModal, setIsOtherRequestModal] = useState<boolean>()

  const items = [
    {
      key: '1',
      label: (
        <div
          className="flex align-center px-10px py-5px"
          onClick={() => handlePopup(request?._id)}
        >
          <Trash size={24} color="#FF6961" />
          <span className="ml-16px mr-40px w-500 text-14 danger-txt">
            Reject Request
          </span>
        </div>
      ),
    },
  ]

  const hanldeRequestDecline = (data: MentorRequestResp) => {
    setRequestId(data?._id)
    setIsOtherRequestModal(true)
  }

  const approveRequestMutation = useMutation({
    mutationFn: async (payload: any) => {
      const { requestId, status } = payload
      rejectMentorRequest(requestId, { status: status })
    },
    onSuccess: () => {
      dispatch({
        type: actions.SET_OTHER_REQUEST_APPROVE,
      })
      dispatch({
        type: actions.SET_MENTOR_REQUEST_REJECT,
      })
    },
  })

  const handleApprove = (data: MentorRequestResp) => {
    approveRequestMutation.mutate({ requestId: data?._id, status: 'active' })
  }

  return (
    <div className="border-1px px-20px py-10px pointer">
      <div className="flex-center justify-between mb-10px">
        <div
          className="flex-center"
          onClick={() => {
            history.push(`/RequestDetails/${request._id}`)
          }}
        >
          {request?.guardianRequester || request?.organization ? (
            <GetAvatar
              person={request?.guardianRequester || request?.organization}
              size={48}
            />
          ) : (
            <></>
          )}
          <div className="ml-20px">
            <h2 className="text-16 w-600">
              {getUserName(request?.guardianRequester || request?.organization)}
            </h2>
            <h4
              className="text-12 w-400  grey-txt"
              style={{ marginBottom: '-10px' }}
            >
              {capitalizeFirstLetter(request.createdBy) +
                ' . ' +
                convertToLocal(request.createdAt, '', true)}
            </h4>
          </div>
        </div>

        {request?.status === 'draft' ? (
          <div className="flex gap-20px">
            <button
              className="danger-txt text-16 w-600 btn-borderless"
              onClick={() => hanldeRequestDecline(request)}
            >
              Decline
            </button>
            <button className="prim-btn" onClick={() => handleApprove(request)}>
              Approve
            </button>
          </div>
        ) : (
          <div className="flex-center">
            {request.mentorProposed > 0 && (
              <h2 className="text-16 mr-20px prim-txt">
                Proposed( {request.mentorProposed} )
              </h2>
            )}
            {request.hiredMentorsCount > 0 && (
              <h2 className="text-16 mr-20px prim-txt">
                Hired( {request.hiredMentorsCount} )
              </h2>
            )}
            {request.rejectedMentorsCount > 0 && (
              <h2
                className="text-16 mr-20px"
                style={{
                  color: '#93959880',
                }}
              >
                Rejected( {request.rejectedMentorsCount} )
              </h2>
            )}
            <h2
              className="text-16 mr-20px"
              style={{
                color: `${
                  request.interestedMentorsCount > 0 ? '#2BA7CA' : '#93959880'
                }`,
              }}
            >
              Applicants( {request.interestedMentorsCount} )
            </h2>
            <Link
              to={{
                pathname: '/propose-mentor',
                state: { id: request._id },
              }}
            >
              <button
                className="bordered-prim-btn w-500 text-14 mr-20px"
                style={{ paddingRight: '24px', paddingLeft: '24px' }}
              >
                Propose Mentor
              </button>
            </Link>
            <Link
              to={{
                pathname: `/RequestDetails/${request._id}`,
              }}
            >
              <button
                className="prim-btn w-500 text-14 mr-20px"
                style={{ paddingRight: '24px', paddingLeft: '24px' }}
              >
                View details
              </button>
            </Link>
            <Dropdown
              menu={{
                items,
              }}
            >
              <DotsThree size={32} />
            </Dropdown>
          </div>
        )}

        {/* <div className="flex-center">
          {request.mentorProposed > 0 && (
            <h2 className="text-16 mr-20px prim-txt">
              Proposed( {request.mentorProposed} )
            </h2>
          )}
          {request.hiredMentorsCount > 0 && (
            <h2 className="text-16 mr-20px prim-txt">
              Hired( {request.hiredMentorsCount} )
            </h2>
          )}
          {request.rejectedMentorsCount > 0 && (
            <h2
              className="text-16 mr-20px"
              style={{
                color: '#93959880',
              }}
            >
              Rejected( {request.rejectedMentorsCount} )
            </h2>
          )}
          <h2
            className="text-16 mr-20px"
            style={{
              color: `${
                request.interestedMentorsCount > 0 ? '#2BA7CA' : '#93959880'
              }`,
            }}
          >
            Applicants( {request.interestedMentorsCount} )
          </h2>
          <Link
            to={{
              pathname: '/propose-mentor',
              state: { id: request._id },
            }}
          >
            <button
              className="bordered-prim-btn w-500 text-14 mr-20px"
              style={{ paddingRight: '24px', paddingLeft: '24px' }}
            >
              Propose Mentor
            </button>
          </Link>
          <Link
            to={{
              pathname: `/RequestDetails/${request._id}`,
            }}
          >
            <button
              className="prim-btn w-500 text-14 mr-20px"
              style={{ paddingRight: '24px', paddingLeft: '24px' }}
            >
              View details
            </button>
          </Link>
          <Dropdown
            menu={{
              items,
            }}
          >
            <DotsThree size={32} />
          </Dropdown>
        </div> */}
      </div>
      <div className="px-10px border-btm w-full"></div>
      <div className="flex-center justify-between py-20px">
        <div className="w-full">
          <div className="flex-center">
            {request.category?.icon ? (
              <img
                src={request.category.icon}
                alt={request.category.name}
                className="w-30px"
              />
            ) : (
              <CirclesThreePlus size={30} />
            )}
            <h4 className="ml-10px text-16 w-600">{request.title}</h4>
          </div>
        </div>
        <div className="flex gap-30px mr-40px w-full justify-end">
          {!(
            request.type === 'organization-event-request' ||
            request.type === 'organization-request'
          ) && (
            <div className="mr-40px">
              <h2 className="text-14 w-500">Budget</h2>
              <h4 className="text-14 w-400 mt-5px">
                ${request.category?.price} per hour
              </h4>
            </div>
          )}

          <div style={{ width: 'auto', maxWidth: '70%' }}>
            {request.timesPerWeek === 'one-time' ? (
              <OneTimeRequest request={request} />
            ) : request.timesPerWeek === 'recurring' ? (
              <ReccuringRequest request={request} />
            ) : request.timesPerWeek === 'flexible' ? (
              <div>
                <div className="flex-center ">
                  <img src={iconFlexible} alt="flexi" />
                  <h2 className="text-14 ml-10px w-600">
                    {convertToLocal(
                      request.flexibleSessionSchedule?.startDate,
                      'MMM DD, YYYY',
                    ) + ' '}
                    -
                    {'  ' +
                      convertToLocal(
                        request.flexibleSessionSchedule?.endDate,
                        'MMM DD, YYYY',
                      )}
                  </h2>
                </div>
                <p className="text-12 mt-5px">
                  {request.flexibleSessionSchedule.restrictions.length > 270
                    ? request.flexibleSessionSchedule.restrictions.substring(
                        0,
                        270,
                      ) + '....'
                    : request.flexibleSessionSchedule.restrictions}
                </p>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      {request?.status === 'archived' && (
        <p>
          This request has been{' '}
          {request.status === 'archived' ? 'Deleted' : null} due to{' '}
          {request.rejectedReason}
        </p>
      )}

      {request?.status === 'rejected' && (
        <p>This request has been rejected due to {request.rejectedReason}</p>
      )}

      <div>
        <h2 className="text-14 w-500">Description</h2>
        <h4 className="text-12 w-400 mt-8px w-70p">
          {request.extraDetails ||
            (request?.additionalNotes?.trim() !== '' &&
              request.additionalNotes) ||
            'No description is added.'}
        </h4>
      </div>

      {isOtherReqeustModal && (
        <RejectOtherRequestModal
          open={isOtherReqeustModal}
          setOpen={setIsOtherRequestModal}
          requestId={requestId}
        />
      )}

      {model && (
        <RejectRequestModal
          open={model}
          setOpen={setModel}
          requestId={requestId}
        />
      )}
    </div>
  )
}
export default RequestCard
