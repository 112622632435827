import React from 'react'
import { Modal, Form, Select, Row, Col, Button, Input, DatePicker } from 'antd'
import closeIcon from 'assets/Images/close.svg'
import { creationEventStore } from 'state/eventCreationStore'
import { useCreateDiscountCode } from 'api/EventCreation/CreationStep4'
import moment from 'moment'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { DiscountCreateProps } from 'types/eventStep4'
import { infoMessage } from 'api/global'

interface DiscountCodeProps {
  modalOpen: boolean
  modalClose: React.Dispatch<React.SetStateAction<boolean>>
}

const DiscountCode: React.FC<DiscountCodeProps> = ({
  modalOpen,
  modalClose,
}) => {
  const [form] = Form.useForm()
  const { _id, admissionTickets, additionalOptions } = creationEventStore(
    store => store.eventData,
  )
  const { mutate: createDiscount, isLoading: createDiscountLoading } =
    useCreateDiscountCode()
  const formSchema = {}

  //   const groupedOptions = [
  //     {
  //       label: <h3 className="text-14 w-600">Admission ticket</h3>,
  //       options: admissionTickets.map(ticket => ({
  //         // value: ticket._id,
  //         value: { ticketId: ticket._id, type: 'admission' },
  //         label: ticket.name,
  //       })),
  //     },
  //     {
  //       label: <h3 className="text-14 w-600">Additional ticket</h3>,
  //       options: additionalOptions.map(ticket => ({
  //         // value: ticket._id,
  //         value: { ticketId: ticket._id, type: 'additional' },
  //         label: ticket.name,
  //       })),
  //     },
  //   ]
  const groupedOptions = [
    {
      label: <h3 className="text-14 w-600">Admission ticket</h3>,
      options: admissionTickets.map(ticket => ({
        value: ticket._id,
        label: ticket.name,
      })),
    },
    {
      label: <h3 className="text-14 w-600">Additional ticket</h3>,
      options: additionalOptions.map(ticket => ({
        value: ticket._id,
        label: ticket.name,
      })),
    },
  ]

  const handleCancel = () => {
    modalClose(false)
  }

  const onFinish = (values: DiscountCreateProps) => {
    console.log(values)
    if (!values.code) {
      return infoMessage('Discount name is required')
    }
    if (!values.type) {
      return infoMessage('Please select discount type')
    }
    if (!values.discount) {
      return infoMessage('Please enter discount price')
    }
    if (!values.admissionTicketIds || values.admissionTicketIds.length === 0) {
      return infoMessage('Please select ticket')
    }

    const selectedTicketIds = values.admissionTicketIds
    const admissionTicketIds = selectedTicketIds.filter((ticketId: string) =>
      admissionTickets.some(ticket => ticket._id === ticketId),
    )
    const additionalTicketIds = selectedTicketIds.filter((ticketId: string) =>
      additionalOptions.some(ticket => ticket._id === ticketId),
    )
    const payload = {
      ...values,
      admissionTicketIds: admissionTicketIds,
      additionalOptionIds: additionalTicketIds,
      expiry: values.expiry ? moment.utc(values.expiry).format() : undefined,
    }

    createDiscount(
      { payload: payload, eventId: _id },
      {
        onSuccess: result => {
          modalClose(false)
        },
      },
    )
  }

  return (
    <div>
      <Modal
        open={modalOpen}
        onCancel={handleCancel}
        className="discount-modal"
        footer={false}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Add discount code</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="h-30rem mt-20px overflow-auto-y overflow-hidden-x scroller">
          <Form
            form={form}
            layout="vertical"
            initialValues={formSchema}
            onFinish={onFinish}
          >
            <Row gutter={10}>
              <Col>
                <label className="input-label required-input">
                  Discount name
                </label>
              </Col>
              <Col>
                <Form.Item className="form-control" name="code">
                  <Input
                    placeholder="Discount name"
                    className="form-control-field custom-input-field"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col>
                <label className="input-label required-input">Tickets</label>
              </Col>
              <Col>
                <Form.Item
                  className="pointer selector-input"
                  name="admissionTicketIds"
                >
                  <Select
                    className="select-input-field type-select-field"
                    placeholder="Select"
                    optionLabelProp="label"
                    mode="multiple"
                    options={groupedOptions}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div>
              <div
                className="flex align-center mx-auto"
                style={{ width: '98%' }}
              >
                <div className="flex align-center gap-20px w-50p">
                  <div className="relative w-70px">
                    <label className="input-label required-input">
                      Discount amount
                    </label>
                  </div>
                  <div className="w-30p">
                    <Form.Item name="discount">
                      <Input
                        type="number"
                        placeholder="0"
                        className="text-end br-12"
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="w-50p">
                  <Form.Item className="pointer selector-input" name="type">
                    <Select
                      className="select-input-field type-select-field"
                      placeholder="Select"
                      optionLabelProp="label"
                      options={[
                        {
                          value: 'percent',
                          label: '% (Percent)',
                        },
                        {
                          value: 'usd',
                          label: '$ (USD)',
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>

            <Row className="mt-20px">
              <Col className="d-flex align-items-center">
                <div
                  className="relative"
                  style={{ width: '100%', marginTop: '-14px' }}
                >
                  <span className="custom-field-label">Total limit</span>
                </div>
                <Form.Item name="totalLimit" style={{ width: '20%' }}>
                  <Input
                    type="number"
                    placeholder="0"
                    className="text-end br-12"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col className="d-flex align-items-center">
                <div
                  className="relative"
                  style={{ width: '100%', marginTop: '-14px' }}
                >
                  <span className="custom-field-label">Limit per Family</span>
                </div>
                <Form.Item name="limitPerFamily" style={{ width: '20%' }}>
                  <Input
                    type="number"
                    placeholder="0"
                    className="text-end br-12"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <label htmlFor="" className="input-label">
                  Expiration date
                </label>
                <Form.Item name="expiry">
                  <DatePicker
                    className="w-100 form-control-field"
                    placeholder="Expiration date"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <label htmlFor="" className="custom-field-label">
                  Notes
                </label>
                <Form.Item name="note">
                  <Input.TextArea
                    className="note-field"
                    placeholder="Terms of usage of the ticket"
                    autoSize={{ minRows: 3, maxRows: 10 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end" className="mt-20px">
              <Button
                className="stepper-button"
                disabled={createDiscountLoading}
                htmlType="submit"
              >
                {createDiscountLoading ? (
                  <ButtonSpinner size={20} color="#000" />
                ) : (
                  'Save'
                )}
              </Button>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default DiscountCode
