const actions = {
  GET_RECENT_CHECK_IN: 'GET_RECENT_CHECK_IN',
  GET_EVENT_REQUEST: 'GET_EVENT_REQUEST',
  GET_EVENT_REQUEST_SUCCESS: 'GET_EVENT_REQUEST_SUCCESS',
  GET_EVENT_REQUEST_FAILURE: 'GET_EVENT_REQUEST_FAILURE',
  GET_MENTOR_EVENTS: 'GET_MENTOR_EVENTS',
  GET_MENTOR_EVENTS_SUCCESS: 'GET_MENTOR_EVENTS_SUCCESS',
  GET_MENTOR_EVENTS_FAILURE: 'GET_MENTOR_EVENTS_FAILURE',
  GET_EVENT_ORGANIZATION_REQUEST: 'GET_EVENT_ORGANIZATION_REQUEST',
  GET_EVENT_ORGANIZATION_REQUEST_SUCCESS:
    'GET_EVENT_ORGANIZATION_REQUEST_SUCCESS',
  GET_EVENT_ORGANIZATION_REQUEST_FAILURE:
    'GET_EVENT_ORGANIZATION_REQUEST_FAILURE',
  ADD_EVENTS_DATA: 'ADD_EVENTS_DATA',
  ADD_EVENTS_DATA_SUCCESS: 'ADD_EVENTS_DATA_SUCCESS',
  ADD_EVENTS_DATA_FAILURE: 'ADD_EVENTS_DATA_FAILURE',

  ADD_MENTOR_REQUEST_DATA_IN_EVENT: 'ADD_MENTOR_REQUEST_DATA_IN_EVENT',
  ADD_MENTOR_REQUEST_DATA_IN_EVENT_SUCCESS:
    'ADD_MENTOR_REQUEST_DATA_IN_EVENT_SUCCESS',
  ADD_MENTOR_REQUEST_DATA_IN_EVENT_FAILURE:
    'ADD_MENTOR_REQUEST_DATA_IN_EVENT_FAILURE',

  ADD_MENTOR_SHIFT_DATA_IN_EVENT: 'ADD_MENTOR_SHIFT_DATA_IN_EVENT',
  ADD_MENTOR_SHIFT_DATA_IN_EVENT_SUCCESS:
    'ADD_MENTOR_SHIFT_DATA_IN_EVENT_SUCCESS',
  ADD_MENTOR_SHIFT_DATA_IN_EVENT_FAILURE:
    'ADD_MENTOR_SHIFT_DATA_IN_EVENT_FAILURE',

  EDIT_EVENTS_DATA: 'EDIT_EVENTS_DATA',
  EDIT_EVENTS_DATA_SUCCESS: 'EDIT_EVENTS_DATA_SUCCESS',
  EDIT_EVENTS_DATA_FAILURE: 'EDIT_EVENTS_DATA_FAILURE',

  EDIT_MENTOR_REQUEST_DATA_IN_EVENT: 'EDIT_MENTOR_REQUEST_DATA_IN_EVENT',
  EDIT_MENTOR_REQUEST_DATA_IN_EVENT_SUCCESS:
    'EDIT_MENTOR_REQUEST_DATA_IN_EVENT_SUCCESS',
  EDIT_MENTOR_REQUEST_DATA_IN_EVENT_FAILURE:
    'EDIT_MENTOR_REQUEST_DATA_IN_EVENT_FAILURE',

  UPDATE_MENTOR_REQUEST_STATUS_IN_EVENT:
    'UPDATE_MENTOR_REQUEST_STATUS_IN_EVENT',
  UPDATE_MENTOR_REQUEST_STATUS_IN_EVENT_SUCCESS:
    'UPDATE_MENTOR_REQUEST_STATUS_IN_EVENT_SUCCESS',
  UPDATE_MENTOR_REQUEST_STATUS_IN_EVENT_FAILURE:
    'UPDATE_MENTOR_REQUEST_STATUS_IN_EVENT_FAILURE',

  UPDATE_EVENT_POST_STATUS: 'UPDATE_EVENT_POST_STATUS',
  UPDATE_EVENT_POST_STATUS_SUCCESS: 'UPDATE_EVENT_POST_STATUS_SUCCESS',
  UPDATE_EVENT_POST_STATUS_FAILURE: 'UPDATE_EVENT_POST_STATUS_FAILURE',

  EVENTS_MODAL_VISIBLE: 'EVENTS_MODAL_VISIBLE',
  CURRENT_MENTOR_DATA: 'CURRENT_MENTOR_DATA',
  DELTE_MENTOR_DATA: 'DELETE_MENTOR_DATA',
  GET_EVENT_CATEGORIES: 'GET_EVENT_CATEGORIES',
  GET_EVENT_CATEGORIES_SUCCESS: 'GET_EVENT_CATEGORIES_SUCCESS',
  GET_EVENT_CATEGORIES_FAILURE: 'GET_EVENT_CATEGORIES_FAILURE',

  GET_EVENT_ACTIVITY_CATEGORIES: 'GET_EVENT_ACTIVITY_CATEGORIES',
  GET_EVENT_ACTIVITY_CATEGORIES_SUCCESS:
    'GET_EVENT_ACTIVITY_CATEGORIES_SUCCESS',
  GET_EVENT_ACTIVITY_CATEGORIES_FAILURE:
    'GET_EVENT_ACTIVITY_CATEGORIES_FAILURE',

  GET_EVENT_ACTIVITY_GRADES: 'GET_EVENT_ACTIVITY_GRADES',
  GET_EVENT_ACTIVITY_GRADES_SUCCESS: 'GET_EVENT_ACTIVITY_GRADES_SUCCESS',
  GET_EVENT_ACTIVITY_GRADES_FAILURE: 'GET_EVENT_ACTIVITY_GRADES_FAILURE',

  CURRENT_EVENT_STEPPER_STEP: 'CURRENT_EVENT_STEPPER_STEP',
  ADD_EVENTS_DATA_STEP_TWO: 'ADD_EVENTS_DATA_STEP_TWO',
  ADD_EVENTS_DATA__STEP_TWO_SUCCESS: 'ADD_EVENTS_DATA__STEP_TWO_SUCCESS',
  ADD_EVENTS_DATA__STEP_TWO_FAILURE: 'ADD_EVENTS_DATA__STEP_TWO_FAILURE',
  ADD_EVENTS_DATA_STEP_THREE: 'ADD_EVENTS_DATA_STEP_THREE',
  ADD_EVENTS_DATA__STEP_THREE_SUCCESS: 'ADD_EVENTS_DATA__STEP_THREE_SUCCESS',
  ADD_EVENTS_DATA__STEP_THREE_FAILURE: 'ADD_EVENTS_DATA__STEP_THREE_FAILURE',
  ADD_EVENTS_DATA_STEP_FOUR_ADMISSION: 'ADD_EVENTS_DATA_STEP_FOUR_ADMISSION',
  ADD_EVENTS_DATA_STEP_FOUR_ADMISSION_SUCCESS:
    'ADD_EVENTS_DATA_STEP_FOUR_ADMISSION_SUCCESS',
  ADD_EVENTS_DATA_STEP_FOUR_ADMISSION_FAILURE:
    'ADD_EVENTS_DATA_STEP_FOUR_ADMISSION_FAILURE',
  ADD_EVENTS_DATA_STEP_FOUR_ADDITIONAL: 'ADD_EVENTS_DATA_STEP_FOUR_ADDITIONAL',
  ADD_EVENTS_DATA_STEP_FOUR_ADDITIONAL_SUCCESS:
    'ADD_EVENTS_DATA_STEP_FOUR_ADDITIONAL_SUCCESS',
  ADD_EVENTS_DATA_STEP_FOUR_ADDITIONAL_FAILURE:
    'ADD_EVENTS_DATA_STEP_FOUR_ADDITIONAL_FAILURE',
  ADD_EVENTS_DATA_STEP_FOUR_DROPIN: 'ADD_EVENTS_DATA_STEP_FOUR_DROPIN',
  ADD_EVENTS_DATA_STEP_FOUR_DROPIN_SUCCESS:
    'ADD_EVENTS_DATA_STEP_FOUR_DROPIN_SUCCESS',
  ADD_EVENTS_DATA_STEP_FOUR_DROPIN_FAILURE:
    'ADD_EVENTS_DATA_STEP_FOUR_DROPIN_FAILURE',
  ADD_EVENTS_DATA_STEP_FIVE: 'ADD_EVENTS_DATA_STEP_FIVE',
  ADD_EVENTS_DATA_STEP_FIVE_SUCCESS: 'ADD_EVENTS_DATA_STEP_FIVE_SUCCESS',
  ADD_EVENTS_DATA_STEP_FIVE_FAILURE: 'ADD_EVENTS_DATA_STEP_FIVE_FAILURE',
  SET_ADD_ITEM_BRING_DATA: 'SET_ADD_ITEM_BRING_DATA',
  SET_ADD_FAQ_DATA: 'SET_ADD_FAQ_DATA',
  SET_ADD_REQ_DOCUMENT_DATA: 'SET_ADD_REQ_DOCUMENT_DATA',
  ADD_EVENTS_PAYMENT_FREQUENCY_DATA: 'ADD_EVENTS_PAYMENT_FREQUENCY_DATA',
  ADD_EVENTS_PAYMENT_FREQUENCY_DATA_SUCCESS:
    'ADD_EVENTS_PAYMENT_FREQUENCY_DATA_SUCCESS',
  ADD_EVENTS_PAYMENT_FREQUENCY_DATA_FAILURE:
    'ADD_EVENTS_PAYMENT_FREQUENCY_DATA_FAILURE',
  GET_EVENT_DETAILS_REQUEST: 'GET_EVENT_DETAILS_REQUEST',
  GET_EVENT_DETAILS_REQUEST_SUCCESS: 'GET_EVENT_DETAILS_REQUEST_SUCCESS',
  GET_EVENT_DETAILS_REQUEST_FAILURE: 'GET_EVENT_DETAILS_REQUEST_FAILURE',

  GET_EVENT_DEFAULT_ACTIVITIES: 'GET_EVENT_DEFAULT_ACTIVITIES',
  GET_EVENT_DEFAULT_ACTIVITIES_SUCCESS: 'GET_EVENT_DEFAULT_ACTIVITIES_SUCCESS',
  GET_EVENT_DEFAULT_ACTIVITIES_FAILURE: 'GET_EVENT_DEFAULT_ACTIVITIES_FAILURE',

  DELETE_SCHEDULED_EVENT_ACTIVITY: 'DELETE_SCHEDULED_EVENT_ACTIVITY',
  DELETE_SCHEDULED_EVENT_ACTIVITY_SUCCESS:
    'DELETE_SCHEDULED_EVENT_ACTIVITY_SUCCESS',
  DELETE_SCHEDULED_EVENT_ACTIVITY_FAILURE:
    'DELETE_SCHEDULED_EVENT_ACTIVITY_FAILURE',

  DELETE_ADMISSION_TICKET: 'DELETE_ADMISSION_TICKET',
  DELETE_ADMISSION_TICKET_SUCCESS: 'DELETE_ADMISSION_TICKET_SUCCESS',
  DELETE_ADMISSION_TICKET_FAILURE: 'DELETE_ADMISSION_TICKET_FAILURE',

  DELETE_DROP_IN_TICKET: 'DELETE_DROP_IN_TICKET',
  DELETE_DROP_IN_TICKET_SUCCESS: 'DELETE_DROP_IN_TICKET_SUCCESS',
  DELETE_DROP_IN_TICKET_FAILURE: 'DELETE_DROP_IN_TICKET_FAILURE',

  DELETE_ADDITIONAL_OPTION_TICKET: 'DELETE_ADDITIONAL_OPTION_TICKET',
  DELETE_ADDITIONAL_OPTION_TICKET_SUCCESS:
    'DELETE_ADDITIONAL_OPTION_TICKET_SUCCESS',
  DELETE_ADDITIONAL_OPTION_TICKET_FAILURE:
    'DELETE_ADDITIONAL_OPTION_TICKET_FAILURE',

  DUPLICATE_ADMISSION_TICKET: 'DUPLICATE_ADMISSION_TICKET',
  DUPLICATE_ADMISSION_TICKET_SUCCESS: 'DUPLICATE_ADMISSION_TICKET_SUCCESS',
  DUPLICATE_ADMISSION_TICKET_FAILURE: 'DUPLICATE_ADMISSION_TICKET_FAILURE',

  DUPLICATE_ADDITIONAL_OPTION_TICKET: 'DUPLICATE_ADDITIONAL_OPTION_TICKET',
  DUPLICATE_ADDITIONAL_OPTION_TICKET_SUCCESS:
    'DUPLICATE_ADDITIONAL_OPTION_TICKET_SUCCESS',
  DUPLICATE_ADDITIONAL_OPTION_TICKET_FAILURE:
    'DUPLICATE_ADDITIONAL_OPTION_TICKET_FAILURE',

  DISABLE_ADMISSION_TICKET: 'DISABLE_ADMISSION_TICKET',
  DISABLE_ADMISSION_TICKET_SUCCESS: 'DISABLE_ADMISSION_TICKET_SUCCESS',
  DISABLE_ADMISSION_TICKET_FAILURE: 'DISABLE_ADMISSION_TICKET_FAILURE',

  CREATE_EVENT_SPECIFIC_ACTIVITY: 'CREATE_EVENT_SPECIFIC_ACTIVITY',
  CREATE_EVENT_SPECIFIC_ACTIVITY_SUCCESS:
    'CREATE_EVENT_SPECIFIC_ACTIVITY_SUCCESS',
  CREATE_EVENT_SPECIFIC_ACTIVITY_FAILURE:
    'CREATE_EVENT_SPECIFIC_ACTIVITY_FAILURE',

  EDIT_EVENT_SPECIFIC_ACTIVITY: 'EDIT_EVENT_SPECIFIC_ACTIVITY',
  EDIT_EVENT_SPECIFIC_ACTIVITY_SUCCESS: 'EDIT_EVENT_SPECIFIC_ACTIVITY_SUCCESS',
  EDIT_EVENT_SPECIFIC_ACTIVITY_FAILURE: 'EDIT_EVENT_SPECIFIC_ACTIVITY_FAILURE',

  ADD_ADMISSION_TICKET_EVENTS_STEP_FOUR:
    'ADD_ADMISSION_TICKET_EVENTS_STEP_FOUR',
  ADD_ADMISSION_TICKET_EVENTS_STEP_FOUR_SUCCESS:
    'ADD_ADMISSION_TICKET_EVENTS_STEP_FOUR_SUCCESS',
  ADD_ADMISSION_TICKET_EVENTS_STEP_FOUR_FAILURE:
    'ADD_ADMISSION_TICKET_EVENTS_STEP_FOUR_FAILURE',

  GET_ORGANIZATION_LOCATIONS: 'GET_ORGANIZATION_LOCATIONS',
  GET_ORGANIZATION_LOCATIONS_SUCCESS: 'GET_ORGANIZATION_LOCATIONS_SUCCESS',
  GET_ORGANIZATION_LOCATIONS_FAILURE: 'GET_ORGANIZATION_LOCATIONS_FAILURE',

  GET_APPROVED_LOCATIONS: 'GET_APPROVED_LOCATIONS',
  GET_APPROVED_LOCATIONS_SUCCESS: 'GET_APPROVED_LOCATIONS_SUCCESS',
  GET_APPROVED_LOCATIONS_FAILURE: 'GET_APPROVED_LOCATIONS_FAILURE',

  GET_EVENT_ORGANIZATION_FAQS: 'GET_EVENT_ORGANIZATION_FAQS',
  GET_EVENT_ORGANIZATION_FAQS_SUCCESS: 'GET_EVENT_ORGANIZATION_FAQS_SUCCESS',
  GET_EVENT_ORGANIZATION_FAQS_FAILURE: 'GET_EVENT_ORGANIZATION_FAQS_FAILURE',

  APPROVE_PARTICIPANTS: 'APPROVE_PARTICIPANTS',

  modalVisible: (payload = false) => ({
    type: actions.EVENTS_MODAL_VISIBLE,
    payload,
  }),

  getEventRequest: params => ({
    type: actions.GET_EVENT_REQUEST,
    params,
  }),

  getEventRequestSuccess: payload => ({
    type: actions.GET_EVENT_REQUEST_SUCCESS,
    payload,
  }),

  getEventRequestFailure: () => ({
    type: actions.GET_EVENT_REQUEST_FAILURE,
  }),

  getMentorEvents: params => ({
    type: actions.GET_MENTOR_EVENTS,
    params,
  }),

  getMentorEventsSuccess: payload => ({
    type: actions.GET_MENTOR_EVENTS_SUCCESS,
    payload,
  }),

  getMentorEventsFailure: () => ({
    type: actions.GET_MENTOR_EVENTS_FAILURE,
  }),

  getEventDetailRequest: payload => ({
    type: actions.GET_EVENT_DETAILS_REQUEST,
    payload,
  }),

  getEventDetailRequestSuccess: payload => ({
    type: actions.GET_EVENT_DETAILS_REQUEST_SUCCESS,
    payload,
  }),

  getEventDetailRequestFailure: () => ({
    type: actions.GET_EVENT_DETAILS_REQUEST_FAILURE,
  }),

  getEventOrganizationRequest: eventId => ({
    type: actions.GET_EVENT_ORGANIZATION_REQUEST,
    eventId,
  }),

  getEventOrganizationRequestSuccess: payload => ({
    type: actions.GET_EVENT_ORGANIZATION_REQUEST_SUCCESS,
    payload,
  }),

  getEventOrganizationRequestFailure: () => ({
    type: actions.GET_EVENT_ORGANIZATION_REQUEST_FAILURE,
  }),

  getEventCategoriesRequest: () => ({
    type: actions.GET_EVENT_CATEGORIES,
  }),

  getEventCategoriesSuccess: payload => ({
    type: actions.GET_EVENT_CATEGORIES_SUCCESS,
    payload,
  }),

  getEventCategoriesFailure: () => ({
    type: actions.GET_EVENT_CATEGORIES_FAILURE,
  }),

  getEventActivityCategoriesRequest: () => ({
    type: actions.GET_EVENT_ACTIVITY_CATEGORIES,
  }),

  getEventActivityCategoriesSuccess: payload => ({
    type: actions.GET_EVENT_ACTIVITY_CATEGORIES_SUCCESS,
    payload,
  }),

  getEventActivityCategoriesFailure: () => ({
    type: actions.GET_EVENT_ACTIVITY_CATEGORIES_FAILURE,
  }),

  getEventActivityGradesRequest: () => ({
    type: actions.GET_EVENT_ACTIVITY_GRADES,
  }),

  getEventActivityGradesSuccess: payload => ({
    type: actions.GET_EVENT_ACTIVITY_GRADES_SUCCESS,
    payload,
  }),

  getEventActivityGradesFailure: () => ({
    type: actions.GET_EVENT_ACTIVITY_GRADES_FAILURE,
  }),

  updateCurrentEventStepperStep: payload => ({
    type: actions.CURRENT_EVENT_STEPPER_STEP,
    payload,
  }),

  createEvent: payload => ({
    type: actions.ADD_EVENTS_DATA,
    payload,
  }),

  createEventSuccess: payload => ({
    type: actions.ADD_EVENTS_DATA_SUCCESS,
    payload,
  }),

  createEventFailure: () => ({
    type: actions.ADD_EVENTS_DATA_FAILURE,
  }),

  createMentorRequestInEvent: payload => ({
    type: actions.ADD_MENTOR_REQUEST_DATA_IN_EVENT,
    payload,
  }),

  createMentorRequestInEventSuccess: payload => ({
    type: actions.ADD_MENTOR_REQUEST_DATA_IN_EVENT_SUCCESS,
    payload,
  }),

  createMentorRequestInEventFailure: () => ({
    type: actions.ADD_MENTOR_REQUEST_DATA_IN_EVENT_FAILURE,
  }),

  editMentorRequestInEvent: payload => ({
    type: actions.EDIT_MENTOR_REQUEST_DATA_IN_EVENT,
    payload,
  }),

  editMentorRequestInEventSuccess: payload => ({
    type: actions.EDIT_MENTOR_REQUEST_DATA_IN_EVENT_SUCCESS,
    payload,
  }),

  editMentorRequestInEventFailure: () => ({
    type: actions.EDIT_MENTOR_REQUEST_DATA_IN_EVENT_FAILURE,
  }),

  updateMentorRequestStatusInEvent: payload => ({
    type: actions.UPDATE_MENTOR_REQUEST_STATUS_IN_EVENT,
    payload,
  }),

  updateMentorRequestStatusInEventSuccess: payload => ({
    type: actions.UPDATE_MENTOR_REQUEST_STATUS_IN_EVENT_SUCCESS,
    payload,
  }),

  updateMentorRequestStatusInEventFailure: () => ({
    type: actions.UPDATE_MENTOR_REQUEST_STATUS_IN_EVENT_FAILURE,
  }),

  updateEventPostStatus: payload => ({
    type: actions.UPDATE_EVENT_POST_STATUS,
    payload,
  }),

  updateEventPostStatusSuccess: payload => ({
    type: actions.UPDATE_EVENT_POST_STATUS_SUCCESS,
    payload,
  }),

  updateEventPostStatusFailure: () => ({
    type: actions.UPDATE_EVENT_POST_STATUS_FAILURE,
  }),

  createMentorShiftInEvent: payload => ({
    type: actions.ADD_MENTOR_SHIFT_DATA_IN_EVENT,
    payload,
  }),

  createMentorShiftInEventSuccess: payload => ({
    type: actions.ADD_MENTOR_SHIFT_DATA_IN_EVENT_SUCCESS,
    payload,
  }),

  createMentorShiftInEventFailure: () => ({
    type: actions.ADD_MENTOR_SHIFT_DATA_IN_EVENT_FAILURE,
  }),

  editEvent: payload => ({
    type: actions.EDIT_EVENTS_DATA,
    payload,
  }),

  editEventSuccess: payload => ({
    type: actions.EDIT_EVENTS_DATA_SUCCESS,
    payload,
  }),

  editEventFailure: () => ({
    type: actions.EDIT_EVENTS_DATA_FAILURE,
  }),

  createEventForStepTwo: payload => ({
    type: actions.ADD_EVENTS_DATA_STEP_TWO,
    payload,
  }),

  createEventForStepTwoSuccess: payload => ({
    type: actions.ADD_EVENTS_DATA__STEP_TWO_SUCCESS,
    payload,
  }),

  createEventForStepTwoFailure: () => ({
    type: actions.ADD_EVENTS_DATA__STEP_TWO_FAILURE,
  }),

  createEventSpecificActivity: payload => ({
    type: actions.CREATE_EVENT_SPECIFIC_ACTIVITY,
    payload,
  }),

  createEventSpecificActivitySuccess: payload => ({
    type: actions.CREATE_EVENT_SPECIFIC_ACTIVITY_SUCCESS,
    payload,
  }),

  createEventSpecificActivityFailure: () => ({
    type: actions.CREATE_EVENT_SPECIFIC_ACTIVITY_FAILURE,
  }),

  createEventForStepThree: payload => ({
    type: actions.ADD_EVENTS_DATA_STEP_THREE,
    payload,
  }),

  createEventForStepThreeSuccess: payload => ({
    type: actions.ADD_EVENTS_DATA__STEP_THREE_SUCCESS,
    payload,
  }),

  createEventForStepThreeFailure: () => ({
    type: actions.ADD_EVENTS_DATA__STEP_THREE_FAILURE,
  }),

  createEventForStepFourAdmission: payload => ({
    type: actions.ADD_EVENTS_DATA_STEP_FOUR_ADMISSION,
    payload,
  }),

  createEventForStepFourAdmissionSuccess: payload => ({
    type: actions.ADD_EVENTS_DATA_STEP_FOUR_ADMISSION_SUCCESS,
    payload,
  }),

  createEventForStepFourAdmissionFailure: () => ({
    type: actions.ADD_EVENTS_DATA_STEP_FOUR_ADMISSION_FAILURE,
  }),

  createEventForStepFourAdditional: payload => ({
    type: actions.ADD_EVENTS_DATA_STEP_FOUR_ADDITIONAL,
    payload,
  }),

  createEventForStepFourAdditionalSuccess: payload => ({
    type: actions.ADD_EVENTS_DATA_STEP_FOUR_ADDITIONAL_SUCCESS,
    payload,
  }),

  createEventForStepFourAdditionalFailure: () => ({
    type: actions.ADD_EVENTS_DATA_STEP_FOUR_ADDITIONAL_FAILURE,
  }),

  createEventForStepFourDropIn: payload => ({
    type: actions.ADD_EVENTS_DATA_STEP_FOUR_DROPIN,
    payload,
  }),

  createEventForStepFourDropInSuccess: payload => ({
    type: actions.ADD_EVENTS_DATA_STEP_FOUR_DROPIN_SUCCESS,
    payload,
  }),

  createEventForStepFourDropInFailure: () => ({
    type: actions.ADD_EVENTS_DATA_STEP_FOUR_DROPIN_FAILURE,
  }),

  createEventForStepFive: payload => ({
    type: actions.ADD_EVENTS_DATA_STEP_FIVE,
    payload,
  }),

  createEventForStepFiveSuccess: payload => ({
    type: actions.ADD_EVENTS_DATA_STEP_FIVE_SUCCESS,
    payload,
  }),

  createEventForStepFiveFailure: () => ({
    type: actions.ADD_EVENTS_DATA_STEP_FIVE_FAILURE,
  }),

  setAddItemBringData: payload => ({
    type: actions.SET_ADD_ITEM_BRING_DATA,
    payload,
  }),

  setAddFaqData: payload => ({
    type: actions.SET_ADD_FAQ_DATA,
    payload,
  }),

  setAddReqDocumentData: payload => ({
    type: actions.SET_ADD_REQ_DOCUMENT_DATA,
    payload,
  }),

  createEventPaymentFrequency: payload => ({
    type: actions.ADD_EVENTS_PAYMENT_FREQUENCY_DATA,
    payload,
  }),

  createEventPaymentFrequencySuccess: payload => ({
    type: actions.ADD_EVENTS_PAYMENT_FREQUENCY_DATA_SUCCESS,
    payload,
  }),

  createEventPaymentFrequencyFailure: () => ({
    type: actions.ADD_EVENTS_PAYMENT_FREQUENCY_DATA_FAILURE,
  }),

  getEventDefaultActivitiesRequest: params => ({
    type: actions.GET_EVENT_DEFAULT_ACTIVITIES,
    params,
  }),

  getEventDefaultActivitiesSuccess: payload => ({
    type: actions.GET_EVENT_DEFAULT_ACTIVITIES_SUCCESS,
    payload,
  }),

  getEventDefaultActivitiesFailure: () => ({
    type: actions.GET_EVENT_DEFAULT_ACTIVITIES_FAILURE,
  }),

  deleteScheduledEventActivity: payload => ({
    type: actions.DELETE_SCHEDULED_EVENT_ACTIVITY,
    payload,
  }),

  deleteScheduledEventActivitySuccess: payload => ({
    type: actions.DELETE_SCHEDULED_EVENT_ACTIVITY_SUCCESS,
    payload,
  }),

  deleteScheduledEventActivityFailure: () => ({
    type: actions.DELETE_SCHEDULED_EVENT_ACTIVITY_FAILURE,
  }),

  deleteAdmissionTicket: payload => ({
    type: actions.DELETE_ADMISSION_TICKET,
    payload,
  }),

  deleteAdmissionTicketSuccess: payload => ({
    type: actions.DELETE_ADMISSION_TICKET_SUCCESS,
    payload,
  }),

  deleteAdmissionTicketFailure: () => ({
    type: actions.DELETE_ADMISSION_TICKET_FAILURE,
  }),

  deleteDropInTicket: payload => ({
    type: actions.DELETE_DROP_IN_TICKET,
    payload,
  }),

  deleteDropInTicketSuccess: payload => ({
    type: actions.DELETE_DROP_IN_TICKET_SUCCESS,
    payload,
  }),

  deleteDropInTicketFailure: () => ({
    type: actions.DELETE_DROP_IN_TICKET_FAILURE,
  }),

  deleteAdditionalOptionTicket: payload => ({
    type: actions.DELETE_ADDITIONAL_OPTION_TICKET,
    payload,
  }),

  deleteAdditionalOptionTicketSuccess: payload => ({
    type: actions.DELETE_ADDITIONAL_OPTION_TICKET_SUCCESS,
    payload,
  }),

  deleteAdditionalOptionTicketFailure: () => ({
    type: actions.DELETE_ADDITIONAL_OPTION_TICKET_FAILURE,
  }),

  duplicateAdditionalOptionTicket: payload => ({
    type: actions.DUPLICATE_ADDITIONAL_OPTION_TICKET,
    payload,
  }),

  duplicateAdditionalOptionTicketSuccess: payload => ({
    type: actions.DUPLICATE_ADDITIONAL_OPTION_TICKET_SUCCESS,
    payload,
  }),

  duplicateAdditionalOptionTicketFailure: () => ({
    type: actions.DUPLICATE_ADDITIONAL_OPTION_TICKET_FAILURE,
  }),

  duplicateAdmissionTicket: payload => ({
    type: actions.DUPLICATE_ADMISSION_TICKET,
    payload,
  }),

  duplicateAdmissionTicketSuccess: payload => ({
    type: actions.DUPLICATE_ADMISSION_TICKET_SUCCESS,
    payload,
  }),

  duplicateAdmissionTicketFailure: () => ({
    type: actions.DUPLICATE_ADMISSION_TICKET_FAILURE,
  }),

  editEventSpecificActivity: payload => ({
    type: actions.EDIT_EVENT_SPECIFIC_ACTIVITY,
    payload,
  }),

  editEventSpecificActivitySuccess: payload => ({
    type: actions.EDIT_EVENT_SPECIFIC_ACTIVITY_SUCCESS,
    payload,
  }),

  editEventSpecificActivityFailure: () => ({
    type: actions.EDIT_EVENT_SPECIFIC_ACTIVITY_FAILURE,
  }),

  createAdmissionTicketInStepFour: payload => ({
    type: actions.ADD_ADMISSION_TICKET_EVENTS_STEP_FOUR,
    payload,
  }),

  createAdmissionTicketInStepFourSuccess: payload => ({
    type: actions.ADD_ADMISSION_TICKET_EVENTS_STEP_FOUR_SUCCESS,
    payload,
  }),

  createAdmissionTicketInStepFourFailure: () => ({
    type: actions.ADD_ADMISSION_TICKET_EVENTS_STEP_FOUR_FAILURE,
  }),

  getOrganizationLocations: params => ({
    type: actions.GET_ORGANIZATION_LOCATIONS,
    params,
  }),

  getOrganizationLocationsSuccess: payload => ({
    type: actions.GET_ORGANIZATION_LOCATIONS_SUCCESS,
    payload,
  }),

  getOrganizationLocationsFailure: () => ({
    type: actions.GET_ORGANIZATION_LOCATIONS_FAILURE,
  }),

  getApprovedLocationsRequest: params => ({
    type: actions.GET_APPROVED_LOCATIONS,
    params,
  }),

  getApprovedLocationsSuccess: payload => ({
    type: actions.GET_APPROVED_LOCATIONS_SUCCESS,
    payload,
  }),

  getApprovedLocationsFailure: () => ({
    type: actions.GET_APPROVED_LOCATIONS_FAILURE,
  }),

  getEventOrganizationFaqs: params => ({
    type: actions.GET_EVENT_ORGANIZATION_FAQS,
    params,
  }),

  getEventOrganizationFaqsSuccess: payload => ({
    type: actions.GET_EVENT_ORGANIZATION_FAQS_SUCCESS,
    payload,
  }),

  getEventOrganizationFaqsFailure: () => ({
    type: actions.GET_EVENT_ORGANIZATION_FAQS_FAILURE,
  }),
  getapproveParticipants: () => ({
    type: actions.APPROVE_PARTICIPANTS,
  }),
  getRecentCheckin: payload => ({
    type: actions.GET_RECENT_CHECK_IN,
    payload: payload,
  }),
}

export default actions
