import { Avatar, Dropdown } from 'antd'
import { capitalizeFirstLetter, getNameSignature } from 'helper/utility'
import moment from 'moment'
import { useState } from 'react'
import Ticket from 'assets/Images/Ticket.svg'
import Edit from 'assets/Images/rename.svg'
import X from 'assets/Images/XCircle.svg'
const ParticpantCard = ({ data }: any) => {
  const ManageOptions = [
    {
      key: 'tickets',
      label: (
        <div>
          <div className="flex align-center">
            <img src={Ticket} alt="contac-attende" />
            <h4 className="w-500 text-14 ml-10px">View tickets</h4>
          </div>
        </div>
      ),
    },
    {
      key: 'edit',
      label: (
        <div>
          <div className="flex align-center">
            <img src={Edit} alt="contac-attende" />
            <h4 className="w-500 text-14 ml-10px">Edit Registration</h4>
          </div>
        </div>
      ),
    },
    {
      key: 'cancel',
      label: (
        <div>
          <div className="flex align-center">
            <img src={X} alt="contac-attende" />
            <h4 className="w-500 text-14 ml-10px danger-txt">
              Cancel Registration
            </h4>
          </div>
        </div>
      ),
    },
  ]
  const handleMenuItemClick = (label: any) => {}
  return (
    <div className="flex justify-between mt-10px">
      <div className="flex">
        <Avatar src={data?.photo?.thumb} size={40}>
          {getNameSignature(data?.firstName)}
        </Avatar>
        <div className="ml-10px flex flex-col justify-start">
          <h4 className="text-14 w-500">
            {data?.firstName + ' ' + data?.lastName}
          </h4>
          <p className="text-12 w-400  grey-txt">
            {capitalizeFirstLetter(data?.gender) +
              '.' +
              moment().diff(data?.dob, 'years') +
              ' y.o.'}
          </p>
        </div>
      </div>
      <Dropdown
        menu={{
          items: ManageOptions,
          onClick: label => handleMenuItemClick(label),
        }}
        placement="bottom"
        arrow={false}
        className="ml-20px mr-10px"
        overlayStyle={{ width: '200px' }}
      >
        <button className=" w-500 px-35px py-10px h-fit bg-white border-prim radius-6 text-edit">
          Manage
        </button>
      </Dropdown>
    </div>
  )
}
export default ParticpantCard
