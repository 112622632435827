import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import AppLayout from '../../components/layout/layout'
import { SearchOutlined } from '@ant-design/icons'
import { Row, Col, Divider, Select, Image, Space, Checkbox } from 'antd'
import { Menu, Dropdown, Button } from 'antd'
import parse from 'html-react-parser'
import { Card, Avatar } from 'antd'
import eventCard1 from '../../assets/Images/eventCard-1.png'
import GreenPoll from '../../assets/Images/GreenPoll.svg'
import BluePencil from '../../assets/Images/bluePencil.svg'
import Actionicon from '../../assets/Images/Action.svg'
import {
  DeleteOutlined,
  PushpinOutlined,
  EditOutlined,
  ShareAltOutlined,
} from '@ant-design/icons'
import NewsModal from '../Modals/NewsModal'
import actions from '../../Redux/Organization/action'
import newsFeedActions from '../../Redux/NewsFeed/action'
import { SorterResult } from 'antd/lib/table/interface'
import { values } from 'lodash'
const { Meta } = Card
const { Option } = Select
const Newsfeed = ({ newsFeedData, organizationData, organizersData }: any) => {
  const dispatch = useDispatch()
  const [isModalVisible, setModalVisible] = useState(false)
  const [data, setData] = useState([])
  const [organizersList, setOrganizersList] = useState([])

  const [currentData, setCurrentData] = useState<any>(null)
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({})
  const [selectedOrganizationId, setSelectedOrganizationId] = useState('')
  const [selectedOrganizerId, setSelectedOrganizerId] = useState<string | null>(
    null,
  )

  useEffect(() => {
    setData(newsFeedData)
  }, [newsFeedData])

  useEffect(() => {
    setOrganizersList(organizersData)
  }, [organizersData])

  // useEffect(() => {
  //   dispatch({
  //     type: actions.GET_ORGANIZATIONS,
  //     payload: '125',
  //   })
  // }, [dispatch])
  useEffect(() => {
    dispatch({
      type: actions.GET_ORGANIZATIONS,
    })
  }, [dispatch])

  useEffect(() => {
    if (selectedOrganizationId) {
      dispatch({
        type: actions.GET_ORGANIZERS_OF_ORGANIZATION,
        payload: null,
        id: selectedOrganizationId,
      })
      dispatch({
        type: newsFeedActions.GET_ALL_NEWS_OF_ORGANIZATION,
        payload: null,
        params: {
          id: selectedOrganizationId,
        },
      })
    } else {
      dispatch({
        type: newsFeedActions.GET_ALL_NEWS,
      })
    }
  }, [dispatch, selectedOrganizationId])

  const handleMenuClick = (menuItem: any) => {
    if (menuItem.key === 'delete') {
      let filterData = data.filter((item: any) => {
        return item.id !== currentData.id
      })
      dispatch({
        type: newsFeedActions.DELETE_NEWS_POST,
        payload: filterData,
      })
    }
    if (menuItem.key === 'pin') {
      let filteredData: any = data.filter((item: any) => {
        return item.id !== currentData.id
      })
      filteredData.unshift(currentData)
      setData(filteredData)
    }
    if (menuItem.key === 'edit') {
      setModalVisible(true)
    }
  }
  const Actionmenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="edit">
        <p>
          <EditOutlined /> Edit
        </p>
      </Menu.Item>
      <Menu.Item key="pin">
        <p>
          <PushpinOutlined /> Pin to the top
        </p>
      </Menu.Item>
      <Menu.Item key="delete">
        <p>
          <DeleteOutlined /> Delete
        </p>
      </Menu.Item>
      <Menu.Item key="share">
        <p>
          <ShareAltOutlined /> Share
        </p>
      </Menu.Item>
    </Menu>
  )
  const searchOrganization = (searchKey: any) => {
    let filteredData = newsFeedData.filter((item: any) => {
      return item.organizationName
        .toLowerCase()
        .includes(searchKey.toLowerCase())
    })
    setData(filteredData)
    if (searchKey !== '') {
      return filteredData
    } else {
      setData(newsFeedData)
    }
  }

  useEffect(() => {
    if (selectedOrganizerId) {
      // @ts-ignore
      setData([
        ...newsFeedData.filter(
          (news: any) => news.organizerId === selectedOrganizerId,
        ),
      ])
    } else {
      setData(newsFeedData)
    }
  }, [selectedOrganizerId])

  const handleChangeSortName = (value: any) => {
    if (value) {
      const val = JSON.parse(value)
      setSelectedOrganizerId(val.organizerId)
      setSortedInfo({
        order: 'descend',
        columnKey: value,
      })
    } else {
      setSelectedOrganizerId(null)
    }
  }

  return (
    <AppLayout>
      <div>
        <div className="page-content">
          <Row>
            <Col span={16}>
              <div>
                <Row className="mb-20">
                  <h2>
                    <b className="b2">News Feed</b>
                  </h2>{' '}
                </Row>
              </div>
              <div style={{ marginBottom: '30px' }}>
                <Row gutter={[10, 24]} align="middle">
                  <Col xl={{ span: 24 }}>
                    <Space size={15}>
                      <Select
                        className="custom-select-field text-color-black"
                        defaultValue="Select a Author"
                        placeholder="Author"
                        allowClear
                        onChange={handleChangeSortName}
                      >
                        {organizersList?.map((item: any) => {
                          return (
                            <Option
                              style={{ width: '100%' }}
                              key={item.id}
                              name="organizer"
                              value={JSON.stringify({
                                organizerId: item?.organizer?._id,
                                name: item?.organizer?.firstName,
                                avatar: item?.organizer?.photo?.post,
                              })}
                            >
                              <Avatar
                                src={item.organizer?.photo?.post}
                              ></Avatar>{' '}
                              {item?.organizer?.firstName}{' '}
                              {item?.organizer?.lastName}
                            </Option>
                          )
                        })}
                      </Select>
                      <Select
                        className="custom-select-field text-color-black"
                        defaultValue="all"
                        placeholder="sort by"
                        allowClear
                        onChange={handleChangeSortName}
                      >
                        <Option value="all">Type: All</Option>
                        <Option value="else">select : any</Option>
                      </Select>
                      <Button className="btn-item mr-15">More filters</Button>
                    </Space>
                    <div
                      className="right-search-block w-100 d-inline-block"
                      style={{ maxWidth: '293px' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          width: '100%',
                        }}
                      >
                        <div
                          className={'search-input-box'}
                          style={{ marginRight: '0px' }}
                        >
                          <input
                            type="search"
                            className="search-input-group"
                            placeholder="Search by Keyword"
                            //   onChange={(e: any) => searchActive(e.target.value)}
                          />
                          <SearchOutlined />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <Row>
                {data?.length > 0 &&
                  data?.map((item: any) => {
                    if (
                      item.schedule
                        ? moment(item.schedule_date).format('MM-DD-YYYY') <=
                            moment(new Date()).format('MM-DD-YYYY') &&
                          moment(item.schedule_time).format('hh:mm:ss a') <=
                            moment(new Date()).format('hh:mm:ss a')
                        : true
                    ) {
                      return (
                        <Card
                          style={{ width: '100%', marginBottom: '20px' }}
                          key={item?.date}
                        >
                          <Row>
                            <Col span={22}>
                              <Meta
                                avatar={
                                  <Avatar
                                    size={48}
                                    src={item.organization?.photo?.post}
                                  />
                                }
                                title={
                                  <div className="d-flex item-center">
                                    <h5 style={{ marginBottom: '0px' }}>
                                      <b className="b1">
                                        {' '}
                                        {item.organization?.name}
                                      </b>
                                    </h5>
                                    <span className=" time">
                                      <b className="b3">
                                        {moment(item.date).format('hh:mm A')}
                                      </b>
                                    </span>
                                  </div>
                                }
                                description={
                                  <span className="font12">
                                    <b className="b3">
                                      Written by {item?.organizer?.firstName}{' '}
                                      {item?.organizer?.lastName}
                                    </b>
                                  </span>
                                }
                              ></Meta>
                            </Col>
                            <Col span={2}>
                              <Dropdown
                                overlay={Actionmenu}
                                placement="bottom"
                                className="event-btns-card"
                              >
                                <Button
                                  onMouseOver={() => {
                                    setCurrentData(item)
                                  }}
                                >
                                  <img alt="" src={Actionicon} />
                                </Button>
                              </Dropdown>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: '10px' }}>
                            <h5>
                              <b>{item?.title}</b>
                            </h5>
                          </Row>
                          <Row>{item?.text}</Row>
                          <Row>
                            {item?.images?.length > 0 &&
                              item?.images?.map((image: any) => {
                                return (
                                  <img
                                    height={336}
                                    width={336}
                                    style={{ margin: 1, marginTop: 10 }}
                                    src={image?.post}
                                    key={item._id}
                                  />
                                )
                              })}
                          </Row>
                        </Card>
                      )
                    }
                  })}
              </Row>
            </Col>
            <Col span={8}>
              <div className="newsfeed-right-block">
                <Col span={1}>
                  <Divider type="vertical" style={{ height: '100%' }} />
                </Col>
                <Button
                  className="primary br-8 justify-center w-100"
                  size="large"
                  onClick={() => {
                    setModalVisible(true)
                    setCurrentData([])
                  }}
                >
                  Add news
                </Button>
                <Col>
                  <Space direction="vertical" size={20} className="mt-40">
                    <Space
                      direction="vertical"
                      className="mb-10"
                      size={20}
                      style={{ width: '300px' }}
                    >
                      <Space
                        direction="horizontal"
                        size={20}
                        style={{ width: '300px' }}
                      >
                        <h3 className="b1 mb-16">Organizations</h3>
                        <Button
                          className="primary  w-150"
                          size="small"
                          style={{ float: 'right', marginLeft: '100px' }}
                          onClick={() => {
                            setSelectedOrganizationId('')
                            setOrganizersList([])
                          }}
                        >
                          Reset
                        </Button>
                      </Space>
                      {organizationData?.map((item: any) => {
                        return (
                          <div>
                            <Avatar src={item?.photo?.post}></Avatar>{' '}
                            {item.name}
                            <Checkbox
                              style={{ float: 'right' }}
                              onChange={() => {
                                setSelectedOrganizationId(item._id)
                              }}
                              checked={
                                selectedOrganizationId == item._id
                                  ? true
                                  : false
                              }
                              defaultChecked={false}
                            />
                          </div>
                        )
                      })}
                    </Space>
                  </Space>
                </Col>
              </div>
            </Col>
          </Row>
          {isModalVisible ? (
            <NewsModal
              isModalVisible={isModalVisible}
              setModalVisible={setModalVisible}
              organizationData={organizationData}
              currentData={currentData}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </AppLayout>
  )
}
const mapStateToProps = (state: any) => ({
  newsFeedData: state.newsFeed.newsFeedData,
  organizationData: state.organization.activeOrganizations,
  organizersData: state.organization.organizersData,
})
export default connect(mapStateToProps)(Newsfeed)
