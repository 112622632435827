import { Button, Row, Col, Form, Input } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useDispatch } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import actions from "../../../../../../Redux/Events/action";

const AddDocumentmodal = ({ setIsAddFormOpen }: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const formSchema = {
    name: "",
    description: "",
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onFinish = (values: any) => {
    console.log("val:", values);
    dispatch({
      type: actions.SET_ADD_REQ_DOCUMENT_DATA,
      payload: values,
    });
    setIsAddFormOpen(false);
  };
  return (
    <div>
      <Modal
        visible={true}
        className="map-modal add-more-fees-modal"
        footer={null}
        centered={true}
        width={600}
        onCancel={() => {
          setIsAddFormOpen(false);
        }}
      >
        <h1 className="mb-20 b1">Add document</h1>
        <Form
          form={form}
          layout="horizontal"
          initialValues={formSchema}
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Row gutter={10}>
            <Col>
              <label className="input-label required-input">
                Document’s name
              </label>
              <Form.Item
                className="form-control mb-0"
                name="name"
                rules={[{ required: true, message: "Name is required" }]}
              >
                <Input
                  placeholder="Enter document name"
                  className="form-control-field"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col className="mt-10">
              <label className="input-label required-input">Description</label>
              <Form.Item
                className="form-control"
                name="description"
                rules={[{ required: true, message: "Description is required" }]}
              >
                <TextArea
                  rows={5}
                  placeholder="Short description of the document"
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="justify-center">
            <Row className="justify-end">
              <Button className="stepper-button mt-20" htmlType="submit">
                Save
              </Button>
            </Row>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default AddDocumentmodal;
