import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Select, Avatar } from 'antd'
import { OrgOrganizerType } from 'types/organization'
import { Person } from 'types/globalTypes'
import '../styles/general-details.scss'
import { getNameSignature } from 'helper/utility'
const { Option } = Select

interface EventManagerProps {
  selectedOrganizer: string[]
  setSelectedOrganizer: React.Dispatch<React.SetStateAction<string[]>>
  orgOrganizers: OrgOrganizerType[]
  defaultOrganizer: Person
}

const EventManager: React.FC<EventManagerProps> = ({
  selectedOrganizer,
  setSelectedOrganizer,
  orgOrganizers,
  defaultOrganizer,
}) => {
  const [isDefault, setIsDefaul] = useState<boolean>()

  useEffect(() => {
    if (selectedOrganizer.length > 0) {
      setIsDefaul(false)
    } else {
      setIsDefaul(true)
    }
  }, [selectedOrganizer, defaultOrganizer])

  return (
    <>
      {isDefault ? (
        <Row gutter={10} className="mb-16">
          <Col>
            <label className="input-label required-input">Event manager</label>
          </Col>
          <Col>
            <div className="flex align-center justify-between form-input-style">
              <p className="text-14 w3">{defaultOrganizer?.firstName}</p>
              <button className="button" onClick={() => setIsDefaul(false)}>
                Custom select
              </button>
            </div>
          </Col>
        </Row>
      ) : (
        <Row gutter={10}>
          <Col>
            <label className="input-label required-input">Event Manager</label>
          </Col>
          <Col>
            <Form.Item colon={false} className="form-control" name="organizers">
              <Select
                mode={'multiple'}
                className="form-control-field"
                placeholder="Select"
                defaultValue={selectedOrganizer}
                onChange={(val: any, elm: any) => {
                  setSelectedOrganizer(val)
                }}
              >
                {orgOrganizers?.length > 0 &&
                  orgOrganizers?.map((item: OrgOrganizerType) => (
                    <Option
                      value={item.organizerId}
                      key={item.organizerId}
                      name={item?.organizer?.firstName}
                      _id={item.organizerId}
                    >
                      <Avatar
                        size={20}
                        src={item?.organizer?.photo?.post}
                        className="mr-5"
                      >
                        {getNameSignature(item?.organizer?.firstName)}
                      </Avatar>

                      {item?.organizer?.firstName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  )
}

export default EventManager
