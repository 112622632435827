import React, { useState, useEffect } from 'react'
import {
  Button,
  Row,
  Col,
  Menu,
  Form,
  Input,
  Switch,
  InputNumber,
  Radio,
} from 'antd'
import { RadioChangeEvent } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import Modal from 'antd/lib/modal/Modal'
import deleteRed from '../../../../../../../assets/Images/delete_red.svg'
import plusBlue from '../../../../../../../assets/Images/plusBlue.svg'
import AmenitiesModal from './AmenitiesModal'
import darkBluePencil from '../../../../../../../assets/Images/darkBluePencil.svg'
import {
  useGetAmenties,
  useCreateOrgVenue,
  useUpdateVenue,
} from 'api/EventCreation/EventCreation'
import { Address, AmenityType } from 'types/globalTypes'
import OtherType from 'assets/Images/otherCategory.svg'
import Loader from 'helper/Loader'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { Amenity, OrganizationVenues } from 'types/organization'

interface VenueModalTypes {
  organizationId: string
  dataItem?: OrganizationVenues
  setCreateVenueModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setFieldsValue: any
  mapData: Address
}

const CreateVenueModal: React.FC<VenueModalTypes> = ({
  organizationId,
  dataItem,
  setCreateVenueModalVisible,
  setFieldsValue,
  mapData,
}) => {
  const [form] = Form.useForm()
  const [amenityModalVisible, setAmenityModalVisible] = useState(false)
  // const [amenitiesValue, setAmenitiesValue] = useState(
  //   dataItem.amenities.length > 0 ? dataItem.amenities : [],
  // )
  const [amenitiesValue, setAmenitiesValue] = useState<AmenityType[]>(
    dataItem?.amenities.length > 0
      ? dataItem.amenities.map(({ _id, name, icon }) => ({
          _id,
          name,
          icon: icon?.post,
        }))
      : [],
  )
  const [additionalAmenities, setAdditionalAmenities] = useState([])
  const [amenityIndexToEdit, setAmenityIndexToEdit] = useState(null)
  const [currentAmenity, setCurrentAmenity] = useState(null)
  const [costType, setCostType] = useState<string>(
    dataItem?.pricePerPersonEnabled ? 'price-per-person' : 'one-time',
  )
  const [priceType, setPriceType] = useState<string>(
    dataItem?.priceRange ? 'price-range' : 'price',
  )

  const {
    data: amentiesList,
    isLoading: amentiesLoading,
    isFetching: amentiesFetching,
    refetch: amenitiesRefetch,
  } = useGetAmenties()
  const { mutate: orgVenueCreate, isLoading: createVenueLoading } =
    useCreateOrgVenue()
  const { mutate: orgVenueUpdate, isLoading: updateVenueLoading } =
    useUpdateVenue()

  const formSchema = {
    name: dataItem?.name ? dataItem?.name : '',
    venueCost: dataItem?.oneTimeCost ? dataItem?.oneTimeCost : '',
    priceUnit: dataItem?.pricePerPersonEnabled
      ? 'price-per-person'
      : 'one-time',
    priceType: dataItem?.priceRange ? 'price-range' : 'price',
    price: dataItem?.oneTimeCostEnabled ? dataItem.price : 0,
    minPrice: dataItem?.pricePerPersonEnabled ? dataItem?.priceRange.min : 0,
    maxPrice: dataItem?.pricePerPersonEnabled ? dataItem?.priceRange.max : 0,
    instruction: dataItem?.instruction ? dataItem?.instruction : '',
    amenities: dataItem?.amenities.length > 0 ? dataItem?.amenities : '',
  }

  useEffect(() => {
    amenitiesRefetch()
  }, [])

  useEffect(() => {
    const customAmenities = dataItem?.amenities.filter((amenity: Amenity) => {
      return !amentiesList.some(amen => amen._id === amenity._id)
    })

    setAdditionalAmenities(customAmenities)
  }, [amentiesList])

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  const onFinish = values => {
    const dataValues = {
      organizationId: organizationId,
      name: values.name,
      oneTimeCostEnabled: costType === 'one-time' ? true : false,
      pricePerPersonEnabled:
        values.priceUnit === 'price-per-person' ? true : false,
      price: priceType === 'price' ? values.price : undefined,
      priceRange:
        priceType === 'price-range'
          ? { min: values.minPrice, max: values.maxPrice }
          : undefined,
      instruction: values.instruction,
      coordinates: {
        longitude: mapData?.coordinates?.longitude,
        latitude: mapData?.coordinates?.latitude,
      },
      amenities: amenitiesValue,
    }
    if (dataItem?._id) {
      orgVenueUpdate(
        { payload: dataValues, venueId: dataItem?._id },
        {
          onSuccess: () => {
            setCreateVenueModalVisible(false)
          },
        },
      )
    } else {
      orgVenueCreate(
        { payload: dataValues, orgId: organizationId },
        {
          onSuccess: () => {
            setCreateVenueModalVisible(false)
          },
        },
      )
    }
  }

  const onToggleChange = (checked: boolean, data: any) => {
    form.setFieldsValue({ [data.name]: checked })
    setAmenitiesValue((oldArr): any => {
      const indexOfObject = oldArr.findIndex((object: any) => {
        return object?._id === data._id
      })
      if (indexOfObject >= 0 && !checked) {
        oldArr.splice(indexOfObject, 1)
        return oldArr
      } else {
        return [...oldArr, { _id: data._id, name: data.name, icon: data.icon }]
      }
    })
  }

  return (
    <div>
      <Modal
        open={true}
        className="map-modal"
        footer={null}
        centered={true}
        width={600}
        onCancel={() => {
          setCreateVenueModalVisible(false)
        }}
      >
        <h2 className="mb-20 b1" style={{ paddingTop: 20 }}>
          {dataItem ? 'Edit venue' : 'Create new venue'}
        </h2>
        <Form
          form={form}
          layout="horizontal"
          initialValues={formSchema}
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <>
            {amentiesLoading || amentiesFetching ? (
              <div className="pt-40px pb-40px">
                <Loader />
              </div>
            ) : (
              <>
                <div className="modal-h-element add-activity-modal-h">
                  <Row gutter={10}>
                    <Col>
                      <label className="input-label required-input">
                        Venue name
                      </label>
                      <Form.Item
                        className="form-control"
                        name="name"
                        rules={[
                          { required: true, message: 'Venue name is required' },
                        ]}
                      >
                        <Input
                          placeholder="Venue name"
                          className="form-control-field"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col span={24} className="d-flex">
                      <label
                        className="input-label mr-30 mb-10 align-self-center w-100"
                        style={{ maxWidth: '90px' }}
                      >
                        Venue’s cost
                      </label>
                    </Col>
                    <Col>
                      <Form.Item
                        name="priceUnit"
                        rules={[
                          {
                            required: true,
                            message: 'Price unit is required',
                          },
                        ]}
                        className="w-100"
                      >
                        <Radio.Group
                          onChange={(val: RadioChangeEvent) => {
                            setCostType(val.target.value)
                          }}
                          defaultValue={costType}
                        >
                          <div className="flex justify-between align-center w-70p">
                            <Radio value={'one-time'} className="mr-40 radio">
                              One time
                            </Radio>
                            <Radio
                              value={'price-per-person'}
                              className="mr-40 radio"
                            >
                              Price per person
                            </Radio>
                          </div>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={10} className="mt-10px">
                    <Col>
                      <Form.Item
                        name="priceType"
                        rules={[
                          {
                            required: true,
                            message: 'Price unit is required',
                          },
                        ]}
                        className="w-100"
                      >
                        <Col span={24} className="d-flex">
                          <label
                            className="input-label mr-30 mb-10 align-self-center w-100 mb-0px"
                            style={{ maxWidth: '90px' }}
                          >
                            Price type
                          </label>
                        </Col>
                        <Radio.Group
                          onChange={(val: RadioChangeEvent) => {
                            // setCostType(val.target.value)
                            setPriceType(val.target.value)
                          }}
                          defaultValue={priceType}
                        >
                          <div className="flex justify-between align-center w-70p">
                            <Radio value={'price'} className="mr-40 radio">
                              Price
                            </Radio>
                            {priceType === 'price' && (
                              <div className="flex align-center">
                                <p className="mr-10px">$</p>
                                <Form.Item name="price" className="mb-0px">
                                  <InputNumber
                                    placeholder="0"
                                    className="br-12 custom-input-num-field font16 text-end"
                                  />
                                </Form.Item>
                              </div>
                            )}
                          </div>
                          <div className="flex gap-16px align-center">
                            <Radio
                              value={'price-range'}
                              className="mr-40 radio"
                            >
                              Price range
                            </Radio>
                            {priceType === 'price-range' && (
                              <div className="flex gap-16px align-center">
                                <div className="flex align-center">
                                  <p className="mr-10px">$</p>
                                  <Form.Item name="minPrice" className="mb-0px">
                                    <InputNumber
                                      placeholder="0"
                                      className="br-12 custom-input-num-field font16 text-end"
                                    />
                                  </Form.Item>
                                </div>
                                -
                                <div className="flex align-center">
                                  <p className="mr-10px">$</p>
                                  <Form.Item name="maxPrice" className="mb-0px">
                                    <InputNumber
                                      placeholder="0"
                                      className="br-12 custom-input-num-field font16 text-end"
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            )}
                          </div>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={10}>
                    <Col>
                      <label className="input-label">Instruction</label>
                      <Form.Item className="form-control" name="instruction">
                        <TextArea
                          className="w-100"
                          rows={5}
                          placeholder="Add instruction how to get to the venue"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col className="mt-10">
                      <label className="input-label required-input">
                        Amenities
                      </label>
                    </Col>
                    <Col className="mb-10">
                      <Row gutter={10}>
                        {amentiesList.map(
                          (item: AmenityType, index: number) => (
                            <Col
                              key={index}
                              className="mb-10 inner-toggle-swich"
                              style={{
                                display: 'flex',
                              }}
                            >
                              <div className="flex gap-16px align-center">
                                <div className="w-36px h-36px flex align-center justify-center radius-rounded shadow">
                                  <img
                                    src={`${item.icon ? item.icon : OtherType}`}
                                    alt={item.name}
                                    width={28}
                                    height={28}
                                  />
                                </div>
                                <p className="f-regular">{item.name} </p>
                              </div>
                              <Form.Item
                                valuePropName="checked"
                                name={item.name}
                                noStyle
                              >
                                <Switch
                                  className="custom-switch-btn"
                                  checked={dataItem?.amenities.some(
                                    (elm: any) =>
                                      elm.name === item.name &&
                                      form.setFieldsValue({
                                        [item.name]: true,
                                      }),
                                  )}
                                  onChange={(checked: any) =>
                                    onToggleChange(checked, item)
                                  }
                                />
                              </Form.Item>
                            </Col>
                          ),
                        )}
                      </Row>
                      <Row gutter={10}>
                        {additionalAmenities &&
                          additionalAmenities.length > 0 &&
                          additionalAmenities.map(
                            (item: Amenity, index: number) => (
                              <Col
                                className="mb-10 inner-toggle-swich"
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <div style={{ display: 'flex' }}>
                                  <div className="flex gap-16px align-center">
                                    <div className="w-36px h-36px radius-rounded flex justify-center align-center shadow">
                                      <img
                                        src={`${
                                          item.icon ? item.icon : OtherType
                                        }`}
                                        alt={item.name}
                                        width={28}
                                        height={28}
                                      />
                                    </div>
                                    <p className="f-regular">{item.name} </p>
                                  </div>

                                  <img
                                    style={{
                                      paddingBottom: 5,
                                      marginLeft: 10,
                                      cursor: 'pointer',
                                    }}
                                    src={darkBluePencil}
                                    alt="Edit icon"
                                    onClick={() => {
                                      setAmenityIndexToEdit(index)
                                      setCurrentAmenity(item)
                                      setAmenityModalVisible(true)
                                    }}
                                  />
                                  <img
                                    style={{
                                      paddingBottom: 5,
                                      marginLeft: 10,
                                      cursor: 'pointer',
                                    }}
                                    src={deleteRed}
                                    alt="Delete icon"
                                    onClick={() => {
                                      const newArray = [
                                        ...additionalAmenities.slice(0, index),
                                        ...additionalAmenities.slice(index + 1),
                                      ]

                                      onToggleChange(false, item)

                                      setAdditionalAmenities(newArray)
                                    }}
                                  />
                                </div>
                                <Form.Item
                                  valuePropName="checked"
                                  name={item.name}
                                  noStyle
                                >
                                  <Switch
                                    className="custom-switch-btn"
                                    checked={dataItem?.amenities.some(
                                      (elm: any) =>
                                        elm.name === item.name &&
                                        form.setFieldsValue({
                                          [item.name]: true,
                                        }),
                                    )}
                                    onChange={(checked: any) =>
                                      onToggleChange(checked, item)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            ),
                          )}
                      </Row>
                    </Col>
                  </Row>
                </div>
                <Row justify="space-between" align="middle">
                  <h5
                    className="text-primary cursor_pointer"
                    onClick={() => {
                      setAmenityModalVisible(true)
                    }}
                  >
                    <b className="b2">
                      <img src={plusBlue}></img> Add new amenity
                    </b>
                  </h5>
                  <Button className="stepper-button" htmlType="submit">
                    {createVenueLoading ? (
                      <ButtonSpinner color="#000" size={20} />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </Row>
              </>
            )}
          </>
        </Form>
        {amenityModalVisible && (
          <AmenitiesModal
            setAmenityModalVisible={setAmenityModalVisible}
            setFieldsValue={setFieldsValue}
            setAdditionalAmenities={setAdditionalAmenities}
            amenitiesData={additionalAmenities}
            amenityIndexToEdit={amenityIndexToEdit}
            currentAmenity={currentAmenity}
            setAmenityIndexToEdit={setAmenityIndexToEdit}
            setCurrentAmenity={setCurrentAmenity}
          />
        )}
      </Modal>
    </div>
  )
}

export default CreateVenueModal
