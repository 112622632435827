import React, { useEffect, useState } from 'react'
import IconsTextNavigation from 'components/global/IconsTextNavigation'
import RequiredDetailsModal from '../../Modals/RequiredDetailsModal'
import { creationEventStore } from 'state/eventCreationStore'

const RequiredDetails: React.FC = () => {
  const [requiredDetailsModal, setRequiredDetails] = useState<boolean>(false)

  return (
    <>
      <div onClick={() => setRequiredDetails(true)}>
        <IconsTextNavigation
          iconName="CheckCircle"
          heading="Required Details"
          details="Not added"
        />
      </div>
      {requiredDetailsModal && (
        <RequiredDetailsModal
          openModal={requiredDetailsModal}
          closeModal={setRequiredDetails}
        />
      )}
    </>
  )
}

export default RequiredDetails
