import { useState, useEffect , useRef } from 'react'
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,

} from 'react-google-maps'
import {
  StandaloneSearchBox
} from '@react-google-maps/api'
import Geocode from 'react-geocode'
import Autocomplete from 'react-google-autocomplete'
import { Button, Row, Col } from 'antd'
import Pin from '../../assets/Images/pin.svg'

Geocode.setApiKey(process.env.REACT_APP_googleMapsAPI)
Geocode.enableDebug()

const Map = props => {
  const [state, setState] = useState({
    address: '',
    city: '',
    area: '',
    state: '',
    zipCode: '',
    apartment: '',
    country: '',
    mapPosition: {
      lat: props.center.lat,
      lng: props.center.lng,
    },
    markerPosition: {
      lat: props.center.lat,
      lng: props.center.lng,
    },
  })
  useEffect(() => {
    if (props.mapData) {
      setState(prevState => ({
        ...prevState,
        mapPosition: {
          lat: props.mapData.coordinates.latitude,
          lng: props.mapData.coordinates.longitude,
        },
        markerPosition: {
          lat: props.mapData.coordinates.latitude,
          lng: props.mapData.coordinates.longitude,
        },
        area: props.mapData.street,
        city: props.mapData.city,
        country: props.mapData.country,
        address: props.mapData.address,
      }))
    }
    console.log(" i m here mapData ")

  }, [props.mapData])

  useEffect(() => {

    Geocode.fromLatLng(props.mapData?.coordinates?.latitude, props?.mapData?.coordinates?.longitude).then(
        response => {
          const address = response.results[0].formatted_address,
              addressArray = response.results[0].address_components,
              city = getCity(addressArray),
              area = getArea(addressArray),
              state = getState(addressArray)
          const country = getCountry(addressArray)
          setState(prevState => ({
            ...prevState,
            country: country,
          }))
        },
        error => {
          console.error(error)
        },
    )
    console.log(" i m here  lat lng")

  }, [state.mapPosition.lat, state.mapPosition.lng])

  const shouldComponentUpdate = (nextProps, nextState) => {
    return (
      state.markerPosition.lat !== props.center.lat ||
      state.address !== nextState.address ||
      state.city !== nextState.city ||
      state.area !== nextState.area ||
      state.state !== nextState.state
    )
  }
  const getCity = addressArray => {
    let city = ''
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0]) {
        city = addressArray[i].long_name
        return city
      }
    }
  }
  const getApartment = addressArray => {
    let apartment = ''
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        ('premise' === addressArray[i].types[0] ||
          'plus_code' === addressArray[i].types[0] ||
          'street_number' === addressArray[i].types[0])
      ) {
        apartment = addressArray[i].long_name
        return apartment
      }
    }
  }
  const getZip = addressArray => {
    let zip = ''
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        'postal_code' === addressArray[i].types[0]
      ) {
        zip = addressArray[i].long_name
        return zip
      }
    }
  }
  const getArea = addressArray => {
    let area = ''
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            'sublocality_level_1' === addressArray[i].types[j] ||
            'locality' === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name
            return area
          }
        }
      }
    }
  }
  const getCountry = addressArray => {
    let country = ''
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
            addressArray[i].types[0] &&
            'country' === addressArray[i].types[0]
        ) {
          country = addressArray[i].long_name
          return country
        }
      }
    }
  }
  const getState = addressArray => {
    let state = ''
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          'administrative_area_level_1' === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name
          return state
        }
      }
    }
  }



  const onChange = event => {
    setState(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }
  const onInfoWindowClose = event => {}
  const onMarkerDragEnd = event => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng()

    Geocode.fromLatLng(newLat, newLng).then(
      response => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = getCity(addressArray),
          apartment = getApartment(addressArray),
          area = getArea(addressArray),
          zip = getZip(addressArray),
          state = getState(addressArray)
        const country = getCountry(addressArray)
        setState(prevState => ({
          ...prevState,
          address: address ? address : '',
          area: area ? area : '',
          city: city ? city : '',
          state: state ? state : '',
          zipCode: zip ? zip : '',
          apartment: apartment ? apartment : '',
          country: country ? country : '',
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        }))
      },
      error => {
        console.error(error)
      },
    )
  }

  const searchBoxRef = useRef(null);
  const handlePlacesChanged = () => {
    console.log('Selected Place:' , props.mapData);

    const places = searchBoxRef.current.getPlaces();
    if (places.length === 0) {
      return;
    }
    const selectedPlace = places[0];

    const address = selectedPlace.formatted_address,
      addressArray = selectedPlace.address_components,
      city = getCity(addressArray),
      apartment = getApartment(addressArray),
      zip = getZip(addressArray),
      area = getArea(addressArray),
      state = getState(addressArray),
      latValue = selectedPlace.geometry.location.lat(),
      lngValue = selectedPlace.geometry.location.lng()

    setState(prevState => ({
      ...prevState,
      address: address ? address : '',
      area: area ? area : '',
      city: city ? city : '',
      zipCode: zip ? zip : '',
      apartment: apartment ? apartment : '',
      state: state ? state : '',
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
    }))


    console.log('Selected Place:', selectedPlace.formatted_address);
  };
  const onPlaceSelected = (place) => {

    const address = place.formatted_address,
      addressArray = place.address_components,
      city = getCity(addressArray),
      apartment = getApartment(addressArray),
      zip = getZip(addressArray),
      area = getArea(addressArray),
      state = getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng()

    setState(prevState => ({
      ...prevState,
      address: address ? address : '',
      area: area ? area : '',
      city: city ? city : '',
      zipCode: zip ? zip : '',
      apartment: apartment ? apartment : '',
      state: state ? state : '',
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
    }))
  }

  const AsyncMap = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        google={props.google}
        defaultZoom={props.zoom}
        defaultCenter={{
          lat: state.mapPosition.lat,
          lng: state.mapPosition.lng,
        }}
      >
        <InfoWindow
          onClose={onInfoWindowClose}
          position={{
            lat: state.markerPosition.lat + 0.0018,
            lng: state.markerPosition.lng,
          }}
        >
          <div>
            <span style={{ padding: 0, margin: 0 }}>{state.address}</span>
          </div>
        </InfoWindow>
        <Marker
          google={props.google}
          name={'Dolores park'}
          draggable={true}
          onDragEnd={onMarkerDragEnd}
          position={{
            lat: state.markerPosition.lat,
            lng: state.markerPosition.lng,
          }}
          icon={Pin}
        />
        <Marker />
        <StandaloneSearchBox
            onLoad={(ref) => (searchBoxRef.current = ref)}
            onPlacesChanged={handlePlacesChanged}
        >
          <input
              type="text"
              placeholder="Search for an address"
              style={{
                width: '91%',
                position: 'absolute',
                top: '14%',
                height: '40px',
                paddingLeft: '16px',
                marginTop: '2px',
                marginBottom: '500px',
                borderRadius: '8px',

              }}
          />
        </StandaloneSearchBox>
        {/*<Autocomplete*/}
        {/*  className="secondary"*/}
        {/*  style={{*/}
        {/*    width: '91%',*/}
        {/*    position: 'absolute',*/}
        {/*    top: '14%',*/}
        {/*    height: '40px',*/}
        {/*    paddingLeft: '16px',*/}
        {/*    marginTop: '2px',*/}
        {/*    marginBottom: '500px',*/}
        {/*    borderRadius: '8px',*/}
        {/*    border: 'none',*/}
        {/*  }}*/}
        {/*  onPlaceSelected={onPlaceSelected}*/}
        {/*/>*/}
      </GoogleMap>
    )),
  )

  let map
  if (props.center.lat !== undefined) {
    map = (
      <div>
        <AsyncMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_googleMapsAPI}&libraries=places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: props.height }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
        <Row justify="center">
          <h5 className="drag-pin-to-choose-location">
            Drag pin to choose the location
          </h5>
        </Row>
        {state.area && (
          <>
            <Row>
              <Col span={18}>
                <Row>
                  <h5>
                    <b>Location </b>
                  </h5>
                </Row>
                <Row>
                  {' '}
                  {state.address ? state.address : props.mapData.address}
                </Row>
              </Col>
              <Col span={6} className="text-right">
                <Button
                  type="primary"
                  className="primary br-8 confirm-location-btn"
                  size="large"
                  onClick={() => {
                    props.next({
                      country: state.country,
                      street: state.area,
                      city: state.city,
                      zip: state.zipCode,
                      apartment: state.apartment,
                      state: state.state,
                      coordinates: {
                        latitude: state.mapPosition.lat,
                        longitude: state.mapPosition.lng,
                      },
                      address: state.address,
                    })
                  }}
                >
                  Confirm
                </Button>
              </Col>
            </Row>
          </>
        )}
      </div>
    )
  } else {
    map = <div style={{ height: props.height }} />
  }
  return map
}

export default Map
