import { axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import {
  WeatherRespType,
  GetActivitiesTypes,
  defaultActivitiesResp,
  EventStepTwoPayload,
  ActivityListType,
  ActivityStep1Payload,
  ActivityStep2Payload,
  InventoryLocationPayload,
  inventoryStorageLocType,
  AddInventoryPayload,
  InventoryResponse,
  AddActivityStep3SuppliesPayload,
  AddActivityStep4TasksPayload,
  CustomActivityResponse,
} from 'types/events'

// get request event weather
export const getEventWeather = async (eventId: string) => {
  const response = await axiosGet(`v1/weather/${eventId}`)
  return response.data
}

export const useGetEventWeather = (eventId: string) => {
  const eventWeather = useQuery<WeatherRespType[]>(
    ['event-weather'],
    () => getEventWeather(eventId),
    { enabled: false },
  )
  return eventWeather
}

// get request event default activiteis
export const getEventDefaultActivities = async (params: GetActivitiesTypes) => {
  const { organizationId } = params
  const response = await axiosGet(
    `v1/event-activities/new/${organizationId}`,
    params,
  )
  return response.data
}

export const useGetEventDefaultActivities = (params: GetActivitiesTypes) => {
  const defaultActivities = useQuery<defaultActivitiesResp[]>(
    ['default-event-activities'],
    () => getEventDefaultActivities(params),
    { enabled: false },
  )
  return defaultActivities
}

// patch request event-step2
export const eventStepTwo = async ({
  payload,
  eventId,
}: {
  payload: EventStepTwoPayload
  eventId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/organization-events/${eventId}/new/event-step2`,
    'Activity added to event',
  )
  return response.data
}

export const useEventStepTwo = () => {
  const queryClient = useQueryClient()

  return useMutation(eventStepTwo, {
    onSuccess: updatedData => {
      queryClient.setQueriesData(['event-detail-by-id'], () => {
        return updatedData
      })
    },
  })
}

// get request for lists
export const getActivityCategories = async () => {
  const response = await axiosGet(`v1/lists/activity-categories`)
  return response.data
}

export const getActivityHighlightedElem = async () => {
  const response = await axiosGet(`v1/lists/highlighted-elements`)
  return response.data
}

export const getActivityGoals = async () => {
  const response = await axiosGet(`v1/lists/goals`)
  return response.data
}

export const getActivityEnergyLevels = async () => {
  const response = await axiosGet(`v1/lists/energy-levels`)
  return response.data
}

export const useGetActivityCategories = () => {
  const activityCategories = useQuery<ActivityListType[]>(
    ['activity-categories'],
    getActivityCategories,
    { enabled: false },
  )
  return activityCategories
}

export const useGetActivityHighlightedElem = () => {
  const activityHighlightedElem = useQuery<ActivityListType[]>(
    ['activity-highlighted-elements'],
    getActivityHighlightedElem,
    { enabled: false },
  )
  return activityHighlightedElem
}

export const useGetActivityGoals = () => {
  const activityGoals = useQuery<ActivityListType[]>(
    ['activity-goals'],
    getActivityGoals,
    {
      enabled: false,
    },
  )
  return activityGoals
}

export const useGetActivityEnergyLevels = () => {
  const activityEnergyLevels = useQuery<ActivityListType[]>(
    ['activity-energy-levels'],
    getActivityEnergyLevels,
    { enabled: false },
  )
  return activityEnergyLevels
}

// post request step1 create activity
export const customActivityStep1 = async ({
  payload,
  orgId,
}: {
  payload: ActivityStep1Payload
  orgId: string
}) => {
  const response = await axiosPost(
    payload,
    `v1/event-activities/${orgId}/activity-step1`,
  )
  return response.data
}

export const useCustomActivityStep1 = () => {
  const queryClient = useQueryClient()
  return useMutation(customActivityStep1, {
    onSuccess: newActivity => {
      queryClient.setQueryData(
        ['default-event-activities'],
        (oldData: CustomActivityResponse[] | undefined) => {
          return oldData ? [...oldData, newActivity] : [newActivity]
        },
      )
    },
  })
}

// get request grade list
export const getActivityGrade = async () => {
  const response = await axiosGet(`v1/lists/grades`)
  return response.data
}

export const useGetActivityGrade = () => {
  const activityGrades = useQuery(
    ['activity-energy-levels'],
    getActivityGrade,
    { enabled: false },
  )
  return activityGrades
}

// patch request add activity step2
export const customActivityStep2 = async ({
  payload,
  activityId,
}: {
  payload: ActivityStep2Payload
  activityId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/event-activities/${activityId}/activity-step2`,
  )
  return response.data
}

export const useCustomActivityStep2 = () => {
  const queryClient = useQueryClient()
  return useMutation(customActivityStep2, {
    onSuccess: updatedActivity => {
      queryClient.setQueryData(
        ['default-event-activities'],
        (oldData: CustomActivityResponse[] | undefined) => {
          if (!oldData) return [updatedActivity]

          return oldData.map(activity =>
            activity._id === updatedActivity._id ? updatedActivity : activity,
          )
        },
      )
    },
  })
}

// get request list of oganization inventory
export const getOrgInventoryList = async (organizationId: string) => {
  const response = await axiosGet(
    `v1/supply-inventory/organization-inventory/${organizationId}`,
  )
  return response.data
}

export const useGetOrgInventoryList = (organizationId: string) => {
  const orgInventoryList = useQuery<InventoryResponse[]>(
    ['org-inventory-list'],
    () => getOrgInventoryList(organizationId),
    { enabled: false },
  )
  return orgInventoryList
}

// get request get inventory by id
export const getSupplyInventoryById = async (inventoryId: string) => {
  const response = await axiosGet(`v1/supply-inventory/${inventoryId}`)
  return response.data
}

export const useGetSupplyInventoryById = (inventoryId?: string) => {
  const supplyInventory = useQuery(
    ['supply-inventory-by-id'],
    () => (inventoryId ? getSupplyInventoryById(inventoryId) : null),
    { enabled: false },
  )
  return supplyInventory
}

// get request inventory storage location
export const getInventoryStorageLoc = async (organizationId: string) => {
  const response = await axiosGet(
    `v1/inventory-storage-location/${organizationId}`,
  )
  return response.data
}

export const useGetInventoryStorageLoc = (organizationId: string) => {
  const inventoryStroageLocation = useQuery<inventoryStorageLocType[]>(
    ['inventory-storage-location'],
    () => getInventoryStorageLoc(organizationId),
    { enabled: false },
  )
  return inventoryStroageLocation
}

// post request add inventory storage location
export const addInventoryStorageLoc = async ({
  payload,
  organizationId,
}: {
  payload: InventoryLocationPayload
  organizationId: string
}) => {
  const response = await axiosPost(
    payload,
    `v1/inventory-storage-location/add/${organizationId}`,
  )
  return response.data
}

export const useAddInventoryStorageLoc = () => {
  const queryClient = useQueryClient()
  return useMutation(addInventoryStorageLoc, {
    onSuccess: newLoc => {
      queryClient.setQueryData(
        ['inventory-storage-location'],
        (oldData: InventoryLocationPayload[] | undefined) => {
          return oldData ? [...oldData, newLoc] : [newLoc]
        },
      )
    },
  })
}

// post request add supply
export const addIventoryByOrg = async ({
  payload,
  organizationId,
}: {
  payload: AddInventoryPayload
  organizationId: string
}) => {
  const response = await axiosPost(
    payload,
    `v1/supply-inventory/add-inventory/${organizationId}`,
  )
  return response.data
}

export const useAddInventoryByOrg = () => {
  const queryClient = useQueryClient()
  return useMutation(addIventoryByOrg, {
    onSuccess: (newInventory: InventoryResponse) => {
      queryClient.setQueryData(
        ['org-inventory-list'],
        (oldData: InventoryLocationPayload[] | undefined) => {
          return oldData ? [...oldData, newInventory] : [newInventory]
        },
      )
    },
  })
}

// patch request add activity step 3
export const addActivityStep3 = async ({
  payload,
  activityId,
}: {
  payload: AddActivityStep3SuppliesPayload
  activityId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/event-activities/${activityId}/activity-step3-supplies`,
  )
  return response.data
}

export const useAddActivityStep3 = () => {
  const queryClient = useQueryClient()
  return useMutation(addActivityStep3, {
    onSuccess: updatedActivity => {
      queryClient.setQueryData(
        ['default-event-activities'],
        (oldData: CustomActivityResponse[] | undefined) => {
          if (!oldData) return [updatedActivity]

          return oldData.map(activity =>
            activity._id === updatedActivity._id ? updatedActivity : activity,
          )
        },
      )
    },
  })
}

// patch request activity step 4
export const addActivityStep4 = async ({
  payload,
  activityId,
}: {
  payload: AddActivityStep4TasksPayload
  activityId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/event-activities/${activityId}/activity-step4-tasks`,
  )
  return response.data
}

export const useAddActivityStep4 = () => {
  const queryClient = useQueryClient()
  return useMutation(addActivityStep4, {
    onSuccess: updatedActivity => {
      queryClient.setQueryData(
        ['default-event-activities'],
        (oldData: CustomActivityResponse[] | undefined) => {
          if (!oldData) return [updatedActivity]

          return oldData.map(activity =>
            activity._id === updatedActivity._id ? updatedActivity : activity,
          )
        },
      )
    },
  })
}
