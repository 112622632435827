import { CSVLink } from 'react-csv'
import { Row, Col, Button, Badge, Tooltip, Dropdown, Menu, Space } from 'antd'
import printerImg from '../../assets/Images/Printer.svg'
import downloadImg from '../../assets/Images/Download.svg'
import statusimg from '../../assets/Images/Activity.svg'
import deleteImg from '../../assets/Images/delete_white.svg'
import { useState } from 'react'
import ExportModalFamily from './ExportModalFamily'
import { DownOutlined } from '@ant-design/icons'
const PageNameHeader = ({
  selectedRows,
  completedata,

  fullData, // setcompleteData,
}: any) => {
  const csvData = completedata
    ? completedata?.map((item: any) => {
        return {
          parent: item.family,
          kid: item.personalDetails?.kid,
          status: 'active',
        }
      })
    : ''
  const headers = [
    { label: 'Adults', key: 'parent' },
    { label: 'kids', key: 'adminName' },
    { label: 'Primary Contact', key: 'referralType' },
    { label: 'Status', key: 'status' },
  ]
  // const deleteMultipleRecords = () => {
  //   let _data = [...completedata];
  //   selectedRows.forEach((row: any) => {
  //     _data = _data.filter((t) => t.personalDetails.id !== row.key);
  //   });
  //   setcompleteData(_data);
  //   // dispatch({
  //   //   type: familyAction.ADD_FAMILY_DATA,
  //   //   payload: _data,
  //   // });
  // };
  // console.log(fullData, 'fam')
  const [isExportModalVisible, setExportModalVisible] = useState(false)
  const handleModalClose = () => {
    setExportModalVisible(false)
  }
  const handleExportClick = () => {
    setExportModalVisible(true)
  }
  const menu = (
    <Menu>
      <Menu.Item onClick={handleExportClick}>
        <Space>
          <img alt="img" src={downloadImg} /> Export
        </Space>
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="page-header">
      <Row>
        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
          <h1 className="table-head mb-0">Families</h1>
        </Col>
        <Col lg={{ span: 18 }} offset={6} xs={{ span: 24, offset: 0 }}>
          {selectedRows?.length > 0 ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Tooltip placement="bottom" title="Status" color="blue">
                <Button
                  className={'gray-btn'}
                  shape="round"
                  icon={
                    <img
                      alt="img"
                      src={statusimg}
                      style={{
                        padding: '1%',
                        height: '20px',
                        marginRight: '10px',
                      }}
                    ></img>
                  }
                >
                  Status
                </Button>
              </Tooltip>
              <Tooltip placement="bottom" title="Print" color="blue">
                <Button
                  className={'gray-btn'}
                  shape="round"
                  onClick={() => {
                    window.print()
                  }}
                  icon={
                    <img
                      alt="img"
                      src={printerImg}
                      style={{
                        padding: '1%',
                        height: '20px',
                        marginRight: '10px',
                      }}
                    ></img>
                  }
                >
                  Print
                </Button>
              </Tooltip>
              <Tooltip placement="bottom" title="Export" color="blue">
                <Button
                  shape="round"
                  className={'gray-btn'}
                  icon={
                    <img
                      alt="img"
                      src={downloadImg}
                      style={{
                        padding: '1%',
                        height: '20px',
                        marginRight: '10px',
                      }}
                    ></img>
                  }
                >
                  <CSVLink
                    data={csvData}
                    headers={headers}
                    style={{ color: 'black' }}
                    filename="family.csv"
                    target="_blank"
                  >
                    Export
                  </CSVLink>
                </Button>
              </Tooltip>
              <Tooltip placement="bottom" title="Delete" color="blue">
                <Button
                  shape="round"
                  className={'gray-btn'}
                  onClick={() => {
                    // deleteMultipleRecords();
                  }}
                  icon={
                    <img
                      alt="img"
                      src={deleteImg}
                      style={{
                        padding: '1%',
                        height: '20px',
                        marginRight: '10px',
                      }}
                    ></img>
                  }
                >
                  Delete
                </Button>
              </Tooltip>
            </div>
          ) : (
            <div className="right-search-block">
              <div
                className="table-action-btn new-organizations-export-btn"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                {/* <Tooltip placement="bottom" title="Export" color="blue">
                  <CSVLink
                    style={{ color: 'black' }}
                    data={csvData}
                    headers={headers}
                    filename="family.csv"
                    target="_blank"
                  >
                    <Button
                      icon={<img alt="" src={downloadImg}></img>}
                      className={'btn-export bg-grey br-8'}
                    >
                      Export
                    </Button>
                  </CSVLink>
                </Tooltip> */}
                <Dropdown
                  overlay={menu}
                  placement="bottom"
                  className="bg-grey mx-20px"
                >
                  <button className=" prim-sm-btn" style={{ color: 'black' }}>
                    More <DownOutlined />
                  </button>
                </Dropdown>
                <Tooltip placement="bottom" title="Create">
                  <Button
                    className={'btn-new primary br-8'}
                    // onClick={() => {
                    //   dispatch({
                    //     type: actions.FAMILY_MODAL_VISIBLE,
                    //     payload: true,
                    //   });
                    // }}
                  >
                    New family
                  </Button>
                </Tooltip>
              </div>
            </div>
          )}
        </Col>
        <ExportModalFamily
          visible={isExportModalVisible}
          onClose={handleModalClose}
          data={fullData}
        />
      </Row>
    </div>
  )
}
export default PageNameHeader
