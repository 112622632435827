// import React, { useState } from 'react'
// import { Modal, Radio, Checkbox } from 'antd'
// import { CSVLink } from 'react-csv'
// import { saveAs } from 'file-saver'
// import * as XLSX from 'xlsx'
// import jsPDF from 'jspdf'
// import 'jspdf-autotable'
// import Cancel from 'assets/Images/close.svg'
// import moment from 'moment'
// const ExportEventAttendee = ({ visible, onClose, data }) => {
//   const [fileFormat, setFileFormat] = useState('csv')
//   const [selectedColumns, setSelectedColumns] = useState([
//     'Key',
//     'Profile Photo',
//     'Name',
//     'Age',
//     'Date of Birth',
//     'Type',
//     'Gender',
//     'Allergies',
//     'Guardians',
//     'Adults',
//     'Tickets',
//   ])

//   const handleCancel = () => {
//     onClose()
//   }

//   const csvData = data?.map(item => ({
//     key: item?._id,
//     profilePhoto: item?.profilePhoto,
//     name: item?.name,
//     age: item?.age,
//     dob: moment(item?.dob).format('MMM/DD/YYYY'),
//     type: item?.type,
//     gender: item?.gender,
//     allergies: item?.allergies,
//     guardians: item?.guardians,
//     adults: item?.adults,
//     tickets: item?.tickets,
//   }))

//   const headers = [
//     // { label: 'Key', key: 'key' },
//     { label: 'Profile Photo', key: 'profilePhoto' },
//     { label: 'Name', key: 'name' },
//     { label: 'Age', key: 'age' },
//     { label: 'Date of Birth', key: 'dob' },
//     { label: 'Type', key: 'type' },
//     { label: 'Gender', key: 'gender' },
//     { label: 'Allergies', key: 'allergies' },
//     { label: 'Guardians', key: 'guardians' },
//     { label: 'Adults', key: 'adults' },
//     { label: 'Tickets', key: 'tickets' },
//   ]

//   const handleFormatChange = e => {
//     setFileFormat(e.target.value)
//   }

//   const handleColumnChange = (checkedValue, label) => {
//     setSelectedColumns(prev =>
//       checkedValue ? [...prev, label] : prev.filter(col => col !== label),
//     )
//   }

//   const handleExport = () => {
//     const filteredHeaders = headers.filter(header =>
//       selectedColumns.includes(header.label),
//     )
//     const filteredData = csvData.map(row => {
//       const filteredRow = {}
//       filteredHeaders.forEach(header => {
//         filteredRow[header.key] = row[header.key]
//       })
//       return filteredRow
//     })

//     if (fileFormat === 'csv') {
//       document.getElementById('csv-download-link').click()
//     } else if (fileFormat === 'excel') {
//       const ws = XLSX.utils.json_to_sheet(filteredData)
//       const wb = XLSX.utils.book_new()
//       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
//       XLSX.writeFile(wb, 'Event_Attendee.xlsx')
//     } else if (fileFormat === 'pdf') {
//       const doc = new jsPDF()
//       const tableColumn = filteredHeaders.map(header => header.label)
//       const tableRows = filteredData.map(row =>
//         filteredHeaders.map(header => row[header.key]),
//       )

//       ;(doc as any).autoTable({
//         head: [tableColumn],
//         body: tableRows,
//       })

//       doc.save('Event_Attendee.pdf')
//     }
//     onClose()
//   }

//   return (
//     <Modal visible={visible} footer={false} className="addExport-model">
//       <div className="popup-header ">
//         <h2>Export Data</h2>
//         <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
//       </div>
//       <div className="formatText ">Format </div>
//       <Radio.Group onChange={handleFormatChange} value={fileFormat}>
//         <Radio value="csv">CSV</Radio>
//         <Radio value="excel">Excel</Radio>
//         {/* <Radio value="pdf">PDF</Radio> */}
//       </Radio.Group>
//       <div className="radioText mt-15px">Please select columns to export </div>
//       <div className="checkbox-group">
//         {[
//           //   'Key',
//           'Profile Photo',
//           'Name',
//           'Age',
//           'Date of Birth',
//           'Type',
//           'Gender',
//           'Allergies',
//           'Guardians',
//           'Adults',
//           'Tickets',
//         ].map(label => (
//           <div key={label}>
//             <Checkbox
//               checked={selectedColumns.includes(label)}
//               onChange={e => handleColumnChange(e.target.checked, label)}
//             >
//               {label}
//             </Checkbox>
//           </div>
//         ))}
//       </div>
//       <div className="flex justify-end w-full mt-10px">
//         <div className="bg-grey radius-8 pointer mr-30px">
//           <button
//             className="prim-sm-btn"
//             style={{ background: '#EBEDF1', color: 'black' }}
//             onClick={handleCancel}
//           >
//             Cancel
//           </button>
//         </div>
//         <button className="prim-sm-btn" onClick={handleExport}>
//           Export
//         </button>
//       </div>
//       <CSVLink
//         data={csvData}
//         headers={headers.filter(header =>
//           selectedColumns.includes(header.label),
//         )}
//         filename="Event_Attendee.csv"
//         id="csv-download-link"
//         style={{ display: 'none' }}
//       />
//     </Modal>
//   )
// }

// export default ExportEventAttendee

import React, { useState } from 'react'
import { Modal, Radio, Checkbox } from 'antd'
import { CSVLink } from 'react-csv'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Cancel from 'assets/Images/close.svg'
import moment from 'moment'
import { convertToLocalExportTicketTime } from 'helper/utility'
const ExportEventAttendee = ({ visible, onClose, data }) => {
  const [fileFormat, setFileFormat] = useState('csv')
  const [selectedColumns, setSelectedColumns] = useState([
    'Key',
    'Profile Photo',
    'Name',
    'Age',
    'Date of Birth',
    'Type',
    'Gender',
    'Allergies',
    'Guardians',
    'Adults',
    'Tickets',
  ])

  const handleCancel = () => {
    onClose()
  }
  const csvData = data?.map(item => ({
    key: item?._id,
    profilePhoto: item?.profilePhoto,
    name: item?.name,
    age: item?.age,
    dob: moment(item?.dob).format('MMM/DD/YYYY'),
    type: item?.type,
    gender: item?.gender,
    allergies: item?.allergies,
    guardians: item?.guardians,
    adults: item?.adults,
    // tickets: item?.tickets,
    // tickets: convertToLocalExportTicketTime(item?.tickets),
    tickets: convertToLocalExportTicketTime(item?.tickets),
  }))

  const headers = [
    // { label: 'Key', key: 'key' },
    { label: 'Profile Photo', key: 'profilePhoto' },
    { label: 'Name', key: 'name' },
    { label: 'Age', key: 'age' },
    { label: 'Date of Birth', key: 'dob' },
    { label: 'Type', key: 'type' },
    { label: 'Gender', key: 'gender' },
    { label: 'Allergies', key: 'allergies' },
    { label: 'Guardians', key: 'guardians' },
    { label: 'Adults', key: 'adults' },
    { label: 'Tickets', key: 'tickets' },
  ]

  const handleFormatChange = e => {
    setFileFormat(e.target.value)
  }

  const handleColumnChange = (checkedValue, label) => {
    setSelectedColumns(prev =>
      checkedValue ? [...prev, label] : prev.filter(col => col !== label),
    )
  }

  const handleExport = () => {
    const filteredHeaders = headers.filter(header =>
      selectedColumns.includes(header.label),
    )
    const filteredData = csvData.map(row => {
      const filteredRow = {}
      filteredHeaders.forEach(header => {
        filteredRow[header.key] = row[header.key]
      })
      return filteredRow
    })

    if (fileFormat === 'csv') {
      document.getElementById('csv-download-link').click()
    } else if (fileFormat === 'excel') {
      const ws = XLSX.utils.json_to_sheet(filteredData)

      // Add hyperlinks to profilePhoto column
      const profilePhotoColIndex = filteredHeaders.findIndex(
        header => header.key === 'profilePhoto',
      )
      if (profilePhotoColIndex !== -1) {
        for (let i = 2; i <= filteredData.length + 1; i++) {
          // Skip header row
          const cellRef = XLSX.utils.encode_cell({
            r: i - 1,
            c: profilePhotoColIndex,
          })
          const cellValue = ws[cellRef]?.v
          if (cellValue) {
            ws[cellRef] = {
              f: `HYPERLINK("${cellValue}", "Profile Photo")`,
            }
          }
        }
      }

      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, 'Event_Attendee.xlsx')
    } else if (fileFormat === 'pdf') {
      const doc = new jsPDF()
      const tableColumn = filteredHeaders.map(header => header.label)
      const tableRows = filteredData.map(row =>
        filteredHeaders.map(header => row[header.key]),
      )

      ;(doc as any).autoTable({
        head: [tableColumn],
        body: tableRows,
      })

      doc.save('Event_Attendee.pdf')
    }
    onClose()
  }

  return (
    <Modal visible={visible} footer={false}>
      <div className="popup-header ">
        <h2>Export Data</h2>
        <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
      </div>
      <div className="formatText ">Format </div>
      <Radio.Group onChange={handleFormatChange} value={fileFormat}>
        <Radio value="csv">CSV</Radio>
        <Radio value="excel">Excel</Radio>
        {/* <Radio value="pdf">PDF</Radio> */}
      </Radio.Group>
      <div className="radioText mt-15px">Please select columns to export </div>
      <div className="checkbox-group">
        {[
          //   'Key',
          'Profile Photo',
          'Name',
          'Age',
          'Date of Birth',
          'Type',
          'Gender',
          'Allergies',
          'Guardians',
          'Adults',
          'Tickets',
        ].map(label => (
          <div key={label}>
            <Checkbox
              checked={selectedColumns.includes(label)}
              onChange={e => handleColumnChange(e.target.checked, label)}
            >
              {label}
            </Checkbox>
          </div>
        ))}
      </div>
      <div className="flex justify-end w-full mt-10px">
        <div className="bg-grey radius-8 pointer mr-30px">
          <button
            className="prim-sm-btn"
            style={{ background: '#EBEDF1', color: 'black' }}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
        <button className="prim-sm-btn" onClick={handleExport}>
          Export
        </button>
      </div>
      <CSVLink
        data={csvData}
        headers={headers.filter(header =>
          selectedColumns.includes(header.label),
        )}
        filename="Event_Attendee.csv"
        id="csv-download-link"
        style={{ display: 'none' }}
      />
    </Modal>
  )
}

export default ExportEventAttendee
