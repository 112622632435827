import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import {
  AddFaqPayload,
  AddOrgDocsPayload,
  EventFaqs,
  OrgDocumentResp,
  OrgFaqs,
} from 'types/eventStep5'

export const eventAdditionalSetting = async ({
  payload,
  eventId,
}: {
  payload: any
  eventId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/organization-events/${eventId}/event-step5`,
  )
  return response.data
}

export const useEventAdditionalSetting = () => {
  const queryClient = useQueryClient()
  return useMutation(eventAdditionalSetting, {
    onSuccess: updatedData => {
      //   queryClient.setQueryData(
      //     ['default-event-activities'],
      //     (oldData: any[] | undefined) => {
      //       return oldData ? [...oldData, newActivity] : [newActivity]
      //     },
      //   )
    },
  })
}

// * get organization faqs
export const getOrganizationFaq = async (orgId: string) => {
  const response = await axiosGet(`v1/event-questions/organization/${orgId}`)
  return response.data
}

export const useGetOrganizationFaq = (orgId: string) => {
  const orgFaqs = useQuery<OrgFaqs[]>(
    ['org-faqs'],
    () => getOrganizationFaq(orgId),
    {
      enabled: false,
    },
  )
  return orgFaqs
}

// * get event faq
const getEventFaq = async (eventId: string) => {
  const response = await axiosGet(`v1/event-questions/event/${eventId}`)
  return response.data
}

export const useGetEventFaq = (eventId: string) => {
  const getEventFaqs = useQuery<EventFaqs[]>(
    ['event-faqs'],
    () => getEventFaq(eventId),
    { enabled: false },
  )
  return getEventFaqs
}

// * post add event faq
export const addEventFaq = async (payload: AddFaqPayload) => {
  const response = await axiosPost(payload, `v1/event-questions/create`)
  return response.data
}
export const useAddEventFaq = () => {
  const queryClient = useQueryClient()
  return useMutation(addEventFaq, {
    onSuccess: newQuestion => {
      console.log(newQuestion)
      queryClient.setQueryData(
        ['event-faqs'],
        (oldData: EventFaqs[] | undefined) => {
          return oldData ? [...oldData, newQuestion] : [newQuestion]
        },
      )
    },
  })
}

// * get org documents
const getOrgDocuments = async (orgId: string) => {
  const response = await axiosGet(`v1/document-types`, {
    organizationId: orgId,
  })
  return response.data
}

export const useGetOrgDocuments = (orgId: string) => {
  const orgDocuments = useQuery<OrgDocumentResp[]>(
    ['org-docs'],
    () => getOrgDocuments(orgId),
    { enabled: false },
  )
  return orgDocuments
}

// * create org doc
const addOrgDocument = async (payload: AddOrgDocsPayload) => {
  const response = await axiosPost(payload, `v1/document-types/create`)
  return response.data
}

export const useAddOrgDocument = () => {
  const queryClient = useQueryClient()
  return useMutation(addOrgDocument, {
    onSuccess: newDoc => {
      queryClient.setQueryData(
        ['org-docs'],
        (oldData: OrgDocumentResp[] | undefined) => {
          return oldData ? [...oldData, newDoc] : [newDoc]
        },
      )
    },
  })
}
