import React, { Component } from "react";
import Map from "./Map";

class App extends Component {
  render() {
    console.log("this.props:",this.props)
    return (
      <div style={{ marginTop: "25px" }}>
        <Map
          next={this.props.next}
          google={this.props.google}
          setLoactionVisible={this.props.setLoactionVisible}
          center={{ lat: 18.5204, lng: 73.8567 }}
          mapData={this.props.mapData}
          height="386px"
          width="100%"
          zoom={15}
        />
      </div>
    );
  }
}

export default App;
