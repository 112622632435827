import {
  Button,
  Dropdown,
  Menu,
  Space,
  Table,
  Tooltip,
  Select,
  Avatar,
  Row,
  Col,
  Checkbox,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import type { ColumnsType } from 'antd/es/table'
import React, { useState, useEffect } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import active from '../../../assets/Images/activeGreen.png'
import pendingImg from '../../../assets/Images/pending.svg'
import blockedImg from '../../../assets/Images/blocked.svg'
import editblackPencil from '../../../assets/Images/editblackPencil.svg'
import deleteBlackIcon from '../../../assets/Images/deleteBlackIcon.svg'
import { useQuery, useMutation } from '@tanstack/react-query'
import {
  getOrganizationMembers,
  updateOrganizationMemeberStatus,
} from '../../../api/Organizations'
import { useLocation } from 'react-router'
import AlertModal from '../../../helper/AlertModal'
import actions from '../../../Redux/Organization/action'
import BasicinfoModal from '../../Modals/OrganizationModal/MembersModel/BasicInfo'
import { RequestParms } from '../../../types/globalTypes'
import { debounce } from 'lodash'
import { getNameSignature } from '../../../helper/utility'
import moment from 'moment'
import filterSvg from 'assets/Images/filter.svg'
interface DataType {
  key: React.Key
  name: string
  email: String
  number: string
  dob: String
  totalHours: Number
  trainingLevel: Number
  status: String
}

const Organizers = () => {
  const [view, setView] = useState('New organizer')
  const dispatch = useDispatch()
  const location = useLocation()
  const organizationKey = location.state
  const [searchQuery, setSearchQuery] = useState('')
  const [alertModal, setAlertModal] = useState(false)
  const [selectedKey, setSelectedKey] = useState<any>()
  const [selectedItem, setSelectedItem] = useState<DataType | null>(null)
  const [openEditModal, setEditModal] = useState(false)
  const [statusSort, setStatusSort] = useState('')
  const [parms, setParms] = useState<RequestParms>({
    page: 1,
  })

  const { data, refetch } = useQuery({
    queryKey: ['getOrganizationMembers', parms],
    queryFn: () => getOrganizationMembers(organizationKey, parms),
  })

  const updateAdminStatus = useMutation({
    mutationFn: async ({ organizationKey, data, status }: any) => {
      return await updateOrganizationMemeberStatus(
        organizationKey,
        data,
        status,
      )
    },
  })
  const handleChange = (key: string) => {
    if (selectedItem) {
      updateAdminStatus.mutate({
        organizationKey,
        data: selectedItem,
        status: key,
      })
      refetch()
    }
  }

  const alertModalData = {
    name: 'Orgadmins',
    heading: 'Are you sure want to delete this',
    message2: 'Admin',
  }

  const handleEditClick = (item: DataType) => {
    setSelectedItem(item)
    dispatch({ type: actions.ORGANIZER_MODAL_VISIBLE, payload: true })
  }

  const menu = (
    <Menu
      className="absolute w-full"
      selectedKeys={[selectedKey]}
      onClick={item => {
        setSelectedKey(item.key)
        handleChange(item.key)
      }}
    >
      <Menu.Item key={'approved'}>
        <img alt="img" src={active}></img> Approved
      </Menu.Item>
      <Menu.Item key={'requested'}>
        <img alt="img" src={pendingImg}></img> Pending
      </Menu.Item>
      <Menu.Item key={'rejected'}>
        <img alt="img" src={pendingImg}></img> Archived
      </Menu.Item>
      <Menu.Item key={'archived'}>
        <img alt="img" src={blockedImg}></img> Declined
      </Menu.Item>
    </Menu>
  )
  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (item, member: any) => {
        return (
          <div>
            <Space size={8}>
              <Avatar size={24} src={item?.photo?.post}>
                {getNameSignature(item?.firstName + '' + item?.lastName)}
              </Avatar>
              {item?.firstName}
              {item?.lastName}
              {/* {item} */}
            </Space>
          </div>
        )
      },
      // render: (item, member: any) => (
      //   <div>
      //     <Space size={8}>
      //       <Avatar size={24} src={member?.organizer?.photo?.post}>
      //         {/* {getNameSignature(item)} */}
      //       </Avatar>
      //       {/* {item} */}
      //     </Space>
      //   </div>
      // ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    { title: 'Phone', dataIndex: 'phone' },
    { title: 'Date of Birth', dataIndex: 'dob' },
    {
      title: 'Access Level',
      dataIndex: 'accessLevel',
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: String, item: any) => {
        return (
          <Dropdown overlay={menu}>
            <Button
              className="status_button"
              onMouseEnter={() => {
                setSelectedKey(status)
                setSelectedItem(item)
              }}
              onClick={() => {
                setSelectedItem(item)
              }}
            >
              <Space>
                <img
                  alt="img"
                  src={
                    status === 'approved'
                      ? active
                      : status === 'requested'
                      ? pendingImg
                      : status === 'rejected'
                      ? pendingImg
                      : pendingImg
                  }
                ></img>
                {status} <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text: String, item: any) => {
        return (
          <Space key={1} className="orgn-action-btn">
            <Tooltip placement="bottom" color={'black'} title="Edit">
              {/* <EditOutlined /> */}
              <img
                src={editblackPencil}
                width={20}
                alt="edit"
                onClick={() => handleEditClick(item)}
                className="cursor_pointer"
              />
            </Tooltip>

            <Tooltip placement="bottom" color={'blue'} title="Delete">
              <img
                src={deleteBlackIcon}
                alt="delete"
                className="cursor_pointer"
                onClick={e => {
                  setAlertModal(true)
                }}
              />
            </Tooltip>
          </Space>
        )
      },
    },
  ]

  let members: any = []

  members = data?.map((member: any) => ({
    key: member._id,
    // name: `${member?.organizer?.firstName} ${member?.organizer?.lastName}`,
    name: member?.organizer,
    email: member?.organizer?.email,
    phone: member?.organizer?.phone?.number,
    dob: moment(member?.organizer?.dob).format('MMMM DD, YYYY'),
    accessLevel: member?.accessLevel,
    designation: member?.designation,
    status: member?.status,
  }))

  // filtering the data based on the search
  // members = data
  // .filter((member: any) =>
  //   searchQuery === '' ||
  //   (member?.organizer?.firstName
  //     ?.toLowerCase()
  //     .includes(searchQuery.toLowerCase()))
  // )
  // .map((member: any) => ({
  //   key: member._id,
  //   name: `${member?.organizer?.firstName ?? ''} ${member?.organizer?.lastName ?? ''}`,
  //   email: member?.organizer?.email ?? '',
  //   accessLevel: member?.accessLevel ?? '',
  //   designation: member?.designation ?? '',
  //   status: member?.status ?? '',
  // }));

  // handling the sort by date and name filter
  const handleChangeSortName = (
    value: 'createdAt' | 'updatedAt' | '-createdAt' | 'name' | 'email',
  ) => {
    setParms({ ...parms, sort: value })
  }

  const onSearchChange = (e: any) => {
    // setParms({ ...parms, searchQuery: e });
  }
  const debounced = debounce(onSearchChange, 300)

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {},
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  }
  console.log(data?.organizer?.photo, 'ok')
  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value)
  }
  const { Option } = Select
  const handleChangeStatus = (value: string) => {
    // console.log('selected status', statusSort );
    setStatusSort(value)
  }
  const org = (
    <Menu onClick={e => setView(e.key)}>
      <Menu.Item key="cardview">New organizer</Menu.Item>
      <Menu.Item key="calendar">Calendar view</Menu.Item>
      <Menu.Item key="TableView">Table view</Menu.Item>
    </Menu>
  )
  const menu2 = (
    <Menu
      // onClick={handleMenuClick}
      title="show all"
      items={[
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox defaultChecked={data?.name} name="name"></Checkbox>
              </Col>
              <Col span={15}>Name</Col>
            </Row>
          ),
          key: '1',
        },
      ]}
    />
  )
  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }
  const [visible, setVisible] = useState(false)
  return (
    <>
      <div className="bg-grey padding-top-10">
        <div className="bg-white" style={{ paddingTop: '30px' }}>
          <div className="p-20px flex justify-between">
            <div className="flex justify-between align-center w-35p">
              <div style={{ marginRight: '15px' }}>
                <Dropdown
                  overlay={menu2}
                  onOpenChange={handleVisibleChange}
                  open={visible}
                >
                  <h5 onClick={e => e.preventDefault()}>
                    <Space>
                      <img src={filterSvg} alt=""></img>
                    </Space>
                  </h5>
                </Dropdown>
              </div>
              <div
                className="right-search-block w-100 d-inline-block"
                style={{ maxWidth: '100%' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <div
                    className={'search-input-box'}
                    style={{ marginRight: '15px' }}
                  >
                    <input
                      type="search"
                      className="search-input-group"
                      placeholder="Search"
                      onChange={handleSearchChange}
                    />
                    <SearchOutlined />
                  </div>
                </div>
              </div>
              <Select
                className="custom-select-field black text-color-black width-140"
                defaultValue="Status: All"
                placeholder="Status"
                style={{ width: 'auto' }}
                allowClear
                onChange={handleChangeStatus}
              >
                <Option value="">status: All</Option>
                <Option value="draft">Draft</Option>
                <Option value="public">Public</Option>
                <Option value="following">Following</Option>
                <Option value="subscribed">Subscribed</Option>
                <Option value="private">Private</Option>
                <Option value="mentor_social">Mentor socail</Option>
                <Option value="archived">Archived </Option>
              </Select>
            </div>

            <div style={{ marginLeft: 'auto', marginRight: '1rem' }}>
              <Dropdown overlay={org} trigger={['click']}>
                <Button className="nav-btn br-8">
                  {view} <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </div>

          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={members}
            style={{ width: '100%' }}
            pagination={false}
          />

          {alertModal && (
            <AlertModal
              isModalVisible={alertModal}
              setIsModalVisible={setAlertModal}
              data={alertModalData}
              deleteOrgAdmin={handleChange}
            />
          )}

          {openEditModal && (
            <BasicinfoModal
              modalVisible={setEditModal}
              currentData={selectedItem}
              updateData={updateAdminStatus}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default Organizers
