import React from 'react'
import './styles/component-styles.scss'
import { EyeSlash } from '@phosphor-icons/react'
import { DropinTicketResp, TicketsResp } from 'types/eventStep4'
import { capitalizeFirstLetter } from 'helper/utility'
import moment from 'moment'

interface TicketsComp {
  visible: boolean
  details?: TicketsResp | DropinTicketResp
  ticketType: 'admission' | 'additional' | 'dropin' | 'discount'
}

const TicketComp: React.FC<TicketsComp> = ({
  visible,
  details,
  ticketType,
}) => {
  const isTicketsResp = (
    details: TicketsResp | DropinTicketResp,
  ): details is TicketsResp => {
    return 'expiry' in details
  }
  return (
    <>
      <div
        className="ticket-main-cont"
        style={{ backgroundColor: `#${details.color}` }}
      >
        <div className="content">
          <div className="flex justify-between align-center">
            <h2 className="text-18 w-600">
              {capitalizeFirstLetter(details.name)}
            </h2>
            {visible && <EyeSlash size={20} color="#000" />}
          </div>
          {isTicketsResp(details) && (
            <p className="text-14 w-500">{`${
              details.days.length ?? null
            } days for $${details.price.toFixed(2)}`}</p>
          )}
          <button className="ticket-button">Manage</button>
        </div>
        <div className="dull-color"></div>
      </div>
    </>
  )
}

export default TicketComp
