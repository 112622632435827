import { useMutation, useQuery } from '@tanstack/react-query'
import { getKidbyId } from '../../../../api/family'
import { useParams } from 'react-router-dom'
import AppLayout from '../../../../components/layout/layout'
import { Avatar, Col, Menu, Row, Space, Tabs } from 'antd'
import KidProfileHeader from './Header'
import KidBasicInfo from './BasicInfo'
import KidEvents from './Events'
import KidDocuments from './Documents'
import KidActivity from './Activity'
// import Notes from './Notes/Notes'
import Notes from '../NotesComp/Notes'
const { TabPane } = Tabs

const KidProfile = () => {
  let { id }: any = useParams()
  const { isLoading, data, refetch } = useQuery(['getFamiles'], () =>
    getKidbyId(id),
  )

  return (
    <AppLayout id={id}>
      <div className="container bg-grey">
        <KidProfileHeader data={data} refetch={refetch} />
        <Row>
          <Tabs
            defaultActiveKey="1"
            style={{ width: '100%', height: '100%' }}
            className="bg-white profile-tabs"
          >
            <TabPane tab="Activity" key="1">
              <KidActivity />
            </TabPane>
            <TabPane tab="Events" key="2">
              <KidEvents />
            </TabPane>
            <TabPane tab="Basic info" key="3">
              <KidBasicInfo data={data} />
            </TabPane>
            <TabPane tab="Documents" key="4">
              <KidDocuments />
            </TabPane>
            <TabPane tab="Notes" key="5">
              {/* <Notes kidId={id} /> */}
              <Notes id={id} familyId={data?.familyId} />
            </TabPane>
          </Tabs>
        </Row>
      </div>
    </AppLayout>
  )
}
export default KidProfile
