import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Tabs, Modal } from 'antd'
import OrganizationListing from './OrganizationListing'
import { OrganizationParams } from 'types/events'
import { useGetOrganizations } from 'api/EventCreation/EventCreation'
import { OrganizationResp } from 'types/globalTypes'

interface SelectOrganizationModalTypes {
  selectOrganizationModal: boolean
  setSelectOrganizationModal: React.Dispatch<
    React.SetStateAction<OrganizationParams>
  >
  setSelectedOrganization: React.Dispatch<
    React.SetStateAction<OrganizationResp>
  >
}

const SelectOrganizationModel: React.FC<any> = ({
  selectOrganizationModal,
  setSelectOrganizationModal,
  setSelectedOrganization,
}: any) => {
  const [params, setParams] = useState<OrganizationParams>({
    page: 1,
    limit: 10,
    queryString: '',
    status: 'approved',
    sort: '-createdAt',
  })
  const TabChange = (key: string) => {
    if (key === '1') {
      setParams({ ...params, status: 'approved' })
    }
    if (key === '2') {
      setParams({ ...params, status: 'under_review' })
    }
  }
  const { data: organizations, refetch: organizationReFetch } =
    useGetOrganizations(params)

  useEffect(() => {
    organizationReFetch()
  }, [params])

  const items = [
    {
      key: '1',
      label: 'Active',
      children: (
        <OrganizationListing
          organizationData={organizations?.records}
          parms={params}
          setParms={setParams}
          setSelectedOrganization={setSelectedOrganization}
        />
      ),
    },
    {
      key: '2',
      label: 'Pending',
      children: (
        <OrganizationListing
          organizationData={organizations?.records}
          parms={params}
          setParms={setParams}
          setSelectedOrganization={setSelectedOrganization}
        />
      ),
    },
  ]

  return (
    <div>
      <Modal
        title="Select Organization"
        open={selectOrganizationModal}
        className="map-modal"
        footer={null}
        centered={true}
        width={600}
        onCancel={() => {
          setSelectOrganizationModal(false)
        }}
      >
        <div className=" pb-10px">
          <Tabs
            defaultActiveKey="1"
            items={items}
            className="org-tab"
            onChange={TabChange}
          />
          <Row justify="end" className="mr-1px" style={{ marginTop: 10 }}>
            <Col span={24} className="text-end">
              <Button
                className="stepper-button mr-1px"
                onClick={() => {
                  setSelectOrganizationModal(false)
                }}
              >
                Done
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

export default SelectOrganizationModel
