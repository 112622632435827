import { connect, useDispatch } from 'react-redux'
import Person from './Person'
import EmptyFamily from './EmptyFamily'
import { Row } from 'antd'
import deleteIcon from '../../../../../assets/Images/Trash.png'
import Edit from '../../../../../assets/Images/editblackPencil.svg'
const FamilyData = ({ setMembersVisible, fdata }: any) => {
  return (
    <div className="bg-white p-30 h-full h-100vh overflow-auto">
      {fdata?.guardians[0] || fdata?.kids[0] || fdata?.adults[0] ? (
        <div>
          <div className="flex justify-between align-center">
            <h4 className="text-20 w-600">Family members</h4>
            <button
              className="borderd-btn"
              onClick={() => {
                setMembersVisible(true)
              }}
            >
              Add members
            </button>
          </div>
          {fdata?.guardians[0] ? (
            <h5 className="text-14 w-600 mt-20 ">Guardians</h5>
          ) : (
            <></>
          )}
          {fdata?.guardians?.map((data: any) => {
            return (
              <>
                <div className="flex justify-between align-center">
                  <Person
                    id={data?._id}
                    photo={data?.photo?.post}
                    name={data?.firstName + ' ' + data?.lastName}
                    relation={data?.relation?.name}
                    to="parent-profile"
                    bcase={'guardian'}
                  />
                  <Row>
                    <img
                      src={Edit}
                      alt="edit"
                      className="pointer pr-10px w-30px"
                    />
                    <img
                      className="pointer"
                      src={deleteIcon}
                      alt="deleteIcon"
                      style={{ marginLeft: '10px' }}
                    />
                  </Row>
                </div>
              </>
            )
          })}
          {fdata?.kids[0] ? (
            <h5 className="text-14 w-600 mt-20 mb-20">Children</h5>
          ) : (
            <></>
          )}
          {fdata?.kids?.map((data: any) => {
            return (
              <>
                <div className="flex justify-between align-center">
                  <Person
                    id={data?._id}
                    photo={data?.photo?.post}
                    name={data?.firstName + ' ' + data?.lastName}
                    relation={data?.relation?.name}
                    to="kid-profile"
                    bcase={'kid'}
                  />
                  <Row>
                    <img
                      src={Edit}
                      alt="edit"
                      className="pointer pr-10px w-30px"
                    />
                    <img
                      className="pointer"
                      src={deleteIcon}
                      alt="deleteIcon"
                      style={{ marginLeft: '10px' }}
                    />
                  </Row>
                </div>
              </>
            )
          })}
          {fdata?.adults[0] ? (
            <h5 className="text-14 w-600 mt-20 mb-20">Authorized Adults</h5>
          ) : (
            <></>
          )}
          {fdata?.adults?.map((data: any) => {
            return (
              <>
                <div className="flex justify-between align-center">
                  <Person
                    id={data?._id}
                    photo={data?.photo?.post}
                    name={data?.firstName + ' ' + data?.lastName}
                    relation={data?.relation?.name}
                    to="kid-profile"
                    bcase={'kid'}
                  />
                  <Row>
                    <img
                      src={Edit}
                      alt="edit"
                      className="pointer pr-10px w-30px"
                    />
                    <img
                      className="pointer"
                      src={deleteIcon}
                      alt="deleteIcon"
                      style={{ marginLeft: '10px' }}
                    />
                  </Row>
                </div>
              </>
            )
          })}
        </div>
      ) : (
        <EmptyFamily />
      )}
    </div>
  )
}

export default FamilyData
