import ParticpantCard from './ParticipantsCard'

const Participants = ({ ungrouped, grouped }: any) => {
  let AllGroupedParticpents: any[] = []
  let UnGroupedParticpents = [
    ...(ungrouped?.guardians || []),
    ...(ungrouped?.kids || []),
    ...(ungrouped?.authorizedAdults || []),
  ]

  grouped.map((data: any) => {
    AllGroupedParticpents = [
      ...(data?.guardianParticipants || []),
      ...(data?.kidsParticipants || []),
      ...(data?.authorizedAdultParticipants || []),
    ]
  })
  const groupedParticipants = AllGroupedParticpents.filter((item: any) => item)
  return (
    <div>
      {UnGroupedParticpents[0] && (
        <h2 className="text-16 w-600 mt-20px">Not assigned to a group</h2>
      )}

      {UnGroupedParticpents.map((item: any, index: number) => {
        return <ParticpantCard key={index} data={item} />
      })}

      {groupedParticipants[0] && (
        <h2 className="text-16 w-600 mt-20px">grouped</h2>
      )}
      {groupedParticipants.map((item: any, index: number) => (
        <ParticpantCard key={index} data={item} />
      ))}
    </div>
  )
}
export default Participants
