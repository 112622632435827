import React, { useState } from 'react'
import { Modal, Input } from 'antd'
import closeIcon from 'assets/Images/close.svg'

interface AddTaskModalProps {
  modalOpen: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  tasks: string[]
  setTasks: React.Dispatch<React.SetStateAction<string[]>>
}

const AddTaskModal: React.FC<AddTaskModalProps> = ({
  modalOpen,
  closeModal,
  tasks,
  setTasks,
}) => {
  const [inputValue, setInputValue] = useState<string>('')

  const handleCancel = () => {
    closeModal(false)
  }

  const handleAdd = () => {
    if (inputValue.trim() !== '') {
      setTasks([...tasks, inputValue.trim()])
      setInputValue('')
    }
    handleCancel()
  }

  return (
    <div>
      <Modal
        open={modalOpen}
        onCancel={handleCancel}
        className="add-supplyform-modal"
        footer={false}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Add task</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="mt-30px flex align-center justify-between gap-20px">
          <Input
            placeholder="Add task"
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
          />
        </div>

        <div className="mt-10px flex align-center justify-between">
          <button className="create-activity-btn" onClick={handleCancel}>
            Cancel
          </button>
          <button className="confirm-btn" onClick={handleAdd}>
            Apply
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default AddTaskModal
