import React, { useState, useEffect, useCallback } from 'react'
import { Modal, Form } from 'antd'
import Cancel from 'assets/Images/close.svg'
import '../styles/style.scss'
import { useMutation } from '@tanstack/react-query'
import { addNotes } from 'api/notes'
import { useParams } from 'react-router-dom'
import BasicFields from '../Components/Modal/BasicFields'
import TaggedMembers from '../Components/Modal/TaggedMembers'
import CommentField from '../Components/Modal/CommentField'
import IncidentFields from '../Components/Modal/IncidentFields'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import actions from 'Redux/Family/action'
interface addNoteModalType {
  open: boolean
  setOpen: (value: boolean) => void
  familyId: string
}
interface addNotesMutationType {
  adminId: string
  payload: any
}

const AddNoteModal: React.FC<addNoteModalType> = ({
  open,
  setOpen,
  familyId,
}) => {
  const { pathname } = useLocation()
  const { id }: any = useParams()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [memberPage, setMemberPage] = useState<any>()
  const [adminId, setAdminId] = useState<string>('')
  const [taggedMembers, setTaggedMembers] = useState<any>([])
  const [error, setError] = useState<boolean>(false)
  const [basicField, setBasicField] = useState({
    type: 'comment',
    method: 'none',
  })
  const [event, selectedEvent] = useState<any>()
  const [mapData, setMapData] = useState<any>()
  const [documentIds, setDocumentIds] = useState<string[]>([])

  const handleEventChange = (value: any) => {
    console.log(value, 'this is the event value...')
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const addNotesMut = useMutation({
    mutationFn: async ({ adminId, payload }: addNotesMutationType) => {
      const data = await addNotes(adminId, payload)
      return data
    },
    onSuccess: () => {
      dispatch({
        type: actions.ADD_NOTE_SUCCESS,
      })
      setOpen(false)
    },
  })

  const handleSubmit = (values: any) => {
    if (taggedMembers.length === 0) {
      setError(true)
      return
    }
    const payload = {
      type: values.noteType,
      method: values.method,
      taggedPeople: taggedMembers.map((member: any) => member._id),
      description: values.description,
      // familyId: id,
      [memberPage]: id,

      // when type is incident
      ...(values.noteType === 'incident' && {
        incident: {
          name: values.incidentName,
          date: moment(values.incidentDate),
          time: moment(values.incidentTime),
          eventOccurAt: event.name,
          type: values.noteType,
          partiesInvolved: values.partiesInvolved,
          description: values.description,
          otherInfo: values.relevantInfo,
          mediaFilesIds: documentIds,
          address: mapData,
          notifiedAgencies: values.agencyNotified,
        },
      }),
      eventId: event?._id,
    }
    addNotesMut.mutateAsync({ adminId, payload })
  }

  const initialFormValues = {
    noteType: 'comment',
    method: 'none',
    // Add other form field initial values here
  }

  useEffect(() => {
    const storedAdminId = localStorage.getItem('adminId')
    if (storedAdminId) {
      setAdminId(storedAdminId)
    }
  }, [])

  useEffect(() => {
    if (event?.location) {
      setMapData(event?.location)
    }
  }, [event])

  useEffect(() => {
    if (pathname.includes('parent-profile')) {
      setMemberPage('guardianId')
    } else if (pathname.includes('kid-profile')) {
      setMemberPage('kidId')
    } else if (pathname.includes('adult-profile')) {
      setMemberPage('authorizedAdultId')
    } else {
      setMemberPage('familyId')
    }
  }, [])

  useEffect(() => {
    if (taggedMembers.length > 0) {
      setError(false)
    }
  }, [taggedMembers])

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      centered={true}
      footer={null}
      className="add-note-modal"
    >
      <div className="scroller note-modal-height">
        <div className="flex-center justify-between ">
          <h2 className="w-600 text-24">Add a note</h2>
          <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
        </div>

        <Form
          onFinish={handleSubmit}
          initialValues={initialFormValues}
          className="mt-20px"
        >
          <BasicFields basicField={basicField} setBasicField={setBasicField} />

          <TaggedMembers
            familyId={familyId}
            taggedMembers={taggedMembers}
            setTaggedMembers={setTaggedMembers}
          />
          {error && (
            <p className="-mt-10px mb-10px text-14 danger-txt">
              Please tag any member
            </p>
          )}

          {basicField.type !== 'incident' ? (
            <CommentField />
          ) : (
            <IncidentFields
              event={event}
              setEvent={selectedEvent}
              handleChange={handleEventChange}
              mapData={mapData}
              setMapData={setMapData}
              documentIds={documentIds}
              setDocumentIds={setDocumentIds}
            />
          )}

          <div className="flex align-center justify-end gap-30px mt-30px pb-30px">
            <button className="prim-btn" type="submit">
              Create
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default AddNoteModal
