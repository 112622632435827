import ReturnBreadCrumbs from 'components/breadCrumb/NewBreadCrumbs'
import { useLocation } from 'react-router-dom'
import availble from 'assets/Images/spotsAvailable.png'
import waitlist from 'assets/Images/waitlist.png'
import pending from 'assets/Images/pending.png'
import almost from 'assets/Images/almost.svg'
import limited from 'assets/Images/limited.svg'
import clock from 'assets/Images/Clock.svg'
import time from 'assets/Images/time-event.svg'
import location_img from 'assets/Images/location-event.svg'
import blue from 'assets/Images/more-blue.svg'
import { Avatar } from 'antd'
import {
  capitalizeFirstLetter,
  convertMinutesToLocalTime,
  convertToLocal,
} from 'helper/utility'
import ManageButton from 'components/Events/Views/CardView/SingleCard/ManageButton'

const ProfileHeader = ({event}) => {
  const location = useLocation()
  // const event: any = location.state
  const imgSrc =
    event?.seatsStatus === 'Pending Confirmation'
      ? pending
      : event?.seatsStatus === 'Limited seats left'
      ? limited
      : event?.seatsStatus === 'Almost Full'
      ? almost
      : event?.seatsStatus === 'Waitlist'
      ? waitlist
      : event?.seatsStatus === 'Spots Available'
      ? availble
      : ''
  const colors =
    event?.seatsStatus === 'Pending Confirmation'
      ? '#FEF4E8'
      : event?.seatsStatus === 'Limited seats left'
      ? '#E7F0F8'
      : event?.seatsStatus === 'Almost Full'
      ? '#E8E9F7'
      : event?.seatsStatus === 'Waitlist'
      ? '#FFE2E0'
      : event?.seatsStatus === 'Spots Available'
      ? '#F1F8E7'
      : ''

  const items = [
    {
      key: '1',
      label: (
        <div>
          {event?.datesOfEvent?.map((item: string) => {
            return (
              <div>
                {event?.timings.specialSchedules[0] ? (
                  event?.timings?.specialSchedules?.map((spec: any) => {
                    return (
                      <div>
                        {item === spec?.day && (
                          <div className="flex justify-between">
                            <h4 className="text-12 w-400 w-40p">
                              {convertToLocal(spec?.day, 'MMM DD, YYYY')}
                            </h4>
                            <div className="flex justify-between w-50p">
                              <div className="w-10p mr-10px">
                                <img
                                  src={clock}
                                  alt="time"
                                  className="w-25px"
                                />
                              </div>
                              <div className="w-80p text-start">
                                <h4 className="text-10 w-400">
                                  {convertMinutesToLocalTime(spec?.startTime)} -{' '}
                                  {convertMinutesToLocalTime(spec?.endTime)}
                                </h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  })
                ) : (
                  <div className="flex justify-between">
                    <h4 className="text-12 w-400 w-40p">
                      {convertToLocal(item, 'MMM DD, YYYY')}
                    </h4>
                    <div className="flex justify-between w-50p">
                      <div className="w-10p mr-10px">
                        <img src={clock} alt="time" className="w-25px" />
                      </div>
                      <div className="w-80p text-start">
                        <h4 className="text-10 w-400">
                          {convertMinutesToLocalTime(event?.timings?.startTime)}{' '}
                          - {convertMinutesToLocalTime(event?.timings?.endTime)}
                        </h4>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      ),
    },
  ]
  return (
    <div>
      <div className="flex justify-between">
        <ReturnBreadCrumbs
          links={[
            { name: 'Events', link: '/events' },
            { name: `${event?.name}`, link: `/events-details/${event?._id}` },
          ]}
        />
        <ManageButton
          id={event?._id}
          name={event?.name}
          btnName={'Manage Event'}
        />
      </div>

      <div className="w-80p">
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '40% 60%',
            gap: '50px',
          }}
        >
          <img
            src={event?.coverPhotos?.[0]?.post}
            alt="event-img"
            style={{ width: '100%', height: '300px', borderRadius: '15px' }}
          />
          <div className="flex justify-end flex-col">
            <div
              style={{ backgroundColor: colors, width: '250px' }}
              className="flex align-center radius-8 px-10px py-5px justify-center"
            >
              <Avatar src={imgSrc} alt="status" size={24}>
                Stat
              </Avatar>
              <h4 className="ml-10px text-12 w-600">{event?.seatsStatus}</h4>
            </div>
            <h2 className="text-40 my-20px">
              {capitalizeFirstLetter(event?.name)}
            </h2>
            <div className="flex  mt-20px">
              <div className="flex ">
                <div>
                  <img src={time} alt="" />
                </div>
                <div className="ml-10px">
                  <h4 className="text-12 grey-txt">Time</h4>
                  <h4 className="text-14 w-500">
                    Starts on {convertToLocal(event?.startDate, 'MMM D,YYYY')}
                  </h4>

                  <h4 className="text-14 w-500">
                    {convertMinutesToLocalTime(event?.timings?.startTime)}-
                    {convertMinutesToLocalTime(event?.timings?.endTime)}
                  </h4>
                  <div className="flex justify-start pointer">
                    <h4 className="text-14 w-500 prim-txt">More Options</h4>
                    <img src={blue} alt="more-blue" className="ml-10px" />
                  </div>
                </div>
              </div>
              <div className="flex ml-30px">
                <div>
                  <img src={location_img} alt="location" />
                </div>
                <div className="ml-10px">
                  <h4 className="text-12 grey-txt">Location</h4>
                  <div>
                    {event?.location ||
                    event?.location?.title ||
                    event?.location?.street ||
                    event?.state ? (
                      <div>
                        <h4 className="text-14 w-500">
                          {`${
                            event?.location?.title !== undefined
                              ? event?.location?.title
                              : ''
                          }`}
                        </h4>
                        <h4 className="text-14 w-500">
                          {`${
                            event?.location?.street !== undefined
                              ? event?.location?.street
                              : ''
                          }  `}
                        </h4>
                        <h4 className="text-14 w-500">{` ${
                          event?.state !== undefined ? event?.state : ''
                        }`}</h4>
                      </div>
                    ) : event?.venueType == 'to-be-determined' ? (
                      <h4 className="text-14 w-500">To Be Determined</h4>
                    ) : (
                      <h4 className="text-14 w-500">Online Event</h4>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProfileHeader
