import React, { useState } from "react";
import Home from ".";
import Address from "./Address";

const Location = ({ setMapModalVisible, setMapModalData, mapData, setLoactionVisible }: any) => {
  const [current, setCurrent] = React.useState(0);
  const [data, setData] = useState(mapData || null);
console.log('setLoactionVisible:',setLoactionVisible)
  const next = (childData: any) => {
    console.log('childData:',childData)
    setCurrent(current + 1);
    setData({
      ...data,
      ...childData,
    });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onSubmit = (formData: any) => {
    setMapModalData(formData);
    setMapModalVisible(false);
  };
  return (
    <div>
        {/* {current === 0 &&  */}
        <Home setLoactionVisible={setLoactionVisible} next={next} mapData={data} />
        {/* } */}
        {/* {current === 1 && (
          <Address prev={prev} mapData={data} onSubmit={onSubmit} />
        )} */}
    </div>
  );
};

export default Location;
