import React, { useEffect, useState } from 'react'
import { useGetOrgInventoryList } from 'api/EventCreation/CreationStep2'
import { creationEventStore } from 'state/eventCreationStore'
import Loader from 'helper/Loader'
import { Plus } from '@phosphor-icons/react'
import { Empty } from 'antd'
import SupplyModal from './Modals/SupplyModal'
import './style/styles.scss'
import { InventoryResponse } from 'types/events'

interface Step3Props {
  handleBack: () => void
  handleNext: () => void
}

const Step3: React.FC<Step3Props> = ({ handleBack, handleNext }) => {
  const customActivity = creationEventStore(store => store.customActivity)
  const [openSupplyModal, setOpenSupplyModal] = useState<boolean>(false)
  return (
    <>
      <div>
        <div>
          <h3 className="text-20px w-600">Supplies</h3>
        </div>
        <div className="main-inventory-container">
          {customActivity.supplies.length === 0 ? (
            <div className="no-supplies-height flex flex-col align-center justify-center">
              <Empty />
              <h3>No supplies found</h3>
            </div>
          ) : (
            <div className="h-15 overflow-auto-y overflow-hidden-x scrooler">
              {customActivity.supplies.map((supply: any) => {
                return (
                  <div key={supply._id}>
                    <p>{supply.name}</p>
                  </div>
                )
              })}
            </div>
          )}
          <div
            className="flex justify-center mb-10px"
            onClick={() => setOpenSupplyModal(true)}
          >
            <button className="big-btn gap-10px">
              <Plus size={18} color="#2ba7ca" />
              Add Supply
            </button>
          </div>
        </div>
        <div className="mt-10px flex align-center justify-between">
          <button className="create-activity-btn" onClick={handleBack}>
            Cancel
          </button>
          <button className="confirm-btn" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
      {openSupplyModal && (
        <SupplyModal
          modalOpen={openSupplyModal}
          setModalOpen={setOpenSupplyModal}
        />
      )}
    </>
  )
}

export default Step3
