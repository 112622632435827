import React, { useEffect, useState } from "react";
import { Button, Card, Col, Input, Row, Slider, InputNumber } from "antd";
import { connect, useDispatch } from "react-redux";
import "antd-country-phone-input/dist/index.css";
import "antd/dist/antd.css";
import "antd-country-phone-input/dist/index.css";
import backarrow from "../../../../../../assets/Images/backarrow.svg";
import darkBluePencil from "../../../../../../assets/Images/darkBluePencil.svg";
import TextArea from "antd/lib/input/TextArea";
import FeeModal from "./SupplyModal/FeeModal";
import actions from "../../../../../../Redux/Events/action";
const Supplies = ({
                    handleNext,
                    handleBack,
                    data,
                    eventsDataValue,
                    getOrganizationEvents,
                    eventOrganizationData,
                  }: any) => {
  const dispatch = useDispatch();
  const [FeeModalVisible, setFeeModalVisible] = useState(false);
  const [FeeData, setFeeData] = useState<any>([]);
  const [inputValue, setInputValue] = useState(
      eventOrganizationData?.numberOfStaff || 1
  );

  useEffect(() => {
    if (eventsDataValue?._id) getOrganizationEvents(eventsDataValue?._id);
  }, [eventsDataValue]);
  const onChange = (newValue: any) => {
    setInputValue(newValue);
  };

  const handleFinish = () => {
    // console.log("values data", inputValue, FeeData);

    const dataValues = {
      eventId: eventsDataValue._id,
      // eventId: "635f70e1936ea3f3125a2b8c",
      numberOfStaff: inputValue,
      unitStaffSalary: 0,
      additionalFee:
          FeeData &&
          FeeData.map((item: any) => {
            return {
              name: item.feeName,
              active: true,
              price: item?.price ? item.price : 0,
              minPrice: item.priceRange ? item.priceRange[0] : 0,
              maxPrice: item.priceRange ? item.priceRange[1] : 0,
            };
          }),
    };

    dispatch({ type: actions.ADD_EVENTS_DATA_STEP_THREE, payload: dataValues });
    handleNext();
    // console.log("dataValues:", dataValues);
  };

  return (
      console.log("FeeData:", FeeData),
          (
              <>
                <div>
                  <div className="supplies-heading-block">
                    <h1>Step 3. Supplies & Cost</h1>
                    <div className="mt-20">
                      <label className="text-black">
                        Based on your event details we recommend the number of stuff
                      </label>
                    </div>
                  </div>
                  <Row className="mt-20 mb-26" gutter={{ xl: 60, sm: 15 }}>
                    <Col lg={12} span={24}>
                      <div className="mb-40">
                        <div className="d-flex justify-between mt-20 align-items-center">
                          <label className="font12 b1">Number of staff</label>
                          <InputNumber
                              type={"number"}
                              className="text-center br-8 number-of-staff custom-select-field"
                              style={{ width: "64px", height: "38px" }}
                              min={1}
                              max={20}
                              value={inputValue}
                              onChange={onChange}
                          />
                        </div>
                        <div className="mt-20 number-of-staff-slide mb-40">
                          <Slider
                              min={0}
                              max={50}
                              onChange={onChange}
                              value={typeof inputValue === "number" ? inputValue : 0}
                              defaultValue={25}
                          />
                        </div>
                        {/* <div className="mt-20">
              <Slider defaultValue={25} />
            </div> */}
                        <Row className="mb-20">
                          <div className="d-flex justify-between w-100">
                            <label className="font14 b1">
                              Estimated salary for each person
                            </label>
                            <label
                                className="font16 b1"
                                style={{ fontWeight: "600", fontSize: "12px" }}
                            >
                              $100
                            </label>
                          </div>
                        </Row>
                        <Row>
                          <Button
                              className="br-8 b2 font15 text-black large_add_button max-w-100"
                              onClick={() => {
                                setFeeModalVisible(true);
                              }}
                          >
                            + Add more fees
                          </Button>
                        </Row>
                        <label className="mt-20 font13 b1">Estimated Summary</label>
                        <div className="mt-20">
                          <div className="justify-between">
                            <div className="estimated-summ-container">
                              <span>Staff (x7)</span>
                              <span>$700.00 - $740.00</span>
                            </div>
                            <div className="estimated-summ-container">
                              <span>Venue</span>
                              <span>$10.00 - $20.00</span>
                            </div>
                            <div className="estimated-summ-container">
                              <span>Activity 1</span>
                              <span>$10.00 - $20.00</span>
                            </div>
                            <div className="estimated-summ-container">
                              <span>Activity 2</span>
                              <span>$10.00 - $20.00</span>
                            </div>
                            {eventOrganizationData?.additionalFee &&
                                eventOrganizationData?.additionalFee.map(
                                    (item: any): any => (
                                        <div
                                            className="estimated-summ-container b1"
                                            style={{ color: "#3274B6" }}
                                        >
                            <span className="b1">
                              {item?.name}{" "}
                              <img src={darkBluePencil} alt="Edit icon" />
                            </span>
                                          <span className="b1">
                              {item?.minPrice
                                  ? "$" +
                                  item?.minPrice +
                                  " - " +
                                  "$" +
                                  item?.maxPrice
                                  : "$" + item?.maxPrice}
                            </span>
                                        </div>
                                    )
                                )}
                            {FeeData &&
                                FeeData.map((item: any): any => (
                                    <div
                                        className="estimated-summ-container b1"
                                        style={{ color: "#3274B6" }}
                                    >
                          <span className="b1">
                            {item?.feeName}{" "}
                            <img src={darkBluePencil} alt="Edit icon" />
                          </span>
                                      <span className="b1">
                            {item?.price
                                ? "$" + item?.price
                                : "$" +
                                item?.priceRange[0] +
                                " - " +
                                "$" +
                                item?.priceRange[1]}
                          </span>
                                    </div>
                                ))}
                          </div>
                          <div className="d-flex justify-between">
                            <label
                                className="font16 b1"
                                style={{ fontWeight: "600", fontSize: "12px" }}
                            >
                              Estimated Total
                            </label>
                            <label
                                className="font16 b1"
                                style={{ fontWeight: "600", fontSize: "12px" }}
                            >
                              $730.00 - $840.00
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={12} span={24}>
                      <Card
                          style={{ borderColor: "#8CC73F", borderRadius: "10px" }}
                          className="text-center mb-40"
                      >
                        <div className="font13 mb-16">
                          <span>Estimated average ticket price to break even</span>
                        </div>
                        <div>
                  <span
                      style={{
                        fontSize: "24px",
                        fontWeight: "500",
                        color: "#000",
                        lineHeight: "30px",
                      }}
                  >
                    $15.00-$25.00
                  </span>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <Row
                      justify="space-between"
                      align="middle"
                      className="mb-26 event-step-cont-btn"
                  >
                    <h5 className="stepperBackh5" onClick={() => handleBack(data)}>
              <span className="mr-10 d-inline-block">
                <img src={backarrow} />
              </span>
                      Back
                    </h5>
                    <Button className="stepper-button" onClick={() => handleFinish()}>
                      Next
                    </Button>
                  </Row>
                </div>
                {FeeModalVisible && (
                    <FeeModal
                        setFeeModalVisible={setFeeModalVisible}
                        FeeData={FeeData}
                        setFeeData={setFeeData}
                    />
                )}
              </>
          )
  );
};
const mapStateToProps = (state: any) => ({
  eventCategoryData: state.events.eventCategoryData,
  eventsDataValue: state.events.eventsDataValue,
  eventOrganizationData: state.events.eventOrganizationData,
});
export default connect(mapStateToProps)(Supplies);
