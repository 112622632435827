import React from 'react'
import { Col, Form, Input, Modal, Row } from 'antd'

interface AddItemsModal {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  items?: string[]
  setItems?: React.Dispatch<React.SetStateAction<string[]>>
  selectedIndex: { index: number; item: string }
}

const AddItemsModal: React.FC<AddItemsModal> = ({
  openModal,
  closeModal,
  items,
  setItems,
  selectedIndex,
}) => {
  const [form] = Form.useForm()

  const formSchema = {
    item: selectedIndex ? selectedIndex.item : '',
  }

  const handleCancel = () => {
    closeModal(false)
  }

  const onFinish = (values: { item: string }) => {
    if (selectedIndex) {
      items[selectedIndex.index] = values.item
      setItems(items)
    } else {
      setItems([...items, values.item])
    }
    // setItems([...items, values.item])
    handleCancel()
  }

  return (
    <div>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        className="items-t0-bring"
        footer={false}
        centered
      >
        <div className="flex-center pt-20px center">
          <h2 className="w-600 text-18">Add item to bring</h2>
        </div>
        <div className="mt-20px">
          <Form
            form={form}
            layout="vertical"
            initialValues={formSchema}
            onFinish={onFinish}
          >
            <Row gutter={10}>
              <Col>
                <label className="input-label required-input">
                  Item’s name
                </label>
                <Form.Item
                  className="form-control"
                  name="item"
                  rules={[{ required: true, message: 'Item is required' }]}
                >
                  <Input
                    placeholder="Enter item’s name"
                    className="form-control-field"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="mt-10px flex align-center justify-center gap-16px">
              <button className="btn-secondary" onClick={handleCancel}>
                Cancel
              </button>
              <button className="confirm-btn" type="submit">
                Save
              </button>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default AddItemsModal
