import React, { useState, useEffect } from 'react'
import { Button, Card, Row, Col, Dropdown, Menu, Space, Modal } from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'
import { EditOutlined } from '@ant-design/icons'
import CreateVenueModal from './CreateVenueModal'
import AlertModal from '../../../../../../../helper/AlertModal'
import Actionicon from '../../../../../../../assets/Images/Action.svg'
import deleteIcon from '../../../../../../../assets/Images/delete_red.svg'
import RouteModal from '../../Modal/RouteModal/RouteModal'
import {
  useDeleteVenue,
  useGetOrgVenues,
} from 'api/EventCreation/EventCreation'
import {
  OrgVenuesParams,
  OrganizationVenues,
  Amenity,
} from 'types/organization'
import Loader from 'helper/Loader'
import { useUpdateVenue } from 'api/EventCreation/EventCreation'

const VenueModal = ({
  organizationId,
  setVenueModalVisible,
  setMapModalData,
  mapData,
  setFieldsValue,
  setVenueDataItem,
}: any) => {
  const [current, setCurrent] = React.useState(0)
  const [alertModal, setAlertModal] = useState(false)
  const [data, setData] = useState(mapData || null)
  const [params, setParams] = useState<OrgVenuesParams>({
    organizationId: organizationId,
    longitude: mapData?.coordinates?.longitude,
    latitude: mapData?.coordinates?.latitude,
  })
  const [dataItem, setDataItem] = useState<any>()
  const [currentData, setCurrentData] = useState<any>([])
  const [createVenueModalVisible, setCreateVenueModalVisible] = useState(false)
  const [routeModalVisible, setRouteModalVisible] = useState(false)
  const { mutate: deleteVenue } = useDeleteVenue()
  const alertModalData = {
    name: 'venue',
    heading: 'Delete the venue',
    message1: `Are you sure you want to delete the venue?`,
  }
  const {
    data: organizationVenues,
    isLoading,
    isFetching,
    refetch,
  } = useGetOrgVenues(params)

  useEffect(() => {
    refetch()
  }, [])

  const onYesClick = () => {
    deleteVenue(
      { venueId: dataItem._id },
      {
        onSuccess: () => {
          setAlertModal(false)
        },
      },
    )
  }

  const items = [
    {
      key: 'edit',
      label: (
        <p>
          <EditOutlined /> Edit venue
        </p>
      ),
    },
    {
      key: 'delete',
      label: (
        <p className="text-color-red">
          <img src={deleteIcon} /> Delete venue
        </p>
      ),
    },
  ]

  const handleDropdownItemClick = (e: MenuInfo, data: OrganizationVenues) => {
    if (e.key === 'edit') {
      setDataItem(data)
      setCreateVenueModalVisible(true)
    }

    if (e.key === 'delete') {
      setAlertModal(true)
      setDataItem(data)
    }
  }

  const next = (childData: any) => {
    setCurrent(current + 1)
    setData({
      ...data,
      ...childData,
    })
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const onSubmit = (formData: any) => {
    setFieldsValue(formData)
    setMapModalData(formData)
    setVenueModalVisible(false)
  }
  return (
    <div>
      <Modal
        open={true}
        className="map-modal"
        footer={null}
        centered={true}
        width={600}
        onCancel={() => {
          setVenueModalVisible(false)
        }}
      >
        <h2 className="mb-20" style={{ paddingTop: 20 }}>
          Venues
        </h2>
        {isLoading || isFetching ? (
          <Loader />
        ) : organizationVenues.length > 0 ? (
          <div className="modal-h-element add-activity-modal-h">
            {organizationVenues.map((item: OrganizationVenues) => (
              <Card className="venue-card">
                <Space
                  direction="vertical"
                  size={'small'}
                  style={{ width: '100%' }}
                >
                  <div style={{ margin: '10px' }}>
                    <Space
                      direction="vertical"
                      size={'small'}
                      style={{ width: '100%' }}
                    >
                      <Row className="d-flex justify-between">
                        <h3>{item.name}</h3>
                        <Dropdown
                          // overlay={Actionmenu(item)}
                          menu={{
                            items: items,
                            onClick: e => handleDropdownItemClick(e, item),
                          }}
                          placement="bottom"
                          className="venue-more-action"
                        >
                          <Button>
                            <img alt="img" src={Actionicon} />
                          </Button>
                        </Dropdown>
                      </Row>
                      <Row>
                        {' '}
                        <p className="venue_cost_text">
                          $
                          {`${
                            item?.priceRange
                              ? `${item?.priceRange?.min} - $${item?.priceRange?.max}`
                              : item?.price
                          }`}
                          {item.pricePerPersonEnabled && ' / per person'}
                        </p>
                      </Row>
                      <div className="flex-center flex-wrap gap-16px">
                        {item.amenities.map((amentiy: Amenity) => (
                          <div
                            key={amentiy._id}
                            className="flex-center gap-10px"
                          >
                            <img
                              src={amentiy?.icon?.post}
                              width={24}
                              height={24}
                              alt={amentiy.name}
                            />
                            <p>{amentiy.name}</p>
                          </div>
                        ))}
                      </div>
                    </Space>
                  </div>
                  <Row>
                    <Col className="d-flex">
                      <Button
                        className="route_button-events br-8"
                        onClick={() => {
                          setRouteModalVisible(true)
                          // setCurrentData(events);
                        }}
                      >
                        Route
                      </Button>
                      <Button
                        className="venue_button-events br-8"
                        onClick={() => {
                          setVenueModalVisible(false)
                          setVenueDataItem(item)
                        }}
                      >
                        Select Venue
                      </Button>
                    </Col>
                  </Row>
                </Space>
              </Card>
            ))}
          </div>
        ) : (
          <h3 className="pt-30 pb-30 text-center">No Venue is available</h3>
        )}
        <Button
          className="venue-primary-transparent br-8"
          style={{ width: '100%' }}
          onClick={() => {
            setCreateVenueModalVisible(true)
            setDataItem(null)
          }}
        >
          Add new venue
        </Button>
        {alertModal && (
          <AlertModal
            isModalVisible={alertModal}
            setIsModalVisible={setAlertModal}
            data={alertModalData}
            dataItem={dataItem}
            onYesClick={onYesClick}
          />
        )}

        {createVenueModalVisible && (
          <CreateVenueModal
            dataItem={dataItem}
            mapData={mapData}
            organizationId={organizationId}
            setCreateVenueModalVisible={setCreateVenueModalVisible}
            setFieldsValue={setFieldsValue}
          />
        )}

        {routeModalVisible && (
          <RouteModal
            mapData={mapData}
            setRouteModalVisible={setRouteModalVisible}
            setMapModalData={setMapModalData}
            setFieldsValue={setFieldsValue}
          />
        )}
      </Modal>
    </div>
  )
}

export default VenueModal
