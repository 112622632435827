import React, { useState } from 'react'
import { Modal, Form, Row, Col, Input } from 'antd'
import closeIcon from 'assets/Images/close.svg'
import { AddFaqPayload, AddOrgDocsPayload } from 'types/eventStep5'
import { creationEventStore } from 'state/eventCreationStore'
import UploadDocument from 'components/global/UploadDocument'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { useAddOrgDocument } from 'api/EventCreation/creationStep5'
import { infoMessage } from 'api/global'
interface AddNewDocProps {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
}

const AddNewDoc: React.FC<AddNewDocProps> = ({ openModal, closeModal }) => {
  const [form] = Form.useForm()
  const [documentIds, setDocumentIds] = useState<string[]>([])
  const { _id, organizationId } = creationEventStore(store => store.eventData)
  const { mutate: orgDocMutate, isLoading: orgDocLoading } = useAddOrgDocument()

  const formSchema = {}
  const handleCancel = () => {
    closeModal(false)
  }

  const onFinish = (values: AddOrgDocsPayload) => {
    if (documentIds.length === 0) {
      return infoMessage('Document must be added')
    }
    const payload = {
      ...values,
      organizationId,
      fileId: documentIds[0],
    }
    orgDocMutate(payload, {
      onSuccess: data => {
        handleCancel()
      },
    })
  }
  return (
    <div>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        className="items-add-question"
        footer={false}
        width={600}
        maskClosable={false}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Add document</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="mt-20px">
          <Form
            form={form}
            layout="vertical"
            initialValues={formSchema}
            onFinish={onFinish}
          >
            <Row gutter={10}>
              <Col>
                <label className="input-label required-input">Name</label>
                <Form.Item
                  className="form-control"
                  name="name"
                  rules={[{ required: true, message: 'Title is required' }]}
                >
                  <Input
                    className="question-field"
                    placeholder="Enter name of document"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col>
                <label className="input-label required-input">
                  Description
                </label>
                <Form.Item
                  className="form-control"
                  name="description"
                  rules={[
                    { required: true, message: 'Description is required' },
                  ]}
                >
                  <Input.TextArea
                    className="answer-field"
                    placeholder="Enter short description"
                    autoSize={{ minRows: 5, maxRows: 8 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="relative">
              <label className="input-label required-input">
                Upload document
              </label>
              <UploadDocument
                documentIds={documentIds}
                setDocumentIds={setDocumentIds}
                multiple={false}
                docType=".pdf,.doc,.docx,.txt"
              />
            </div>
            <Row className="mt-10px justify-end">
              <button className="confirm-btn" type="submit">
                {orgDocLoading ? (
                  <ButtonSpinner size={20} color="#000" />
                ) : (
                  'Save'
                )}
              </button>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default AddNewDoc
