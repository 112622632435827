import React from 'react'
import './styles/component-styles.scss'
import { DotsThree } from '@phosphor-icons/react'
import { DiscountResp } from 'types/eventStep4'

const Discounts: React.FC<any> = ({ data }) => {
  return (
    <div className="discount-cont mt-20px">
      <div className="discount-header flex gap-10px">
        <h3 className="text-13 w-600 heading">Discount</h3>
        <h3 className="text-13 w-600 heading">Code</h3>
        <h3 className="text-13 w-600 heading">Used</h3>
        <h3 className="text-13 w-600 heading">Limit</h3>
      </div>
      <div>
        {data.map((discount: DiscountResp) => {
          return (
            <div className="flex gap-10px discount-body">
              <p className="body-content">{discount.code}</p>
              <p className="body-content">{`${discount.discount}${
                discount.type === 'percent' ? '%' : '$'
              }`}</p>
              <p className="body-content">{discount.userIds.length}</p>
              <p className="body-content">
                {discount?.totalLimit ? discount.totalLimit : 'no limit'}
              </p>
              <p className="body-content-edit w-600 pointer">Edit</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Discounts
