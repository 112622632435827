import React, { useState } from 'react'
import IconsTextNavigation from 'components/global/IconsTextNavigation'
import FaqModal from '../../Modals/FAQ/FaqModal'
import { creationEventStore } from 'state/eventCreationStore'

const FAQ: React.FC = () => {
  const [faqModalOpne, setFaqModalOpen] = useState<boolean>(false)
  // todo replace with type
  const payload: any = creationEventStore(store => store.step5Payload)
  return (
    <>
      <div onClick={() => setFaqModalOpen(true)}>
        <IconsTextNavigation
          iconName="Question"
          heading="FAQ"
          details={payload?.faqIds ? payload.faqIds.length : 'No questions'}
        />
      </div>
      {faqModalOpne && (
        <FaqModal openModal={faqModalOpne} closeMdoal={setFaqModalOpen} />
      )}
    </>
  )
}

export default FAQ
