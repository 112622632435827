import React from 'react'
import { Button } from 'antd'
import { CaretLeft } from '@phosphor-icons/react'
import './schedule-activities.scss'
import WeatherCard from './essential-components/WeatherCard'
import MonthlyCalendar from './essential-components/MonthlyCalendar'
import ActivityCalendar from './essential-components/ActivityCalendar'
import { creationEventStore } from 'state/eventCreationStore'

interface ScheduleAndActivitiesProps {
  handleNext: (data: any) => void
  handleBack: () => void
}

const ScheduleAndActivities: React.FC<ScheduleAndActivitiesProps> = ({
  handleNext,
  handleBack,
}) => {
  const eventData = creationEventStore(store => store.eventData)
  const { venueType } = creationEventStore(store => store.eventData)
  return (
    <div className="step2-main-container">
      <div>
        <h3 className="text-24 w-600"> Step 2. Schedule and activities</h3>
        {venueType === 'physical' && (
          <div className="mt-20px">
            <WeatherCard />
          </div>
        )}
        <div
          className={`calendar-schedule-container ${
            venueType === 'physical' ? 'mt-20px' : 'mt-40px'
          }`}
        >
          <div className="calendar-cont">
            <MonthlyCalendar />
          </div>
          <div className="activities-cont">
            <ActivityCalendar />
          </div>
        </div>
      </div>

      <div className="mt-15px event-button-cont">
        <h5 className="flex gap-10px align-center" onClick={() => handleBack()}>
          <CaretLeft size={20} color="#000" />
          <span className="text-15 w-500">Back</span>
        </h5>
        <Button
          className="stepper-button"
          onClick={() => handleNext(eventData)}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

export default ScheduleAndActivities
