import React, { useState } from 'react'
import { Checkbox } from 'antd'
import { EventFaqs, OrgFaqs } from 'types/eventStep5'
import { CaretDown } from '@phosphor-icons/react'
import '../../styles/additional-settings.scss'

interface FaqContainerProps {
  data: OrgFaqs | EventFaqs
  questions: string[]
  setQuestion: React.Dispatch<React.SetStateAction<string[]>>
}

const FaqContainer: React.FC<FaqContainerProps> = ({
  data,
  questions,
  setQuestion,
}) => {
  const [active, setActive] = useState<boolean>(false)

  const hanldeOnChange = (questionData: OrgFaqs | EventFaqs) => {
    if (!questions.includes(questionData._id)) {
      setQuestion([...questions, questionData._id])
    } else {
      setQuestion(questions.filter(item => item !== questionData._id))
    }
  }

  const hanldeOpenAnswer = () => {
    setActive(prev => !prev)
  }

  return (
    <div className="my-15px">
      <div
        className={`flex align-start gap-16px faq-container ${
          active && 'active'
        }`}
      >
        <Checkbox
          checked={questions.includes(data._id)}
          onChange={event => hanldeOnChange(data)}
        />
        <div className="w-full" style={{ marginTop: '3px' }}>
          <div className="question" onClick={hanldeOpenAnswer}>
            <div className="flex align-center">
              <h4 className="text-14 w-600">{data.question}</h4>
            </div>
            <div>
              <CaretDown size={20} color="#000" className="question-carret" />
            </div>
          </div>
          <div className="answer">
            <p>{data.answer}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FaqContainer
