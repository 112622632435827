/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Col, Row, Space, Dropdown, Button, Menu } from 'antd'
import phone from '../../../../../assets/Images/Phone.svg'
import envelopSimple from '../../../../../assets/Images/EnvelopeSimple.svg'
import location from '../../../../../assets/Images/MapPin.png'
import warning from '../../../../../assets/Images/Warning.png'
import { useEffect, useState } from 'react'
import { guardianProfile } from '../../../../../types/family'
import ChangeEmail from '../../../../../components/modals/changeEmail/changeEmail'
import { useMutation } from '@tanstack/react-query'
import { updateGuardianProfile } from '../../../../../api/family'
import ChangePhone from '../../../../../components/modals/changePhone/changePhone'
import MapModal from '../../../../../helper/Map/MapModal'
interface guardianInfo {
  data: guardianProfile
  refetchData: () => any
}
const GuardianInfo = ({ data, refetchData }: guardianInfo) => {
  const [modal, setModal] = useState<Number | null | false>(null)
  const [mapModalVisible, setMapModalVisible] = useState(false)
  const [mapData, setMapModalData] = useState({
    address: '',
  })
  const menu = (
    <Menu>
      <Menu.Item
        key="email"
        onClick={() => {
          setModal(1)
        }}
      >
        <Space>
          <img alt="img" src={envelopSimple}></img> Change email
        </Space>
      </Menu.Item>
      <Menu.Item
        key="address"
        onClick={() => {
          setMapModalVisible(true)
        }}
      >
        <Space>
          <img alt="img" src={location}></img> Edit address
        </Space>
      </Menu.Item>
      <Menu.Item
        key="phone"
        onClick={() => {
          setModal(3)
        }}
      >
        <Space>
          <img alt="img" src={phone}></img> Edit phone number
        </Space>
      </Menu.Item>
      <Menu.Item key="block">
        <Space className="text-color-red">
          <img alt="img" src={warning}></img> Block email
        </Space>
      </Menu.Item>
    </Menu>
  )
  const changeMutation = useMutation({
    mutationFn: async ({ id, data }: any) => {
      return await updateGuardianProfile(id, data)
    },
  })
  const updateGuardianData = (arg: { [key: string]: string | {} }) => {
    changeMutation.mutate({ id: data?.id, data: arg })
    refetchData()
  }
  useEffect(() => {
    mapData.address !== '' ? updateGuardianData({ address: mapData }) : <></>
  }, [mapData])
  return (
    <Col span={12}>
      <div className="profile_container">
        <div className="flex justify-between align-center">
          <h2 className="b1 h6">Contact information</h2>
          <Dropdown overlay={menu} className="status_dropdown">
            <Button
              type="primary"
              className="actionButton actionButton-org-profile"
              shape="round"
            >
              Manage
            </Button>
          </Dropdown>
        </div>

        <Row className="pt-40px">
          <Col>
            <div className="contact-information">
              <Row gutter={[5, 30]}>
                <Col>
                  <Row gutter={[12, 20]}>
                    <Col>
                      <Space size={12}>
                        <img alt="alt" src={phone}></img>
                        <h4 className="b3">{data?.phone?.number}</h4>
                      </Space>
                    </Col>
                    <Col>
                      <Space size={12}>
                        <img alt="alt" src={envelopSimple}></img>
                        <h4 className="b3"> {data?.email}</h4>
                      </Space>
                    </Col>
                    <Col>
                      <Space size={12}>
                        {data?.address && (
                          <>
                            <Avatar src={location} size={28} />
                            <h4 className="b3">
                              {data.address.apartment} {data.address.street}{' '}
                              {data.address.state}
                            </h4>
                          </>
                        )}
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      {modal === 1 && (
        <ChangeEmail
          setModal={setModal}
          prevEmail={data?.email}
          changeEmail={updateGuardianData}
        />
      )}
      {modal === 3 && (
        <ChangePhone
          setModal={setModal}
          prevPhone={data?.phone?.number}
          changePhone={updateGuardianData}
        />
      )}
      {mapModalVisible && (
        <MapModal
          setMapModalVisible={setMapModalVisible}
          setMapModalData={setMapModalData}
        />
      )}{' '}
    </Col>
  )
}
export default GuardianInfo
