import React from 'react'
import { Menu } from 'antd'
import {
  Eye,
  Pencil,
  ArrowsClockwise,
  Clock,
  Trash,
} from '@phosphor-icons/react'

const ActivityMenu = ({ handleMenuClick }) => {
  const onClick = e => {
    handleMenuClick(e.key)
  }

  return (
    <Menu onClick={onClick}>
      <Menu.Item key="view">
        <div className="flex align-center gap-20px">
          <Eye size={18} color="#000" />
          <p>View activity</p>
        </div>
      </Menu.Item>
      <Menu.Item key="edit-activity">
        <div className="flex align-center gap-20px">
          <Pencil size={18} color="#000" />
          <p>Edit activity</p>
        </div>
      </Menu.Item>
      <Menu.Item key="overlapping">
        <div className="flex align-center gap-20px">
          <ArrowsClockwise size={18} color="#000" />
          <p>Add overlaping activity</p>
        </div>
      </Menu.Item>
      <Menu.Item key="edit-time">
        {' '}
        <div className="flex align-center gap-20px">
          <Clock size={18} color="#000" />
          <p>Edit time</p>
        </div>
      </Menu.Item>
      <Menu.Item key="delete-activity">
        {' '}
        <div className="flex align-center gap-20px">
          <Trash size={18} color="#FF6961" />
          <p className="danger-txt">Delete activity</p>
        </div>
      </Menu.Item>
    </Menu>
  )
}

export default ActivityMenu
