import { Avatar, Col, Row, Space, Dropdown, Button, Menu } from 'antd'
import envelopSimple from 'assets/Images/EnvelopeSimple.svg'
import location from 'assets/Images/MapPin.png'
import warning from 'assets/Images/Warning.png'
import { useEffect, useState } from 'react'
import phone from 'assets/Images/Phone.svg'
const OrganizerContactInfo = ({ data, refetchData }: any) => {
  const [modal, setModal] = useState<Number | null | false>(null)
  const menu = (
    <Menu>
      <Menu.Item
        key="email"
        onClick={() => {
          setModal(1)
        }}
      >
        <Space>
          <img alt="img" src={envelopSimple}></img> Change email
        </Space>
      </Menu.Item>

      <Menu.Item key="block">
        <Space className="text-color-red">
          <img alt="img" src={warning}></img> Block email
        </Space>
      </Menu.Item>
    </Menu>
  )
  return (
    <Col span={12}>
      <div className="profile_container">
        <div className="flex justify-between align-center">
          <h2 className="b1 h6">Contact information</h2>
          <Dropdown overlay={menu} className="status_dropdown">
            <Button
              type="primary"
              className="actionButton actionButton-org-profile"
              shape="round"
            >
              Manage
            </Button>
          </Dropdown>
        </div>

        <Row className="pt-40px">
          <Col>
            <div className="contact-information">
              <Row gutter={[5, 30]}>
                <Col>
                  <Row gutter={[12, 20]}>
                    <Col>
                      <Space size={12}>
                        {data?.phone?.number && (
                          <>
                            <img alt="alt" src={phone}></img>
                            <h4 className="b3">{data?.phone?.number}</h4>
                          </>
                        )}
                      </Space>
                    </Col>
                    <Col>
                      <Space size={12}>
                        {data?.email && (
                          <>
                            <img alt="alt" src={envelopSimple}></img>
                            <h4 className="b3"> {data?.email}</h4>
                          </>
                        )}
                      </Space>
                    </Col>
                    <Col>
                      <Space size={12}>
                        {data?.address && (
                          <>
                            <Avatar src={location} size={28} />
                            <h4 className="b3">
                              {data.address.apartment} {data.address.street}{' '}
                              {data.address.state}
                            </h4>
                          </>
                        )}
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  )
}

export default OrganizerContactInfo
