import { axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  AdmissionTicketPayloadTypes,
  DiscountCreateProps,
  DiscountResp,
  TicketColors,
} from 'types/eventStep4'

// get ticket colors
export const getTicketColor = async () => {
  const response = await axiosGet(`v1/lists/ticket-colors`)
  return response.data
}

export const useGetTicketColors = () => {
  const ticketColors = useQuery<TicketColors[]>(
    ['ticket-colors'],
    getTicketColor,
    {
      enabled: false,
    },
  )
  return ticketColors
}

// patch request create admission ticket
export const createAdmissionTicket = async ({
  payload,
  eventId,
}: {
  payload: AdmissionTicketPayloadTypes
  eventId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/organization-events/${eventId}/event-step4-admission-ticket`,
  )
  return response.data
}

export const useCreateAdmissionTicket = () => {
  const queryClient = useQueryClient()
  return useMutation(createAdmissionTicket, {
    onSuccess: newTicket => {},
  })
}

// patch request create additional ticket
export const createAdditionalTicket = async ({
  payload,
  eventId,
}: {
  payload: AdmissionTicketPayloadTypes
  eventId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/organization-events/${eventId}/event-step4-additional-option`,
  )
  return response.data
}

export const useCreateAdditionalTicket = () => {
  return useMutation(createAdditionalTicket, {
    onSuccess: additionalTicket => {},
  })
}

// patch reqeust create dropin ticket
export const createDropinTicket = async ({
  payload,
  eventId,
}: {
  payload: any
  eventId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/organization-events/${eventId}/event-step4-dropin-ticket`,
  )
  return response.data
}

export const useCreateDropinTicket = () => {
  return useMutation(createDropinTicket, {
    onSuccess: dropinTicket => {},
  })
}

// post request create discount code
export const createDiscountCode = async ({
  payload,
  eventId,
}: {
  payload: DiscountCreateProps
  eventId: string
}) => {
  const response = await axiosPost(payload, `v1/discount/${eventId}`)
  return response.data
}

export const useCreateDiscountCode = () => {
  const queryClient = useQueryClient()
  return useMutation(createDiscountCode, {
    onSuccess: discount => {
      queryClient.setQueryData(
        ['event-discount-code'],
        (oldData: DiscountResp[] | undefined) => {
          return oldData ? [...oldData, discount] : [discount]
        },
      )
    },
  })
}

// get discount code for the event
export const getDiscountCode = async (eventId: string) => {
  const response = await axiosGet(`v1/discount/event/${eventId}`)
  return response.data
}

export const useGetDiscountCode = (eventId: string) => {
  const discountCodes = useQuery<DiscountResp[]>(
    ['event-discount-code'],
    () => getDiscountCode(eventId),
    { enabled: false },
  )

  return discountCodes
}
