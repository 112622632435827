import {
  Avatar,
  Col,
  Row,
  Space,
  Dropdown,
  Button,
  Menu,
  Typography,
  Carousel,
} from 'antd'
import { useEffect, useState, useRef } from 'react'
import envelopSimple from 'assets/Images/EnvelopeSimple.svg'
import location from 'assets/Images/MapPin.png'
import warning from 'assets/Images/Warning.png'
import phone from 'assets/Images/Phone.svg'
import { getNameSignature } from 'helper/utility'
import facebook from 'assets/Images/facebook.svg'
import insta from 'assets/Images/instagram.svg'
import tiktok from 'assets/Images/tiktok.svg'
import snapchat from 'assets/Images/snapIcon.svg'
import twitter from 'assets/Images/twitter.svg'
import linkedIn from 'assets/Images/linkedin.svg'
import youtube from 'assets/Images/youtube.svg'
import pencilLine from 'assets/Images/PencilLine.svg'
import icon from 'assets/Images/otherCategory.svg'
import moment from 'moment'
const OrganizationDetails = ({ data, refetchData }: any) => {
  let socialKeys: string[] | undefined
  let socialValues: string[] | undefined
  if (data && data.social) {
    socialKeys = Object.keys(data.social)
    socialValues = Object.values(data.social)
  }
  const socialIcons: any = {
    facebook: facebook,
    twitter: twitter,
    insta: insta,
    tiktok: tiktok,
    youtube: youtube,
    snapchat: snapchat,
    linkedIn: linkedIn,
  }
  const [modal, setModal] = useState<Number | null | false>(null)
  const [mapModalVisible, setMapModalVisible] = useState(false)
  const menu = (
    <Menu>
      <Menu.Item
        key="email"
        onClick={() => {
          setModal(1)
        }}
      >
        <Space>
          <img alt="img" src={envelopSimple}></img> Change email
        </Space>
      </Menu.Item>
      <Menu.Item
        key="address"
        onClick={() => {
          setMapModalVisible(true)
        }}
      >
        <Space>
          <img alt="img" src={location}></img> Edit address
        </Space>
      </Menu.Item>
      <Menu.Item
        key="phone"
        onClick={() => {
          setModal(3)
        }}
      >
        <Space>
          <img alt="img" src={phone}></img> Edit phone number
        </Space>
      </Menu.Item>
      <Menu.Item key="block">
        <Space className="text-color-red">
          <img alt="img" src={warning}></img> Block email
        </Space>
      </Menu.Item>
    </Menu>
  )
  return (
    <Col span={12}>
      <div className="profile_container">
        <div className="flex justify-between align-center">
          <h2 className="b1 h6">Contact information</h2>
          <Dropdown overlay={menu} className="status_dropdown">
            {/* previous code of 06 May 2024
            <Button
              type="primary"
              className="actionButton actionButton-org-profile"
              shape="round"
            >
              Manage
            </Button> */}
            <button className="bordered-prim-btn">Manage</button>
          </Dropdown>
        </div>

        <Row className="pt-20px">
          <Col>
            <div className="contact-information">
              <Row gutter={[5, 30]}>
                <Col>
                  <Row gutter={[12, 20]}>
                    <Col>
                      <Space size={12}>
                        {data?.phone && (
                          <>
                            <img alt="alt" src={phone}></img>
                            <h4 className="b3">{data?.phone?.number}</h4>
                          </>
                        )}
                      </Space>
                    </Col>
                    <Col>
                      <Space size={12}>
                        {data?.email && (
                          <>
                            <img alt="alt" src={envelopSimple}></img>
                            <h4 className="b3"> {data?.email}</h4>
                          </>
                        )}
                      </Space>
                    </Col>
                    <Col>
                      <Space size={12}>
                        {data?.address && (
                          <>
                            <Avatar src={location} size={28} />
                            <h4 className="b3">
                              {data.address.apartment} {data.address.street}{' '}
                              {data.address.state}
                            </h4>
                          </>
                        )}
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <div className="pt-40px flex justify-between align-center">
          <h2 className="b1 h6">Organization details</h2>
          <Dropdown overlay={menu} className="status_dropdown">
            {/* previous code of 06 May 2024
            <Button
              type="primary"
              className="actionButton actionButton-org-profile"
              shape="round"
            >
              Add Kid
            </Button> */}
            <button className="bordered-prim-btn">Manage</button>
          </Dropdown>
        </div>
        {data?.owner && (
          <Row gutter={[12, 20]} align="middle" className="mt-10px">
            <Col span={24}>
              <h5>
                <b className="b1">Admin</b>
              </h5>
            </Col>
            {data?.owner && (
              <Col span={24}>
                <Space size={12}>
                  <Avatar size={32} src={data?.owner?.photo?.post}>
                    {getNameSignature(data?.owner?.firstName)}
                  </Avatar>
                  <h4 className="mb-0">
                    <b className="b3"></b>
                    {data ? data.owner?.firstName : 'No Name Found'}
                  </h4>
                </Space>
              </Col>
            )}
          </Row>
        )}

        <Row gutter={[12, 20]} align="middle" className="mt-20px">
          <Col span={24}>
            <h5>
              <b className="b1">Social</b>
            </h5>
          </Col>
          <Col>
            <Space size={24}>
              {socialKeys?.map((item: string, index: number) => {
                return (
                  <a
                    key={index}
                    href={socialValues[index]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Avatar alt="alt" src={socialIcons[item]}>
                      <img src={icon} alt="othericon" />
                    </Avatar>
                  </a>
                )
              })}
            </Space>
          </Col>
        </Row>

        <Row>
          {data?.address && (
            <>
              <Col span={24} className="mt-20px">
                <h5>
                  <b className="b1">Location</b>
                </h5>
              </Col>
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                className="mt-10px"
              >
                <div>
                  <p>
                    {/* 5 July code 
                    {data
                      ? `${data.address?.street}, ${data.address?.apartment}, ${data.address?.city}, ${data.address?.country}`
                      : 'No address found'} */}
                    {data?.address?.title
                      ? `${data?.address?.title}`
                      : 'No title found'}
                  </p>
                </div>
                {/* <Button
              className="outlined-button br-8 register_child_button-events mr-0"
              style={{ marginLeft: '2rem' }}
              onClick={() => setMapModalVisible(true)}
            >
              Open Map
            </Button> */}
              </div>
            </>
          )}
        </Row>
        <Row>
          <Col span={24} className="mt-20px">
            <h5>
              <b className="b1">Year established</b>
            </h5>
          </Col>
          <div className="mt-10px">
            <p>{moment(data?.createdAt).format('YYYY')}</p>
          </div>
        </Row>

        {data?.about && (
          <Row>
            <Col span={24} className="mt-20px">
              <h5>
                <b className="b1">About</b>
              </h5>
            </Col>
            <div className="mt-10pxv b3 text-left font14">
              <p className="mt-10px">{data?.about}</p>
            </div>
          </Row>
        )}
      </div>
    </Col>
  )
}
export default OrganizationDetails
