import { create } from 'zustand'
import { StoredActivityType, inventoryStorageLocType } from 'types/events'
const eventCreationStore = (set: any) => ({
  // todo change it into the 0
  stepCounter: 4,
  setStepCounter: (state: number) => set(() => ({ stepCounter: state })),

  selectedDate: undefined,
  setSelectedDate: (state: Date) => set(() => ({ selectedDate: state })),

  activity: undefined,
  setActivity: (state: StoredActivityType) => set(() => ({ activity: state })),

  customActivity: undefined,
  setCustomActivity: (state: any) => set(() => ({ customActivity: state })),

  storageLocation: null,
  setStorageLocation: (state: inventoryStorageLocType) =>
    set(() => ({ storageLocation: state })),

  // todo change this to the null
  // eventData: null,
  eventData: {
    _id: '66b0b364d60d42c5c95fa1e6',
    name: 'new event test',
    category: {
      name: 'Camp',
      _id: '63286df7f0e6031bafcb6e80',
    },
    venueType: 'physical',
    locationId: '66a87efd4712bdca12a79e6a',
    venueId: '66a880254712bdca12a7a11d',
    datesOfEvent: [
      '2024-08-05T04:00:00.000Z',
      '2024-08-06T04:00:00.000Z',
      '2024-08-07T04:00:00.000Z',
      '2024-08-08T04:00:00.000Z',
      '2024-08-09T04:00:00.000Z',
      '2024-08-10T04:00:00.000Z',
      '2024-08-11T04:00:00.000Z',
      '2024-08-12T04:00:00.000Z',
      '2024-08-13T04:00:00.000Z',
      '2024-08-14T04:00:00.000Z',
      '2024-08-15T04:00:00.000Z',
      '2024-08-16T04:00:00.000Z',
      '2024-08-17T04:00:00.000Z',
      '2024-08-18T04:00:00.000Z',
      '2024-08-19T04:00:00.000Z',
      '2024-08-20T04:00:00.000Z',
      '2024-08-21T04:00:00.000Z',
      '2024-08-22T04:00:00.000Z',
      '2024-08-23T04:00:00.000Z',
      '2024-08-24T04:00:00.000Z',
    ],
    daysOfEvent: [
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
    ],
    timings: {
      startTime: 240,
      endTime: 600,
      specialSchedules: [],
    },
    coverPhotosIds: [],
    itemsToBring: [],
    requiredDocumentIds: [],
    faqIds: [],
    kidRequiredDetails: {
      profilePhoto: false,
      gender: false,
      dateOfBirth: false,
      school: false,
      grade: false,
      weight: false,
      height: false,
      allergies: false,
      medicalNotes: false,
      _id: '66b0b364d60d42c5c95fa1e5',
    },
    assignedMentorsIds: [],
    status: 'draft',
    isArchived: false,
    organizerIds: ['66a87ea74712bdca12a79e3f'],
    organizationId: '66a87efd4712bdca12a79e6b',
    inventoryIds: [],
    isGoogleCalendarEventCreated: false,
    activitiesSchedule: [],
    activitiesScheduleV2: [],
    emailTemplates: [],
    additionalFee: [],
    admissionTickets: [],
    additionalOptions: [],
    dropInTicket: {
      name: 'dropin',
      price: 10,
      priceUnit: 'day',
      color: '0affe0',
      _id: '66a87efd4712bdca12a79999',
      status: 'active',
    },
    createdAt: '2024-08-05T11:11:32.137Z',
    updatedAt: '2024-08-05T11:12:12.506Z',
    startDate: '2024-08-05T04:00:00.000Z',
    endDate: '2024-08-24T10:00:00.000Z',
    __v: 0,
    description: 'fsgfsgfdgfg',
    venue: {
      priceRange: {
        min: 200,
        max: 300,
      },
      location: {
        type: 'Point',
        coordinates: [71.46224800497293, 30.204392650399836],
      },
      _id: '66a880254712bdca12a7a11d',
      name: 'V',
      oneTimeCostEnabled: false,
      pricePerPersonEnabled: true,
      instruction: 'Test',
      amenities: [
        {
          name: 'Wheelchair accessible',
          icon: 'https://storage.googleapis.com/bamrec-f5107.appspot.com/images%2Famenity%2F6465b1b3ec681d10a09d12c0-original.png',
          _id: '61fbd92f5d55c71a7190e90c',
        },
        {
          name: 'Wifi',
          icon: 'https://storage.googleapis.com/bamrec-f5107.appspot.com/images%2Famenity%2F6465b1b7ec681d10a09d12d1-original.png',
          _id: '61fbd939b0debb30477de433',
        },
        {
          name: 'Screen',
          icon: 'https://storage.googleapis.com/bamrec-f5107.appspot.com/images%2Famenity%2F6465b1baec681d10a09d12d9-original.png',
          _id: '61fbd943870e399d83d5829d',
        },
        {
          name: 'Projector',
          icon: 'https://storage.googleapis.com/bamrec-f5107.appspot.com/images%2Famenity%2F6465b1bcec681d10a09d12e1-original.png',
          _id: '61fbd94bf8bfb2f9c386be94',
        },
        {
          name: 'Parking',
          icon: 'https://storage.googleapis.com/bamrec-f5107.appspot.com/images%2Famenity%2F6465b1bfec681d10a09d12e9-original.png',
          _id: '61fbd95264f086c6d1d2d0cf',
        },
        {
          name: 'Soccer',
          icon: 'https://storage.googleapis.com/bamrec-f5107.appspot.com/images%2Famenity%2F6465b1c2ec681d10a09d12f1-original.png',
          _id: '61fbd95a258a6ceffc43e9c0',
        },
        {
          name: 'Basketball',
          icon: 'https://storage.googleapis.com/bamrec-f5107.appspot.com/images%2Famenity%2F6465b1c4ec681d10a09d12f9-original.png',
          _id: '61fbd96834a2959f5d16bcd4',
        },
      ],
      organizerId: '66a87ea74712bdca12a79e3f',
      organizationId: '66a87efd4712bdca12a79e6b',
      status: 'active',
      createdAt: '2024-07-30T05:54:45.409Z',
      updatedAt: '2024-07-30T05:54:45.409Z',
      __v: 0,
      coordinates: {
        longitude: 71.46224800497293,
        latitude: 30.204392650399836,
      },
      id: '66a880254712bdca12a7a11d',
    },
    coverPhotos: [],
    requiredDocuments: [],
    organization: {
      social: {
        facebook: 'https://www.facebook.com/bamrecreation',
        insta: 'https://www.instagram.com/bamrecreation',
        twitter: '',
        youtube: '',
        linkedIn: '',
        snapchat: '',
        tiktok: '',
        bereal: '',
      },
      analytics: {
        reviewsCount: 0,
        averageRating: 0,
      },
      _id: '66a87efd4712bdca12a79e6b',
      photoId: '66a87efa4712bdca12a79e62',
      name: 'Organization two sixty',
      phone: {
        dialCode: '+1',
        isoCode: 'US',
        number: '3007313367',
      },
      isLandlinePhone: false,
      email: 'abubakarmuneer.tts+260@gmail.com',
      establishedOn: '2014-02-28T00:00:00.000Z',
      nonProfit: false,
      locationId: '66a87efd4712bdca12a79e6a',
      website: '',
      about: '',
      sameEmailForCommunication: true,
      mailingAddress: {
        location: {
          coordinates: [71.46224800497293, 30.204392650399836],
          type: 'Point',
        },
        title: 'Work',
        apartment: '',
        street: '6F36+MP4',
        city: 'Multan',
        state: 'Punjab',
        zip: '',
        country: 'PK',
        _id: '66ac88e7b0e379d41a89a341',
        coordinates: {
          longitude: 71.46224800497293,
          latitude: 30.204392650399836,
        },
        id: '66ac88e7b0e379d41a89a341',
      },
      status: 'approved',
      ownerId: '66a87ea74712bdca12a79e3f',
      createdAt: '2024-07-30T05:49:49.469Z',
      updatedAt: '2024-08-02T07:21:11.673Z',
      __v: 0,
      id: '66a87efd4712bdca12a79e6b',
    },
    id: '66b0b364d60d42c5c95fa1e6',
    requiredDetails: {
      guardians: [
        {
          title: 'Profile Picture',
          key: 'profilePhoto',
          value: false,
        },
        {
          title: 'Relationship',
          key: 'relation',
          value: false,
        },
        {
          title: 'Gender',
          key: 'gender',
          value: false,
        },
        {
          title: 'Date Of birth',
          key: 'dateOfBirth',
          value: false,
        },
        {
          title: 'Email',
          key: 'email',
          value: false,
        },
        {
          title: 'Phone Number',
          key: 'phoneNumber',
          value: false,
        },
        {
          title: 'Home Address',
          key: 'homeAddress',
          value: false,
        },
        {
          title: 'Allergies',
          key: 'allergies',
          value: false,
        },
        {
          title: 'Medical Notes',
          key: 'medicalNotes',
          value: false,
        },
      ],
      kids: [
        {
          title: 'Profile Picture',
          key: 'profilePhoto',
          value: false,
        },
        {
          title: 'Gender',
          key: 'gender',
          value: false,
        },
        {
          title: 'Date Of Birth',
          key: 'dateOfBirth',
          value: false,
        },
        {
          title: 'School',
          key: 'school',
          value: false,
        },
        {
          title: 'Grade',
          key: 'grade',
          value: false,
        },
        {
          title: 'Weight',
          key: 'weight',
          value: false,
        },
        {
          title: 'Height',
          key: 'height',
          value: false,
        },
        {
          title: 'Allergies',
          key: 'allergies',
          value: false,
        },
        {
          title: 'Medical Notes',
          key: 'medicalNotes',
          value: false,
        },
      ],
      adults: [
        {
          title: 'Profile Picture',
          key: 'profilePhoto',
          value: false,
        },
        {
          title: 'Relation',
          key: 'relation',
          value: false,
        },
        {
          title: 'Gender',
          key: 'gender',
          value: false,
        },
        {
          title: 'Date Of Birth',
          key: 'dateOfBirth',
          value: false,
        },
        {
          title: 'Email',
          key: 'email',
          value: false,
        },
        {
          title: 'Phone Number',
          key: 'phoneNumber',
          value: false,
        },
        {
          title: 'Home Address',
          key: 'homeAddress',
          value: false,
        },
        {
          title: 'Allergies',
          key: 'allergies',
          value: false,
        },
        {
          title: 'Medical Notes',
          key: 'medicalNotes',
          value: false,
        },
      ],
    },
  },
  setEventData: (state: any) => set(() => ({ eventData: state })),
  step5Payload: {},
  setStep5Payload: (state: any) => set(() => ({ step5Payload: state })),
})

export const creationEventStore = create(eventCreationStore)
