import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import closeIcon from 'assets/Images/close.svg'
import plusBlue from 'assets/Images/plusBlue.svg'
import { useGetOrgDocuments } from 'api/EventCreation/creationStep5'
import { creationEventStore } from 'state/eventCreationStore'
import Spinner from 'components/Spinner'
import { OrgDocumentResp } from 'types/eventStep5'
import SelectDocContainer from './SelectDocContainer'
import EmptyData from 'components/global/EmptyData'
import AddNewDoc from './AddNewDoc'

interface RequiredDocumentsProps {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
}

const RequiredDocumentsModal: React.FC<RequiredDocumentsProps> = ({
  openModal,
  closeModal,
}) => {
  const { organizationId } = creationEventStore(store => store.eventData)
  // todo change this to type later
  const step5Payload: any = creationEventStore(store => store.step5Payload)
  const setStep5Payload = creationEventStore(store => store.setStep5Payload)
  const [eventSelectedDocs, setEventSelectedDocs] = useState<string[]>(
    step5Payload.requiredDocumentIds || [],
  )
  const [openAddDoc, setOpenAddDoc] = useState<boolean>(false)

  const {
    data: orgDocuments,
    isLoading: orgDocumentsLoading,
    isFetching: orgDocumentFetching,
    refetch: orgDocumentRefetch,
  } = useGetOrgDocuments(organizationId)
  const handleCancel = () => {
    closeModal(false)
  }

  useEffect(() => {
    orgDocumentRefetch()
  }, [organizationId])

  const handleSubmitDoc = () => {
    setStep5Payload({ ...step5Payload, requiredDocumentIds: eventSelectedDocs })
    handleCancel()
  }

  return (
    <div>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        className="document-modal"
        footer={false}
        width={600}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Required details</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="mt-20px h-22rem overflow-auto-y scroller">
          <p>
            Select mandatory documents that should be uploaded on registration
            of the event. You also can add any document you need.
          </p>
          <div className="mt-10px">
            {orgDocumentsLoading || orgDocumentFetching ? (
              <div className="h-full flex justify-center align-center py-30px">
                <Spinner />
              </div>
            ) : (
              <div className="mt-10px">
                {orgDocuments.length > 0 ? (
                  orgDocuments.map((doc: OrgDocumentResp) => {
                    return (
                      <div key={doc._id}>
                        <SelectDocContainer
                          data={doc}
                          eventSelectedDocs={eventSelectedDocs}
                          setEventSelectedDocs={setEventSelectedDocs}
                        />
                      </div>
                    )
                  })
                ) : (
                  <div className="py-30px">
                    <EmptyData message="no docs" />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="mt-10px flex justify-between w-full">
          <button
            className="pointer flex align-center gap-16px simple-btn"
            onClick={() => setOpenAddDoc(true)}
          >
            <img src={plusBlue}></img> <p>Add new document</p>
          </button>
          <button
            className="confirm-btn"
            type="submit"
            onClick={handleSubmitDoc}
          >
            Save
          </button>
        </div>
      </Modal>

      {openAddDoc && (
        <AddNewDoc openModal={openAddDoc} closeModal={setOpenAddDoc} />
      )}
    </div>
  )
}

export default RequiredDocumentsModal
