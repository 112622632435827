import React, { useEffect, useState } from 'react'
import UploadDocument from 'components/global/UploadDocument'
import { Input } from 'antd'
import { creationEventStore } from 'state/eventCreationStore'

const CoverAndDescription = () => {
  const setStep5Payload = creationEventStore(store => store.setStep5Payload)
  const payload = creationEventStore(store => store.step5Payload)
  const [documentIds, setDocumentIds] = useState<string[]>([])
  const [description, setDescription] = useState<string | undefined>()

  useEffect(() => {
    setStep5Payload({
      ...payload,
      coverPhotosIds: documentIds,
      description: description,
    })
  }, [documentIds, description])

  return (
    <>
      <div>
        <UploadDocument
          documentIds={documentIds}
          setDocumentIds={setDocumentIds}
          multiple={true}
          docType="image/*"
        />
      </div>
      <div className={`${documentIds.length !== 0 ? 'mt-20px' : ''}`}>
        <label htmlFor="" className="custom-field-label">
          Description
        </label>

        <Input.TextArea
          className="note-field"
          placeholder="Few words about the event"
          autoSize={{ minRows: 5, maxRows: 10 }}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setDescription(e.target.value)
          }
        />
      </div>
    </>
  )
}

export default CoverAndDescription
