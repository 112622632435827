import React, { useState } from "react";
import { Button, Row, Col, Form, Input } from "antd";
import Modal from "antd/lib/modal/Modal";
import Location from "./Location/Location";

const RouteModal = ({
  setRouteModalVisible,
  setMapModalData,
  mapData,
  setFieldsValue,
}: any) => {
  const [form] = Form.useForm();
  const [current, setCurrent] = React.useState(0);
  const [data, setData] = useState(mapData || null);

  const formSchema = {
    amenityName: data?.amenityName ? data?.amenityName : "",
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const next = (childData: any) => {
    setCurrent(current + 1);
    setData({
      ...data,
      ...childData,
    });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onSubmit = (formData: any) => {
    setFieldsValue(formData);
    setMapModalData(formData);
    setRouteModalVisible(false);
  };

  const onFinish = (values: any) => {
    console.log("val:", values);
  };

  return (
    <div>
      <Modal
        title="Route to venue"
        visible={true}
        className="map-modal"
        footer={null}
        centered={true}
        width={500}
        onCancel={() => {
          setRouteModalVisible(false);
        }}
      >
        <Location />
        <Row className="mt-20">
          <Col span={24}>
            <span><b>How to find a venue?</b></span>
          </Col>
          <Col span={24}>
            <span>
                Here will be some text instruction how to find the venue... here
                will be some text instruction how to find the venue
            </span>
          </Col>
        </Row>
        <Row>
          <Button className="venue_button-events br-8">Select Venue</Button>
        </Row>
      </Modal>
    </div>
  );
};

export default RouteModal;
