import { useEffect, useState } from 'react'
import { Row, Col, Button, Tooltip, Space, Dropdown, Menu } from 'antd'
import AppLayout from 'components/layout/layout'
import { Tabs } from 'antd'
import printerImg from 'assets/Images/Printer.svg'
import downloadImg from 'assets/Images/Download.svg'
import statusimg from 'assets/Images/Activity.svg'
import deleteImg from 'assets/Images/delete_white.svg'
import LocationTable from './Table/LocationTable'
import { useQuery } from '@tanstack/react-query'
import { getApprovedLocations, getPendingLocations } from 'api/Location'
import { RequestParms } from 'types/globalTypes'
import { locationBody } from 'types/location'
import AddNewLocation from './NewLocation'
import Spinner from 'components/Spinner'
import { DownOutlined } from '@ant-design/icons'
import ExportLocation from './ExportLoaction'
const { TabPane } = Tabs
const Location = () => {
  const [selectedRows, setSelectedRows] = useState([])
  const [params, setParams] = useState<RequestParms>({
    page: 1,
    limit: 10,
  })
  const [locClick, setLocClic] = useState<boolean>(false)
  const {
    data,
    isLoading,
    refetch,
    isFetching: getFetching,
  } = useQuery(['getLocations'], {
    queryFn: () => getApprovedLocations(params),
    enabled: false,
  })
  const [active, setActive] = useState<string>('1')
  const {
    data: PendingLocation,
    isLoading: PendingLoading,
    refetch: PendingRefetch,
    isFetching,
  } = useQuery(['getPendingLocations'], {
    queryFn: () => getPendingLocations(params),
    enabled: false,
  })
  function callback(key: string) {
    if (key === '1') {
      setActive(key)
    }
    if (key === '2') {
      setActive(key)
    }
  }
  useEffect(() => {
    setParams(prev => ({ ...prev, page: 1 }))
  }, [active])
  useEffect(() => {
    active === '1' ? refetch() : active === '2' ? PendingRefetch() : <></>
  }, [params])
  const [isExportModalVisible, setExportModalVisible] = useState(false)

  const handleExportClick = () => {
    setExportModalVisible(true)
  }

  const handleModalClose = () => {
    setExportModalVisible(false)
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={handleExportClick}>
        <Space>
          <img alt="img" src={downloadImg} /> Export
        </Space>
      </Menu.Item>
    </Menu>
  )
  return (
    <AppLayout>
      <Row className="page-header" justify="space-between">
        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
          <h1 className="table-head mb-0">Location</h1>
        </Col>
        <Col lg={{ span: 18 }} offset={6} xs={{ span: 24, offset: 0 }}>
          {selectedRows.length > 0 ? (
            <Space
              className="organizations-table-onselect-btn"
              size={10}
              align="end"
            >
              <Tooltip placement="bottom" title="Status" color="blue">
                <Button
                  className={'bg-grey br-8'}
                  icon={
                    <img
                      alt="img"
                      src={statusimg}
                      style={{
                        padding: '1%',
                        height: '20px',
                        marginRight: '10px',
                      }}
                    ></img>
                  }
                  onClick={() => {}}
                >
                  Status
                </Button>
              </Tooltip>
              <Tooltip placement="bottom" title="Print" color="blue">
                <Button
                  className={'bg-grey br-8'}
                  onClick={() => {
                    window.print()
                  }}
                  icon={
                    <img
                      alt="img"
                      src={printerImg}
                      style={{
                        padding: '1%',
                        height: '20px',
                        marginRight: '10px',
                      }}
                    ></img>
                  }
                >
                  Print
                </Button>
              </Tooltip>
              <Tooltip placement="bottom" title="Export" color="blue">
                <Button
                  className={'bg-grey br-8'}
                  icon={<img alt="img" src={downloadImg}></img>}
                ></Button>
              </Tooltip>
              <Tooltip placement="bottom" title="Delete" color="blue">
                <Button
                  className={'bg-grey br-8'}
                  icon={
                    <img
                      alt="img"
                      src={deleteImg}
                      style={{
                        padding: '1%',
                        height: '20px',
                        marginRight: '10px',
                      }}
                    ></img>
                  }
                >
                  Delete
                </Button>
              </Tooltip>
            </Space>
          ) : (
            <div className="right-search-block">
              <div
                className="table-action-btn new-organizations-export-btn"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <Dropdown
                  overlay={menu}
                  placement="bottom"
                  className="bg-grey mx-20px"
                >
                  <button className=" prim-sm-btn" style={{ color: 'black' }}>
                    More <DownOutlined />
                  </button>
                </Dropdown>
                <Tooltip placement="bottom" title="Create">
                  <Button
                    className={'btn-new primary br-8'}
                    onClick={() => {
                      setLocClic(true)
                    }}
                  >
                    New Location
                  </Button>
                </Tooltip>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col style={{ width: '100%' }}>
          <Tabs className="orgn-tabs" defaultActiveKey="1" onChange={callback}>
            <TabPane tab="Approved" key="1">
              {isLoading || getFetching ? (
                <div className="h-[80vh] flex-center justify-center ">
                  <Spinner />
                </div>
              ) : (
                <Row style={{ width: '100%' }}>
                  <Col style={{ alignItems: 'center', width: '100%' }}>
                    <LocationTable
                      activeData={data}
                      setSelectedRows={setSelectedRows}
                      params={params}
                      setParms={setParams}
                      refetchData={refetch}
                    />
                  </Col>
                </Row>
              )}
            </TabPane>
            <TabPane tab={<span>Pending</span>} key="2">
              {PendingLoading || isFetching ? (
                <div className="h-[80vh] flex-center justify-center ">
                  <Spinner />
                </div>
              ) : (
                <Row style={{ width: '100%' }}>
                  <Col style={{ alignItems: 'center', width: '100%' }}>
                    <LocationTable
                      activeData={PendingLocation}
                      setSelectedRows={setSelectedRows}
                      setParms={setParams}
                      params={params}
                      refetchData={PendingRefetch}
                    />
                  </Col>
                </Row>
              )}
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <ExportLocation
        visible={isExportModalVisible}
        onClose={handleModalClose}
        data={active === '1' ? data?.records : PendingLocation?.records}
      />
      {locClick && <AddNewLocation setOpen={setLocClic} open={locClick} />}
    </AppLayout>
  )
}

export default Location
