import { Avatar, Dropdown, Table } from 'antd'
import usePagination from 'helper/usePagination'
import { convertDOBToAge, getNameSignature } from 'helper/utility'
import { useState } from 'react'
import { EvenetAttendie, EventAttendeeListing } from 'pages/Events/types'
import { RequestParms } from 'types/globalTypes'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { history } from '../../../../../Redux/app-store'
import ManageBtn from './ManageBtn'
import { convertToLocalWithAt } from 'helper/utility'

interface AttendieTableInterface {
  data: {
    count: number
    hasNextPage: boolean
    hasPrevPage: boolean
    page: number
    records: EventAttendeeListing[]
  }
  params: RequestParms
  setParams: React.Dispatch<React.SetStateAction<RequestParms>>
}
const AttendieTable = ({ data, params, setParams }: AttendieTableInterface) => {
  const [paginationInfo, onTableChange] = usePagination({
    totalCount: data?.count || 0,
    currentPage: params.page,
    setParams,
  })
  const [showMoreId, setShowMoreID] = useState<any>([])

  const newArray = data?.records?.map((item: EventAttendeeListing) => {
    // console.log(item,'item')
    const checkinDateDisplay =
      item.checkinDate === 'Not checked in yet'
        ? item.checkinDate
        : convertToLocalWithAt(item.checkinDate, 'DD MMM YYYY [at] h:mm A')
    return {
      key: item.id,
      familyName: item.familyName,
      attendee: item.attendee,
      tickets: item.tickets,
      checkinDate: checkinDateDisplay,
      action: [<ManageBtn id={item.id} attendieDetails={item.attendee} />],
    }
  })
  const rowSelection = {}

  const renderTicketNames = (text: string[], index: number) => {
    const showAllTickets = showMoreId.includes(index)

    return (
      <div key={`ticket-${index}`}>
        {text.map((ticket, i) => (
          <h2 key={`ticket-${index}-${i}`} className="text-14">
            {showAllTickets || i === 0 ? ticket : null}
          </h2>
        ))}
        {text.length > 1 && (
          <h5
            style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
            className="cursor_pointer align-items-center"
            onClick={() => {
              if (showAllTickets) {
                setShowMoreID(showMoreId.filter((id: any) => id !== index))
              } else {
                setShowMoreID([...showMoreId, index])
              }
            }}
          >
            {showAllTickets ? 'Hide' : 'Show More'}
            {showAllTickets ? <UpOutlined /> : <DownOutlined />}
          </h5>
        )}
      </div>
    )
  }
  // console.log(showMoreId, 'this is show more')
  const handleClick = (data: any) => {
    // console.log(data, 'data')
    if (data?.type === 'authorizedAdult') {
      history.push({
        pathname: `/adult-profile/${data?._id}`,
      })
    } else if (data?.type === 'guardian') {
      history.push({
        pathname: `/parent-profile/${data?._id}`,
      })
    } else if (data?.type === 'kid' && data?._id !== undefined) {
      history.push({
        pathname: `/kid-profile/${data?._id}`,
      })
    } else if (data?.type === 'authorized-adult' && data?._id !== undefined) {
      history.push({
        pathname: `/adult-profile/${data?._id}`,
      })
    }

    // if (data?.attendee?.type === 'authorizedAdult') {
    //   history.push({
    //     pathname: `/adult-profile/${data?.key}`,
    //   })
    // } else if (data?.attendee?.type === 'guardian') {
    //   history.push({
    //     pathname: `/parent-profile/${data?.key}`,
    //   })
    // } else if (data?.attendee?.type === 'kid' && data?.key !== undefined) {
    //   history.push({
    //     pathname: `/kid-profile/${data?.key}`,
    //   })
    // } else if (
    //   data?.attendee?.type === 'authorized-adult' &&
    //   data?.key !== undefined
    // ) {
    //   history.push({
    //     pathname: `/adult-profile/${data?.key}`,
    //   })
    // }
  }

  const columns = [
    {
      title: 'Family',
      dataIndex: 'familyName',
      key: 'familyName',
    },
    {
      title: 'Attendees',
      dataIndex: 'attendee',
      key: 'attendee',
      render: (text: EvenetAttendie, index: any) => (
        <div key={index} className="flex-center">
          <Avatar src={text?.photo?.thumb} size={32} alt={text.firstName}>
            {getNameSignature(text.firstName)}
          </Avatar>
          <h2
            className="text-14 flex ml-10px pointer"
            onClick={() => handleClick(text)}
            // onClick={() => handleClick(index)}
          >
            {text.firstName + ' ' + text.lastName + ' '}
            {
              <span className="grey-txt text-12 w-500 pl-5px">
                {'(' + convertDOBToAge(text.dob) + '.y.o)'}
              </span>
            }
          </h2>
        </div>
      ),
    },
    {
      title: 'Ticket name',
      dataIndex: 'tickets',
      key: 'tickets',
      render: (text: string[], record: any) => (
        <div key={record.key}>{renderTicketNames(text, record.key)}</div>
      ),
    },
    {
      title: 'Check-in date',
      dataIndex: 'checkinDate',
      key: 'checkinDate',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
    },
  ]
  return (
    <div className="mt-20px">
      <Table
        className="mentors-table organizations_table"
        style={{ width: '100%' }}
        columns={columns}
        rowSelection={rowSelection}
        dataSource={newArray}
        pagination={paginationInfo}
        scroll={{ x: 400 }}
        onChange={onTableChange}
      />
    </div>
  )
}
export default AttendieTable
