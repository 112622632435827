import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./action";
import { axiosGet, axiosPost, axiosPatch, axiosDelete } from '../axiosHelper'
import failureAction from "../Failure/action";

export function* getEventRequest({ params }) {
  try {
    let currentParams = {
      page: params?.current || 1,
      organizationId: params?.orgId,
      status: params?.status || null,
      limit: params?.pageSize || 5,
      sort: params?.sort?.order || "desc",
      categoryId: params?.id || null,
      populate: '[{"path":"photo"}]',
    };
    const { data } = yield axiosGet(`v1/organization-events`, currentParams);
    yield put(actions.getEventRequestSuccess(data));
  } catch (error) {
    yield put(actions.getEventRequestFailure());
    yield put(failureAction.failure(error));
  }
}

export function* getMentorEvents({payload}) {
  try {
    console.log('params', payload)
    const mentorId = payload?.mentorId
    let currentParams = {
      page: payload?.current || 1,
      organizationId: payload?.orgId,
      status: payload?.status || null,
      limit: payload?.pageSize || 5,
      sort: payload?.sort?.order || "desc",
      categoryId: payload?.id || null,
      populate: '[{"path":"photo"}]',
    };
    const { data } = yield axiosGet(`v1/mentors/${mentorId}/events`, currentParams);
    yield put(actions.getMentorEventsSuccess(data));
  } catch (error) {
    yield put(actions.getMentorEventsFailure());
    yield put(failureAction.failure(error));
  }
}


export function* getEventDetailRequest(payload) {
  try {
    const { data } = yield axiosGet(
      `v1/organization-events/${payload?.payload}`
    );
    yield put(actions.getEventDetailRequestSuccess(data));
  } catch (error) {
    yield put(actions.getEventDetailRequestFailure());
    yield put(failureAction.failure(error));
  }
}

export function* getEventOrganizationRequest({ eventId }) {
  try {
    const { data } = yield axiosGet(`v1/organization-events/${eventId}`);
    yield put(actions.getEventOrganizationRequestSuccess(data));
  } catch (error) {
    yield put(actions.getEventOrganizationRequestFailure());
    yield put(failureAction.failure(error));
  }
}

export function* getEventCategoriesRequest() {
  try {
    const { data } = yield axiosGet(`v1/lists/event-categories`);
    yield put(actions.getEventCategoriesSuccess(data));
  } catch (error) {
    yield put(actions.getEventCategoriesFailure());
    yield put(failureAction.failure(error));
  }
}

export function* getEventActivityCategoriesRequest() {
  try {
    const { data } = yield axiosGet(`v1/lists/activity-categories`);
    yield put(actions.getEventActivityCategoriesSuccess(data));
  } catch (error) {
    yield put(actions.getEventActivityCategoriesFailure());
    yield put(failureAction.failure(error));
  }
}


export function* getEventActivityGradesRequest() {
  try {

    const { data } = yield axiosGet(`v1/lists/grades`);
    yield put(actions.getEventActivityGradesSuccess(data));
  } catch (error) {
    yield put(actions.getEventActivityGradesFailure());
    yield put(failureAction.failure(error));
  }
}




export function* addEventRequest({ payload }) {
  try {
    const organizationId = payload.organizationId;
    const { data } = yield axiosPost(
      payload,
      `v1/organization-events/${organizationId}/event-step1`
    );
    yield put({
      type: actions.GET_EVENT_ORGANIZATION_REQUEST,
      eventId: data?._id,
    });
    yield put(actions.createEventSuccess(data));
  } catch (error) {
    yield put(actions.createEventFailure());
    yield put(failureAction.failure(error));
  }
}



export function* addMentorRequestInEvent({ payload }) {
  try {
    const { data } = yield axiosPost(
        payload,
        `v1/mentor-request/create-event-request1`
    );
    yield put(actions.createMentorRequestInEventSuccess(data));
  } catch (error) {
    yield put(actions.createMentorRequestInEventFailure());
    yield put(failureAction.failure(error));
  }
}

export function* addMentorShiftInEvent({ payload }) {
  try {
    const { data } = yield axiosPatch(
        payload,
        `v1/mentor-request/add-mentor-shift`
    );
    yield put(actions.createMentorShiftInEventSuccess(data));
  } catch (error) {
    yield put(actions.createMentorShiftInEventFailure());
    yield put(failureAction.failure(error));
  }
}



export function* editMentorRequestInEventRequest({ payload }) {
  try {

    const requestId = payload.requestId;
    const { data } = yield axiosPatch(
        payload,
        `v1/mentor-request/edit-event-request/${requestId}`
    );
    yield put(actions.editMentorRequestInEventSuccess(data));
  } catch (error) {
    yield put(actions.editMentorRequestInEventFailure());
    yield put(failureAction.failure(error));
  }
}

export function* updateMentorRequestStatusInEvent({ payload }) {
  try {

    const eventId = payload.eventId;
    const { data } = yield axiosPatch(
        payload,
        `v1/mentor-request/update-event-request-status/${eventId}`
    );
    yield put(actions.updateMentorRequestStatusInEventSuccess(data));
  } catch (error) {
    yield put(actions.updateMentorRequestStatusInEventFailure());
    yield put(failureAction.failure(error));
  }
}



export function* updateEventPostStatus({ payload }) {
  try {

    const eventId = payload.eventId;
    const { data } = yield axiosPatch(
        payload,
        `v1/organization-events/${eventId}/status`
    );
    yield put(actions.updateEventPostStatusSuccess(data));
  } catch (error) {
    yield put(actions.updateEventPostStatusFailure());
    yield put(failureAction.failure(error));
  }
}


export function* editEventRequest({ payload }) {
  try {
    const eventId = payload.eventId;
    const { data } = yield axiosPatch(
      payload,
      `v1/organization-events/${eventId}/event-step1`
    );
    yield put({
      type: actions.GET_EVENT_ORGANIZATION_REQUEST,
      eventId: payload.eventId,
    });
    yield put(actions.editEventSuccess(data));
  } catch (error) {
    yield put(actions.editEventFailure());
    yield put(failureAction.failure(error));
  }
}

export function* addScheduleActivitiesEventRequest({ payload }) {
  try {
    const eventId = payload?.activitiesSchedule?.eventId;
    const { data } = yield axiosPatch(
      payload,
      `v1/organization-events/${eventId}/event-step2`
    );
    yield put({
      type: actions.GET_EVENT_ORGANIZATION_REQUEST,
      eventId: payload?.activitiesSchedule?.eventId,
    });
    yield put(actions.createEventForStepTwoSuccess(data));
  } catch (error) {
    yield put(actions.createEventForStepTwoFailure());
    yield put(failureAction.failure(error));
  }
}

export function* addSuppliesEventRequest({ payload }) {
  try {
    const eventId = payload?.eventId;
    const { data } = yield axiosPatch(
      payload,
      `v1/organization-events/${eventId}/event-step3`
    );
    yield put(actions.createEventForStepThreeSuccess(data));
    yield put({
      type: actions.GET_EVENT_ORGANIZATION_REQUEST,
      eventId: payload?.eventId,
    });
  } catch (error) {
    yield put(actions.createEventForStepThreeFailure());
    yield put(failureAction.failure(error));
  }
}

export function* addAdmissionTicketEventRequest({ payload }) {
  try {
    const eventId = payload?.eventId,
      ticketId = payload?.ticketId,
      url = ticketId
        ? `v1/organization-events/${eventId}/event-step4-admission-ticket?ticketId=${ticketId}`
        : `v1/organization-events/${eventId}/event-step4-admission-ticket`;
    const { data } = yield axiosPatch(payload, url);
    yield put({
      type: actions.GET_EVENT_ORGANIZATION_REQUEST,
      eventId: payload?.eventId,
    });
    yield put(actions.createEventForStepFourAdmissionSuccess(data));
  } catch (error) {
    yield put(actions.createEventForStepFourAdmissionFailure());
    yield put(failureAction.failure(error));
  }
}

export function* addAdditionalTicketEventRequest({ payload }) {
  try {
    const eventId = payload?.eventId,
      ticketId = payload?.ticketId,
      url = ticketId
        ? `v1/organization-events/${eventId}/event-step4-additional-option?ticketId=${ticketId}`
        : `v1/organization-events/${eventId}/event-step4-additional-option`;
    const { data } = yield axiosPatch(payload, url);
    yield put({
      type: actions.GET_EVENT_ORGANIZATION_REQUEST,
      eventId: payload?.eventId,
    });
    yield put(actions.createEventForStepFourAdditionalSuccess(data));
  } catch (error) {
    yield put(actions.createEventForStepFourAdditionalFailure());
    yield put(failureAction.failure(error));
  }
}

export function* addDropInTicketEventRequest({ payload }) {
  try {
    const eventId = payload?.eventId,
      ticketId = payload?.ticketId,
      url = ticketId
        ? `v1/organization-events/${eventId}/event-step4-dropin-ticket?ticketId=${ticketId}`
        : `v1/organization-events/${eventId}/event-step4-dropin-ticket`;
    const { data } = yield axiosPatch(payload, url);
    yield put({
      type: actions.GET_EVENT_ORGANIZATION_REQUEST,
      eventId: payload?.eventId,
    });
    yield put(actions.createEventForStepFourDropInSuccess(data));
  } catch (error) {
    yield put(actions.createEventForStepFourDropInFailure());
    yield put(failureAction.failure(error));
  }
}

export function* addAdditionalEventRequest({ payload }) {
  try {
    const eventId = payload?.eventId;
    const { data } = yield axiosPatch(
      payload,
      `v1/organization-events/${eventId}/event-step5`
    );
    yield put(actions.createEventForStepFiveSuccess(data));
  } catch (error) {
    yield put(actions.createEventForStepFiveFailure());
    yield put(failureAction.failure(error));
  }
}

export function* addEventPaymentFrequencyRequest({ payload }) {
  try {
    const eventId = payload?.eventId;
    const { data } = yield axiosPatch(
      payload,
      `v1/organization-events/${eventId}/payment-frequency`
    );
    // yield put({
    //   type: actions.GET_EVENT_ORGANIZATION_REQUEST,
    //   eventId: payload?.eventId,
    // });
    yield put(actions.createEventPaymentFrequencySuccess(data));
  } catch (error) {
    yield put(actions.createEventPaymentFrequencyFailure());
    yield put(failureAction.failure(error));
  }
}

export function* deleteScheduledActivity({ payload }) {
  try {

    const { activityId, eventId } = payload
    console.log("hashim 1 ")

    let temAct =[];
    temAct.push(activityId);
    console.log("hashim 2 ", temAct)

    let temPayload = {}
    temPayload.activityIds = temAct
    console.log("hashim")
    const { data } = yield axiosPatch(temPayload,`v1/organization-events/${eventId}`);
    yield put(actions.deleteScheduledEventActivitySuccess(data));
    yield put({
      type: actions.GET_EVENT_ORGANIZATION_REQUEST,
      eventId: eventId,
    });
  } catch (error) {
    yield put(actions.deleteScheduledEventActivityFailure());
    yield put(failureAction.failure(error));
  }
}



export function* deleteAdmissionticket({ payload }) {
  try {
    const { ticketId, eventId } = payload

    const { data } = yield axiosPatch(payload,`v1/organization-events/${eventId}/delete-admission-ticket/${ticketId}`);
    yield put(actions.deleteAdmissionTicketSuccess(data));
    yield put({
      type: actions.GET_EVENT_ORGANIZATION_REQUEST,
      eventId: eventId,
    });
  } catch (error) {
    yield put(actions.deleteAdmissionTicketFailure());
    yield put(failureAction.failure(error));
  }
}




export function* deleteDropInTicket({ payload }) {
  try {
    console.log("here in delete drop in : ")
    const {eventId } = payload
    const { data } = yield axiosPatch(payload,`v1/organization-events/${eventId}/delete-dropin-ticket`);
    yield put(actions.deleteDropInTicketSuccess(data));
    yield put({
      type: actions.GET_EVENT_ORGANIZATION_REQUEST,
      eventId: eventId,
    });
  } catch (error) {
    yield put(actions.deleteDropInTicketFailure());
    yield put(failureAction.failure(error));
  }
}



export function* deleteAdditionalOptionTicket({ payload }) {
  try {

    const { ticketId, eventId } = payload
    const { data } = yield axiosPatch(payload,`v1/organization-events/${eventId}/delete-additional-ticket/${ticketId}`);

    yield put(actions.deleteAdditionalOptionTicketSuccess(data));
    yield put({
      type: actions.GET_EVENT_ORGANIZATION_REQUEST,
      eventId: eventId,
    });
  } catch (error) {
    yield put(actions.deleteAdditionalOptionTicketFailure());
    yield put(failureAction.failure(error));
  }
}






export function* duplicateAdmissionticket({ payload }) {
  try {
    const { ticketId, eventId } = payload

    const { data } = yield axiosPost(payload,`v1/organization-events/${eventId}/admission-tickets/${ticketId}/duplicate`);
    yield put(actions.duplicateAdmissionTicketSuccess(data));
    yield put({
      type: actions.GET_EVENT_ORGANIZATION_REQUEST,
      eventId: eventId,
    });
  } catch (error) {
    yield put(actions.duplicateAdmissionTicketFailure());
    yield put(failureAction.failure(error));
  }
}



export function* duplicateAdditionalOptionTicket({ payload }) {
  try {
    const { ticketId, eventId } = payload



    const { data } = yield axiosPost(payload,`v1/organization-events/${eventId}/additional-options/${ticketId}/duplicate`);
    yield put(actions.duplicateAdditionalOptionTicketSuccess(data));
    yield put({
      type: actions.GET_EVENT_ORGANIZATION_REQUEST,
      eventId: eventId,
    });
  } catch (error) {
    yield put(actions.duplicateAdditionalOptionTicketFailure());
    yield put(failureAction.failure(error));
  }
}





export function* getEventDefaultActivitiesRequest({params}) {

  try {
    console.log("params", params)
    let currentParams = {
      defaultActivities: params?.isDefault,
    };
    const { data } = yield axiosGet(`v1/event-activities/${params?.orgId}`, currentParams);
    yield put(actions.getEventDefaultActivitiesSuccess(data));
  } catch (error) {
    yield put(actions.getEventDefaultActivitiesFailure());
    yield put(failureAction.failure(error));
  }
}

export function* createEventSpecificActivity({ payload }) {
  try {
    console.log("payload to check ", payload)
    const organizationId = payload.organizationId;
    const { data } = yield axiosPost(
        payload,
        `v1/event-activities/${organizationId}/event-activity`
    );
    yield put(actions.createEventSpecificActivitySuccess(data));
  } catch (error) {
    yield put(actions.createEventSpecificActivityFailure());
    yield put(failureAction.failure(error));
  }
}

export function* editEventSpecificActivity({ payload }) {
  try {

    const activityId = payload.activityId;
    const { data } = yield axiosPatch(
        payload,
        `v1/event-activities/${activityId}`
    );
    yield put(actions.editEventSpecificActivitySuccess(data));
  } catch (error) {
    yield put(actions.editEventSpecificActivityFailure());
    yield put(failureAction.failure(error));
  }
}

export function* addAdmissionTicketInStepFourRequest({ payload }) {
  try {
    const eventId = payload?.eventId,
        ticketId = payload?.ticketId,
        url = ticketId
            ? `v1/organization-events/${eventId}/event-step4-admission-ticket?ticketId=${ticketId}`
            : `v1/organization-events/${eventId}/event-step4-admission-ticket`;
    const { data } = yield axiosPatch(payload, url);
    yield put({
      type: actions.GET_EVENT_ORGANIZATION_REQUEST,
      eventId: payload?.eventId,
    });
    yield put(actions.createEventForStepFourAdmissionSuccess(data));
  } catch (error) {
    yield put(actions.createEventForStepFourAdmissionFailure());
    yield put(failureAction.failure(error));
  }
}

export function* getOrganizationLocations({ payload }) {
  try {
    console.log("saga : ", payload);
    const { data } = yield axiosGet(`v1/location/organization-locations/${payload?.orgId}`);
    yield put(actions.getOrganizationLocationsSuccess(data));
  } catch (error) {
    yield put(actions.getOrganizationLocationsFailure());
    yield put(failureAction.failure(error));
  }
}


export function* getApprovedLocationsRequest({ params, searchQuery, payload }) {
  try {
    console.log("receiving params :" , params)
    // let currentParams = {
    //   page: params?.page || 1,
    //   limit: params?.limit || 10,
    //   sort: params?.sort || "name",
    //   // sort: params?.sort?.field || "organizationName",
    //   status: payload?.status || null,
    //   queryString: params?.queryString || "",
    //   populate: '[{"path":"photo"}, {"path":"owner","populate":"photo"}]',
    // };
    const { data } = yield axiosGet(`v1/location/approved`);
    yield put(actions.getApprovedLocationsSuccess(data));
  } catch (error) {
    yield put(actions.getApprovedLocationsFailure());
    yield put(failureAction.failure(error));
  }
}


export function* getEventOrganizationFaqs({payload}) {
  try {
    console.log('params', payload)
    const { data } = yield axiosGet(`v1/event-questions/organization/${payload}`);
    console.log("data in saga : ", data);
    yield put(actions.getEventOrganizationFaqsSuccess(data));
  } catch (error) {
    yield put(actions.getEventOrganizationFaqsFailure());
    yield put(failureAction.failure(error));
  }
}



export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_EVENT_REQUEST, getEventRequest),
    takeLatest(actions.GET_MENTOR_EVENTS, getMentorEvents),
    takeLatest(actions.GET_EVENT_DETAILS_REQUEST, getEventDetailRequest),
    takeLatest(
      actions.GET_EVENT_ORGANIZATION_REQUEST,
      getEventOrganizationRequest
    ),
    takeLatest(actions.GET_EVENT_CATEGORIES, getEventCategoriesRequest),
    takeLatest(actions.GET_EVENT_DEFAULT_ACTIVITIES, getEventDefaultActivitiesRequest),

    takeLatest(actions.ADD_EVENTS_DATA, addEventRequest),
    takeLatest(actions.ADD_MENTOR_REQUEST_DATA_IN_EVENT, addMentorRequestInEvent),

    takeLatest(actions.EDIT_EVENTS_DATA, editEventRequest),
    takeLatest(
      actions.ADD_EVENTS_DATA_STEP_TWO,
      addScheduleActivitiesEventRequest
    ),
    takeLatest(actions.ADD_EVENTS_DATA_STEP_THREE, addSuppliesEventRequest),
    takeLatest(
      actions.ADD_EVENTS_DATA_STEP_FOUR_ADMISSION,
      addAdmissionTicketEventRequest
    ),
    takeLatest(
      actions.ADD_EVENTS_DATA_STEP_FOUR_ADDITIONAL,
      addAdditionalTicketEventRequest
    ),
    takeLatest(
      actions.ADD_EVENTS_DATA_STEP_FOUR_DROPIN,
      addDropInTicketEventRequest
    ),
    takeLatest(actions.ADD_EVENTS_DATA_STEP_FIVE, addAdditionalEventRequest),
    takeLatest(
      actions.ADD_EVENTS_PAYMENT_FREQUENCY_DATA,
      addEventPaymentFrequencyRequest
    ),
    takeLatest(
      actions.DELETE_SCHEDULED_EVENT_ACTIVITY,
      deleteScheduledActivity
    ),
    takeLatest(
      actions.CREATE_EVENT_SPECIFIC_ACTIVITY,
      createEventSpecificActivity
      ),
    takeLatest(
        actions.EDIT_EVENT_SPECIFIC_ACTIVITY,
        editEventSpecificActivity
    ),
    takeLatest(
        actions.ADD_ADMISSION_TICKET_EVENTS_STEP_FOUR,
        addAdmissionTicketInStepFourRequest
    ),
    takeLatest(
        actions.GET_ORGANIZATION_LOCATIONS,
        getOrganizationLocations
    ),
    takeLatest(
        actions.GET_APPROVED_LOCATIONS,
        getApprovedLocationsRequest
    ),
    takeLatest(
        actions.GET_EVENT_ORGANIZATION_FAQS,
        getEventOrganizationFaqs
    ),
    takeLatest(
        actions.ADD_MENTOR_SHIFT_DATA_IN_EVENT,
        addMentorShiftInEvent
    ),
    takeLatest(
        actions.EDIT_MENTOR_REQUEST_DATA_IN_EVENT,
        editMentorRequestInEventRequest
    ),
    takeLatest(
        actions.UPDATE_MENTOR_REQUEST_STATUS_IN_EVENT,
        updateMentorRequestStatusInEvent,
    )
    ,
    takeLatest(
        actions.UPDATE_EVENT_POST_STATUS,
        updateEventPostStatus,
    ),
    takeLatest(
        actions.DELETE_ADMISSION_TICKET,
        deleteAdmissionticket
    ),
    takeLatest(
        actions.DUPLICATE_ADMISSION_TICKET,
        duplicateAdmissionticket
    ),
    takeLatest(
        actions.DELETE_DROP_IN_TICKET,
        deleteDropInTicket
    ),
    takeLatest(
        actions.DELETE_ADDITIONAL_OPTION_TICKET,
        deleteAdditionalOptionTicket
    ),
    takeLatest(
        actions.DUPLICATE_ADDITIONAL_OPTION_TICKET,
        duplicateAdditionalOptionTicket
    ),
    takeLatest(
        actions.GET_EVENT_ACTIVITY_CATEGORIES,
        getEventActivityCategoriesRequest
    ),
    takeLatest(
        actions.GET_EVENT_ACTIVITY_GRADES,
        getEventActivityGradesRequest
    ),




  ]);
}
