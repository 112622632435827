import React, { useState } from 'react'
import { Button, Dropdown, Space } from 'antd'
import { Row } from 'antd'
import { Table } from 'antd'
import moment from 'moment'
import deleteBlackIcon from '../../../assets/Images/deleteBlackIcon.svg'
import type { ColumnsType, SorterResult } from 'antd/es/table/interface'
import AlertModal from '../../../helper/AlertModal'
import SearchBar from '../../../helper/SearchBar'
import { LocationsDataType } from 'types/location'
import usePagination from 'helper/usePagination'
import activeImg from 'assets/Images/activeGreen.png'
import pendingImg from 'assets/Images/pending.svg'
import { DownOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { approveLocation, rejectLocation } from 'api/Location'
import { successMessage } from 'api/global'

const LocationTable = ({
  activeData,
  setSelectedRows,
  setParms,
  params,
  refetchData,
}: any) => {
  const [paginationInfo, onTableChange] = usePagination({
    totalCount: activeData?.count || 0,
    currentPage: params.page,
    setParams: setParms,
  })
  const [visible, setVisible] = useState(false)
  const [alertModal, setAlertModal] = useState(false)
  const [hiddenColumns, setHiddenColumns] = useState<any>({
    locationTitle: true,
    street: true,
    apartment: true,
    city: true,
    status: true,
    action: true,
  })
  const [currentData, setCurrentData] = useState<any>()
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({})

  const alertModalData = {
    heading: 'Are you sure want to delete this ?  ',
    message1: 'Location',
  }

  const StatusMenu = [
    {
      key: 'active',
      label: (
        <div>
          <img alt="img" src={activeImg}></img> Active
        </div>
      ),
    },
    {
      key: 'pending',
      label: (
        <div>
          <img alt="img" src={pendingImg}></img> Pending
        </div>
      ),
    },
  ]

  const makeActive = useMutation({
    mutationFn: async ({ locationId, location }: any) => {
      return await approveLocation(locationId, location)
    },
    onSuccess: () => {
      successMessage('Approved Location Successfully')
      refetchData()
    },
  })
  const makePending = useMutation({
    mutationFn: async (locationId: string) => {
      return await rejectLocation(locationId)
    },
    onSuccess: () => {
      successMessage('Location moved to pending')

      refetchData()
    },
  })
  const handleMenuClick = ({ key }: any) => {
    if (key === 'active') {
      delete currentData.action
      currentData &&
        makeActive.mutateAsync({
          locationId: currentData.key,
          location: currentData,
        })
    }
    if (key === 'pending') {
      currentData && makePending.mutateAsync(currentData.key)
    }
  }
  const LocationsData = activeData?.records?.map((item: LocationsDataType) => {
    return {
      key: item._id,
      locationTitle: item.title,
      street: item.street,
      apartment: item.apartment,
      city: item.city,
      approvedAt: moment(item.approvedAt).format('MMM/DD/YYYY'),
      status: item.status,
      state: item.state,
      zip: item.zip,
      type: 'admin',
      country: item.country,
      coordinates: item.coordinates,
      title: item.title,
      action: (
        <Space className="orgn-action-btn">
          <img
            src={deleteBlackIcon}
            onClick={e => {
              setAlertModal(true)
            }}
            className="cursor_pointer"
          />
        </Space>
      ),
    }
  })
  const columnss: ColumnsType<any> = [
    {
      title: 'Location Name',
      dataIndex: 'locationTitle',
      key: 'locationTitle',
      render: (text, record: any, index) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            // history.push({
            //   pathname: `/organization-profile/${record.key}`,
            //   state: JSON.stringify(record.key),
            // });
          }}
        >
          <Space size={8}>{text}</Space>
        </div>
      ),
      sorter: (a: any, b: any) =>
        a.locationTitle.length - b.locationTitle.length,
      sortOrder:
        sortedInfo.columnKey === 'locationTitle' ? sortedInfo.order : null,
      className: hiddenColumns.locationTitle === false ? 'display-none' : '',
    },
    {
      title: 'Street',
      dataIndex: 'street',
      key: 'street',
      render: (text, record: any, index) => <Space size={8}>{text}</Space>,
      sorter: (a: any, b: any) => a.street.length - b.street.length,
      sortOrder: sortedInfo.columnKey === 'street' ? sortedInfo.order : null,
      className: hiddenColumns.street === false ? 'display-none' : '',
    },
    {
      title: 'Apartment/unit',
      dataIndex: 'apartment',
      key: 'apartment',
      sorter: (a: any, b: any) => a.apartment.length - b.apartment.length,
      sortOrder: sortedInfo.columnKey === 'apartment' ? sortedInfo.order : null,
      className: hiddenColumns.apartment === false ? 'display-none' : '',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      sorter: (a: any, b: any) => a.city.length - b.city.length,
      sortOrder: sortedInfo.columnKey === 'city' ? sortedInfo.order : null,
      className: hiddenColumns.city === false ? 'display-none' : '',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a: any, b: any) => a.status.length - b.status.length,
      sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
      className: hiddenColumns.status === false ? 'display-none' : '',
      render: (text, record: any) => (
        <Dropdown
          menu={{
            items: StatusMenu,
            onClick: handleMenuClick,
          }}
        >
          <Button
            className="status_button"
            onMouseEnter={() => {
              setCurrentData(record)
            }}
          >
            <Space>
              <img
                alt="img"
                src={
                  text === 'approved'
                    ? activeImg
                    : text === 'pending'
                    ? pendingImg
                    : ''
                }
              ></img>
              {text === 'pending'
                ? 'Pending'
                : text === 'approved'
                ? 'Active'
                : ''}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      sorter: false,
      sortOrder: sortedInfo.columnKey === 'action' ? sortedInfo.order : null,
      className: hiddenColumns.action === false ? 'display-none' : '',
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows)
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  }
  const onChangeColumnCheckBox = (e: any) => {
    setHiddenColumns({
      ...hiddenColumns,
      [e.target.name]: e.target.checked,
    })
  }

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }
  const handleChangeSortName = (value: string) => {
    setSortedInfo({
      order: 'descend',
      columnKey: value,
    })
  }

  return (
    <div>
      <Space
        size={20}
        align="center"
        className="mentors-table-header"
        style={{ width: '100%' }}
      >
        <div className="search-box">
          <SearchBar
            currentTab={1}
            data={activeData}
            dataSource={activeData}
            searchKey="name"
          />
        </div>
      </Space>
      <Row className="container">
        <Table
          className="organizations_table"
          rowSelection={rowSelection}
          style={{ width: '100%' }}
          columns={columnss}
          dataSource={LocationsData}
          rowKey="key"
          pagination={paginationInfo}
          scroll={{ x: 400 }}
          onChange={onTableChange}
        />
      </Row>
      {alertModal && (
        <AlertModal
          isModalVisible={alertModal}
          setIsModalVisible={setAlertModal}
          data={alertModalData}
        />
      )}
    </div>
  )
}
export default LocationTable
