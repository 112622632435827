import React, { useState, useEffect } from 'react'
import { Form, Col, Row, Input, Select, Avatar, Radio } from 'antd'
import DocUpload from 'pages/Family/Profiles/NotesComp/Components/Modal/DocUpload'
import {
  useGetActivityHighlightedElem,
  useGetActivityGoals,
  useGetActivityEnergyLevels,
  useGetActivityCategories,
  useCustomActivityStep1,
} from 'api/EventCreation/CreationStep2'
import { ActivityListType, ActivityStep1Payload } from 'types/events'
import { creationEventStore } from 'state/eventCreationStore'
import Loader from 'helper/Loader'
import ButtonSpinner from 'components/global/ButtonSpinner'
const { Option } = Select

interface Step1Props {
  handleBack: () => void
  handleNext: () => void
}

const Step1: React.FC<Step1Props> = ({ handleBack, handleNext }) => {
  const [form] = Form.useForm()
  const { organizationId } = creationEventStore(store => store.eventData)
  const setCustomActivity = creationEventStore(store => store.setCustomActivity)
  const { mutate: createActivityStep1, isLoading: createActivityStep1Loading } =
    useCustomActivityStep1()
  const [loading, setLoading] = useState<boolean>(true)
  const [documentIds, setDocumentIds] = useState<string[]>([])

  const formSchema = {}

  const onFinish = (values: ActivityStep1Payload) => {
    const payload = {
      name: values.name,
      categoryIds: values.categoryIds,
      highlightedElementId: values.highlightedElementId,
      goalId: values.goalId,
      energyLevelId: values.energyLevelId,
      environment: values.environment,
      time: values.time,
      plan: values.plan,
      galleryIds: documentIds,
    }
    if (organizationId) {
      createActivityStep1(
        { payload, orgId: organizationId },
        {
          onSuccess: data => {
            setCustomActivity(data)
            handleNext()
          },
        },
      )
    }
  }
  const {
    data: activityCategories,
    isLoading: activityLoading,
    isFetching: activityFetching,
    refetch: activityCategoryrefetch,
  } = useGetActivityCategories()
  const {
    data: activityEnergyLevels,
    isLoading: energylevelLoading,
    isFetching: energyLevelFetching,
    refetch: energyLevelRefetch,
  } = useGetActivityEnergyLevels()
  const {
    data: activityGoals,
    isLoading: activityGoalsLoading,
    isFetching: activityGoalsFetching,
    refetch: activityGoalsRefetch,
  } = useGetActivityGoals()
  const {
    data: activityHighlightElem,
    isLoading: activityHighlightElemLoading,
    isFetching: activityHighlightElemFetching,
    refetch: activityHighlightElemRefetch,
  } = useGetActivityHighlightedElem()

  const handleCancel = () => {}

  useEffect(() => {
    activityCategoryrefetch()
    energyLevelRefetch()
    activityGoalsRefetch()
    activityHighlightElemRefetch()
  }, [])

  useEffect(() => {
    setLoading(
      activityLoading ||
        activityFetching ||
        energylevelLoading ||
        energyLevelFetching ||
        activityGoalsLoading ||
        activityGoalsFetching ||
        activityHighlightElemLoading ||
        activityHighlightElemFetching,
    )
  }, [
    activityLoading ||
      activityFetching ||
      energylevelLoading ||
      energyLevelFetching ||
      activityGoalsLoading ||
      activityGoalsFetching ||
      activityHighlightElemLoading ||
      activityHighlightElemFetching,
  ])

  return (
    <div>
      {loading ? (
        <div className="py-30px ">
          <Loader />
        </div>
      ) : (
        <Form
          name="step1"
          initialValues={formSchema}
          form={form}
          onFinish={onFinish}
          layout={'vertical'}
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <label htmlFor="" className="input-label required-input">
                Activity name
              </label>
              <Form.Item
                colon={false}
                name="name"
                className="form-control"
                rules={[{ required: true, message: 'Name is required' }]}
              >
                <Input
                  placeholder="Activity name"
                  name="name"
                  className="form-control-field"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <label className="input-label required-input">Category</label>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item
                className="form-control"
                name="categoryIds"
                rules={[{ required: true, message: 'Category is required' }]}
              >
                <Select
                  mode={'multiple'}
                  className="form-control-field"
                  placeholder="Select"
                >
                  {activityCategories.length > 0 &&
                    activityCategories.map((item: ActivityListType) => (
                      <Option
                        value={item._id}
                        key={item._id}
                        name={item.name}
                        _id={item._id}
                      >
                        {
                          <Avatar size={18} src={item.icon} className="mr-5">
                            {item.name}
                          </Avatar>
                        }{' '}
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <label className="input-label required-input">
                Highlighted element
              </label>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item
                className="form-control"
                name="highlightedElementId"
                rules={[
                  {
                    required: true,
                    message: 'Highlighted element is required',
                  },
                ]}
              >
                <Select className="form-control-field" placeholder="Select">
                  {activityHighlightElem.length > 0 &&
                    activityHighlightElem.map((item: ActivityListType) => (
                      <Option
                        value={item._id}
                        key={item._id}
                        name={item.name}
                        _id={item._id}
                      >
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <label className="input-label required-input">Goals</label>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item
                className="form-control"
                name="goalId"
                rules={[{ required: true, message: 'Goal is required' }]}
              >
                <Select className="form-control-field" placeholder="Select">
                  {activityGoals.length > 0 &&
                    activityGoals.map((item: ActivityListType) => (
                      <Option
                        value={item._id}
                        key={item._id}
                        name={item.name}
                        _id={item._id}
                      >
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <label className="input-label required-input">
                Energy levels
              </label>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item
                className="form-control"
                name="energyLevelId"
                rules={[
                  { required: true, message: 'Energy level is required' },
                ]}
              >
                <Select className="form-control-field" placeholder="Select">
                  {activityEnergyLevels.length > 0 &&
                    activityEnergyLevels.map((item: ActivityListType) => (
                      <Option
                        value={item._id}
                        key={item._id}
                        name={item.name}
                        _id={item._id}
                      >
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <label className="input-label required-input">
                Preferred Environment
              </label>
            </Col>
            <Col>
              <Form.Item
                className="form-control"
                name="environment"
                rules={[
                  {
                    required: true,
                    message: 'Preferred enviornment is required',
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="indoors">Indoor</Radio>
                  <Radio value="outdoors">Outdoor</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <label className="input-label required-input">Time needed</label>
            </Col>
            <Col>
              <Form.Item
                className="form-control"
                name="time"
                rules={[
                  {
                    required: true,
                    message: 'Preferred enviornment is required',
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="30 mins">30 mins</Radio>
                  <Radio value="45 mins">45 mins</Radio>
                  <Radio value="other">Other</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <label className="input-label required-input">
                Plan/Description
              </label>
            </Col>
            <Col>
              <Form.Item
                className="form-control"
                name="plan"
                rules={[
                  {
                    required: true,
                    message: 'Description is required',
                  },
                ]}
              >
                <Input.TextArea
                  className="form-control-field"
                  placeholder="Short description of the activity"
                  style={{ height: '90px' }}
                ></Input.TextArea>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="custom-field-label">Add image</label>
            </Col>
            <Col span={16}>
              <DocUpload
                documentIds={documentIds}
                setDocumentIds={setDocumentIds}
              />
            </Col>
          </Row>

          <div className="mt-10px flex align-center justify-between">
            <button className="create-activity-btn" onClick={handleBack}>
              Cancel
            </button>
            <button
              className="confirm-btn"
              type="submit"
              // onClick={handleSubmit}
            >
              {createActivityStep1Loading ? (
                <ButtonSpinner color="#000" size={20} />
              ) : (
                'Next'
              )}
            </button>
          </div>
        </Form>
      )}
    </div>
  )
}

export default Step1
