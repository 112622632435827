import React from 'react'
import { Form, Col, Row, Input, Select, InputNumber } from 'antd'
import { Address } from 'types/globalTypes'
import { InventoryLocationPayload } from 'types/events'
import { useAddInventoryStorageLoc } from 'api/EventCreation/CreationStep2'
import { creationEventStore } from 'state/eventCreationStore'
import ButtonSpinner from 'components/global/ButtonSpinner'
const { Option } = Select
interface StorageLocationFormInterface {
  handleNext: () => void
  handleBack: () => void
  mapData: Address
}

const StorageLocationForm: React.FC<StorageLocationFormInterface> = ({
  handleNext,
  handleBack,
  mapData,
}) => {
  const [form] = Form.useForm()
  const { organizationId } = creationEventStore(store => store.eventData)
  const setStorageLocation = creationEventStore(
    store => store.setStorageLocation,
  )
  const { mutate: addStorageLocation, isLoading: addStorageLoading } =
    useAddInventoryStorageLoc()
  const formSchema = {
    street: mapData.street || '',
    city: mapData.city,
    state: mapData.state || '',
    zip: mapData.zip || '',
  }

  const unitValues = [
    {
      _id: 1,
      name: 'box',
    },
    {
      _id: 2,
      name: 'bag',
    },
    {
      _id: 3,
      name: 'unit',
    },
  ]

  const onFinish = (values: InventoryLocationPayload) => {
    const payload = {
      ...values,
      coordinates: {
        latitude: mapData.coordinates.latitude,
        longitude: mapData.coordinates.longitude,
      },
      country: mapData.country,
    }

    if (organizationId) {
      addStorageLocation(
        { payload, organizationId },
        {
          onSuccess: data => {
            setStorageLocation(data)
            handleNext()
          },
        },
      )
    }
  }
  return (
    <div className="mt-20px">
      <Form
        name="add-inventory-loc"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
        autoComplete="off"
      >
        <Row gutter={20}>
          <Col span={12}>
            <label htmlFor="" className="input-label required-input">
              Quantity
            </label>
            <Form.Item
              colon={false}
              name="quantity"
              className="form-control"
              rules={[{ required: true, message: 'Quantity is required' }]}
            >
              <Input
                type="number"
                min={0}
                placeholder="Activity name"
                name="quantity"
                className="form-control-field"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Row>
              <Col>
                <label className="input-label required-input">Unit</label>
              </Col>
              <Col style={{ width: '100%' }}>
                <Form.Item
                  className="form-control"
                  name="unit"
                  rules={[{ required: true, message: 'Unit is required' }]}
                >
                  <Select className="form-control-field" placeholder="Select">
                    {unitValues.map(item => (
                      <Option
                        value={item.name}
                        key={item._id}
                        name={item.name}
                        _id={item._id}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label htmlFor="" className="input-label required-input">
              Name of the location
            </label>
            <Form.Item
              colon={false}
              name="title"
              className="form-control"
              rules={[{ required: true, message: 'Location name is required' }]}
            >
              <Input
                placeholder="Enter storage location name"
                name="title"
                className="form-control-field"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label htmlFor="" className="input-label required-input">
              Street
            </label>
            <Form.Item
              colon={false}
              name="street"
              className="form-control"
              rules={[
                { required: true, message: 'Location street is required' },
              ]}
            >
              <Input name="street" className="form-control-field" disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={12}>
            <label htmlFor="" className="input-label">
              Building
            </label>
            <Form.Item colon={false} name="building" className="form-control">
              <Input
                name="building"
                className="form-control-field"
                placeholder="Building"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <label htmlFor="" className="input-label">
              Room
            </label>
            <Form.Item colon={false} name="room" className="form-control">
              <Input
                name="room"
                className="form-control-field"
                placeholder="Room"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={12}>
            <label htmlFor="" className="input-label">
              Shelf
            </label>
            <Form.Item colon={false} name="shelf" className="form-control">
              <Input
                name="shelf"
                className="form-control-field"
                placeholder="Shelf"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <label htmlFor="" className="input-label">
              Box number
            </label>
            <Form.Item colon={false} name="box" className="form-control">
              <Input
                name="box"
                className="form-control-field"
                placeholder="Building"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label htmlFor="" className="input-label required-input">
              City
            </label>
            <Form.Item
              colon={false}
              name="city"
              className="form-control"
              rules={[
                { required: true, message: 'Location street is required' },
              ]}
            >
              <Input name="city" className="form-control-field" disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={12}>
            <label htmlFor="" className="input-label">
              State
            </label>
            <Form.Item colon={false} name="state" className="form-control">
              <Input
                name="state"
                className="form-control-field"
                placeholder="State"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <label htmlFor="" className="input-label">
              Zip
            </label>
            <Form.Item colon={false} name="zip" className="form-control">
              <Input
                name="zip"
                className="form-control-field"
                placeholder="Zip"
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="mt-10px flex align-center justify-between">
          <button className="create-activity-btn" onClick={handleBack}>
            Cancel
          </button>
          <button className="confirm-btn" type="submit">
            {addStorageLoading ? (
              <ButtonSpinner size={20} color="#000" />
            ) : (
              'Save'
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

export default StorageLocationForm
